import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import { authenticationService } from 'services/authentication.service.js';
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function NetworkAppeal(props) {
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [errors, setError] = useState({});
    const [networkID, SetNetworkID] = useState('');
    const [organization, SetOrganization] = useState('');
    const [netHouseNo, SetNetHouseNo] = useState('');
    const [provinceCode, SetProvinceCode] = useState(0);
    const [districtCode, SetDistrictCode] = useState(0);
    const [subDistricCode, SetSubDistricCode] = useState(0);
    const [postCode, SetPostCode] = useState('');
    const [phone, SetPhone] = useState('');
    const [fax, SetFax] = useState('');
    const [coTitleID, SetCoTitleID] = useState(0);
    const [coFirstName, SetCoFirstName] = useState('');
    const [coLastName, SetCoLastName] = useState('');
    const [coPosition, SetCoPosition] = useState('');
    const [coDepartment, SetCoDepartment] = useState('');

    const [titleList, SetTitleList] = useState([]);
    const [coOtherTitle, SetCoOtherTitle] = useState('');
    const [provinceList, SetProvinceList] = useState([]);
    const [allDistrictList, SetAllDistrictList] = useState([]);
    const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
    const [districtList, SetDistrictList] = useState([]);
    const [subDistrictList, SetSubDistrictList] = useState([]);

    const [documentID, SetDocumentID] = useState('');
    const [documentTitle, SetDocumentTitle] = useState('');
    const [documentDate, SetDocumentDate] = useState(new Date());

    const [appealDesc, SetAppealDesc] = useState('');
    const [appealOrganization, SetAppealOrganization] = useState('');
    const [appealFiles, SetAppealFiles] = useState([]);

    const [filespreview, setFilesPreview] = useState([]);

    useMemo(() => {

    });
    useEffect(() => {
        //console.log(props)
        if(typeof props.location.state !== 'undefined'){
            SetNetworkID(props.location.state.networkID);
        }
        else{
            SetNetworkID(localStorage.getItem("networkID"));
        }
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getTitle')
            .then((response) => {
                SetTitleList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getProvince')
            .then((response) => {
                SetProvinceList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getDistrict')
            .then((response) => {
                SetDistrictList(response.data);
                SetAllDistrictList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSubDistrict')
            .then((response) => {
                SetSubDistrictList(response.data);
                SetAllSubDistrictList(response.data);
            });
        setLoading(false);

    }, []); /* <-- add this for run once*/

    const handleChangeProvince = ProvinceID => {
        SetDistrictList(allDistrictList.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetSubDistrictList(allSubDistrictList.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetDistrictCode(0);
        SetSubDistricCode(0);
    };
    const handleChangeDistrict = DistrictID => {
        SetSubDistrictList(allSubDistrictList.filter(item => item['DistrictID'] === Number(DistrictID)));
        SetSubDistricCode(0);
    };
    const handleChangeSubDistrict = SubDistrictID => {
        const subdistrictitem = allSubDistrictList.filter(item => item['SubDistrictID'] === Number(SubDistrictID));
        if (subdistrictitem[0] != null) {
            SetPostCode(subdistrictitem[0].PostCode);
        }
        else {
            SetPostCode('');
        }
    };

    const TitleSelect = titleList.map((title) => (
        <option
            key={title.TitleID}
            value={title.TitleID}
        >
            {title.TitleNameTH}
        </option>
    ));
    const ProvinceSelect = provinceList.map((province) => (
        <option
            key={province.ProvinceID}
            value={province.ProvinceID}
        >
            {province.ProvinceTH}
        </option>
    ));
    const DistrictSelect = districtList.map((district) => (
        <option
            key={district.DistrictID}
            value={district.DistrictID}
        >
            {district.DistrictTH}
        </option>
    ));
    const SubDistrictSelect = subDistrictList.map((subdistrict) => (
        <option
            key={subdistrict.SubDistrictID}
            value={subdistrict.SubDistrictID}
        >
            {subdistrict.SubDistrictTH}
        </option>
    ));
    const handleRemoveActivityFile = (filename) => {
        SetAppealFiles(appealFiles.filter(item => item['name'] !== filename));
    };
    const NetActivityFilesList = appealFiles.map((file, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.path.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveActivityFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (organization.trim().length === 0) {
            formIsValid = false;
            serrors["organization"] = "กรุณาระบุชื่อ";
        }
        if (netHouseNo.trim().length === 0) {
            formIsValid = false;
            serrors["netHouseNo"] = "กรุณาระบุรายละเอียด";
        }
        if (Number(provinceCode) === 0) {
            formIsValid = false;
            serrors["provinceCode"] = "กรุณาเลือกจังหวัด";
        }
        if (Number(districtCode) === 0) {
            formIsValid = false;
            serrors["districtCode"] = "กรุณาเลือกเขต/อำเภอ";
        }
        if (Number(subDistricCode) === 0) {
            formIsValid = false;
            serrors["subDistricCode"] = "กรุณาเลือกแขวง/ตำบล";
        }
        if (postCode.trim().length === 0) {
            formIsValid = false;
            serrors["postCode"] = "กรุณากรอกรหัสไปรษณีย์";
        }
        if (phone.trim().length === 0) {
            formIsValid = false;
            serrors["phone"] = "กรุณาระบุรายละเอียด";
        }
        //fax
        if (Number(coTitleID) === 0) {
            if (coOtherTitle.trim().length === 0) {
                formIsValid = false;
                serrors["coTitleID"] = "กรุณาเลือกคำนำหน้าชื่อ";
                serrors["coOtherTitle"] = "กรุณาระบุคำนำหน้าชื่อที่ต้องการ หากไม่มีในตัวเลือก";
            }
        }
        if (coFirstName.trim().length === 0) {
            formIsValid = false;
            serrors["coFirstName"] = "กรุณาระบุรายละเอียด";
        }
        if (coLastName.trim().length === 0) {
            formIsValid = false;
            serrors["coLastName"] = "กรุณาระบุรายละเอียด";
        }
        if (coPosition.trim().length === 0) {
            formIsValid = false;
            serrors["coPosition"] = "กรุณาระบุรายละเอียด";
        }
        if (coDepartment.trim().length === 0) {
            formIsValid = false;
            serrors["coDepartment"] = "กรุณาระบุรายละเอียด";
        }
        if (documentID.trim().length === 0) {
            formIsValid = false;
            serrors["documentID"] = "กรุณาระบุรายละเอียด";
        }
        if (documentTitle.trim().length === 0) {
            formIsValid = false;
            serrors["documentTitle"] = "กรุณาระบุรายละเอียด";
        }
        if (appealDesc.trim().length === 0) {
            formIsValid = false;
            serrors["appealDesc"] = "กรุณาระบุรายละเอียด";
        }
        if (appealOrganization.trim().length === 0) {
            formIsValid = false;
            serrors["appealOrganization"] = "กรุณาระบุรายละเอียด";
        }
        setError(serrors);
        //console.log(serrors)
        return formIsValid;
    };
    const saveData = () => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("networkID", networkID);
            formData.append("userID", currentUser.UserID);
            formData.append("organization", organization);
            formData.append("netHouseNo", netHouseNo);
            formData.append("provinceCode", provinceCode);
            formData.append("districtCode", districtCode);
            formData.append("subDistricCode", subDistricCode);
            formData.append("postCode", postCode);
            formData.append("phone", phone);
            formData.append("fax", fax);
            formData.append("coTitleID", coTitleID);
            formData.append("coOtherTitle", coOtherTitle);
            formData.append("coFirstName", coFirstName);
            formData.append("coLastName", coLastName);
            formData.append("coPosition", coPosition);
            formData.append("coDepartment", coDepartment);
            formData.append("documentID", documentID);
            formData.append("documentTitle", documentTitle);
            formData.append("documentDate", documentDate);
            formData.append("appealDesc", appealDesc);
            formData.append("appealOrganization", appealOrganization);
            for (let i = 0; i < appealFiles.length; i += 1) {
                formData.append("appealFiles[]", appealFiles[i]);
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveNetworkAppeal', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        //props.history.push({ pathname: "/authen/network/NetworkList" });
                        window.close();
                    })
                });

        } else {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                offset: -50,
                smooth: "easeInOutQuart",
            });
        }
    };
    const cancel = () => {
        props.history.push({ pathname: "/authen/Profile" });
    };

    if (isLoading) {
        return (<></>);
    }
    return (
        <>
            <div id="header-wrapper">
                {/*{<Navbar />}*/}
                <ContentBanner language={language} theader={"เครือข่ายด้านสิทธิมนุษยชน"} eheader={"Network"} bannerkey="NETWORK" banner={banner}
                    path={<>
                        <span>/</span><a href="/" onClick={(e) => { e.preventDefault() }}>{language === "Thai" ? "เครือข่าย" : "Network"}</a>
                        <span>/</span><Link to={{ pathname: "/authen/network/NetworkList", state: { language: language } }}>{language === "Thai" ? " องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ" : "Cooperationnetwork and Profesional Council"}</Link>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/network/CoorperationRegister", state: { language: language } }}>{language === "Thai" ? "ยื่นเรื่องขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน" : "Cooperationnetwork Register"}</Link>
                    </>}
                />
            </div>
            <main className="content-page">
                <div className="container">
                    <section>
                        <div className="content-data">
                            <form method="post" encType="multipart/form-data" className="row formContent">
                                <div className="col-12">
                                    <div className="sub-title">
                                        <h2 className="thai-header text-center"><span className="orang content-underline">คำอุทธรณ์</span></h2>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control1 " id="organization" value={organization} name="organization" placeholder="ชื่อหน่วยงาน" onChange={(e) => { SetOrganization(e.currentTarget.value) }} />
                                    <SError error={errors["organization"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control1 " id="netHouseNo" value={netHouseNo} name="netHouseNo" placeholder="ที่ตั้งเลขที่" onChange={(e) => { SetNetHouseNo(e.currentTarget.value) }} />
                                    <SError error={errors["netHouseNo"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select" id="provinceCode" name="provinceCode" value={provinceCode} onChange={(e) => { SetProvinceCode(e.currentTarget.value); handleChangeProvince(e.currentTarget.value); }} >
                                        <option key="0" value="0" >จังหวัด</option>
                                        {ProvinceSelect}
                                    </select>
                                    <SError error={errors["provinceCode"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select" id="districtCode" name="districtCode" value={districtCode} onChange={(e) => { SetDistrictCode(e.currentTarget.value); handleChangeDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >เขต/อำเภอ</option>
                                        {DistrictSelect}
                                    </select>
                                    <SError error={errors["districtCode"]} />
                                </div>
                                <div className="col-6">
                                    <select className="custom-select" id="subDistricCode" name="subDistricCode" value={subDistricCode} onChange={(e) => { SetSubDistricCode(e.currentTarget.value); handleChangeSubDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >แขวง/ตำบล</option>
                                        {SubDistrictSelect}
                                    </select>
                                    <SError error={errors["subDistricCode"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="postCode" value={postCode} name="postCode" placeholder="รหัสไปรษณีย์" onChange={(e) => { SetPostCode(e.currentTarget.value) }} />
                                    <SError error={errors["postCode"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="phone" value={phone} name="phone" placeholder="หมายเลขโทรศัพท์" onChange={(e) => { SetPhone(e.currentTarget.value) }} />
                                    <SError error={errors["phone"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="fax" value={fax} name="fax" placeholder="โทรสาร" onChange={(e) => { SetFax(e.currentTarget.value) }} />
                                    <SError error={errors["fax"]} />
                                </div>
                                <div className="col-12 title text-blue">
                                    <h2><span>ข้าพเจ้า</span></h2>
                                </div>
                                <div className="col-6">
                                    <select className="custom-select" id="coTitleID" name="coTitleID" value={coTitleID} onChange={(e) => { SetCoTitleID(e.currentTarget.value); }}>
                                        <option key="0" value="0" >คำนำหน้า</option>
                                        {TitleSelect}
                                    </select>
                                    <SError error={errors["coTitleID"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="coOtherTitle" value={coOtherTitle} name="coOtherTitle" placeholder="โปรดระบุเมื่อไม่มีคำนำหน้าที่ต้องการให้เลือก" onChange={(e) => { SetCoOtherTitle(e.currentTarget.value) }} />
                                    <SError error={errors["coOtherTitle"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="coFirstName" value={coFirstName} name="coFirstName" placeholder="ชื่อ" onChange={(e) => { SetCoFirstName(e.currentTarget.value) }} />
                                    <SError error={errors["coFirstName"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="coLastName" value={coLastName} name="coLastName" placeholder="นามสกุล" onChange={(e) => { SetCoLastName(e.currentTarget.value) }} />
                                    <SError error={errors["coLastName"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="coPosition" value={coPosition} name="coPosition" placeholder="ตำแหน่ง" onChange={(e) => { SetCoPosition(e.currentTarget.value) }} />
                                    <SError error={errors["coPosition"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="coDepartment" value={coDepartment} name="coDepartment" placeholder=" สังกัด ( หน่วยงานที่ยื่นคำขอ )" onChange={(e) => { SetCoDepartment(e.currentTarget.value) }} />
                                    <SError error={errors["coDepartment"]} />
                                </div>

                                <div className="col-12">
                                    <h2>มีความประสงค์ขออุทธรณ์คำสั่ง / ประกาศ / หนังสือ / สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</h2>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="documentID" value={documentID} name="documentID" placeholder=" ที่"
                                        onChange={(e) => { SetDocumentID(e.currentTarget.value) }} />
                                    <span className="error">{errors["documentID"]}</span>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="documentTitle" value={documentTitle} name="documentTitle" placeholder=" เรื่อง"
                                        onChange={(e) => { SetDocumentTitle(e.currentTarget.value); }} />
                                    <SError error={errors["documentTitle"]} />
                                </div>
                                <div className="col-6">
                                    <label className="required"> ลงวันที่</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={documentDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                            {SetDocumentDate} name="documentDate" id="documentDate" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-12">
                                    <h2>โดยมีรายละเอียดข้อเท็จจริงและข้ออุทธรณ์ ดังนี้</h2>
                                    <label className="text-gray">(การกระทำทั้งหลายที่เป็นเหตุแห่งการอุทธรณ์ และแสดงข้อเท็จจริงและเหตุผลในการอุทธรณ์ ให้เห็นว่าได้ไม่ถูกต้อง ไม่เหมาะสม หรือไม่เป็นธรรม อย่างไร)</label>
                                </div>
                                <div className="col-12">
                                    <div>
                                        <textarea className="form-control" name="appealDesc" id="appealDesc" rows="8" value={appealDesc}
                                            onChange={e => { SetAppealDesc(e.currentTarget.value); }}></textarea>
                                        <SError error={errors["appealDesc"]} />
                                    </div>
                                </div>
                                <div className="col-6 title text-blue">
                                    <h2><span>เป็นเหตุให้</span></h2>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="appealOrganization" value={appealOrganization} name="appealOrganization" placeholder=" ระบุชื่อหน่วยงานที่ยื่นคำขอ"
                                        onChange={(e) => { SetAppealOrganization(e.currentTarget.value); }} />
                                    <SError error={errors["appealOrganization"]} />
                                </div>
                                <div className="col-12">
                                    <label className="text-gray">( ไม่ได้รับการจดแจ้ง / ถูกเพิกถอนในการเป็นองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ )</label><br /><br />
                                    <label className="text-gray">ทั้งนี้ ตามความในข้อ ๑๙ แห่งระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไขการ
                                        จดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ พ.ศ. ๒๕๖๑ และที่แก้ไขเพิ่มเติม “องค์
                                        กรเอกชนหรือสภาวิชาชีพที่ไม่เห็นด้วยกับการไม่รับจดแจ้งตามข้อ ๑๑ วรรคสอง หรือไม่เห็นด้วยกับคำสั่งเพิกถอนการ
                                        จดแจ้งตามข้อ ๑๘ วรรคสอง มีสิทธิอุทธรณ์ต่อคณะกรรมการภายในสามสิบวันนับแต่วันที่ได้รับแจ้ง”ในการนี้ ข้าพเจ้าจึง
                                        มีความประสงค์ขอให้ท่านพิจารณาคำอุทธรณ์ในการประชุมคณะกรรมการสิทธิมนุษยชนแห่งชาติ ทั้งนี้ หากคณะ
                                        กรรมการสิทธิมนุษยชนแห่งชาติมีมติไปในทางใดให้ถือว่าเป็นที่สุดในชั้นการอุทธรณ์ต่อคณะกรรมการ</label>
                                </div>
                                <div className="subheader col-12" role="alert">
                                    โปรดแนบเอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                                </div>
                                <div className="col-12">
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            /*var filecount=appealFiles.length;
                                            for(let i=0;i<acceptedFiles.length;i++){
                                                curActivity.appealFiles[filecount+i] =acceptedFiles[i];
                                            }  */
                                            //setnetworkActivities({curActivity,['appealFiles']: acceptedFiles});
                                            SetAppealFiles([...appealFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["appealFiles"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {NetActivityFilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12 formContent">
                                    <div className="pl-4 pr-4 text-center" >
                                        <input type="submit" className="fadeIn fourth" value="ตกลง" onClick={(e) => { e.preventDefault(); saveData(); }} />
                                        <input type="button" className="fadeIn fourth" onClick={e => { e.preventDefault(); cancel(); }} value="ยกเลิก" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}
