import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import { authenticationService } from 'services/authentication.service.js';
import { SketchPicker } from 'react-color';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function EditELearningCourseType(props) {
    //console.log("Edit ID = "+props.location.state.id);
    const courseTypeID = props.location.state.id;
    const currentUser = authenticationService.currentUserValue;
    const [nameth, setNameTH] = useState('');
    const [nameen, setNameEN] = useState('');
    const [color, setColor] = useState('#000000');
    const [status, setStatus] = useState(-1);
    const [errors, setError] = useState({});
    //const [data, setData] = useState({});

    useEffect(() => {
        //console.log(courseTypeID)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/ELearningCourseTypeByID', { params: { id: courseTypeID } }).then((response) => {
            console.log(response.data[0])
            setNameTH(response.data[0].CourseTypeNameTH);
            setNameEN(response.data[0].CourseTypeNameEN);
            setColor(response.data[0].CourseColor);
            setStatus(Number(response.data[0].Status));
        })
    }, []); /* <-- add this for run once*/


    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (nameth.trim().length === 0) {
            formIsValid = false;
            serrors["nameth"] = "กรุณากรอกข้อมูล";
        }
        /*if (nameen.trim().length === 0) {
            formIsValid = false;
            serrors["nameen"] = "กรุณากรอกข้อมูล";
        }*/
        if (Number(status) === -1) {
            formIsValid = false;
            serrors["status"] = "กรุณากำหนดสถานะการเผยแพร่ข้อมูล";
        }
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        setError(serrors);
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateELearningCourseType', null, { params: { id: props.location.state.id, titleth: nameth, titleen: nameen, color: color, status: status, updateby: currentUser.UserID } })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/elearning/ELearningCourseList" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const handleChangeColor = (color) => {
        setColor(color.hex)
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/elearning/ELearningCourseList" });
    };

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการอีเลิร์นนิง (E-learning)"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการอีเลิร์นนิง (E-learning)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/ELearningCourseList" }}>{"จัดการหลักสูตร"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/elearning/EditELearningCourseType", state: { id: courseTypeID } }}>{"แก้ไข"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="form-group">
                                    <label htmlFor="nameth" className="required">ชื่อหมวดหมู่ (ภาษาไทย)</label>
                                    <input type="text" className="form-control" id="nameth" name="nameth" placeholder="กรุณากรอก" value={nameth} onChange={(e) => { setNameTH(e.currentTarget.value) }} />
                                    <SError error={errors["nameth"]} />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="form-group">
                                    <label htmlFor="enname">ชื่อหมวดหมู่ (ภาษาอังกฤษ)</label>
                                    <input type="text" className="form-control" id="enname" name="enname" placeholder="กรุณากรอก" value={nameen} onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                    <SError error={errors["nameen"]} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4" >
                            <label htmlFor="color" className="required mr-2">สีหลักสูตร</label>
                            {console.log(color)}
                            <SketchPicker
                                color={color}
                                onChangeComplete={handleChangeColor}
                            />
                            {/*<div style={styles.swatch} onClick={handleClick}>
                                <div style={styles.color} />
                            </div>
                            {displayColorPicker ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={handleClose} />
                                <SketchPicker color={color} onChange={handleChange} />
                        </div> : null}*/}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4" >
                            <label htmlFor="status" className="required mr-2">สถานะ</label>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="status" id="status1" value="1" checked={status === 1} onChange={(e) => { setStatus(1); }} />
                                <label className="form-check-label" htmlFor="status1">เผยแพร่</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="status" id="status2" value="0" checked={status === 0} onChange={(e) => { setStatus(0); }} />
                                <label className="form-check-label" htmlFor="status2">ไม่เผยแพร่</label>
                            </div>
                            <SError error={errors["status"]} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
