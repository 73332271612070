import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import utils from "utils/utils";
import vdoIcon from "assets/images/icon/vdo.svg";

export default function VDOFileList(props) {
  //const [isLoading, setLoading] = useState(true);
  //console.log(props);
  /*if(typeof props.fileList !== "undefined")
    setLoading(false)*/
  /*if(props.fileList!=null)
    setLoading(false)*/
  /*const fileListData = useMemo(() => {
        setLoading(false)
        return props.fileList;
    }, []);*/

  /*if (isLoading) {
        return (<></>);
    }*/
  const [vdoCount, setVDOCount] = useState(0);
  useEffect(() => {
    const vdolist = props.fileList.filter((member) => {
      return member.AttachmentType === "VDO"
    })
    setVDOCount(vdolist.length);
  }, []);
  const FileList = props.fileList.map(
    (fileItem) =>
      fileItem.AttachmentType === "VDO" && (
        <div className="cardItem" key={fileItem.AttachmentID}>
          <div>
            <img src={vdoIcon} className="icon" alt="file icon" />
            <span className="file-name">{fileItem.FilenameOld}</span>
          </div>
          <div>
            <span className="time-ago" style={{ paddingRight: 5 }}>
              <i className="far fa-clock" />
              <p> {utils.MariatoThaiDateStringShort(fileItem.CreatedDate)}</p>
            </span>
            |{" "}
            <a
              href={fileItem.AttachmentPath}
              className="view-download text-gray"
              target="_blank"
              rel="noreferrer"
              style={{ paddingLeft: 5 }}
            >
              <i className="fas fa-play-circle" />
              {/* <p className="text-gray"> </p> */}
            </a>
          </div>
        </div>
      )
  );
  return (
    <div className="cardOut">
      <div className="cardIn">{vdoCount==0?'ไม่พบข้อมูล':FileList}</div>
    </div>
  );
}
