import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
//import { authenticationService } from 'services/authentication.service.js';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import utils from 'utils/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
/*import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';*/
import AdminBanner from 'components/Banner/AdminBanner';
//const swal = withReactContent(Swal)

export default function NewsReorder(props) {
    //const currentUser = authenticationService.currentUserValue;
    const [gridApi, setGridApi] = useState(null);
    //const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    //const [newsCat, setNewsCat] = useState([]);
    useEffect(() => {
        /*axios.get(process.env.REACT_APP_APIURL + '/api-web/newsCat')
            .then((response) => {
                setNewsCat(response.data);
            });*/
    }, []); /* <-- add this for run once*/

    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        const updateData = (data) => {
            setRowData(data);
        };

        axios.get(process.env.REACT_APP_APIURL + '/api-web/getnewsByOrder').then((response) => {
            updateData(response.data);
            //console.log(response.data)
        })

    };

    const getRowHeight = (params) => {
        //console.log(params.data.rowHeight);
        //return params.data.rowHeight;
        return 80;
    };
    const statusRenderer = params => {
        if (params.value === true)
            return 'เผยแพร่';
        return 'ไม่เผยแพร่';
    };
    const pinRenderer = params => {
        if (params.value === true)
            return 'ปักหมุด';
        return 'ไม่ปักหมุด';
    }
    const createYearCellRenderer = params => {
        if (params.value == null)
            return '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p></span>';
        return '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p><p style="line-height:20px;margin-bottom: -4px;">' + params.value + '<p></span>';
    };
    const updateYearCellRenderer = params => {
        if (params.value == null)
            return '';
        return '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' + utils.MariatoThaiDateString(params.data.UpdatedDate) + '</p><p style="line-height:20px;margin-bottom: -4px;">' + params.value + '<p></span>';
    };


    var hashValueGetter = function (params) {
        return params.node.rowIndex + 1;
    };

    const onRowDragEnd = (event) => {
        let orderItem = [];
        gridApi.forEachNode((rowNode, index) => {
            orderItem.push(rowNode.data.NewsID)
        });
        const formData = new FormData();
        orderItem.forEach((item) => {
            formData.append('orderItem[]', JSON.stringify(item));
        });
        axios.post(process.env.REACT_APP_APIURL + '/api-web/newsOrder', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                setRowData(response.data);
            });
    };

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการการประชาสัมพันธ์"} path={<><span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการการประชาสัมพันธ์ </a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/news/News" }}>{"จัดการประชาสัมพันธ์"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/news/NewsReorder" }}>{"จัดลำดับ"}</Link></>}
            />
            <div className="col-12">
                <div className="ag-theme-alpine" style={{ height: 100 + 'vh' }}>
                    <AgGridReact
                        autoGroupColumnDef={{
                            headerName: 'Group',
                            minWidth: 30,
                            field: 'CategoriesID',
                            valueGetter: function (params) {
                                if (params.node.group) {
                                    return params.node.key;
                                } else {
                                    return params.data[params.colDef.field];
                                }
                            },
                            headerCheckboxSelection: true,
                            cellRenderer: 'agGroupCellRenderer',
                            cellRendererParams: { checkbox: true },
                        }}

                        modules={AllCommunityModules}
                        frameworkComponents={{
                        }}
                        defaultColDef={{
                            sortable: true,
                            resizable: true,
                            filter: false,
                            //cellClass: "cell-border cell-vertical-align-text-left",
                        }}
                        getRowHeight={getRowHeight}
                        onGridReady={onGridReady}
                        rowData={rowData}
                        rowSelection={'multiple'}
                        rowDragManaged={true}
                        pagination={false}
                        //paginationPageSize= {10}
                        onRowDragEnd={onRowDragEnd}
                    >
                        <AgGridColumn field="Ordering" headerName="เรียงลำดับ" minWidth={100} rowDrag={true} />
                        <AgGridColumn field="" valueGetter={hashValueGetter} headerName="ลำดับ" minWidth={70} cellClass="cell-border cell-vertical-align-text-left" />
                        <AgGridColumn field="CatName" headerName="หมวดหมู่" minWidth={180} />
                        <AgGridColumn field="TitleTH" headerName="ชื่อเรื่อง" minWidth={190} />
                        <AgGridColumn field="Pin" headerName="ปักหมุด" cellRenderer={pinRenderer} sortable={true} minWidth={100} />
                        <AgGridColumn field="Published" headerName="สถานะ" cellRenderer={statusRenderer} sortable={true} minWidth={100} />
                        <AgGridColumn field="CreateFullname" headerName="ผู้สร้าง" cellRenderer={createYearCellRenderer} minWidth={210} wrapText={true} cellClass="cell-border cell-vertical-align-text-left" />
                        <AgGridColumn field="UpdateFullname" headerName="ผู้แก้ไข" cellRenderer={updateYearCellRenderer} minWidth={210} wrapText={true} cellClass="cell-border cell-vertical-align-text-left" />
                    </AgGridReact>
                </div>
            </div>
        </section>
    );
}
