import * as React from "react";
const SvgComplaint = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 19.917 20.234"
    {...props}
  >
    <path
      d="M10.297 12.658a.75.75 0 0 0-.611.865l.384 2.235a.75.75 0 0 0 .866.611.75.75 0 0 0 .613-.865l-.385-2.234a.75.75 0 0 0-.867-.612M16.908.514a13.05 13.05 0 0 1-7.564 3.455.75.75 0 0 0-.668.822.75.75 0 0 0 .822.668 14.55 14.55 0 0 0 8.436-3.85.75.75 0 0 0 .035-1.06.75.75 0 0 0-1.06-.035M9.498 9.404a.75.75 0 0 0-.822.668.75.75 0 0 0 .668.824 13.05 13.05 0 0 1 7.564 3.456.75.75 0 0 0 1.06-.036.75.75 0 0 0-.034-1.06 14.55 14.55 0 0 0-8.436-3.852M17.832 5.07a.75.75 0 0 0-.816.676.75.75 0 0 0 .675.818.787.787 0 0 1 .721.756l.002.016v.014c.034.41-.13.593-.373.785-.243.191-.498.275-.498.275a.75.75 0 0 0-.498.936.75.75 0 0 0 .935.498s.505-.148.99-.531c.483-.382 1.008-1.113.936-2.07a2.305 2.305 0 0 0-2.074-2.173"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        InkscapeStroke: "none",
      }}
    />
    <path
      d="M17.42.379a.75.75 0 0 0-.748.752l.015 12.658a.75.75 0 0 0 .75.748.75.75 0 0 0 .75-.75L18.173 1.13a.75.75 0 0 0-.752-.75M3.516 4.29s-.778-.105-1.621.183C1.05 4.76.08 5.62.008 7.078-.065 8.561.87 9.532 1.713 9.953c.842.42 1.668.463 1.668.463a.75.75 0 0 0 .795-.703.75.75 0 0 0-.701-.795s-.57-.046-1.092-.307c-.523-.26-.921-.564-.877-1.46.045-.921.407-1.1.871-1.258s.965-.114.965-.114a.75.75 0 0 0 .832-.658.75.75 0 0 0-.658-.832"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        InkscapeStroke: "none",
      }}
    />
    <path
      d="M4.602 10.328a.75.75 0 0 0-.594.879l1.627 8.416a.75.75 0 0 0 .736.607h2.955l-.04-.002s.325.029.702-.087c.19-.058.413-.157.617-.354.198-.19.343-.492.381-.807.152-1.017-.736-1.94-1.76-1.802a.75.75 0 0 0-.644.843.75.75 0 0 0 .844.643c.041-.005.037.031.05.05l-.109.016H6.99l-1.51-7.808a.75.75 0 0 0-.878-.594"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        InkscapeStroke: "none",
      }}
    />
    <path
      d="M7.768 10.33a.75.75 0 0 0-.588.883l1.377 6.853a.75.75 0 0 0 .882.588.75.75 0 0 0 .588-.883L8.65 10.918a.75.75 0 0 0-.882-.588"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        InkscapeStroke: "none",
      }}
    />
    <path
      fill="none"
      stroke="#faab3e"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2 0C.905 0 0 .905 0 2v4.363c0 1.095.905 2 2 2h2.316c1.095 0 2-.905 2-2V2c0-1.095-.905-2-2-2zm0 1.5h2.316c.29 0 .5.21.5.5v4.363c0 .29-.21.5-.5.5H2c-.29 0-.5-.21-.5-.5V2c0-.29.21-.5.5-.5"
      data-name="Rectangle 13072"
      style={{
        color: "#000",
        fill: "#faab3e",
        InkscapeStroke: "none",
      }}
      transform="translate(3.271 3.28)"
    />
    <path
      d="M6.855 7.377a.34.34 0 0 1-.341.341.34.34 0 0 1-.341-.341.34.34 0 0 1 .341-.341.34.34 0 0 1 .341.341M5.831 7.377a.34.34 0 0 1-.341.341.34.34 0 0 1-.341-.341.34.34 0 0 1 .341-.341.34.34 0 0 1 .341.341M7.879 7.377a.34.34 0 0 1-.341.341.34.34 0 0 1-.341-.341.34.34 0 0 1 .341-.341.34.34 0 0 1 .341.341"
      style={{
        color: "#000",
        fill: "#faab3e",
        InkscapeStroke: "none",
      }}
    />
  </svg>
);
export default SvgComplaint;
