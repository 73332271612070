// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.followComplainStep {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.followComplainStep .boxProcess {
    position: relative;
    width: 70px;
    height: 70px;
    border: 1px solid #fff;
    border-radius: 100%;
    background-color: #e7ebf5;
    font-size: 50px;
    font-weight: bold;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
}

.followComplainStep .boxProcess::after {
    content: ". . .";
    position: absolute;
    color: #c6c6c6;
    left: calc(100% + 75px);
    transform: rotate(90deg);
}

.followComplainStep .boxProcess.ending::after {
    content: none;
}

.followComplainStep .boxProcess.pending {
    background-color: #fbb617;
    color: #fff;
}

.followComplainStep .boxProcess.success {
    background-color: #12b72d;
    color: #fff;
}

.followComplainStep .boxProcess .textTitle {
    position: absolute;
    top: 85px;
    width: 145px;
    font-size: 20px;
    color: #b2b2b2;
    text-align: center;
    font-weight: 500;
}

.followComplainStep .boxProcess.pending .textTitle {
    color: #000;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/steper.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".followComplainStep {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 4rem;\n}\n\n.followComplainStep .boxProcess {\n    position: relative;\n    width: 70px;\n    height: 70px;\n    border: 1px solid #fff;\n    border-radius: 100%;\n    background-color: #e7ebf5;\n    font-size: 50px;\n    font-weight: bold;\n    color: #707070;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.followComplainStep .boxProcess::after {\n    content: \". . .\";\n    position: absolute;\n    color: #c6c6c6;\n    left: calc(100% + 75px);\n    transform: rotate(90deg);\n}\n\n.followComplainStep .boxProcess.ending::after {\n    content: none;\n}\n\n.followComplainStep .boxProcess.pending {\n    background-color: #fbb617;\n    color: #fff;\n}\n\n.followComplainStep .boxProcess.success {\n    background-color: #12b72d;\n    color: #fff;\n}\n\n.followComplainStep .boxProcess .textTitle {\n    position: absolute;\n    top: 85px;\n    width: 145px;\n    font-size: 20px;\n    color: #b2b2b2;\n    text-align: center;\n    font-weight: 500;\n}\n\n.followComplainStep .boxProcess.pending .textTitle {\n    color: #000;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
