import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
//import 'react-summernote/lang/summernote-th-TH'; // you can import any other locale
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';

const swal = withReactContent(Swal)

export default function NewESubject(props) {
    const currentUser = authenticationService.currentUserValue;
    //const [fields, setFields] = useState({});
    const [errors, setError] = useState({});
    const [eLearnTitle, setELearnTitle] = useState('');
    const [chapter, setChapter] = useState('');
    const [targetGroup, setTargetGroup] = useState('');
    const [condition, setCondition] = useState('');
    const [courseDetail, setCourseDetail] = useState("");
    const [courseObjective, setCourseObjective] = useState("");
    const [courseCriterion, setCourseCriterion] = useState("");
    const [courseRemark, setCourseRemark] = useState("");

    const [eLearnTitleEN, setELearnTitleEN] = useState('');
    const [chapterEN, setChapterEN] = useState('');
    const [targetGroupEN, setTargetGroupEN] = useState('');
    const [conditionEN, setConditionEN] = useState('');
    const [courseDetailEN, setCourseDetailEN] = useState("");
    const [courseObjectiveEN, setCourseObjectiveEN] = useState("");
    const [courseCriterionEN, setCourseCriterionEN] = useState("");
    const [courseRemarkEN, setCourseRemarkEN] = useState("");

    const [coverType, setCoverType] = useState(0);
    const [courseTypeList, setCourseTypeList] = useState([]);
    const [courseType, setCourseType] = useState(0);
    const [maxEnroll, setMaxEnroll] = useState(0);
    const [registeredStartDate, SetRegisteredStartDate] = useState(new Date());
    const [registeredEndDate, SetRegisteredEndDate] = useState(new Date());
    const [learnStartDate, SetLearnStartDate] = useState(new Date());
    const [learnEndDate, SetLearnEndDate] = useState(new Date());
    const [pin, setPin] = useState(false);
    const [lectureName, setLectureName] = useState('');
    const [lectureImg, setLectureImg] = useState([]);
    const [webCover, setWebCover] = useState(null);
    const [imgMobileCover, setIMGMobileCover] = useState([]);
    const [publishstartDate, SetPublishStartDate] = useState(new Date());
    const [publishendDate, SetPublishEndDate] = useState(new Date());
    const [published, setPublished] = useState(true);
    const [category, setCategory] = useState(0)
    const [selectCourseCategory, setSelectCourseCategory] = useState([]);
    const [socialLink, setSocialLink] = useState([]);
    const [recommendedCourse, setRecommendedCourse] = useState([]);
    const [signList, setSignList] = useState([]);

    const [committeeType, setCommitteeType] = useState(0);
    const [certificateType, setCertificateType] = useState(0);
    const [certLogoFiles, setCertLogoFiles] = useState([]);
    const [filespreview, setFilesPreview] = useState([]);
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchElearningCategories', { params: { status: 1 } })
            .then((response) => {
                setSelectCourseCategory(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchELearningCourseType', { params: { status: 1 } })
            .then((response) => {
                setCourseTypeList(response.data);
            });
    }, []); /* <-- add this for run once*/
    const rx_int = /^\d+$/;
    const handleMaxEnroll = (e) => {
        if (rx_int.test(e.target.value)) {
            setMaxEnroll(parseInt(e.target.value));
        }
    }
    const CourseCategorySelect = selectCourseCategory.map((item) => (
        <option
            key={item.ELearnCatID}
            value={item.ELearnCatID}
        >
            {item.CategoriesNameTH}
        </option>
    ));
    const CourseTypeOption = courseTypeList.map((item, index) => (
        <option
            key={index}
            value={item.ELearnCourseTypeID}
        >
            {item.CourseTypeNameTH}
        </option>
    ));

    const LectureImgPreview = (
        (typeof lectureImg.preview !== 'undefined') && (<img className="preview-upload" src={lectureImg.preview} onChange={(e) => { e.target.src = URL.createObjectURL(lectureImg); }} alt="Web Cover" />)
    );

    const MobileCoverPreview = (
        (typeof imgMobileCover.preview !== 'undefined') && (<img className="preview-upload" src={imgMobileCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgMobileCover); }} alt="Mobile Cover" />)
    );

    const handleRemoveWebCover = () => {
        const formData = new FormData();
        if (Number(coverType) === 1)
            formData.append("folder", 'images');
        if (Number(coverType) === 2)
            formData.append("folder", 'vdo');
        formData.append("url", webCover.file);
        formData.append("fieldName", 'FilePath');
        formData.append("id", props.location.state.id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteELearningFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setWebCover(null);
    };
    const WebCoverPreview = (webCover !== null) && (
        <tr key={webCover.name}>
            <td>{1}</td>
            <td>{webCover.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(webCover.size)}</td>
            <td>{webCover.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={webCover.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveWebCover(); }}>ลบ</button>
            </td>
        </tr>
    );

    const handleLectureImageOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setLectureImg(imageFile);
    };
    const handleWebCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setWebCover(imageFile);
    };

    const handleImgMobileCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGMobileCover(imageFile);
    };

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (eLearnTitle.trim().length === 0) {
            formIsValid = false;
            serrors["eLearnTitle"] = "กรุณาระบุชื่อวิชา";
        }
        if (chapter.trim().length === 0) {
            formIsValid = false;
            serrors["chapter"] = "กรุณากรอกข้อมูล";
        }
        if (targetGroup.trim().length === 0) {
            formIsValid = false;
            serrors["targetGroup"] = "กรุณากรอกข้อมูล";
        }
        if (condition.trim().length === 0) {
            formIsValid = false;
            serrors["condition"] = "กรุณากรอกข้อมูล";
        }
        if (courseDetail.trim().length === 0) {
            formIsValid = false;
            serrors["courseDetail"] = "กรุณากรอกข้อมูล";
        }
        if (courseObjective.trim().length === 0) {
            formIsValid = false;
            serrors["courseObjective"] = "กรุณากรอกข้อมูล";
        }
        if (courseCriterion.trim().length === 0) {
            formIsValid = false;
            serrors["courseCriterion"] = "กรุณากรอกข้อมูล";
        }
        if (courseRemark.trim().length === 0) {
            formIsValid = false;
            serrors["courseRemark"] = "กรุณากรอกข้อมูล";
        }
        /*if (eLearnTitleEN.trim().length === 0) {
            formIsValid = false;
            serrors["eLearnTitleEN"] = "กรุณาระบุชื่อวิชา";
        }
        if (chapterEN.trim().length === 0) {
            formIsValid = false;
            serrors["chapterEN"] = "กรุณากรอกข้อมูล";
        }
        if (targetGroupEN.trim().length === 0) {
            formIsValid = false;
            serrors["targetGroupEN"] = "กรุณากรอกข้อมูล";
        }
        if (conditionEN.trim().length === 0) {
            formIsValid = false;
            serrors["conditionEN"] = "กรุณากรอกข้อมูล";
        }
        if (courseDetailEN.trim().length === 0) {
            formIsValid = false;
            serrors["courseDetailEN"] = "กรุณากรอกข้อมูล";
        }
        if (courseObjectiveEN.trim().length === 0) {
            formIsValid = false;
            serrors["courseObjectiveEN"] = "กรุณากรอกข้อมูล";
        }
        if (courseCriterionEN.trim().length === 0) {
            formIsValid = false;
            serrors["courseCriterionEN"] = "กรุณากรอกข้อมูล";
        }
        if (courseRemarkEN.trim().length === 0) {
            formIsValid = false;
            serrors["courseRemarkEN"] = "กรุณากรอกข้อมูล";
        }*/

        if (Number(courseType) === 0) {
            formIsValid = false;
            serrors["courseType"] = "กรุณาเลือกหลักสูตร";
        }
        if (Number(category) === 0) {
            formIsValid = false;
            serrors["category"] = "กรุณาเลือกหมวดหมู่";
        }
        if (Number(coverType) === 0) {
            formIsValid = false;
            serrors["coverType"] = "กรุณาเลือกประเภทการแสดงผล";
        }
        if (webCover === null) {
            formIsValid = false;
            serrors["webCover"] = "กรุณาเลือกไฟล์หน้าปกสำหรับเว็บไซต์";
        }
        if (imgMobileCover.length === 0) {
            formIsValid = false;
            serrors["mobilecover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับมือถือ";
        }
        if (Number(maxEnroll) <= 0) {
            formIsValid = false;
            serrors["maxEnroll"] = "กรุณากรอกข้อมูลจำนวนที่นั่งที่ถูกต้อง";
        }
        if (lectureName.trim().length === 0) {
            formIsValid = false;
            serrors["lectureName"] = "กรุณากรอกข้อมูล";
        }
        if (lectureImg.length === 0) {
            formIsValid = false;
            serrors["lectureImg"] = "กรุณาเลือกไฟล์ภาพอาจารย์ผู้สอน";
        }
        if (signList.length === 0) {
            formIsValid = false;
            serrors["signList"] = "กรุณาเพิ่มข้อมูลผู้ลงนาม";
        }
        if (Number(committeeType) === 0) {
            formIsValid = false;
            serrors["committeeType"] = "กรุณาเลือกประเภทคณะกรรมการ";
        }
        if (Number(certificateType) === 0) {
            formIsValid = false;
            serrors["certificateType"] = "กรุณาเลือกประเภทประกาศนียบัตร";
        }
        if (certLogoFiles.length === 0) {
            formIsValid = false;
            serrors["certLogoFiles"] = "กรุณาอัปโหลดโลโก้ประกาศนียบัตรอย่างน้อย 1 รูป";
        }
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        signList.map((value, j) => {
            if ((value.SignImage === null) || (value.SignName.trim().length === 0) || (value.SignPos.trim().length === 0)) {
                formIsValid = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูลผู้ลงนามและภาพลายเซ็นต์ให้ครบถ้วน ก่อนบันทึกข้อมูล',
                })
            }
        })
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("category", category);
            formData.append("courseType", courseType);

            formData.append("eLearnTitle", eLearnTitle);
            formData.append("chapter", chapter);
            formData.append("targetGroup", targetGroup);
            formData.append("condition", condition);
            formData.append("courseDetail", courseDetail);
            formData.append("courseObjective", courseObjective);
            formData.append("courseCriterion", courseCriterion);
            formData.append("courseRemark", courseRemark);

            formData.append("eLearnTitleEN", eLearnTitleEN);
            formData.append("chapterEN", chapterEN);
            formData.append("targetGroupEN", targetGroupEN);
            formData.append("conditionEN", conditionEN);
            formData.append("courseDetailEN", courseDetailEN);
            formData.append("courseObjectiveEN", courseObjectiveEN);
            formData.append("courseCriterionEN", courseCriterionEN);
            formData.append("courseRemarkEN", courseRemarkEN);

            formData.append("coverType", coverType);
            formData.append("webCover", webCover.file);
            formData.append("mobilecoverfile", imgMobileCover.file);
            formData.append("maxEnroll", maxEnroll);
            formData.append("registeredStartDate", registeredStartDate);
            formData.append("registeredEndDate", registeredEndDate);
            formData.append("learnStartDate", learnStartDate);
            formData.append("learnEndDate", learnEndDate);

            formData.append("pin", pin);

            formData.append("lectureName", lectureName);
            formData.append("lectureImg", lectureImg.file);
            formData.append("published", published);
            formData.append("publishstartDate", publishstartDate);
            formData.append("publishendDate", publishendDate);
            formData.append("userID", currentUser.UserID);
            for (var i = 0; i < socialLink.length; i++) {
                formData.append('socialLink[]', JSON.stringify(socialLink[i]));
            }
            for (var i = 0; i < recommendedCourse.length; i++) {
                formData.append('recommendedCourse[]', JSON.stringify(recommendedCourse[i]));
            }
            for (var i = 0; i < signList.length; i++) {
                formData.append('signList[]', JSON.stringify(signList[i]));
            }
            for (let i = 0; i < signList.length; i += 1) {
                formData.append("signImageList[]", signList[i].SignImage);
            }

            formData.append("committeeType", committeeType);
            formData.append("certificateType", certificateType);
            for (let i = 0; i < certLogoFiles.length; i += 1) {
                formData.append("certLogoFiles[]", certLogoFiles[i]);
            }
            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveESubject', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            props.history.push({ pathname: "/admin/elearning/NewESubject" });
                        }
                        else {
                            props.history.push({ pathname: "/admin/elearning/ELearningList" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const HandlePin = (pinStatus) => {
        if (pinStatus === true) {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getESubjectPinCount')
                .then((response) => {
                    //console.log(response.data[0].PinCount)
                    if (Number(response.data[0].PinCount) >= 4) {
                        Swal.fire({
                            icon: 'error',
                            title: 'ผิดพลาด',
                            text: 'มีข้อมูลในการปักหมุดครบจำนวนสี่ชุดแล้ว',
                        })
                    }
                    else {
                        setPin(pinStatus)
                    }
                });
        }
        else {
            setPin(pinStatus)
        }
    }
    const cancel = () => {
        props.history.push({ pathname: "/admin/elearning/ELearningList" });
    };
    const handleRemoveSocialLink = (index, idxloop) => {
        const removedata = socialLink.filter((item) => Number(item.SocialURLID) === Number(index));
        //console.log(removedata);
        if (Number(removedata[0].SocialURLID) !== 0) {
            const formData = new FormData();
            formData.append("id", removedata[0].SocialURLID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteSocialLink', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });
        }
        let lstContent = [];
        let loop = 0;
        socialLink.map(row => {
            if (loop !== idxloop) {
                lstContent.push({ SocialURLID: row.SocialURLID, SocialTitle: row.SocialTitle, SocialURL: row.SocialURL });
                //console.log(row)
            }
            loop++;
        });
        setSocialLink(lstContent);

    };
    const AddSocialLink = () => {
        let checkdata = true;
        socialLink.map((value, j) => {
            if ((value.SocialTitle.trim().length === 0) || (value.SocialURL.trim().length === 0)) {
                checkdata = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูล Link Social ให้ครบถ้วน ก่อนเพิ่มข้อมูลถัดไป',
                })
            }
        })
        if (checkdata === true) {
            setSocialLink((prevFriends) => [
                ...prevFriends,
                {
                    SocialURLID: 0,
                    SocialTitle: '',
                    SocialURL: '',
                },
            ]);
        }
    }
    const handleRemoveRecommendedLink = (index, idxloop) => {
        const removedata = recommendedCourse.filter((item) => Number(item.RecommendedCourseID) === Number(index));
        //console.log(removedata);
        if (Number(removedata[0].RecommendedCourseID) !== 0) {
            const formData = new FormData();
            formData.append("id", removedata[0].RecommendedCourseID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteRecommended', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });
        }
        let lstContent = [];
        let loop = 0;
        recommendedCourse.map(row => {
            if (loop !== idxloop) {
                lstContent.push({ RecommendedCourseID: row.RecommendedCourseID, RecommendedTitle: row.RecommendedTitle, RecommendedCourseURL: row.RecommendedCourseURL });
                //console.log(row)
            }
            loop++;
        });
        setRecommendedCourse(lstContent);

    };
    const AddRecommendedLink = () => {
        let checkdata = true;
        recommendedCourse.map((value, j) => {
            if ((value.RecommendedTitle.trim().length === 0) || (value.RecommendedCourseURL.trim().length === 0)) {
                checkdata = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูลหลักสูตรแนะนำให้ครบถ้วน ก่อนเพิ่มข้อมูลถัดไป',
                })
            }
        })
        if (checkdata === true) {
            setRecommendedCourse((prevFriends) => [
                ...prevFriends,
                {
                    RecommendedCourseID: 0,
                    RecommendedTitle: '',
                    RecommendedCourseURL: '',
                },
            ]);
        }
    }

    const handleRemoveSignList = (index, idxloop) => {
        const removedata = signList.filter((item) => Number(item.SignID) === Number(index));
        //console.log(removedata);
        if (Number(removedata[0].SignID) !== 0) {
            const formData = new FormData();
            formData.append("id", removedata[0].SignID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteSign', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });
        }
        let lstContent = [];
        let loop = 0;
        signList.map(row => {
            if (loop !== idxloop) {
                lstContent.push({ SignID: row.SignID, SignName: row.SignName, SignPos: row.SignPos, SignImage: row.SignImage, preview: row.preview });
                //console.log(row)
            }
            loop++;
        });
        setSignList(lstContent);

    };
    const AddSignList = () => {
        let checksignimg = true;
        signList.map((value, j) => {
            if ((value.SignImage === null) || (value.SignName.trim().length === 0) || (value.SignPos.trim().length === 0)) {
                checksignimg = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูลผู้ลงนามและภาพลายเซ็นต์ให้ครบถ้วน ก่อนเพิ่มข้อมูลผู้ลงนามท่านถัดไป',
                })
            }
        })
        if (checksignimg === true) {
            if (signList.length < 3) {
                //const data = [...signList];
                setSignList((prevFriends) => [
                    ...prevFriends,
                    {
                        SignID: 0,
                        SignName: '',
                        SignPos: '',
                        SignImage: null,
                        //preview: '',
                    },
                ]);
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: 'แจ้งให้ทราบ',
                    text: 'ท่านสามารถเพิ่มข้อมูลผู้ลงนามได้สูงสุด 3 รายการ',
                })
            }
        }
    }
    const handleRemoveCertLogoFile = filename => {
        for (let file of certLogoFiles) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteCertificateLogo', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched files
        }
        setCertLogoFiles(certLogoFiles.filter(item => item['name'] !== filename));
    };
    const files = certLogoFiles.map((file, i) => (

        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveCertLogoFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการอีเลิร์นนิง (E-learning)"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการอีเลิร์นนิง (E-learning)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/elearning/ELearningList" }}>{"จัดการวิชา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/elearning/NewESubject" }}>{"สร้าง"}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="card">
                                    <div className="card-body row">
                                        <div className="col-12">
                                            <label htmlFor="eLearnTitle" className="required">ชื่อวิชา</label>
                                            <input type="text" className="form-control" id="eLearnTitle" name="eLearnTitle" placeholder="กรุณากรอก" maxLength="300" value={eLearnTitle} onChange={(e) => { setELearnTitle(e.currentTarget.value) }} />
                                            <SError error={errors["eLearnTitle"]} />
                                        </div>
                                        <div className="alert alert-dark col-12" role="alert">รายละเอียด</div>
                                        <div className="col-12">
                                            <label htmlFor="chapter" className="required">เนื้อหา</label>
                                            <input type="text" className="form-control " id="chapter" name="chapter" placeholder="กรุณากรอก" maxLength="300" value={chapter} onChange={(e) => { setChapter(e.currentTarget.value); }} />
                                            <SError error={errors["chapter"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="targetGroup" className="required">กลุ่มเป้าหมาย</label>
                                            <input type="text" className="form-control " id="targetGroup" name="targetGroup" placeholder="กรุณากรอก" maxLength="300" value={targetGroup} onChange={(e) => { setTargetGroup(e.currentTarget.value); }} />
                                            <SError error={errors["targetGroup"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="condition" className="required">เกณฑ์การเรียนจบ</label>
                                            <input type="text" className="form-control " id="condition" name="condition" placeholder="กรุณากรอก" maxLength="300" value={condition} onChange={(e) => { setCondition(e.currentTarget.value); }} />
                                            <SError error={errors["condition"]} />
                                        </div>
                                        <div className="col-12">
                                            <label className="required">รายละเอียดรายวิชา</label>
                                            <ReactSummernote
                                                value={courseDetail}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 150,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setCourseDetail}
                                            />
                                            <SError error={errors["courseDetail"]} />
                                        </div>
                                        <div className="col-12">
                                            <label className="required">วัตถุประสงค์</label>
                                            <ReactSummernote
                                                value={courseObjective}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 150,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setCourseObjective}
                                            />
                                            <SError error={errors["courseObjective"]} />
                                        </div>
                                        <div className="col-12">
                                            <label className="required">เกณฑ์การวัดและประเมินผล</label>
                                            <ReactSummernote
                                                value={courseCriterion}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 150,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setCourseCriterion}
                                            />
                                            <SError error={errors["courseCriterion"]} />
                                        </div>
                                        <div className="col-12">
                                            <label className="required">หมายเหตุ</label>
                                            <ReactSummernote
                                                value={courseRemark}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 150,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setCourseRemark}
                                            />
                                            <SError error={errors["courseRemark"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="card">
                                    <div className="card-body row">
                                        <div className="col-12">
                                            <label htmlFor="eLearnTitleEN">ชื่อวิชา</label>
                                            <input type="text" className="form-control" id="eLearnTitleEN" name="eLearnTitleEN" placeholder="กรุณากรอก" maxLength="300" value={eLearnTitleEN} onChange={(e) => { setELearnTitleEN(e.currentTarget.value) }} />
                                            <SError error={errors["eLearnTitleEN"]} />
                                        </div>
                                        <div className="alert alert-dark col-12" role="alert">รายละเอียด</div>
                                        <div className="col-12">
                                            <label htmlFor="chapterEN">เนื้อหา</label>
                                            <input type="text" className="form-control " id="chapterEN" name="chapterEN" placeholder="กรุณากรอก" maxLength="300" value={chapterEN} onChange={(e) => { setChapterEN(e.currentTarget.value); }} />
                                            <SError error={errors["chapterEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="targetGroupEN">กลุ่มเป้าหมาย</label>
                                            <input type="text" className="form-control " id="targetGroupEN" name="targetGroupEN" placeholder="กรุณากรอก" maxLength="300" value={targetGroupEN} onChange={(e) => { setTargetGroupEN(e.currentTarget.value); }} />
                                            <SError error={errors["targetGroupEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="conditionEN">เกณฑ์การเรียนจบ</label>
                                            <input type="text" className="form-control " id="conditionEN" name="conditionEN" placeholder="กรุณากรอก" maxLength="300" value={conditionEN} onChange={(e) => { setConditionEN(e.currentTarget.value); }} />
                                            <SError error={errors["conditionEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label>รายละเอียดรายวิชา</label>
                                            <ReactSummernote
                                                value={courseDetailEN}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 150,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setCourseDetailEN}
                                            />
                                            <SError error={errors["courseDetailEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label>วัตถุประสงค์</label>
                                            <ReactSummernote
                                                value={courseObjectiveEN}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 150,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setCourseObjectiveEN}
                                            />
                                            <SError error={errors["courseObjectiveEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label>เกณฑ์การวัดและประเมินผล</label>
                                            <ReactSummernote
                                                value={courseCriterionEN}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 150,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setCourseCriterionEN}
                                            />
                                            <SError error={errors["courseCriterionEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label>หมายเหตุ</label>
                                            <ReactSummernote
                                                value={courseRemarkEN}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 150,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setCourseRemarkEN}
                                            />
                                            <SError error={errors["courseRemarkEN"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">

                                <div className="col-6">
                                    <label htmlFor="courseType" className="required">หมวดหมู่</label>
                                    <select className="form-control mb-2 mr-2" id="category" name="category" value={category} onChange={(e) => { setCategory(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {CourseCategorySelect}
                                    </select>
                                    <SError error={errors["category"]} />
                                </div>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <label htmlFor="courseType" className="required">หลักสูตร</label>
                                    <select className="form-control mb-2 mr-2" id="courseType" name="courseType" value={courseType} onChange={(e) => { setCourseType(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {CourseTypeOption}
                                    </select>
                                    <SError error={errors["courseType"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="maxEnroll" className="required">จำนวนที่นั่ง</label>
                                    <input type="text" className="form-control" id="maxEnroll" name="maxEnroll" placeholder="กรุณากรอก" value={maxEnroll} onChange={(e) => { handleMaxEnroll(e); }} />
                                    <SError error={errors["maxEnroll"]} />
                                </div>
                                <div className="col-5">
                                    <label className="required">วันที่เริ่มลงทะเบียน</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={registeredStartDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                            {SetRegisteredStartDate} name="registeredStartDate" id="registeredStartDate" />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-5">
                                    <label className="required">วันที่สิ้นสุดลงทะเบียน</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={registeredEndDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                            minDate={registeredStartDate}
                                            onChange={SetRegisteredEndDate} name="registeredEndDate" id="registeredEndDate" />
                                    </MuiPickersUtilsProvider>
                                    <SError error={errors["registeredEndDate"]} />
                                </div>
                                <div className="col-5">
                                    <label className="required">วันที่เริ่มเรียน</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={learnStartDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                            {SetLearnStartDate} name="learnStartDate" id="learnStartDate" />
                                    </MuiPickersUtilsProvider>

                                </div>
                                <div className="col-5">
                                    <label className="required">วันที่สิ้นสุดการเรียน</label>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                        <DatePicker value={learnEndDate} format="D MMMM YYYY"
                                            pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                            minDate={learnStartDate}
                                            onChange={SetLearnEndDate} name="learnEndDate" id="learnEndDate" />
                                    </MuiPickersUtilsProvider>
                                    <SError error={errors["learnEndDate"]} />
                                </div>
                                <div className="col-2"></div>

                                <div className="col-12 text-right"><a href="/" onClick={(e) => { e.preventDefault(); AddSocialLink(); }}><i className="fas fa-plus-circle"></i> เพิ่ม Link Social</a></div>
                                <div className="col-12">
                                    {socialLink.map((item, i) => (
                                        <div key={i} className="row mt-2">
                                            <div className="col-4">
                                                <label htmlFor="listofContent">Link Social Title</label>
                                                <input type="text" className="form-control " id="socialTitle" value={item.SocialTitle} name="socialTitle" placeholder="กรุณากรอก" onChange={(e) => {
                                                    setSocialLink(
                                                        socialLink.map((value, j) => {
                                                            if (i === j) {
                                                                value.SocialTitle = e.target.value;
                                                            }
                                                            return value;
                                                        })
                                                    )
                                                }} />
                                            </div>
                                            <div className="col-7">
                                                <label htmlFor="listofContent">Link Social URL</label>
                                                <input type="text" className="form-control " id="socialLink" value={item.SocialURL} name="socialLink" placeholder="กรุณากรอก" onChange={(e) => {
                                                    setSocialLink(
                                                        socialLink.map((value, j) => {
                                                            if (i === j) {
                                                                value.SocialURL = e.target.value;
                                                            }
                                                            return value;
                                                        })
                                                    )
                                                }} />
                                            </div>
                                            <span className="col-1" style={{ marginTop: 40 }}><a onClick={(e) => { e.preventDefault(); handleRemoveSocialLink(item.SocialURLID, i) }}><i className="text-danger fas fa-minus-circle"></i> ลบ</a></span>
                                        </div>
                                    ))
                                    }
                                </div>

                                <div className="col-12 text-right"><a href="/" onClick={(e) => { e.preventDefault(); AddRecommendedLink(); }}><i className="fas fa-plus-circle"></i> เพิ่ม Link แนะนำหลักสูตร</a></div>
                                <div className="col-12">
                                    {recommendedCourse.map((item, i) => (
                                        <div key={i} className="row mt-2">
                                            <div className="col-4">
                                                <label htmlFor="listofContent">หลักสูตรที่แนะนำ</label>
                                                <input type="text" className="form-control " id="recommendedTitle" value={item.RecommendedTitle} name="recommendedTitle" placeholder="กรุณากรอก" onChange={(e) => {
                                                    setRecommendedCourse(
                                                        recommendedCourse.map((value, j) => {
                                                            if (i === j) {
                                                                value.RecommendedTitle = e.target.value;
                                                            }
                                                            return value;
                                                        })
                                                    )
                                                }} />
                                            </div>
                                            <div className="col-7">
                                                <label htmlFor="listofContent">Link แนะนำหลักสูตร</label>
                                                <input type="text" className="form-control " id="recommendedLink" value={item.RecommendedCourseURL} name="recommendedLink" placeholder="กรุณากรอก" onChange={(e) => {
                                                    setRecommendedCourse(
                                                        recommendedCourse.map((value, j) => {
                                                            if (i === j) {
                                                                value.RecommendedCourseURL = e.target.value;
                                                            }
                                                            return value;
                                                        })
                                                    )
                                                }} />
                                            </div>
                                            <span className="col-1" style={{ marginTop: 40 }}><a onClick={(e) => { e.preventDefault(); handleRemoveRecommendedLink(item.RecommendedCourseID, i) }}><i className="text-danger fas fa-minus-circle"></i> ลบ</a></span>
                                        </div>
                                    ))
                                    }
                                </div>

                                <div className="col-6">
                                    <label htmlFor="lectureName" className="required">อาจารย์ผู้สอน</label>
                                    <input type="text" className="form-control " id="lectureName" name="lectureName" placeholder="กรุณากรอก" value={lectureName} onChange={(e) => { setLectureName(e.currentTarget.value); }} />
                                    <SError error={errors["lectureName"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปภาพอาจารย์</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleLectureImageOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {LectureImgPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                    <span className="error">{errors["lectureImg"]}</span>
                                </div>

                                <div className="col-12 text-right"><a href="/" onClick={(e) => { e.preventDefault(); AddSignList(); }}><i className="fas fa-plus-circle"></i> เพิ่มผู้ลงนามในประกาศนียบัตร</a></div>
                                <div className="col-12">
                                    {signList.map((item, i) => (
                                        <div key={i} className="row mt-2">
                                            <div className="col-4">
                                                <label htmlFor="listofContent">ผู้ลงนามในประกาศนียบัตร</label>
                                                <input type="text" className="form-control " id="recommendedTitle" value={item.SignName} name="recommendedTitle" placeholder="กรุณากรอก" onChange={(e) => {
                                                    setSignList(
                                                        signList.map((value, j) => {
                                                            if (i === j) {
                                                                value.SignName = e.target.value;
                                                            }
                                                            return value;
                                                        })
                                                    )
                                                }} />
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="listofContent">ตำแหน่ง</label>
                                                <input type="text" className="form-control " id="recommendedLink" value={item.SignPos} name="recommendedLink" placeholder="กรุณากรอก" onChange={(e) => {
                                                    setSignList(
                                                        signList.map((value, j) => {
                                                            if (i === j) {
                                                                value.SignPos = e.target.value;
                                                            }
                                                            return value;
                                                        })
                                                    )
                                                }} />
                                            </div>
                                            <div className="col-2">
                                                <label className="required">ภาพลายเซ็นต์</label>
                                                <Dropzone
                                                    className='dropzone'
                                                    activeClassName='active-dropzone'
                                                    multiple={false}
                                                    //onDrop={handleSignImageOnDrop(i,)}
                                                    onDrop={acceptedFiles => {
                                                        setSignList(
                                                            signList.map((value, j) => {
                                                                if (i === j) {
                                                                    value.SignImage = acceptedFiles[0];
                                                                    value.preview = URL.createObjectURL(acceptedFiles[0]);
                                                                }
                                                                return value;
                                                            })
                                                        )
                                                    }}
                                                    accept="image/*"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="container">
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                            <div className="col-2">
                                                {
                                                    (typeof item.preview !== 'undefined') && (<img className="preview-upload" src={item.preview} alt="Sign " onChange={(e) => { e.target.src = URL.createObjectURL(item.SignImage); }} />)
                                                }
                                            </div>
                                            <span className="col-1" style={{ marginTop: 40 }}><a onClick={(e) => { e.preventDefault(); handleRemoveSignList(item.SignID, i) }}><i className="text-danger fas fa-minus-circle"></i> ลบ</a></span>
                                        </div>
                                    ))
                                    }
                                    <span className="error">{errors["signList"]}</span>
                                </div>

                                <div className="col-6">
                                    <label htmlFor="coverType" className="required">ประเภทคณะกรรมการ</label>
                                    <select className="form-control mb-2 mr-2" id="committeeType" name="committeeType" value={committeeType} onChange={(e) => { setCommitteeType(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        <option key="1" value="1" >คณะกรรมการสิทธิมนุษยชนแห่งชาติ</option>
                                        <option key="2" value="2" >สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</option>
                                    </select>
                                    <SError error={errors["committeeType"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coverType" className="required">ประเภทประกาศนียบัตร</label>
                                    <select className="form-control mb-2 mr-2" id="certificateType" name="certificateType" value={certificateType} onChange={(e) => { setCertificateType(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        <option key="1" value="1" >ประกาศนียบัตร</option>
                                        <option key="2" value="2" >เกียรติบัตร</option>
                                        <option key="3" value="3" >วุฒิบัตร</option>
                                    </select>
                                    <SError error={errors["certificateType"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="enname" >รูปภาพโลโก้ประกาศนียบัตร</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        maxFiles={3}
                                        onDrop={acceptedFiles => {
                                            var filecount = certLogoFiles.length;
                                            if (filecount < 3) {
                                                setCertLogoFiles([...certLogoFiles, ...acceptedFiles]);
                                                setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                    id: 0,
                                                    preview: URL.createObjectURL(file)
                                                })));
                                            }
                                        }}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {files}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <SError error={errors["certLogoFiles"]} />
                                </div>

                                <div className="col-12">
                                    <label className="required">รูปภาพหน้าปก สำหรับมือถือ</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgMobileCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {MobileCoverPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                    <span className="error">{errors["mobilecover"]}</span>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coverType" className="required">ประเภทการแสดงผล</label>
                                    <select className="form-control mb-2 mr-2" id="coverType" name="coverType" value={coverType} onChange={(e) => { setCoverType(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        <option key="1" value="1" >รูปภาพ</option>
                                        <option key="2" value="2" >VDO</option>
                                    </select>
                                    <SError error={errors["coverType"]} />
                                </div>
                                {Number(coverType) === 1 && (
                                    <div className="col-12">
                                        <label className="required">รูปภาพหน้าปกรายวิชา</label>
                                        <Dropzone
                                            className='dropzone'
                                            activeClassName='active-dropzone'
                                            multiple={false}
                                            onDrop={handleWebCoverOnDrop}
                                            accept="image/*"
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="container">
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                    </div>
                                                    <aside>
                                                        <h4>Files</h4>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>ลำดับ</th>
                                                                        <th>ชื่อ</th>
                                                                        <th>ขนาดไฟล์</th>
                                                                        <th>ประเภทไฟล์</th>
                                                                        <th>จัดการ</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody id="your-table-body-id">
                                                                    {WebCoverPreview}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </aside>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <SError error={errors["webcover"]} />
                                    </div>)}
                                {Number(coverType) === 2 && (
                                    <div className="col-12">
                                        <label className="required">วีดีโอหน้าปกรายวิชา</label>
                                        <Dropzone
                                            className='dropzone'
                                            activeClassName='active-dropzone'
                                            multiple={false}
                                            onDrop={handleWebCoverOnDrop}
                                            accept=".mp3,.mp4,.avi,.fla,.wma,.wmv,.mpeg,.mpeg4"
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="container">
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                    </div>
                                                    <aside>
                                                        <h4>Files</h4>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>ลำดับ</th>
                                                                        <th>ชื่อ</th>
                                                                        <th>ขนาดไฟล์</th>
                                                                        <th>ประเภทไฟล์</th>
                                                                        <th>จัดการ</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody id="your-table-body-id">
                                                                    {WebCoverPreview}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </aside>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <SError error={errors["webcover"]} />
                                    </div>)}
                                <div className="col-12">
                                    <label className="required mr-2">ปักหมุด</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pin" id="pin1" value="1" checked={pin === true} onChange={(e) => { HandlePin(true); }} />
                                        <label className="form-check-label" htmlFor="pin1"> ปักหมุด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pin" id="pin2" value="0" checked={pin === false} onChange={(e) => { HandlePin(false); }} />
                                        <label className="form-check-label" htmlFor="pin2"> ไม่ปักหมุด</label>
                                    </div>
                                    <span className="error">{errors["pin"]}</span>
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published1" value="1" checked={published === true} onChange={(e) => { setPublished(true); }} />
                                        <label className="form-check-label" htmlFor="published1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published2" value="0" checked={published === false} onChange={(e) => { setPublished(false); }} />
                                        <label className="form-check-label" htmlFor="published2"> ไม่เผยแพร่</label>
                                    </div>
                                    <span className="error">{errors["published"]}</span>
                                </div>
                                {published === true && <>
                                    <div className="col-5">
                                        <label className="required">วันที่เผยแพร่</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                                {SetPublishStartDate} name="startpublishday" id="startpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-5">
                                        <label className="required">วันที่สิ้นสุด</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                minDate={publishstartDate}
                                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                                        </MuiPickersUtilsProvider>
                                        <SError error={errors["publishendDate"]} />
                                    </div>
                                    <div className="col-2"></div>
                                </>}

                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
