import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'moment/locale/th';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { authenticationService } from 'services/authentication.service.js';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function ESubjectChapter(props) {
    const currentUser = authenticationService.currentUserValue;
    //const [fields, setFields] = useState({});
    const elerningID = props.location.state.id;
    const [errors, setError] = useState({});
    const [mode, setMode] = useState(0);
    const [eLearnTitle, setELearnTitle] = useState('');
    const [chapterList, setChapterList] = useState([]);
    const [activeChapter, setActiveChapter] = useState("");
    const [chapterTitle, setChapterTitle] = useState("");
    let chapterlist = [];
    let subchapter = [];
    let testdata = [];
    function reLoadData() {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/ESubjectMainChapter', { params: { elearnID: elerningID, userID: currentUser.UserID } }).then((main) => {
            let data = main.data;
            if (data.length > 0) {
                setELearnTitle(data[0].ELearnTitle);
            }
            for (let i = 0; i < main.data.length; i++) {
                chapterlist.push(main.data[i]);
            }
        })
            .then(() => {
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getSubChapter', { params: { elearnID: elerningID } }).then((sub) => {
                    for (let j = 0; j < sub.data.length; j++) {
                        subchapter.push(sub.data[j]);
                    }
                })
                    .then(() => {
                        PrepairData();
                    })
            })
    }
    function PrepairData() {
        for (let i = 0; i < chapterlist.length; i++) {
            chapterlist[i].subChapter = subchapter.filter((item) => {
                return item.ParentChapterID === chapterlist[i].ChapterID /*&& item.QuestionStatus!==false*/
            })
        }
        const chapterPrepair = [...chapterlist];
        setChapterList(chapterPrepair)
    }
    useEffect(() => {
        reLoadData();
    }, []); /* <-- add this for run once*/
    const saveRootChapter = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (chapterTitle.trim().length === 0) {
            formIsValid = false;
            serrors["chapterTitle"] = "กรุณากรอกข้อมูล";
        }
        setError(serrors);
        if (formIsValid) {
            const formData = new FormData();
            formData.append("eLearnID", props.location.state.id);
            formData.append("chapterTitle", chapterTitle);
            formData.append("chapterContentType", "ROOT");
            formData.append("userID", currentUser.UserID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveChapter', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    reLoadData();
                    setMode(0);
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {

                    })
                });
        }
    }
    const updateRootChapter = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (chapterTitle.trim().length === 0) {
            formIsValid = false;
            serrors["chapterTitle"] = "กรุณากรอกข้อมูล";
        }
        setError(serrors);
        if (formIsValid) {
            const formData = new FormData();
            formData.append("chapterID", activeChapter.ChapterID);
            formData.append("chapterTitle", chapterTitle);
            formData.append("chapterContentType", "ROOT");
            formData.append("userID", currentUser.UserID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateChapter', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        window.location.reload();
                    })
                });
        }
    }
    const deleteChapter = (item) => {        
        Swal.fire({
            title: '<strong><u>ยืนยันการลบข้อมูล</u></strong>',
            icon: '',
            html:
                '<hr/><div class="col-12 text-left">' +
                '<div class="form-check">' +
                '<label >' +
                'เมื่อกดปุ่มยืนยันจะไม่สามารถกู้คืนได้อีก' +
                '</label>' +
                '</div>' +
                '</div>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
                'ยืนยัน',
            cancelButtonText:
                'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed === true) {
                //console.log(item);
                const formData = new FormData();
                formData.append("chapterID", item.ChapterID);
                formData.append("eLearnID", item.ELearnID);
                formData.append("userID", currentUser.UserID);
                axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteChapter', formData, {
                    headers: {
                        'Content-Type': `multipart/form-data`
                    }
                })
                    .then((response) => {
                        console.log(response.data.success)
                        let timerInterval;
                        if (response.data.success === false) {
                            swal.fire({
                                title: response.data.text, showConfirmButton: false,
                                timer: 500, timerProgressBar: true,
                                didOpen: () => {
                                    Swal.showLoading()
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getHtmlContainer()
                                        if (content) {
                                            const b = content.querySelector('b')
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft()
                                            }
                                        }
                                    }, 100)
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);

                                }
                            }).then((result) => {

                            })
                        }
                        else {
                            swal.fire({
                                title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                                timer: 1000, timerProgressBar: true,
                                didOpen: () => {
                                    Swal.showLoading()
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getHtmlContainer()
                                        if (content) {
                                            const b = content.querySelector('b')
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft()
                                            }
                                        }
                                    }, 100)
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);

                                }
                            }).then((result) => {
                                window.location.reload();
                            })
                        }
                    });
            }
        })
    }
    const editChaperActivity = (chapterID) => {
        props.history.push({ pathname: "/admin/elearning/SubChapterList", state: { chapterID: chapterID } });
    };
    const activityPopup = (chapterID) => {
        Swal.fire({
            title: '<strong><u>เพิ่มกิจกรรม</u></strong>',
            icon: '',
            html:
                '<hr/><div class="col-12 text-left">' +
                '<div class="form-check">' +
                '<input class="form-check-input" type="radio" name="activityRadio" id="activityRadio1" value="1">' +
                '<label class="form-check-label" for="activityRadio1">' +
                'แบบทดสอบก่อนเรียน' +
                '</label>' +
                '</div>' +

                '<div class="form-check">' +
                '<input class="form-check-input" type="radio" name="activityRadio" id="activityRadio6" value="6">' +
                '<label class="form-check-label" for="activityRadio6">' +
                'แบบทดสอบ' +
                '</label>' +
                '</div>' +

                '<div class="form-check">' +
                '<input class="form-check-input" type="radio" name="activityRadio" id="activityRadio2" value="2">' +
                '<label class="form-check-label" for="activityRadio2">' +
                'แบบทดสอบหลังเรียน' +
                '</label>' +
                '</div>' +

                '<div class="form-check">' +
                '<input class="form-check-input" type="radio" name="activityRadio" id="activityRadio3" value="3">' +
                '<label class="form-check-label" for="activityRadio3">' +
                'เนื้อหา' +
                '</label>' +
                '</div>' +

                '<div class="form-check">' +
                '<input class="form-check-input" type="radio" name="activityRadio" id="activityRadio4" value="4">' +
                '<label class="form-check-label" for="activityRadio4">' +
                'ไฟล์ วิดีโอ' +
                '</label>' +
                '</div>' +

                '<div class="form-check">' +
                '<input class="form-check-input" type="radio" name="activityRadio" id="activityRadio5" value="5">' +
                '<label class="form-check-label" for="activityRadio5">' +
                'ลิงก์' +
                '</label>' +
                '</div>' +

                '</div>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
                'เพิ่ม',
            cancelButtonText:
                'ยกเลิก',
            preConfirm: () => {
                const radioselect = Swal.getPopup().querySelector('input[name="activityRadio"]:checked');
                let action = 0;
                if (!radioselect) {
                    Swal.showValidationMessage(`กรุณาเลือกประเภทกิจกรรมที่ต้องการเพิ่ม`)
                }
                else {
                    action = radioselect.value
                }
                return { action: action }
            }
        }).then((result) => {
            if (typeof result.value !== 'undefined') {

                if (result.value.action === '1') {
                    props.history.push({ pathname: "/admin/elearning/ChapterQuestion", state: { elerningID: elerningID, chapterID: chapterID, questionType: 'PRE_TEST' } });
                }
                else if (result.value.action === '2') {
                    props.history.push({ pathname: "/admin/elearning/ChapterQuestion", state: { elerningID: elerningID, chapterID: chapterID, questionType: 'POST_TEST' } });
                }
                else if (result.value.action === '3') {
                    props.history.push({ pathname: "/admin/elearning/ChapterContent", state: { elerningID: elerningID, chapterID: chapterID } });
                }
                else if (result.value.action === '4') {
                    props.history.push({ pathname: "/admin/elearning/ChapterVDO", state: { elerningID: elerningID, chapterID: chapterID } });
                }
                else if (result.value.action === '5') {
                    props.history.push({ pathname: "/admin/elearning/ChapterLink", state: { elerningID: elerningID, chapterID: chapterID } });
                }
                else if (result.value.action === '6') {
                    props.history.push({ pathname: "/admin/elearning/ChapterQuestion", state: { elerningID: elerningID, chapterID: chapterID, questionType: 'TEST' } });
                }
            }
        })
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            chapterList,
            result.source.index,
            result.destination.index
        );
        //console.log(items)
        setChapterList(items);
        const formData = new FormData();
        //formData.append("chapterlist", items);
        for (var i = 0; i < items.length; i++) {
            //console.log(listofContent[i])
            formData.append('chapterlist[]', JSON.stringify(items[i]));

        }
        axios.post(process.env.REACT_APP_APIURL + '/api-web/reOrderChapter', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        }).then((response) => {

        });
    }
    function canAddChapter() {
        if (chapterList.length === 0) {
            return true;
        }
        for (let i = 0; i < chapterList.length; i++) {
            if (chapterList[i].subChapter.length === 0)
                return false;
        }
        return true;
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการอีเลิร์นนิง (E-learning)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/ELearningList" }}>{"จัดการวิชา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/elearning/ESubjectChapter", state: { id: props.location.state.id } }}>วิชา {eLearnTitle}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h1>วิชา {eLearnTitle}</h1 >
                                    {mode === 0 && (
                                        <>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppable">
                                                    {provided => (
                                                        <div
                                                            className="list-content"
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {(
                                                                <div className="subject-list-item">
                                                                    {chapterList.map((item, index) => (
                                                                        <Draggable
                                                                            key={item.ChapterID}
                                                                            draggableId={'item-' + item.ChapterID}
                                                                            index={index}
                                                                        >
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className="list-item col-12"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        {(item.subChapter.length === 0) && (<>{item.ChapterTitle}</>)}
                                                                                        {(item.subChapter.length > 0) && (<><a href="/" onClick={(e) => { e.preventDefault(); editChaperActivity(item.ChapterID); }} className="text-dark mr-2" title="คลิกเพื่อแก้ไขกิจกรรม">{item.ChapterTitle}</a></>)}
                                                                                        <div className="float-right">
                                                                                            <a href="/" onClick={(e) => { e.preventDefault(); setActiveChapter(item); activityPopup(item.ChapterID) }} className="text-dark mr-2"><i className="fas fa-plus-circle text-primary"></i> เพิ่มกิจกรรม</a>
                                                                                            <a href="/" onClick={(e) => { e.preventDefault(); setActiveChapter(item); setChapterTitle(item.ChapterTitle); setMode(2); }} className="text-dark mr-2"><i className="fas fa-edit"></i> แก้ไข</a>
                                                                                            <a href="/" onClick={(e) => { e.preventDefault(); setActiveChapter(item); deleteChapter(item); }} className="text-dark mr-2"><i className="fas fa-trash-alt text-danger"></i> ลบ</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                </div>
                                                            )}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            <div className="col-12">
                                                <div className="pl-4 pr-4 text-right" >
                                                    <button type="button" className="btn btn-success mr-2" onClick={e => {
                                                        e.preventDefault();
                                                        if (canAddChapter() === true) {
                                                            setChapterTitle('');
                                                            setMode(1);
                                                        }
                                                        else {
                                                            Swal.fire({
                                                                icon: 'warning',
                                                                title: 'แจ้งเตือน',
                                                                text: 'กรุณาเพิ่มข้อมูลเนื้อหาบทเรียนก่อนเพิ่มบทใหม่',
                                                            })
                                                        }
                                                    }
                                                    }>เพิ่มหัวข้อ</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {mode === 1 && (
                                        <>
                                            <div className="col-12">
                                                <label htmlFor="chapterTitle" className="required">ชื่อบท</label>
                                                <input type="text" className="form-control" id="chapterTitle" name="chapterTitle" placeholder="กรุณากรอก" value={chapterTitle} onChange={(e) => { setChapterTitle(e.currentTarget.value) }} />
                                                <SError error={errors["chapterTitle"]} />
                                            </div>
                                            <div className="col-12">
                                                <div className="pl-4 pr-4 text-right" >
                                                    <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveRootChapter(); }}><i className="fas fa-save"></i> บันทึก</button>
                                                    <button type="button" className="btn btn-dark mr-2" onClick={e => { e.preventDefault(); setMode(0); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {mode === 2 && (
                                        <>
                                            <div className="col-12">
                                                <label htmlFor="chapterTitle" className="required">ชื่อบท</label>
                                                <input type="text" className="form-control" id="chapterTitle" name="chapterTitle" placeholder="กรุณากรอก" value={chapterTitle} onChange={(e) => { setChapterTitle(e.currentTarget.value) }} />
                                                <SError error={errors["chapterTitle"]} />
                                            </div>
                                            <div className="col-12">
                                                <div className="pl-4 pr-4 text-right" >
                                                    <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); updateRootChapter(); }}><i className="fas fa-save"></i> บันทึก</button>
                                                    <button type="button" className="btn btn-dark mr-2" onClick={e => { e.preventDefault(); setMode(0); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
