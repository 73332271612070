import React, { useState, useEffect } from "react";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";


import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import 'moment/locale/th';

import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SingleFileTable from 'components/SingleFileTable';
import SError from 'components/SError';
const swal = withReactContent(Swal)

// pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabun: {
        normal: 'THSarabun.ttf',
        bold: 'THSarabun-Bold.ttf',
        italics: 'THSarabun-Italic.ttf',
        bolditalics: 'THSarabun-BoldItalic.ttf'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
}
export default function EditPersonNetwork(props) {
    const personnetworkid = props.location.state.personnetworkid;
    const currentUser = authenticationService.currentUserValue;
    const [errors, setError] = useState({});
    const [citizenID, SetCitizenID] = useState('');
    const [organization, SetOrganization] = useState('');
    const [houseNo, SetHouseNo] = useState('');
    const [provinceCode, SetProvinceCode] = useState(0);
    const [districtCode, SetDistrictCode] = useState(0);
    const [subDistricCode, SetSubDistricCode] = useState(0);
    const [postCode, SetPostCode] = useState('');
    const [phone, SetPhone] = useState('');
    const [highestEducation, SetHighestEducation] = useState('');
    //const [email, SetEmail] = useState('');
    const [titleID, SetTitleID] = useState(0);
    const [firstName, SetFirstName] = useState('');
    const [lastName, SetLastName] = useState('');
    const [type, SetType] = useState('');

    const [performanceName, SetPerformanceName] = useState('');
    const [performanceDetails, SetPerformanceDetails] = useState('');

    const [networkActivityFiles, setNetworkActivityFiles] = useState([]);
    const [filespreview, setFilesPreview] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [imageLocationFiles, setImageLocationFiles] = useState([]);
    const [memberAnnounceFile, setMemberAnnounceFile] = useState(null);
    const [memberResultFile, setMemberResultFile] = useState(null);

    const [property1, SetProperty1] = useState(false);
    const [property1Extra, SetProperty1Extra] = useState('');

    /*const [typeProperty1, SetTypeProperty1] = useState(false);
    const [typeProperty2, SetTypeProperty2] = useState(false);
    const [typeProperty3, SetTypeProperty3] = useState(false);
    const [typeProperty4, SetTypeProperty4] = useState(false);
    const [typeProperty5, SetTypeProperty5] = useState(false);
    const [typeProperty6, SetTypeProperty6] = useState(false);
    const [typeProperty7, SetTypeProperty7] = useState(false);*/

    const [targetDetail, SetTargetDetail] = useState('');
    const [specificProvinceProperty, SetSpecificProvinceProperty] = useState(false);
    const [specificProvincePropertyExtra, SetSpecificProvincePropertyExtra] = useState('');
    const [pictureProperty, SetPictureProperty] = useState(false);
    const [note, SetNote] = useState('');
    const [approve, SetApprove] = useState(0);
    const [adminNote, SetAdminNote] = useState('');

    const [titleList, SetTitleList] = useState([]);
    const [provinceList, SetProvinceList] = useState([]);
    const [allDistrictList, SetAllDistrictList] = useState([]);
    const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
    const [districtList, SetDistrictList] = useState([]);
    const [subDistrictList, SetSubDistrictList] = useState([]);

    const [topicList, setTopicList] = useState([]);
    const [selecttopicList, setSelectTopicList] = useState([]);
    let seltopiclist = [];

    useEffect(() => {
        //console.log(props.position.state.id)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchMemberInterestTopic', { params: { status: 1 } })
            .then((response) => {
                setTopicList(response.data)
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getProvince')
            .then((response) => {
                SetProvinceList(response.data);
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getDistrict')
                    .then((response) => {
                        SetDistrictList(response.data);
                        SetAllDistrictList(response.data);
                        const alldistrict = [...response.data];
                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSubDistrict')
                            .then((response) => {
                                SetSubDistrictList(response.data);
                                SetAllSubDistrictList(response.data);
                                const allsubdistrict = [...response.data];
                                axios.get(process.env.REACT_APP_APIURL + '/api-web/personNetworkbyID', { params: { id: personnetworkid } }).then((response) => {

                                    let data = response.data[0];
                                    //console.log(data)
                                    SetCitizenID(data.CitizenID);
                                    SetOrganization(data.Organization);
                                    SetType(data.Type);
                                    SetHouseNo(data.HouseNo);
                                    SetProvinceCode(data.ProvinceID);
                                    SetDistrictCode(data.DistrictID);
                                    SetSubDistricCode(data.SubDistrictID);
                                    //console.log(alldistrict.filter(item => item['ProvinceID'] === Number(data.NetProvinceID)))
                                    SetDistrictList(alldistrict.filter(item => item['ProvinceID'] === Number(data.ProvinceID)));
                                    SetSubDistrictList(allsubdistrict.filter(item => item['DistrictID'] === Number(data.DistrictID)));
                                    SetPostCode(data.PostCode);
                                    SetPhone(data.Phone);
                                    SetHighestEducation(data.HighestEducation);
                                    SetSpecificProvinceProperty(data.SpecificProvinceProperty);
                                    SetSpecificProvincePropertyExtra(data.SpecificProvincePropertyExtra);
                                    SetPictureProperty(data.PictureProperty);
                                    SetNote(data.Note);

                                    SetTitleID(data.TitleID);
                                    SetFirstName(data.FirstName);
                                    SetLastName(data.LastName);

                                    /*SetPhone2(data.Phone2);
                                    SetFax(data.Fax);*/
                                    SetPerformanceName(data.PerformanceName);
                                    SetPerformanceDetails(data.PerformanceDetails);
                                    SetProperty1(data.Property1);
                                    SetProperty1Extra(data.Property1Extra);

                                    /*SetTypeProperty1(data.Property1);
                                    SetTypeProperty2(data.Property2)
                                    SetTypeProperty3(data.Property3)
                                    SetTypeProperty4(data.Property4);
                                    SetTypeProperty2(data.Property5)
                                    SetTypeProperty3(data.Property6)
                                    SetTypeProperty4(data.Property7);*/
                                    if (data.ToppicTypeIDList !== null) {
                                        var array = data.ToppicTypeIDList.split(",").map(Number);
                                        //console.log(array)
                                        /*if (seltopiclist.indexOf(item.InterestTopicID) < 0) {
                                            seltopiclist.push(item.InterestTopicID);
                                        }*/
                                        for (let i = 0; i < array.length; i++) {
                                            seltopiclist.push(array[i]);
                                        }
                                        setSelectTopicList(seltopiclist);
                                    }

                                    SetTargetDetail(data.TargetDetail);
                                    SetApprove(data.Approve);
                                    if (data.AdminNote != null)
                                        SetAdminNote(data.AdminNote);
                                    //console.log(data.filelist)
                                    const newFiles = [...networkActivityFiles];
                                    for (let i = 0; i < data.filelist.length; i += 1) {
                                        var filename = data.filelist[i].AttachmentPath.substring(data.filelist[i].AttachmentPath.lastIndexOf('/') + 1);
                                        const filedata = {
                                            name: filename,
                                            preview: data.filelist[i].AttachmentPath,
                                            size: utils.getFileSize(data.filelist[i].AttachmentPath)
                                        };
                                        newFiles[i] = filedata;
                                    }
                                    setNetworkActivityFiles(newFiles);

                                    if (data.MemberAnnounceFilePath.length != 0) {
                                        const FilePath = {
                                            file: data.MemberAnnounceFilePath,
                                            name: data.MemberAnnounceFilePath.substring(data.MemberAnnounceFilePath.lastIndexOf('/') + 1),
                                            preview: data.MemberAnnounceFilePath,
                                            size: utils.getFileSize(data.MemberAnnounceFilePath)
                                        };
                                        setMemberAnnounceFile(FilePath);
                                    }
                                    if (data.MemberResultFilePath.length != 0) {
                                        const FilePath = {
                                            file: data.MemberResultFilePath,
                                            name: data.MemberResultFilePath.substring(data.MemberResultFilePath.lastIndexOf('/') + 1),
                                            preview: data.MemberResultFilePath,
                                            size: utils.getFileSize(data.MemberResultFilePath)
                                        };
                                        setMemberResultFile(FilePath);
                                    }

                                    const newimgFiles = [...imageFiles];
                                    const myarr = data.Images.replaceAll('"', '').replaceAll('[', '').replaceAll(']', '').split(",");
                                    //console.log(myarr)
                                    for (let i = 0; i < myarr.length; i++) {
                                        var filename = myarr[i].substring(myarr[i].lastIndexOf('/') + 1);
                                        if (filename.trim().length > 0) {
                                            const filedata = {
                                                name: filename,
                                                preview: myarr[i],
                                                size: utils.getFileSize(myarr[i])
                                            };
                                            newimgFiles[i] = filedata;
                                        }
                                    }
                                    setImageFiles(newimgFiles);

                                    const actImageFile = {
                                        file: null,
                                        name: null,
                                        preview: data.ActImages,
                                        size: null
                                    };
                                    setImageLocationFiles(actImageFile);
                                    axios.get(process.env.REACT_APP_APIURL + '/api-web/getTitle')
                                        .then((response) => {
                                            SetTitleList(response.data);
                                        });
                                });
                            });
                    });
            });
    }, []); /* <-- add this for run once*/
    const TopicCheckbox = topicList.map((item) => (
        <div className="col-12" key={item.InterestTopicID}>
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id={"operation" + item.InterestTopicID} name={"operation" + item.InterestTopicID} checked={selecttopicList.indexOf(Number(item.InterestTopicID)) > -1}
                    onChange={(e) => {
                        var checked = e.target.checked;
                        seltopiclist = [...selecttopicList];
                        if (checked) {
                            if (seltopiclist.indexOf(item.InterestTopicID) < 0) {
                                seltopiclist.push(item.InterestTopicID);
                            }
                        }
                        else {
                            if (seltopiclist.indexOf(item.InterestTopicID) > -1) {
                                let index = seltopiclist.indexOf(item.InterestTopicID);
                                seltopiclist = seltopiclist.slice(0, index).concat(seltopiclist.slice(index + 1));
                            }
                        }
                        setSelectTopicList(seltopiclist);
                        //console.log(seltopiclist)
                    }} />
                <label className="custom-control-label" htmlFor={"operation" + item.InterestTopicID}>{item.TextTH}</label>
            </div>
        </div>
    ));
    const handleChangeProvince = ProvinceID => {
        SetDistrictList(allDistrictList.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetSubDistrictList(allSubDistrictList.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetDistrictCode(0);
        SetSubDistricCode(0);
    };
    const handleChangeDistrict = DistrictID => {
        SetSubDistrictList(allSubDistrictList.filter(item => item['DistrictID'] === Number(DistrictID)));
        SetSubDistricCode(0);
    };
    const handleChangeSubDistrict = SubDistrictID => {
        const subdistrictitem = allSubDistrictList.filter(item => item['SubDistrictID'] === Number(SubDistrictID));
        if (subdistrictitem[0] != null) {
            SetPostCode(subdistrictitem[0].PostCode);
        }
        else {
            SetPostCode('');
        }
    };
    const TitleSelect = titleList.map((title) => (
        <option
            key={title.TitleID}
            value={title.TitleID}
        >
            {title.TitleNameTH}
        </option>
    ));
    const ProvinceSelect = provinceList.map((province) => (
        <option
            key={province.ProvinceID}
            value={province.ProvinceID}
        >
            {province.ProvinceTH}
        </option>
    ));
    const DistrictSelect = districtList.map((district) => (
        <option
            key={district.DistrictID}
            value={district.DistrictID}
        >
            {district.DistrictTH}
        </option>
    ));
    const SubDistrictSelect = subDistrictList.map((subdistrict) => (
        <option
            key={subdistrict.SubDistrictID}
            value={subdistrict.SubDistrictID}
        >
            {subdistrict.SubDistrictTH}
        </option>
    ));
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (citizenID.trim().length === 0) {
            formIsValid = false;
            serrors["citizenID"] = "กรุณาระบุชื่อภาษาไทย";
        }
        if (organization.trim().length === 0) {
            formIsValid = false;
            serrors["organization"] = "กรุณาระบุชื่อองค์กร";
        }
        if (Number(titleID) === 0) {
            formIsValid = false;
            serrors["titleID"] = "กรุณาเลือกคำนำหน้าชื่อ";
        }
        if (firstName.trim().length === 0) {
            formIsValid = false;
            serrors["firstName"] = "กรุณาระบุรายละเอียด";
        }
        if (lastName.trim().length === 0) {
            formIsValid = false;
            serrors["lastName"] = "กรุณาระบุรายละเอียด";
        }
        if (type.trim().length === 0) {
            formIsValid = false;
            serrors["type"] = "กรุณาระบุประเภทเครือข่าย";
        }
        if (phone.trim().length === 0) {
            formIsValid = false;
            serrors["phone"] = "กรุณาระบุรายละเอียด";
        }

        if (houseNo.trim().length === 0) {
            formIsValid = false;
            serrors["houseNo"] = "กรุณาระบุรายละเอียดภาษาไทย";
        }
        if (Number(provinceCode) === 0) {
            formIsValid = false;
            serrors["provinceCode"] = "กรุณาเลือกจังหวัด";
        }
        if (Number(districtCode) === 0) {
            formIsValid = false;
            serrors["districtCode"] = "กรุณาเลือกเขต/อำเภอ";
        }
        if (Number(subDistricCode) === 0) {
            formIsValid = false;
            serrors["subDistricCode"] = "กรุณาเลือกแขวง/ตำบล";
        }
        if (postCode.trim().length === 0) {
            formIsValid = false;
            serrors["postCode"] = "กรุณากรอกข้อมูล";
        }

        /*if (phone2.trim().length === 0) {
            formIsValid = false;
            serrors["phone2"] = "กรุณาระบุรายละเอียด";
        }*/
        if (highestEducation.trim().length === 0) {
            formIsValid = false;
            serrors["highestEducation"] = "กรุณาระบุวุฒิการศึกษาสูงสุด";
        }
        if (performanceName.trim().length === 0) {
            formIsValid = false;
            serrors["performanceName"] = "กรุณาระบุรายละเอียด";
        }
        if (performanceDetails.trim().length === 0) {
            formIsValid = false;
            serrors["performanceDetails"] = "กรุณาระบุรายละเอียด";
        }
        if (property1 === true) {
            if (property1Extra.trim().length === 0) {
                formIsValid = false;
                serrors["property1Extra"] = "กรุณาระบุรายละเอียด";
            }
        }
        /*if (typeProperty1 === false && typeProperty2 === false && typeProperty3 === false && typeProperty4 === false && typeProperty5 === false && typeProperty6 === false && typeProperty7 === false) {
            formIsValid = false;
            serrors["typeProperty"] = "กรุณาระบุอย่างน้อยหนึ่งตัวเลือก";
        }*/
        if (selecttopicList.length === 0) {
            formIsValid = false;
            //console.log(selecttopicList)
            serrors["seltopiclist"] = "โปรดเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชนอย่างน้อยหนึ่งรายการ";
        }
        if (targetDetail.trim().length === 0) {
            formIsValid = false;
            serrors["targetDetail"] = "กรุณาระบุรายละเอียด";
        }
        if (specificProvinceProperty === true) {
            if (specificProvincePropertyExtra.trim().length === 0) {
                formIsValid = false;
                serrors["specificProvincePropertyExtra"] = "กรุณาระบุรายละเอียด";
            }
        }
        if (pictureProperty === true) {
            if (imageLocationFiles.length === 0) {
                serrors["pictureProperty"] = "กรุณาเลือกไฟล์ภาพเพื่ออัปโหลด";
            }
        }
        if (approve === 0) {
            formIsValid = false;
            serrors["approve"] = "กรุณาระบุผลการอนุมัติ";
        }
        if (Number(approve) === 1) {
            if (memberAnnounceFile === null) {
                formIsValid = false;
                serrors["memberAnnounceFile"] = "กรุณาแนบไฟล์ประกาศรับจดแจ้ง";
            }
        }
        if (memberResultFile === null) {
            formIsValid = false;
            serrors["memberResultFile"] = "กรุณาแนบไฟล์แจ้งผลการอนุมัติ";
        }
        setError(serrors);
        //console.log(serrors)
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", personnetworkid);
            formData.append("citizenID", citizenID);
            formData.append("organization", organization);
            formData.append("titleID", titleID);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("type", type);
            formData.append("phone", phone);
            formData.append("houseNo", houseNo);
            formData.append("provinceCode", provinceCode);
            formData.append("districtCode", districtCode);
            formData.append("subDistricCode", subDistricCode);
            formData.append("postCode", postCode);
            formData.append("highestEducation", highestEducation);

            formData.append("performanceName", performanceName);
            formData.append("performanceDetails", performanceDetails);
            formData.append("property1", property1);
            formData.append("property1Extra", property1Extra);

            /*formData.append("typeProperty1", typeProperty1);
            formData.append("typeProperty2", typeProperty2);
            formData.append("typeProperty3", typeProperty3);
            formData.append("typeProperty4", typeProperty4);
            formData.append("typeProperty5", typeProperty5);
            formData.append("typeProperty6", typeProperty6);
            formData.append("typeProperty7", typeProperty7);*/

            formData.append("targetDetail", targetDetail);
            formData.append("specificProvinceProperty", specificProvinceProperty);
            formData.append("specificProvincePropertyExtra", specificProvincePropertyExtra);
            formData.append("pictureProperty", pictureProperty);
            formData.append("imageLocationFiles", imageLocationFiles.file);
            formData.append("note", note);
            formData.append("seltopiclist", selecttopicList);
            formData.append("approve", approve);
            formData.append("adminNote", adminNote);
            /*for (let i = 0; i < memberResultFile.length; i += 1) {
                //console.log(networkActivities[i])
                formData.append("memberResultFile[]", memberResultFile[i]);
            }*/
            if (Number(approve) === 1) {
                formData.append("memberAnnounceFile", memberAnnounceFile.file);
            }
            formData.append("memberResultFile", memberResultFile.file);
            formData.append("updateby", currentUser.UserID);


            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updatePersonNetwork', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/network/PersonNetworkList" });
                        }
                    })
                });

        } else {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
        }
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/network/PersonNetworkList" });
    };

    const NetActivityFilesList = networkActivityFiles.map((file, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
            </td>
        </tr>
    ));
    const handleRemoveFile = filename => {
        for (let file of memberResultFile) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched files
        }
        setMemberResultFile(memberResultFile.filter(item => item['name'] !== filename));
    };

    const NetImageFilesList = imageFiles.map((file, i) => (
        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
            </td>
        </tr>
    ));

    const NetLocationActivityFilesList = (
        <img className="preview-upload" src={imageLocationFiles.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imageLocationFiles); }} alt="Activity Image" />
    );
    const handleMemberAnnouceFilesOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setMemberAnnounceFile(imageFile);
    };
    const handleMemberResultFilesOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setMemberResultFile(imageFile);
    };
    const handleRemoveMemberAnnouceFile = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", memberAnnounceFile.file);
        formData.append("fieldName", 'MemberAnnounceFilePath');
        formData.append("id", personnetworkid);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteCorporateNetworkFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setMemberAnnounceFile(null);
    };
    const handleRemoveMemberResultFile = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", memberResultFile.file);
        formData.append("fieldName", 'MemberResultFilePath');
        formData.append("id", personnetworkid);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteCorporateNetworkFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setMemberResultFile(null);
    };
    const handleEdit = params => {
        props.history.push({ pathname: "/admin/network/EditPersonNetwork", state: { personnetworkid: personnetworkid } });
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"} path={<><span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/network/PersonNetworkList" }}>{"เครือข่ายบุคคลด้านสิทธิมนุษยชน"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link onClick={e => { e.preventDefault(); handleEdit(); }} id="profile-btn" className="active" to={{ pathname: "/admin/network/EditPersonNetwork" }}>{"แก้ไข"}</Link></>}

            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">

                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-6">
                                    <label htmlFor="citizenID" className="required">รหัสบัตรประชาชน</label>
                                    <input type="text" className="form-control " id="citizenID" value={citizenID} name="citizenID" placeholder="กรุณากรอก" onChange={(e) => { SetCitizenID(e.currentTarget.value) }} />
                                    <SError error={errors["citizenID"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="titleID" className="required">คำนำหน้า</label>
                                    <select className="form-control mb-2 mr-2" id="titleID" name="titleID" value={titleID} onChange={(e) => { SetTitleID(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {TitleSelect}
                                    </select>
                                    <SError error={errors["titleID"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="firstName" className="required">ชื่อ</label>
                                    <input type="text" className="form-control " id="firstName" value={firstName} name="firstName" placeholder="กรุณากรอก" onChange={(e) => { SetFirstName(e.currentTarget.value) }} />
                                    <SError error={errors["firstName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="lastName" className="required">นามสกุล</label>
                                    <input type="text" className="form-control " id="lastName" value={lastName} name="lastName" placeholder="กรุณากรอก" onChange={(e) => { SetLastName(e.currentTarget.value) }} />
                                    <SError error={errors["lastName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="organization" className="required">สังกัด</label>
                                    <input type="text" className="form-control " id="organization" value={organization} name="organization" placeholder="กรุณากรอก" onChange={(e) => { SetOrganization(e.currentTarget.value) }} />
                                    <SError error={errors["organization"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="type" className="required">ประเภทเครือข่าย</label>
                                    <select className="form-control mb-2 mr-2" id="type" name="type" value={type} onChange={(e) => { SetType(e.currentTarget.value); }}>
                                        <option key="0" value="" >กรุณาเลือก</option>
                                        <option key="ภาครัฐ" value="ภาครัฐ" >ภาครัฐ</option>
                                        <option key="ภาคประชาสังคม" value="ภาคประชาสังคม" >ภาคประชาสังคม</option>
                                    </select>
                                    <SError error={errors["type"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="phone" className="required">เบอร์โทรศัพท์ที่ติดต่อได้</label>
                                    <input type="text" className="form-control " id="phone" value={phone} name="phone" placeholder="กรุณากรอก" onChange={(e) => { SetPhone(e.currentTarget.value) }} />
                                    <SError error={errors["phone"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="houseNo" className="required">ที่อยู่ที่ติดต่อได้</label>
                                    <input type="text" className="form-control " id="houseNo" value={houseNo} name="houseNo" placeholder="กรุณากรอก" onChange={(e) => { SetHouseNo(e.currentTarget.value) }} />
                                    <SError error={errors["houseNo"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="provinceCode" className="required">จังหวัด</label>
                                    <select className="form-control mb-2 mr-2" id="provinceCode" name="provinceCode" value={provinceCode} onChange={(e) => { SetProvinceCode(e.currentTarget.value); handleChangeProvince(e.currentTarget.value); }} >
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {ProvinceSelect}
                                    </select>
                                    <SError error={errors["provinceCode"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="districtCode" className="required">เขต/อำเภอ</label>
                                    <select className="form-control mb-2 mr-2" id="districtCode" name="districtCode" value={districtCode} onChange={(e) => { SetDistrictCode(e.currentTarget.value); handleChangeDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {DistrictSelect}
                                    </select>
                                    <SError error={errors["districtCode"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="subDistricCode" className="required">แขวง/ตำบล</label>
                                    <select className="form-control mb-2 mr-2" id="subDistricCode" name="subDistricCode" value={subDistricCode} onChange={(e) => { SetSubDistricCode(e.currentTarget.value); handleChangeSubDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {SubDistrictSelect}
                                    </select>
                                    <SError error={errors["subDistricCode"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="orgName" className="required">รหัสไปรษณีย์</label>
                                    <input type="text" className="form-control " id="postCode" value={postCode} name="postCode" placeholder="กรุณากรอก" onChange={(e) => { SetPostCode(e.currentTarget.value) }} />
                                    <SError error={errors["postCode"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="highestEducation" className="required">ประวัติการศึกษาสูงสุด</label>
                                    <select className="form-control mb-2 mr-2" id="highestEducation" name="highestEducation" value={highestEducation.trim()} onChange={(e) => { SetHighestEducation(e.currentTarget.value);/*alert(highestEducation);*/ }}>
                                        <option key="0" value='' >กรุณาเลือก</option>
                                        <option key="1" value='ประถมศึกษา' >ประถมศึกษา</option>
                                        <option key="2" value='มัธยมศึกษา' >มัธยมศึกษา</option>
                                        <option key="3" value='ปริญญาตรี' >ปริญญาตรี</option>
                                        <option key="4" value='ปริญญาโท' >ปริญญาโท</option>
                                        <option key="5" value='ปริญญาเอก' >ปริญญาเอก</option>
                                        <option key="6" value='ไม่ระบุ' >ไม่ระบุ</option>
                                    </select>
                                    <SError error={errors["highestEducation"]} />
                                </div>


                                <div className="alert alert-dark col-12" role="alert">
                                    ผลงานที่สำคัญด้านสิทธิมนุษยชน
                                </div>
                                <div className="col-12">
                                    <label htmlFor="performanceName" className="required">ชื่อผลการดำเนินงาน</label>
                                    <input type="text" className="form-control " id="performanceName" value={performanceName} name="performanceName" placeholder="กรุณากรอก" onChange={(e) => { SetPerformanceName(e.currentTarget.value) }} />
                                    <SError error={errors["performanceName"]} />
                                </div>
                                <div className="col-12">
                                    <div>
                                        <textarea className="form-control" name="performanceDetails" id="performanceDetails" rows="8" value={performanceDetails} placeholder="รายละเอียดผลงาน (โดยย่อ)" onChange={e => { SetPerformanceDetails(e.target.value) }}></textarea>
                                        <SError error={errors["performanceDetails"]} />
                                    </div>
                                </div>

                                <div className="alert alert-dark col-12" role="alert">
                                    เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                                </div>
                                <div className="col-12">
                                    {/*<Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setNetworkActivityFiles([...networkActivityFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {NetActivityFilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>*/}
                                    <aside>
                                        <h4>Files</h4>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>ลำดับ</th>
                                                        <th>ชื่อ</th>
                                                        <th>ขนาดไฟล์</th>
                                                        <th>ประเภทไฟล์</th>
                                                        <th>จัดการ</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="your-table-body-id">
                                                    {NetActivityFilesList}
                                                </tbody>
                                            </table>
                                        </div>
                                    </aside>
                                </div>

                                <div className="alert alert-dark col-12" role="alert">
                                    รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
                                </div>
                                <div className="col-12">
                                    {/*<Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setImageFiles([...imageFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {NetImageFilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>*/}
                                    <aside>
                                        <h4>Files</h4>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>ลำดับ</th>
                                                        <th>ชื่อ</th>
                                                        <th>ขนาดไฟล์</th>
                                                        <th>ประเภทไฟล์</th>
                                                        <th>จัดการ</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="your-table-body-id">
                                                    {NetImageFilesList}
                                                </tbody>
                                            </table>
                                        </div>
                                    </aside>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="property1Extra" className="required">ไม่เคยมีผลการดำเนินงานด้านสิทธิมนุษยชน แต่มีความเกี่ยวข้องอื่นๆ ในเรื่องสิทธิมนุษยชน (เช่น เคยเป็นยื่นผู้ร้องเรียนต่อ กสม. ฯลฯ)</label>
                                    <input type="text" className="form-control " id="property1Extra" value={property1Extra} name="property1Extra" placeholder="กรุณากรอก" onChange={(e) => { SetProperty1Extra(e.currentTarget.value) }} />
                                    <SError error={errors["property1Extra"]} />
                                </div>

                                <div className="alert alert-dark col-12" role="alert">
                                    ประเด็นสิทธิมนุษยชนหลัก
                                </div>
                                {TopicCheckbox}
                                <div className="col-12">
                                    <SError error={errors["typeProperty"]} />
                                </div>

                                <div className="alert alert-dark col-12" role="alert">
                                    รายละเอียดประเด็นสิทธิมนุษยชนกลุ่มย่อยและเป้าหมายเฉพาะที่เชี่ยวชาญ/ปฏิบัติงาน/สนใจ
                                </div>
                                <div className="col-12">
                                    <label htmlFor="targetDetail" >รายละเอียดผลงาน (โดยย่อ)</label>
                                    <div>
                                        <textarea className="form-control" name="targetDetail" id="targetDetail" rows="8" value={targetDetail} onChange={e => { SetTargetDetail(e.target.value) }}></textarea>
                                        <SError error={errors["targetDetail"]} />
                                    </div>
                                </div>

                                <div className="alert alert-dark col-12" role="alert">
                                    พื้นที่ปฏิบัติงานเฉพาะ
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">ลักษณะพื้นที่ปฏิบัติงาน</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="specificProvinceProperty" id="specificProvinceProperty1" value="0" checked={specificProvinceProperty === false} onChange={(e) => { SetSpecificProvinceProperty(false); }} />
                                        <label className="form-check-label" htmlFor="specificProvinceProperty1"> ทุกจังหวัด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="specificProvinceProperty" id="specificProvinceProperty2" value="1" checked={specificProvinceProperty === true} onChange={(e) => { SetSpecificProvinceProperty(true); }} />
                                        <label className="form-check-label" htmlFor="specificProvinceProperty2"> บางจังหวัด</label>
                                    </div>
                                    <SError error={errors["specificProvinceProperty"]} />
                                </div>
                                {
                                    specificProvinceProperty === true && (
                                        <div className="col-12">
                                            <label htmlFor="specificProvincePropertyExtra" className="required">ระบุจังหวัด (ได้มากกว่า 1 จังหวัด)</label>
                                            <input type="text" className="form-control " id="specificProvincePropertyExtra" value={specificProvincePropertyExtra} name="specificProvincePropertyExtra" placeholder="กรุณากรอก" onChange={(e) => { SetSpecificProvincePropertyExtra(e.currentTarget.value) }} />
                                            <SError error={errors["specificProvincePropertyExtra"]} />
                                        </div>
                                    )
                                }

                                <div className="alert alert-dark col-12" role="alert">
                                    รูปถ่าย
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">การใช้งานรูปถ่าย</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pictureProperty" id="pictureProperty1" value="1" checked={pictureProperty === true} onChange={(e) => { SetPictureProperty(true); }} />
                                        <label className="form-check-label" htmlFor="pictureProperty1"> ประสงค์ให้รูปถ่าย</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pictureProperty" id="pictureProperty2" value="0" checked={pictureProperty === false} onChange={(e) => { SetPictureProperty(false); }} />
                                        <label className="form-check-label" htmlFor="pictureProperty2"> ไม่ประสงค์ให้รูปถ่าย</label>
                                    </div>
                                    <SError error={errors["pictureProperty"]} />
                                </div>
                                {
                                    pictureProperty === true && (
                                        <div className="col-12">
                                            {/*<Dropzone
                                                className="input-dropzone dropzone"
                                                multiple={false}
                                                onDrop={acceptedFiles =>{
                                                    const imageFile = {
                                                        file: acceptedFiles[0],
                                                        name: acceptedFiles[0].name,
                                                        preview: URL.createObjectURL(acceptedFiles[0]),
                                                        size: acceptedFiles[0].size
                                                    };
                                                    setImageLocationFiles(imageFile);
                                                }}
                                                accept="image/*"
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className="container">
                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                            <input {...getInputProps()} />
                                                            <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                        </div>
                                                        <aside>
                                                            {NetLocationActivityFilesList}
                                                        </aside>
                                                    </section>
                                                )}

                                            </Dropzone>*/}
                                            {NetLocationActivityFilesList}
                                        </div>
                                    )

                                }

                                <div className="alert alert-dark col-12" role="alert">
                                    หมายเหตุ
                                </div>
                                <div className="col-12">
                                    <div>
                                        <textarea className="form-control" name="note" id="note" rows="8" value={note} onChange={e => { SetNote(e.target.value) }}></textarea>
                                        <SError error={errors["note"]} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="col-12"><h4 className="text-left ml-4">สำหรับเจ้าหน้าที่</h4></div>
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="approve" id="approve0" value="0" checked={approve === 0} onChange={(e) => { SetApprove(0); }} />
                                        <label className="form-check-label" htmlFor="approve0"> รอการอนุมัติ</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="approve" id="approve1" value="1" checked={approve === 1} onChange={(e) => { SetApprove(1); }} />
                                        <label className="form-check-label" htmlFor="approve1"> อนุมัติ</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="approve" id="approve2" value="2" checked={approve === 2} onChange={(e) => { SetApprove(2); }} />
                                        <label className="form-check-label" htmlFor="approve2"> ไม่อนุมัติ</label>
                                    </div>
                                    <SError error={errors["approve"]} />
                                </div>
                                <div className="col-12">
                                    <label className="mr-2">หมายเหตุ</label>
                                    <div>
                                        <textarea className="form-control" name="adminNote" id="note" rows="8" value={adminNote} onChange={e => { SetAdminNote(e.target.value) }}></textarea>
                                        <SError error={errors["adminNote"]} />
                                    </div>
                                </div>
                                {/*<div className="col-12">
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setMemberResultFile([...memberResultFile, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {NetAdminFilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                        </div>*/}
                                <div className="col-12">
                                    <label>ไฟล์ประกาศรับจดแจ้ง</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        multiple={false}
                                        onDrop={handleMemberAnnouceFilesOnDrop}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["memberAnnounceFile"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <SingleFileTable filedata={memberAnnounceFile} removefn={handleRemoveMemberAnnouceFile} />
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <label>หนังสือแจ้งผล</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        multiple={false}
                                        onDrop={handleMemberResultFilesOnDrop}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["memberResultFile"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <SingleFileTable filedata={memberResultFile} removefn={handleRemoveMemberResultFile} />
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    );
}
