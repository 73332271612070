import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import utils from 'utils/utils';
import AdminBanner from 'components/Banner/AdminBanner';
import { Chart, Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
export default function Banner(props) {
    const [isLoading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState([]);
    const [userCount, setUserCount] = useState([]);
    const [searchCount, setSearchCount] = useState([]);
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getPageReport')
            .then((response) => {
                setPageCount(response.data);
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getUserReport')
                    .then((response) => {
                        setUserCount(response.data);
                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSearchReport')
                            .then((response) => {
                                setSearchCount(response.data);
                                setLoading(false);
                            })
                    })
            })
    }, []); /* <-- add this for run once*/
    const PageChart = props => {
        let div = (<></>);
        let label = [];
        let datalist = [];
        props.item.map((item, i) => {
            label.push(item.DataName);
            datalist.push(item.Total);
        });
        const data = {
            labels: label,
            datasets: [
                {
                    label: props.title,
                    data: datalist,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.9)',
                        'rgba(54, 162, 235, 0.9)',
                        'rgba(255, 206, 86, 0.9)',
                        'rgba(75, 192, 192, 0.9)',
                        'rgba(153, 102, 255, 0.9)',
                        'rgba(255, 159, 64, 0.9)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
        const options = {
            indexAxis: 'y',
            // Elements options apply to all of the options unless overridden in a dataset
            // In this case, we are setting the border of each horizontal bar to be 2px wide
            elements: {
                bar: {
                    borderWidth: 2,
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'right',
                },
                title: {
                    display: true,
                    text: props.title,
                },
                datalabels: {
                    display: true,
                    backgroundColor: function (context) {
                        return context.dataset.backgroundColor;
                    },
                    borderRadius: 4,
                    color: 'white',
                    font: {
                        weight: 'bold'
                    },
                    formatter: function (value) {
                        return "\n" + parseFloat(value);
                    },
                    padding: 6
                }
            },
        };
        div = (
            <div className="col-12 mt-2 question-item" >
                <div style={{ height: props.height }}>
                    <Bar data={data}
                        options={options}
                    />
                </div>
            </div>
        )
        return div;
    }
    if (isLoading) {
        return (<></>);
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"รายงานและสถิติ"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/report/Dashboard" }}>{"รายงานและสถิติ"}</Link></>}
            />
            <div className="col-12">
                <div className="card content-data" style={{ padding: 15, backgroundColor: '#FFF' }}>
                    <div className="card-body row">
                        <h1 className="survey-header">รายงานสถิติจำนวนสมาชิกเข้าใช้บริการ</h1>
                        <div className="col-12" >
                            <hr />
                            <PageChart item={pageCount} title='การเข้าชม' height={pageCount.length * 30 + 50} />
                        </div>
                        <h1 className="survey-header">รายงานสมาชิกที่เข้าใช้บริการบ่อยที่สุด</h1>
                        <div className="col-12" >
                            <hr />
                            <PageChart item={userCount} title='จำนวนครั้ง' height={userCount.length * 30 + 50} />
                        </div>
                        <h1 className="survey-header">รายงานสถิติคำที่ถูกค้นหามากที่สุด</h1>
                        <div className="col-12" >
                            <hr />
                            <PageChart item={searchCount} title='จำนวนครั้ง' height={searchCount.length * 30 + 50} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
