import * as React from "react";
const SvgPr = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 23.022 18.571"
    {...props}
  >
    <path
      d="M1.5-.75A2.26 2.26 0 0 0-.75 1.5v2.895a2.26 2.26 0 0 0 2.25 2.25h1.645a2.26 2.26 0 0 0 2.25-2.25V1.5a2.26 2.26 0 0 0-2.25-2.25zm0 1.5h1.645c.428 0 .75.322.75.75v2.895c0 .428-.322.75-.75.75H1.5a.73.73 0 0 1-.75-.75V1.5c0-.428.322-.75.75-.75"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinejoin: "round",
        InkscapeStroke: "none",
      }}
      transform="translate(7.012 3.3)"
    />
    <path
      d="M925.945 927.44a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h6.37a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75z"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        InkscapeStroke: "none",
      }}
      transform="translate(-912.243 -924.766)"
    />
    <path
      d="M925.945 942.322a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h6.37a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75z"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        InkscapeStroke: "none",
      }}
      transform="translate(-912.243 -936.99)"
    />
    <path
      d="M925.945 957.865a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h6.37a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75z"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        InkscapeStroke: "none",
      }}
      transform="translate(-912.243 -949.757)"
    />
    <path
      d="M886.5 972.75a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h13.42a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75z"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        InkscapeStroke: "none",
      }}
      transform="translate(-879.848 -961.982)"
    />
    <path
      d="M886.5 987.633a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h13.42a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75z"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        InkscapeStroke: "none",
      }}
      transform="translate(-879.848 -974.206)"
    />
    <path
      d="M857.055 912.477a.75.75 0 0 0-.75.75s0 1.972.002 2.859h-2.862a.75.75 0 0 0-.75.75v11.568s-.017.62.28 1.27c.291.64 1.059 1.347 2.18 1.357.006 0 .01-.006.017-.006l.018.018q.007.002.013.004c.018 0 16.545-.013 18.172 0 1.12.009 1.864-.761 2.121-1.412.258-.651.219-1.258.219-1.258v-15.15a.75.75 0 0 0-.75-.75zm.75 1.5h16.41v14.466s.004.343-.113.639-.165.47-.715.465c-1.508-.012-12.939-.002-16.018 0 .19-.394.48-.746.432-1.215l.004.1c.006-.232.005-1.158.006-2.606a5897 5897 0 0 0-.006-11.85m-3.61 3.609h2.114l.002 3.045v5.195c-.001 1.447-.005 2.544-.006 2.563a1 1 0 0 0 .004.1.947.947 0 0 1-1.036 1.058h-.09c-.612.008-.707-.198-.843-.496a1.9 1.9 0 0 1-.145-.635v-.006z"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        InkscapeStroke: "none",
      }}
      transform="translate(-852.695 -912.476)"
    />
  </svg>
);
export default SvgPr;
