import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SingleFileTable from 'components/SingleFileTable';
import SError from 'components/SError';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
const swal = withReactContent(Swal)

export default function NewNetworkMOU(props) {
    const currentUser = authenticationService.currentUserValue;
    const [errors, setError] = useState({});
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [principle, setPrinciple] = useState('');
    const [objective, setObjective] = useState('');
    const [cooperation, setCooperation] = useState('');

    const [nameEN, setNameEN] = useState('');
    const [locationEN, setLocationEN] = useState('');
    const [principleEN, setPrincipleEN] = useState('');
    const [objectiveEN, setObjectiveEN] = useState('');
    const [cooperationEN, setCooperationEN] = useState('');
    //const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [latlong, setLatLong] = useState('');

    const [tel, setTel] = useState('');


    const [status, setStatus] = useState(true);

    const [filespreview, setFilesPreview] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [logoimg, setLogoImg] = useState(null);
    const [publishstartDate, SetPublishStartDate] = useState(new Date());
    const [publishendDate, SetPublishEndDate] = useState(null);

    const [performanceList, setPerformanceList] = useState([]);
    const rx_int = /^\d+$/;
    useEffect(() => {
        const date = new Date();
        let tmppublishendDate = new Date(date.setTime(date.getTime() + 50 * 365 * 86400000));
        SetPublishEndDate(tmppublishendDate);
    }, []); /* <-- add this for run once*/
    useEffect(() => {
        if (moment(publishstartDate).format('YYYY-MM-DD') > moment(publishendDate).format('YYYY-MM-DD')) {
            SetPublishEndDate(publishstartDate);
        }
    }, [publishstartDate]);
    useEffect(() => {
        if (moment(publishendDate).format('YYYY-MM-DD') < moment(publishstartDate).format('YYYY-MM-DD')) {
            SetPublishStartDate(publishendDate);
        }
    }, [publishendDate]);

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (name.trim().length === 0) {
            formIsValid = false;
            serrors["name"] = "กรุณาระบุรายละเอียด";
        }
        if (location.trim().length === 0) {
            formIsValid = false;
            serrors["location"] = "กรุณาระบุรายละเอียด";
        }
        if (principle.trim().length === 0) {
            formIsValid = false;
            serrors["principle"] = "กรุณาระบุรายละเอียด";
        }
        if (objective.trim().length === 0) {
            formIsValid = false;
            serrors["objective"] = "กรุณาระบุรายละเอียด";
        }
        if (cooperation.trim().length === 0) {
            formIsValid = false;
            serrors["cooperation"] = "กรุณาระบุรายละเอียด";
        }
        /*if (nameEN.trim().length === 0) {
            formIsValid = false;
            serrors["nameEN"] = "กรุณาระบุรายละเอียด";
        }
        if (locationEN.trim().length === 0) {
            formIsValid = false;
            serrors["locationEN"] = "กรุณาระบุรายละเอียด";
        }
        if (principleEN.trim().length === 0) {
            formIsValid = false;
            serrors["principleEN"] = "กรุณาระบุรายละเอียด";
        }
        if (objectiveEN.trim().length === 0) {
            formIsValid = false;
            serrors["objectiveEN"] = "กรุณาระบุรายละเอียด";
        }
        if (cooperationEN.trim().length === 0) {
            formIsValid = false;
            serrors["cooperationEN"] = "กรุณาระบุรายละเอียด";
        }*/
        if (website.trim().length === 0) {
            formIsValid = false;
            serrors["website"] = "กรุณาระบุรายละเอียด";
        }
        if (latlong.trim().length === 0) {
            formIsValid = false;
            serrors["latlong"] = "กรุณาระบุรายละเอียด";
        }
        else {
            let tmplatlon = latlong.split(',');
            //console.log(tmplatlon)
            if (tmplatlon.length < 2) {
                formIsValid = false;
                serrors["latlong"] = "กรุณาระบุพิกัดคั่นด้วย ,";
            }
        }
        if (tel.trim().length === 0) {
            formIsValid = false;
            serrors["tel"] = "กรุณาระบุรายละเอียด";
        }
        if (email.trim().length === 0) {
            formIsValid = false;
            serrors["email"] = "กรุณาระบุรายละเอียด";
        }

        if (logoimg === null) {
            formIsValid = false;
            serrors["logoimg"] = "กรุณาเลือกไฟล์ภาพโลโก้";
        }
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        performanceList.map((value, j) => {
            if ((value.PerformanceFile === null && value.PerformanceID === 0) || (value.PerformanceName.trim().length === 0) || (value.PerformanceYear.trim().length === 0)) {
                formIsValid = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูล ชื่อผลการดำเนินงาน ปี พ.ศ. และเอกสารผลการดำเนินงานให้ครบถ้วน',
                })
            }
        })
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            //formData.append("id", mouID);
            //formData.append("uuid", mouUUID);
            formData.append("name", name);
            formData.append("location", location);
            formData.append("principle", principle);
            formData.append("objective", objective);
            formData.append("cooperation", cooperation);

            formData.append("nameEN", nameEN);
            formData.append("locationEN", locationEN);
            formData.append("principleEN", principleEN);
            formData.append("objectiveEN", objectiveEN);
            formData.append("cooperationEN", cooperationEN);

            formData.append("website", website);
            formData.append("latlong", latlong);
            formData.append("tel", tel);
            formData.append("status", status);
            formData.append("email", email);


            for (let i = 0; i < fileList.length; i += 1) {
                formData.append("fileList[]", fileList[i]);
            }
            formData.append("logofile", logoimg.file);
            formData.append("userID", currentUser.UserID);
            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            formData.append("publishstartDate", publishstartDate);
            /*if (publishendDate !== null) {
                formData.append("publishendDate", publishendDate);
            }
            else {
                const date = new Date();
                let tmppublishendDate = new Date(date.setTime(date.getTime() + 50 * 365 * 86400000));
                formData.append("publishendDate", tmppublishendDate);
            }*/
            formData.append("publishendDate", publishendDate);
            for (var i = 0; i < performanceList.length; i++) {
                formData.append('performanceList[]', JSON.stringify(performanceList[i]));
            }
            let performanceFileUpdate = [];
            let loop = 0;
            for (let i = 0; i < performanceList.length; i += 1) {
                if (performanceList[i].PerformanceFile !== null) {
                    performanceFileUpdate.push(loop);
                    formData.append("performanceFileList[]", performanceList[i].PerformanceFile);
                    loop++;
                }
                else {
                    performanceFileUpdate.push(-1);
                }
            }
            formData.append("performanceFileUpdate", performanceFileUpdate.join());
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveNetworkMOU', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    //console.log(response.data)
                    if (response.data.success === false) {
                        Swal.fire({
                            icon: 'error',
                            title: 'ผิดพลาด',
                            text: response.data.text.code,
                        })
                    }
                    else {
                        swal.fire({
                            title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                            timer: 1000, timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer()
                                    if (content) {
                                        const b = content.querySelector('b')
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft()
                                        }
                                    }
                                }, 100)
                            },
                            willClose: () => {
                                clearInterval(timerInterval);

                            }
                        }).then((result) => {
                            if (open) {
                                window.location.reload();
                            }
                            else {
                                props.history.push({ pathname: "/admin/network/NetworkMOUList" });
                            }
                        })
                    }
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const rx_latln = /^[0-9.,]+$/;
    const handleLatLongChange = (e) => {
        if (rx_latln.test(e.target.value)) {
            setLatLong(e.target.value);
        }
    }
    const handleLogoImgOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setLogoImg(imageFile);
    };
    const LogoImgPreview = (
        (logoimg !== null) && (<img className="preview-upload" src={logoimg.preview} onChange={(e) => { e.target.src = URL.createObjectURL(logoimg); }} alt="MOU logo" />)
    );
    const cancel = () => {
        props.history.push({ pathname: "/admin/network/NetworkMOUList" });
    };

    const handleRemoveFile = filename => {
        for (let file of fileList) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched completeDocFilesList
        }
        setFileList(fileList.filter(item => item['name'] !== filename));
    };
    const FilesList = fileList.map((file, i) => (
        <tr key={file.path}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.path.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));

    const handleRemoveLogoFile = () => {
        /*const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", LogoImgPreview.file);
        formData.append("fieldName", 'LogoImgPath');
        formData.append("networkID", networkid);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteMOUNetworkFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });*/
        setLogoImg(null);
    };
    const handleRemovePerformanceList = (index, idxloop) => {
        const removedata = performanceList.filter((item) => Number(item.PerformanceID) === Number(index));
        //console.log(removedata);
        if (Number(removedata[0].PerformanceID) !== 0) {
            const formData = new FormData();
            formData.append("id", removedata[0].PerformanceID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteMOUPerformance', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });
        }
        let lstContent = [];
        let loop = 0;
        performanceList.map(row => {
            if (loop !== idxloop) {
                lstContent.push({ PerformanceID: row.PerformanceID, PerformanceName: row.PerformanceName, PerformanceYear: row.PerformanceYear, PerformanceFile: row.PerformanceFile, preview: row.preview });
                //console.log(row)
            }
            loop++;
        });
        setPerformanceList(lstContent);
        //console.log(lstContent);
    };
    const AddPerformanceList = () => {
        let checksignimg = true;
        //console.log(performanceList)
        performanceList.map((value, j) => {
            //console.log(value.PerformanceName)
            if ((value.PerformanceFile === null && value.PerformanceID === 0) || (typeof value.PerformanceName === 'undefined') || (typeof value.PerformanceYear === 'undefined')) {
                checksignimg = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูลชื่อผลการดำเนินงาน ปี พ.ศ. และไฟล์ประกอบผลการดำเนินงานให้ครบถ้วน',
                })
            }
            else if((value.PerformanceName.trim().length === 0) || (value.PerformanceYear.trim().length === 0)){
                checksignimg = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูลชื่อผลการดำเนินงาน ปี พ.ศ. และไฟล์ประกอบผลการดำเนินงานให้ครบถ้วน',
                })
            }
        })
        if (checksignimg === true) {
            setPerformanceList((prevFriends) => [
                ...prevFriends,
                {
                    PerformanceID: 0,
                    PerformanceName: '',
                    PerformanceYear: '',
                    PerformanceFile: null,
                    //preview: '',
                },
            ]);
        }
    }
    const PreviewFilename = (item) => {
        //console.log(item)
        return (item.PerformanceFile)&&(
                <div key={item.PerformanceName}>
                    <a href={item.preview} rel="noreferrer" target="_blank">{item.PerformanceFile.name}</a>
                </div>
        )
    }

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"} path={<><span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/network/NetworkMOUList" }}>{"การจัดการหน่วยงานตามบันทึกข้อตกลงความร่วมมือ (MOU)"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link onClick={e => { e.preventDefault(); }} id="profile-btn" className="active" to={{ pathname: "/admin/network/NewNetworkMOU" }}>{"สร้าง"}</Link></>}

            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="card">
                                    <div className="card-body row">
                                        <div className="col-12">
                                            <label htmlFor="name" className="required">ชื่อองค์กร</label>
                                            <input type="text" className="form-control " id="name" value={name} name="name" placeholder="กรุณากรอก" onChange={(e) => { setName(e.currentTarget.value) }} />
                                            <SError error={errors["name"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="location" className="required">ที่อยู่</label>
                                            <input type="text" className="form-control " id="location" value={location} name="location" placeholder="กรุณากรอก" onChange={(e) => { setLocation(e.currentTarget.value) }} />
                                            <SError error={errors["location"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="principle" className="required">หลักการและเหตุผล</label>
                                            <div>
                                                <ReactSummernote
                                                    value={principle}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setPrinciple}
                                                />
                                                <SError error={errors["principle"]} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="objective" className="required">วัตถุประสงค์</label>
                                            <div>
                                                <ReactSummernote
                                                    value={objective}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setObjective}
                                                />
                                                <SError error={errors["objective"]} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="cooperation" className="required">กรอบความร่วมมือ</label>
                                            <div>
                                                <ReactSummernote
                                                    value={cooperation}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setCooperation}
                                                />
                                                <SError error={errors["cooperation"]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="card">
                                    <div className="card-body row">
                                        <div className="col-12">
                                            <label htmlFor="nameEN">ชื่อองค์กร</label>
                                            <input type="text" className="form-control " id="nameEN" value={nameEN} name="nameEN" placeholder="กรุณากรอก" onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                            <SError error={errors["nameEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="locationEN">ที่อยู่</label>
                                            <input type="text" className="form-control " id="locationEN" value={locationEN} name="locationEN" placeholder="กรุณากรอก" onChange={(e) => { setLocationEN(e.currentTarget.value) }} />
                                            <SError error={errors["locationEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="principleEN">หลักการและเหตุผล</label>
                                            <div>
                                                <ReactSummernote
                                                    value={principleEN}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setPrincipleEN}
                                                />
                                                <SError error={errors["principleEN"]} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="objectiveEN">วัตถุประสงค์</label>
                                            <div>
                                                <ReactSummernote
                                                    value={objectiveEN}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setObjectiveEN}
                                                />
                                                <SError error={errors["objectiveEN"]} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="cooperationEN">กรอบความร่วมมือ</label>
                                            <div>
                                                <ReactSummernote
                                                    value={cooperationEN}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setCooperationEN}
                                                />
                                                <SError error={errors["cooperationEN"]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-6">
                                    <label htmlFor="tel" className="required">เบอร์โทร</label>
                                    <input type="text" className="form-control " id="tel" value={tel} name="tel" placeholder="กรุณากรอก" onChange={(e) => { setTel(e.currentTarget.value) }} />
                                    <SError error={errors["tel"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="email" className="required">อีเมล</label>
                                    <input type="text" className="form-control " id="email" value={email} name="email" placeholder="กรุณากรอก" onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                    <SError error={errors["email"]} />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="website" className="required">เว็บลิงก์</label>
                                    <input type="text" className="form-control " id="website" value={website} name="website" placeholder="กรุณากรอก" onChange={(e) => { setWebsite(e.currentTarget.value) }} />
                                    <SError error={errors["website"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="latlong" className="required">แผนที่ (ละติจูด,ลองจิจูด)</label>
                                    <input type="text" className="form-control " id="latlong" name="latlong" placeholder="กรุณากรอก ละติจูด,ลองจิจูด" value={latlong} onChange={(e) => { handleLatLongChange(e) }} />
                                    <SError error={errors["latlong"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปภาพโลโก้</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleLogoImgOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["logoimg"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {LogoImgPreview}
                                                <aside>
                                                    <SingleFileTable filedata={logoimg} removefn={handleRemoveLogoFile} />
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12">
                                    <label>เอกสารบันทึกข้อตกลงความร่วมมือ</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setFileList([...fileList, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {FilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <h4>ผลการดำเนินงาน/โครงการ/กิจกรรม ร่วมกัน</h4>
                                    <div className="col-12 text-right"><a href="/" onClick={(e) => { e.preventDefault(); AddPerformanceList(); }}><i className="fas fa-plus-circle"></i> เพิ่มผลการดำเนินงาน/โครงการ/กิจกรรม ร่วมกัน</a></div>
                                    <div className="col-12">
                                        {performanceList.map((item, i) => (
                                            <div key={i} className="row mt-2">
                                                <div className="col-4">
                                                    <label htmlFor="performanceName">ชื่อผลการดำเนินงาน</label>
                                                    <input type="text" className="form-control " id="performanceName" value={item.PerformanceName} maxLength={255} name="performanceName" placeholder="กรุณากรอก" onChange={(e) => {
                                                        setPerformanceList(
                                                            performanceList.map((value, j) => {
                                                                if (i === j) {
                                                                    value.PerformanceName = e.target.value;
                                                                }
                                                                return value;
                                                            })
                                                        )
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <label htmlFor="performanceYear">ปี พ.ศ.</label>
                                                    <input type="text" className="form-control " id="performanceYear" value={item.PerformanceYear} maxLength={4} name="performanceYear" placeholder="กรุณากรอก" onChange={(e) => {
                                                        setPerformanceList(
                                                            performanceList.map((value, j) => {
                                                                if (i === j) {
                                                                    if (rx_int.test(e.target.value)) {
                                                                        value.PerformanceYear = e.target.value;
                                                                    }
                                                                }
                                                                return value;
                                                            })
                                                        )
                                                    }} />
                                                </div>
                                                <div className="col-5">
                                                    <label className="required">ไฟล์เอกสารประกอบผลการดำเนินงาน</label>
                                                    <Dropzone
                                                        className='dropzone'
                                                        activeClassName='active-dropzone'
                                                        multiple={false}
                                                        //onDrop={handleSignImageOnDrop(i,)}
                                                        onDrop={acceptedFiles => {
                                                            setPerformanceList(
                                                                performanceList.map((value, j) => {
                                                                    if (i === j) {
                                                                        value.PerformanceFile = acceptedFiles[0];
                                                                        value.preview = URL.createObjectURL(acceptedFiles[0]);
                                                                        value.name = acceptedFiles[0].name;
                                                                        value.size = acceptedFiles[0].Filesize;
                                                                    }
                                                                    return value;
                                                                })
                                                            )
                                                        }}
                                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section className="container">
                                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                                    <input {...getInputProps()} />
                                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                                </div>
                                                                <aside style={{}}>
                                                                    {PreviewFilename(item)}
                                                                </aside>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                                <span className="col-1" style={{ marginTop: 40 }}>
                                                    <a href={item.preview} className="mr-2" rel="noreferrer" target="_blank"><i className="text-primary far fa-eye"></i> ดู</a>
                                                    <a onClick={(e) => { e.preventDefault(); handleRemovePerformanceList(item.PerformanceID, i) }}><i className="text-danger fas fa-minus-circle"></i> ลบ</a>
                                                </span>
                                            </div>
                                        ))
                                        }
                                        <span className="error">{errors["performanceList"]}</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status1" value="1" checked={status === true} onChange={(e) => { setStatus(true); }} />
                                        <label className="form-check-label" htmlFor="status1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status2" value="0" checked={status === false} onChange={(e) => { setStatus(false); }} />
                                        <label className="form-check-label" htmlFor="status2"> ไม่เผยแพร่</label>
                                    </div>
                                    <SError error={errors["status"]} />
                                </div>
                                {status === true && <>
                                    <div className="col-6">
                                        <label className="required">วันที่เผยแพร่</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                                {SetPublishStartDate} name="startpublishday" id="startpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-6">
                                        <label>วันที่สิ้นสุด</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </>}

                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    );
}
