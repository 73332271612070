import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import { authenticationService } from 'services/authentication.service.js';
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function AddNetworkActivity(props) {
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [errors, setError] = useState({});
    const [networkID, SetNetworkID] = useState('');

    const [organization, SetOrganization] = useState("");

    const [year1, SetYear1] = useState("");
    const [activityName1, SetActivityName1] = useState("");
    const [activityDetail1, SetActivityDetail1] = useState("");
    const [networkActivityFiles1, SetNetworkActivityFiles1] = useState([]);
    const [imageFiles1, SetImageFiles1] = useState([]);
    const [networkActivityFiles1preview, SetNetworkActivityFiles1Preview] = useState([]);
    const [imageFiles1preview, SetImageFiles1Preview] = useState([]);

    const [year2, SetYear2] = useState("");
    const [activityName2, SetActivityName2] = useState("");
    const [activityDetail2, SetActivityDetail2] = useState("");
    const [networkActivityFiles2, SetNetworkActivityFiles2] = useState([]);
    const [imageFiles2, SetImageFiles2] = useState([]);
    const [networkActivityFiles2preview, SetNetworkActivityFiles2Preview] = useState([]);
    const [imageFiles2preview, SetImageFiles2Preview] = useState([]);

    const [year3, SetYear3] = useState("");
    const [activityName3, SetActivityName3] = useState("");
    const [activityDetail3, SetActivityDetail3] = useState("");
    const [networkActivityFiles3, SetNetworkActivityFiles3] = useState([]);
    const [imageFiles3, SetImageFiles3] = useState([]);
    const [networkActivityFiles3preview, SetNetworkActivityFiles3Preview] = useState([]);
    const [imageFiles3preview, SetImageFiles3Preview] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [selecttopicList1, setSelectTopicList1] = useState([]);
    const [selecttopicList2, setSelectTopicList2] = useState([]);
    const [selecttopicList3, setSelectTopicList3] = useState([]);

    const [activitylist, setActivityList] = useState([]);
    let seltopiclist1 = [];
    let seltopiclist2 = [];
    let seltopiclist3 = [];
    let activitytmplist = [];
    const rx_int = /^\d+$/;
    useEffect(() => {
        let networkid='';
        if(typeof props.location.state !== 'undefined'){
            SetNetworkID(props.location.state.networkID);
            networkid = props.location.state.networkID;
        }
        else{
            SetNetworkID(localStorage.getItem("networkID"));
            networkid = localStorage.getItem("networkID");
        }
        //console.log(networkid)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/corporateNetworkbyID', { params: { id: networkid } }).then((response) => {
            let data = response.data[0];
            SetOrganization(data.NetOrganization);
            axios.get(
                process.env.REACT_APP_APIURL + "/api-web/searchMemberInterestTopic",
                { params: { status: 1 } }
            ).then((response) => {
                setTopicList(response.data);
                setLoading(false);
            });
        });

    }, []); /* <-- add this for run once*/
    const TopicCheckbox1 = topicList.map((item) => (
        <div className="col-12" key={item.InterestTopicID}>
            <div className="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={"operation1-" + item.InterestTopicID}
                    name={"operation1-" + item.InterestTopicID}
                    onChange={(e) => {
                        var checked = e.target.checked;
                        seltopiclist1 = [...selecttopicList1];
                        if (checked) {
                            if (seltopiclist1.indexOf(item.InterestTopicID) < 0) {
                                seltopiclist1.push(item.InterestTopicID);
                            }
                        } else {
                            if (seltopiclist1.indexOf(item.InterestTopicID) > -1) {
                                let index = seltopiclist1.indexOf(item.InterestTopicID);
                                seltopiclist1 = seltopiclist1
                                    .slice(0, index)
                                    .concat(seltopiclist1.slice(index + 1));
                            }
                        }
                        setSelectTopicList1(seltopiclist1);
                        //console.log(seltopiclist1)
                    }}
                    defaultChecked={(selecttopicList1.indexOf(item.InterestTopicID) > -1) ? true : false}
                />
                <label
                    className="custom-control-label"
                    htmlFor={"operation1-" + item.InterestTopicID}
                >
                    {language === "Thai" ? item.TextTH : item.TextEN}
                </label>
            </div>
        </div>
    ));

    const handleYearChange1 = (e) => {
        if (e.target.value === '') {
            SetYear1('');
        }
        else if (rx_int.test(e.target.value)) {
            if (e.target.value.length < 5) {
                SetYear1(e.target.value);
                //setnetworkActivities({ ...networkActivities, 'year1': year1 })
            }
        }
    };
    const handleRemoveActivityFile1 = (filename) => {
        SetNetworkActivityFiles1(
            networkActivityFiles1.filter((item) => item["name"] !== filename)
        );
    };
    const handleRemoveActivityImageFile1 = (filename) => {
        SetImageFiles1(imageFiles1.filter((item) => item["name"] !== filename));
    };
    const NetActivityFilesList1 = networkActivityFiles1.map((file, i) => (
        <tr key={i}>
            <td>{Number(i) + 1}</td>
            <td>{file.path.split(".")[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split(".").pop()}</td>
            <td>
                <a
                    className="btn btn-sm btn-primary mr-2"
                    href={file.preview}
                    rel="noreferrer"
                    target="_blank"
                >
                    ดู
                </a>
                <button
                    className="btn btn-sm btn-danger"
                    onClick={(e) => {
                        e.preventDefault();
                        handleRemoveActivityFile1(file.name);
                    }}
                >
                    ลบ
                </button>
            </td>
        </tr>
    ));

    const NetImageFilesList1 = imageFiles1.map((file, i) => (
        <tr key={i}>
            <td>{Number(i) + 1}</td>
            <td>{file.name.split(".")[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split(".").pop()}</td>
            <td>
                <a
                    className="btn btn-sm btn-primary mr-2"
                    href={file.preview}
                    rel="noreferrer"
                    target="_blank"
                >
                    ดู
                </a>
                <button
                    className="btn btn-sm btn-danger"
                    onClick={(e) => {
                        e.preventDefault();
                        handleRemoveActivityImageFile1(file.name);
                    }}
                >
                    ลบ
                </button>
            </td>
        </tr>
    ));

    const TopicCheckbox2 = topicList.map((item) => (
        <div className="col-12" key={item.InterestTopicID}>
            <div className="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={"operation2-" + item.InterestTopicID}
                    name={"operation2-" + item.InterestTopicID}
                    onChange={(e) => {
                        var checked = e.target.checked;
                        seltopiclist2 = [...selecttopicList2];
                        if (checked) {
                            if (seltopiclist2.indexOf(item.InterestTopicID) < 0) {
                                seltopiclist2.push(item.InterestTopicID);
                            }
                        } else {
                            if (seltopiclist2.indexOf(item.InterestTopicID) > -1) {
                                let index = seltopiclist2.indexOf(item.InterestTopicID);
                                seltopiclist2 = seltopiclist2
                                    .slice(0, index)
                                    .concat(seltopiclist2.slice(index + 1));
                            }
                        }
                        setSelectTopicList2(seltopiclist2);
                    }}
                    defaultChecked={(selecttopicList2.indexOf(item.InterestTopicID) > -1) ? true : false}
                />
                <label
                    className="custom-control-label"
                    htmlFor={"operation2-" + item.InterestTopicID}
                >
                    {language === "Thai" ? item.TextTH : item.TextEN}
                </label>
            </div>
        </div>
    ));
    const handleYearChange2 = (e) => {
        if (e.target.value === '') {
            SetYear2('');
        }
        else if (rx_int.test(e.target.value)) {
            if (e.target.value.length < 5) {
                SetYear2(e.target.value);
            }
        }
    };
    const handleRemoveActivityFile2 = (filename) => {
        SetNetworkActivityFiles2(
            networkActivityFiles2.filter((item) => item["name"] !== filename)
        );
    };
    const handleRemoveActivityImageFile2 = (filename) => {
        SetImageFiles2(imageFiles2.filter((item) => item["name"] !== filename));
    };
    const NetActivityFilesList2 = networkActivityFiles2.map((file, i) => (
        <tr key={i}>
            <td>{Number(i) + 1}</td>
            <td>{file.path.split(".")[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split(".").pop()}</td>
            <td>
                <a
                    className="btn btn-sm btn-primary mr-2"
                    href={file.preview}
                    rel="noreferrer"
                    target="_blank"
                >
                    ดู
                </a>
                <button
                    className="btn btn-sm btn-danger"
                    onClick={(e) => {
                        e.preventDefault();
                        handleRemoveActivityFile2(file.name);
                    }}
                >
                    ลบ
                </button>
            </td>
        </tr>
    ));

    const NetImageFilesList2 = imageFiles2.map((file, i) => (
        <tr key={i}>
            <td>{Number(i) + 1}</td>
            <td>{file.name.split(".")[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split(".").pop()}</td>
            <td>
                <a
                    className="btn btn-sm btn-primary mr-2"
                    href={file.preview}
                    rel="noreferrer"
                    target="_blank"
                >
                    ดู
                </a>
                <button
                    className="btn btn-sm btn-danger"
                    onClick={(e) => {
                        e.preventDefault();
                        handleRemoveActivityImageFile2(file.name);
                    }}
                >
                    ลบ
                </button>
            </td>
        </tr>
    ));

    const TopicCheckbox3 = topicList.map((item) => (
        <div className="col-12" key={item.InterestTopicID}>
            <div className="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={"operation3-" + item.InterestTopicID}
                    name={"operation3-" + item.InterestTopicID}
                    onChange={(e) => {
                        var checked = e.target.checked;
                        seltopiclist3 = [...selecttopicList3];
                        if (checked) {
                            if (seltopiclist3.indexOf(item.InterestTopicID) < 0) {
                                seltopiclist3.push(item.InterestTopicID);
                            }
                        } else {
                            if (seltopiclist3.indexOf(item.InterestTopicID) > -1) {
                                let index = seltopiclist3.indexOf(item.InterestTopicID);
                                seltopiclist3 = seltopiclist3
                                    .slice(0, index)
                                    .concat(seltopiclist3.slice(index + 1));
                            }
                        }
                        setSelectTopicList3(seltopiclist3);
                    }}
                    defaultChecked={(selecttopicList3.indexOf(item.InterestTopicID) > -1) ? true : false}
                />
                <label
                    className="custom-control-label"
                    htmlFor={"operation3-" + item.InterestTopicID}
                >
                    {language === "Thai" ? item.TextTH : item.TextEN}
                </label>
            </div>
        </div>
    ));
    const handleYearChange3 = (e) => {
        if (e.target.value === '') {
            SetYear3('');
        }
        else if (rx_int.test(e.target.value)) {
            if (e.target.value.length < 5) {
                SetYear3(e.target.value);
            }
        }
    };
    const handleRemoveActivityFile3 = (filename) => {
        SetNetworkActivityFiles3(
            networkActivityFiles3.filter((item) => item["name"] !== filename)
        );
    };
    const handleRemoveActivityImageFile3 = (filename) => {
        SetImageFiles3(imageFiles3.filter((item) => item["name"] !== filename));
    };
    const NetActivityFilesList3 = networkActivityFiles3.map((file, i) => (
        <tr key={i}>
            <td>{Number(i) + 1}</td>
            <td>{file.path.split(".")[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split(".").pop()}</td>
            <td>
                <a
                    className="btn btn-sm btn-primary mr-2"
                    href={file.preview}
                    rel="noreferrer"
                    target="_blank"
                >
                    ดู
                </a>
                <button
                    className="btn btn-sm btn-danger"
                    onClick={(e) => {
                        e.preventDefault();
                        handleRemoveActivityFile3(file.name);
                    }}
                >
                    ลบ
                </button>
            </td>
        </tr>
    ));

    const NetImageFilesList3 = imageFiles3.map((file, i) => (
        <tr key={i}>
            <td>{Number(i) + 1}</td>
            <td>{file.name.split(".")[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.name.split(".").pop()}</td>
            <td>
                <a
                    className="btn btn-sm btn-primary mr-2"
                    href={file.preview}
                    rel="noreferrer"
                    target="_blank"
                >
                    ดู
                </a>
                <button
                    className="btn btn-sm btn-danger"
                    onClick={(e) => {
                        e.preventDefault();
                        handleRemoveActivityImageFile3(file.name);
                    }}
                >
                    ลบ
                </button>
            </td>
        </tr>
    ));

    function checkActivity() {
        //activitytmplist = [...activitylist];
        activitytmplist = [];
        let serrors = {};
        let formIsValid = true;
        if (organization.trim().length === 0) {
          formIsValid = false;
          serrors["organization"] = "กรุณาระบุชื่อองค์กร";
        }
        if (
          selecttopicList1.length <= 0 &&
          year1.trim().length < 4 &&
          activityName1.trim().length === 0 &&
          activityDetail1.trim().length === 0 &&
          selecttopicList2.length <= 0 &&
          year2.trim().length < 4 &&
          activityName2.trim().length === 0 &&
          activityDetail2.trim().length === 0 &&
          selecttopicList3.length <= 0 &&
          year3.trim().length < 4 &&
          activityName3.trim().length === 0 &&
          activityDetail3.trim().length === 0
        ) {
          formIsValid = false;
          swal.fire({
            icon: "error",
            title: "Failed",
            text: "กรุณากรอกข้อมูลผลการดำเนินงานอย่างน้อยหนึ่งรายการ",
            //footer: '<a href="">Why do I have this issue?</a>'
          });
        }
        /*1*/
        if (
          selecttopicList1.length <= 0 &&
          year1.trim().length < 4 &&
          activityName1.trim().length === 0 &&
          activityDetail1.trim().length === 0
        ) {
        } else {
          if (selecttopicList1.length <= 0) {
            formIsValid = false;
            serrors["seltopiclist1"] =
              "กรุณาเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชน อย่างน้อยหนึ่งรายการ";
          }
          if (year1.trim().length < 4) {
            formIsValid = false;
            serrors["year1"] = "กรุณาระบุรายละเอียด";
          }
          if (activityName1.trim().length === 0) {
            formIsValid = false;
            serrors["activityName1"] = "กรุณาระบุรายละเอียด";
          }
          if (activityDetail1.trim().length === 0) {
            formIsValid = false;
            serrors["activityDetail1"] = "กรุณาระบุรายละเอียด";
          }
          if (formIsValid === true) {
            let getallData = {
              selecttopicList: selecttopicList1,
              year: year1,
              activityName: activityName1,
              activityDetail: activityDetail1,
              networkActivityFiles: networkActivityFiles1,
              imageFiles: imageFiles1,
            };
            activitytmplist.push(getallData);
            setActivityList(activitytmplist);
          }
        }
        /*2*/
        if (
          selecttopicList2.length <= 0 &&
          year2.trim().length < 4 &&
          activityName2.trim().length === 0 &&
          activityDetail2.trim().length === 0
        ) {
        } else {
          if (selecttopicList2.length <= 0) {
            formIsValid = false;
            serrors["seltopiclist2"] =
              "กรุณาเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชน อย่างน้อยหนึ่งรายการ";
          }
          if (year2.trim().length < 4) {
            formIsValid = false;
            serrors["year2"] = "กรุณาระบุรายละเอียด";
          }
          if (activityName2.trim().length === 0) {
            formIsValid = false;
            serrors["activityName2"] = "กรุณาระบุรายละเอียด";
          }
          if (activityDetail2.trim().length === 0) {
            formIsValid = false;
            serrors["activityDetail2"] = "กรุณาระบุรายละเอียด";
          }
          if (formIsValid === true) {
            let getallData = {
              selecttopicList: selecttopicList2,
              year: year2,
              activityName: activityName2,
              activityDetail: activityDetail2,
              networkActivityFiles: networkActivityFiles2,
              imageFiles: imageFiles2,
            };
            activitytmplist.push(getallData);
            setActivityList(activitytmplist);
          }
        }
        /*3*/
        if (
          selecttopicList3.length <= 0 &&
          year3.trim().length < 4 &&
          activityName3.trim().length === 0 &&
          activityDetail3.trim().length === 0
        ) {
        } else {
          if (selecttopicList3.length <= 0) {
            formIsValid = false;
            serrors["seltopiclist3"] =
              "กรุณาเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชน อย่างน้อยหนึ่งรายการ";
          }
          if (year3.trim().length < 4) {
            formIsValid = false;
            serrors["year3"] = "กรุณาระบุรายละเอียด";
          }
          if (activityName3.trim().length === 0) {
            formIsValid = false;
            serrors["activityName3"] = "กรุณาระบุรายละเอียด";
          }
          if (activityDetail3.trim().length === 0) {
            formIsValid = false;
            serrors["activityDetail3"] = "กรุณาระบุรายละเอียด";
          }
          if (formIsValid === true) {
            let getallData = {
              selecttopicList: selecttopicList3,
              year: year3,
              activityName: activityName3,
              activityDetail: activityDetail3,
              networkActivityFiles: networkActivityFiles3,
              imageFiles: imageFiles3,
            };
            activitytmplist.push(getallData);
            setActivityList(activitytmplist);
          }
        }
        setError(serrors);
        return formIsValid;
      }
    const saveData = () => {
        if (checkActivity() === false) {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
            return;
        }


        const formData = new FormData();
        formData.append("userID", currentUser.UserID);
        formData.append("networkID", networkID);

        //formData.append("seltopiclist1", selecttopicList1);
        for (var i = 0; i < activitytmplist.length; i++) {
            formData.append(
                "networkActivities[]",
                JSON.stringify(activitytmplist[i])
            );
            for (
                let j = 0;
                j < activitytmplist[i].networkActivityFiles.length;
                j += 1
            ) {
                formData.append(
                    "activityfiles" + i + "[]",
                    activitytmplist[i].networkActivityFiles[j]
                );
            }
            for (let j = 0; j < activitytmplist[i].imageFiles.length; j += 1) {
                formData.append(
                    "imagefiles" + i + "[]",
                    activitytmplist[i].imageFiles[j]
                );
            }
        }
        axios
            .post(
                process.env.REACT_APP_APIURL + "/api-web/AddNetworkActivity",
                formData,
                {
                    headers: {
                        "Content-Type": `multipart/form-data`,
                    },
                }
            )
            .then((response) => {
                let timerInterval;
                swal
                    .fire({
                        title: "บันทึกข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer();
                                if (content) {
                                    const b = content.querySelector("b");
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft();
                                    }
                                }
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    })
                    .then((result) => {
                        //props.history.push({ pathname: "/authen/network/NetworkList" });
                        window.close();
                    });
            });
    };
    const cancel = () => {
        props.history.push({ pathname: "/authen/Profile" });
    };

    if (isLoading) {
        return (<></>);
    }
    return (
        <>
            <div id="header-wrapper">
                {<Navbar />}
                <ContentBanner language={language} theader={"เครือข่ายด้านสิทธิมนุษยชน"} eheader={"Network"} bannerkey="NETWORK" banner={banner}
                    path={<>
                        <span>/</span><a href="/" onClick={(e) => { e.preventDefault() }}>{language === "Thai" ? "เครือข่าย" : "Network"}</a>
                        <span>/</span><Link to={{ pathname: "/authen/network/NetworkList", state: { language: language } }}>{language === "Thai" ? " องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ" : "Cooperationnetwork and Profesional Council"}</Link>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/network/AddNetworkActivity", state: { language: language } }}>{language === "Thai" ? "เพิ่มผลงาน" : "Add new Activities"}</Link>
                    </>}
                />
            </div>
            <main className="content-page">
                <div className="container">
                    <section>
                        <div className="content-data">
                            <form method="post" encType="multipart/form-data" className="row formContent">
                                <div className="row formContent" style={{ width: "100%" }}>
                                    <div className="subheader col-12" role="alert">
                                        ผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชนอย่างต่อเนื่อง
                                        เป็นระยะเวลาไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้ง
                                        และมีผลการดำเนินงานเป็นที่ประจักษ์ (ไม่เกิน 3 เรื่อง)
                                    </div>
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            className="form-control "
                                            id="organization"
                                            defaultValue={organization}
                                            name="organization"
                                            placeholder="ชื่อองค์กร"
                                            onBlur={(e) => {
                                                SetOrganization(e.currentTarget.value);
                                            }}
                                        />
                                        <span className="error">{errors["organization"]}</span>
                                    </div>
                                    <div className="col-6"></div>
                                </div>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link subheader active"
                                            id="tab1-tab"
                                            data-toggle="tab"
                                            href="#tab1"
                                            role="tab"
                                            aria-controls="tab1"
                                            aria-selected="true"
                                        >
                                            ผลงานที่ 1
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link subheader"
                                            id="tab2-tab"
                                            data-toggle="tab"
                                            href="#tab2"
                                            role="tab"
                                            aria-controls="tab2"
                                            aria-selected="false"
                                        >
                                            ผลงานที่ 2
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link subheader"
                                            id="tab3-tab"
                                            data-toggle="tab"
                                            href="#tab3"
                                            role="tab"
                                            aria-controls="tab3"
                                            aria-selected="false"
                                        >
                                            ผลงานที่ 3
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content pl-4 pr-4" id="myTabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="tab1"
                                        role="tabpanel"
                                        aria-labelledby="tab1-tab"
                                    >
                                        <div className="row formContent">
                                            <div className="col-3">
                                                <div className="input-addon">
                                                    <span className="require">*</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id="year1"
                                                    maxLength={4}
                                                    defaultValue={year1}
                                                    name="year1"
                                                    placeholder="พ.ศ."
                                                    onBlur={(e) => {
                                                        handleYearChange1(e);
                                                    }}
                                                />
                                                <SError error={errors["year1"]} />
                                            </div>
                                            <div className="col-9">
                                                <div className="input-addon">
                                                    <span className="require">*</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id="activityName1"
                                                    defaultValue={activityName1}
                                                    name="activityName1"
                                                    placeholder="ชื่อผลการดำเนินงาน"
                                                    onBlur={(e) => {
                                                        SetActivityName1(e.currentTarget.value);
                                                    }}
                                                />
                                                <SError error={errors["activityName1"]} />
                                            </div>
                                            <div className="col-12">
                                                <div className="input-addon">
                                                    <span className="require">*</span>
                                                </div>
                                                <div>
                                                    <textarea
                                                        className="form-control"
                                                        name="activityDetail1"
                                                        id="activityDetail1"
                                                        rows="8"
                                                        defaultValue={activityDetail1}
                                                        placeholder="รายละเอียดผลงาน (โดยย่อ)"
                                                        onBlur={(e) => {
                                                            SetActivityDetail1(e.currentTarget.value);
                                                        }}
                                                    ></textarea>
                                                    <SError error={errors["activityDetail1"]} />
                                                </div>
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                ประเด็นการดำเนินงานด้านสิทธิมนุษยชน
                                            </div>
                                            {TopicCheckbox1}
                                            <div className="col-12">
                                                <SError error={errors["seltopiclist1"]} />
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                                            </div>
                                            <div className="col-12">
                                                <Dropzone
                                                    className="input-dropzone dropzone"
                                                    onDrop={(acceptedFiles) => {
                                                        //setnetworkActivities({curActivity,['networkActivityFiles1']: acceptedFiles});
                                                        SetNetworkActivityFiles1([
                                                            ...networkActivityFiles1,
                                                            ...acceptedFiles,
                                                        ]);
                                                        SetNetworkActivityFiles1Preview(acceptedFiles.map(file => Object.assign(file, {
                                                            id: 0,
                                                            preview: URL.createObjectURL(file)
                                                        })));
                                                    }}
                                                    accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="container">
                                                            <SError
                                                                error={errors["networkActivityFiles1"]}
                                                            />
                                                            <div
                                                                {...getRootProps({ className: "dropzone" })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <p>
                                                                    ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                                                </p>
                                                            </div>
                                                            <aside>
                                                                <h4>Files</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ลำดับ</th>
                                                                                <th>ชื่อ</th>
                                                                                <th>ขนาดไฟล์</th>
                                                                                <th>ประเภทไฟล์</th>
                                                                                <th>จัดการ</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="your-table-body-id">
                                                                            {NetActivityFilesList1}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </aside>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
                                            </div>
                                            <div className="col-12">
                                                <Dropzone
                                                    className="input-dropzone dropzone"
                                                    maxFiles={4}
                                                    onDrop={(acceptedFiles) => {
                                                        var filecount = imageFiles1.length;
                                                        if (filecount < 4) {
                                                            SetImageFiles1([
                                                                ...imageFiles1,
                                                                ...acceptedFiles,
                                                            ]);
                                                            SetImageFiles1Preview(acceptedFiles.map(file => Object.assign(file, {
                                                                id: 0,
                                                                preview: URL.createObjectURL(file)
                                                            })));
                                                        }
                                                    }}
                                                    accept=".jpg,.jpeg,.gif,.png"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="container">
                                                            <SError error={errors["imageFiles1"]} />
                                                            <div
                                                                {...getRootProps({ className: "dropzone" })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <p>
                                                                    ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                                                </p>
                                                            </div>
                                                            <aside>
                                                                <h4>Files</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ลำดับ</th>
                                                                                <th>ชื่อ</th>
                                                                                <th>ขนาดไฟล์</th>
                                                                                <th>ประเภทไฟล์</th>
                                                                                <th>จัดการ</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="your-table-body-id">
                                                                            {NetImageFilesList1}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </aside>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab2"
                                        role="tabpanel"
                                        aria-labelledby="tab2-tab"
                                    >
                                        <div className="row formContent">
                                            <div className="col-3">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id="year2"
                                                    maxLength={4}
                                                    defaultValue={year2}
                                                    name="year2"
                                                    placeholder="พ.ศ."
                                                    onBlur={(e) => {
                                                        handleYearChange2(e);
                                                    }}
                                                />
                                                <SError error={errors["year2"]} />
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id="activityName2"
                                                    defaultValue={activityName2}
                                                    name="activityName2"
                                                    placeholder="ชื่อผลการดำเนินงาน"
                                                    onBlur={(e) => {
                                                        SetActivityName2(e.currentTarget.value);
                                                    }}
                                                />
                                                <SError error={errors["activityName2"]} />
                                            </div>
                                            <div className="col-12">
                                                <div>
                                                    <textarea
                                                        className="form-control"
                                                        name="activityDetail2"
                                                        id="activityDetail2"
                                                        rows="8"
                                                        defaultValue={activityDetail2}
                                                        placeholder="รายละเอียดผลงาน (โดยย่อ)"
                                                        onBlur={(e) => {
                                                            SetActivityDetail2(e.currentTarget.value);
                                                        }}
                                                    ></textarea>
                                                    <SError error={errors["activityDetail2"]} />
                                                </div>
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                ประเด็นการดำเนินงานด้านสิทธิมนุษยชน
                                            </div>
                                            {TopicCheckbox2}
                                            <div className="col-12">
                                                <SError error={errors["seltopiclist2"]} />
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                                            </div>
                                            <div className="col-12">
                                                <Dropzone
                                                    className="input-dropzone dropzone"
                                                    onDrop={(acceptedFiles) => {
                                                        SetNetworkActivityFiles2([
                                                            ...networkActivityFiles2,
                                                            ...acceptedFiles,
                                                        ]);
                                                        SetNetworkActivityFiles2Preview(acceptedFiles.map(file => Object.assign(file, {
                                                            id: 0,
                                                            preview: URL.createObjectURL(file)
                                                        })));
                                                    }}
                                                    accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="container">
                                                            <SError
                                                                error={errors["networkActivityFiles2"]}
                                                            />
                                                            <div
                                                                {...getRootProps({ className: "dropzone" })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <p>
                                                                    ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                                                </p>
                                                            </div>
                                                            <aside>
                                                                <h4>Files</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ลำดับ</th>
                                                                                <th>ชื่อ</th>
                                                                                <th>ขนาดไฟล์</th>
                                                                                <th>ประเภทไฟล์</th>
                                                                                <th>จัดการ</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="your-table-body-id">
                                                                            {NetActivityFilesList2}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </aside>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
                                            </div>
                                            <div className="col-12">
                                                <Dropzone
                                                    className="input-dropzone dropzone"
                                                    maxFiles={4}
                                                    onDrop={(acceptedFiles) => {
                                                        var filecount = imageFiles2.length;
                                                        if (filecount < 4) {
                                                            SetImageFiles2([
                                                                ...imageFiles2,
                                                                ...acceptedFiles,
                                                            ]);
                                                            SetImageFiles2Preview(acceptedFiles.map(file => Object.assign(file, {
                                                                id: 0,
                                                                preview: URL.createObjectURL(file)
                                                            })));
                                                        }
                                                    }}
                                                    accept=".jpg,.jpeg,.gif,.png"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="container">
                                                            <SError error={errors["imageFiles2"]} />
                                                            <div
                                                                {...getRootProps({ className: "dropzone" })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <p>
                                                                    ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                                                </p>
                                                            </div>
                                                            <aside>
                                                                <h4>Files</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ลำดับ</th>
                                                                                <th>ชื่อ</th>
                                                                                <th>ขนาดไฟล์</th>
                                                                                <th>ประเภทไฟล์</th>
                                                                                <th>จัดการ</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="your-table-body-id">
                                                                            {NetImageFilesList2}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </aside>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab3"
                                        role="tabpanel"
                                        aria-labelledby="tab3-tab"
                                    >
                                        <div className="row formContent">
                                            <div className="col-3">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id="year2"
                                                    maxLength={4}
                                                    defaultValue={year3}
                                                    name="year3"
                                                    placeholder="พ.ศ."
                                                    onBlur={(e) => {
                                                        handleYearChange3(e);
                                                    }}
                                                />
                                                <SError error={errors["year3"]} />
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id="activityName3"
                                                    defaultValue={activityName3}
                                                    name="activityName3"
                                                    placeholder="ชื่อผลการดำเนินงาน"
                                                    onBlur={(e) => {
                                                        SetActivityName3(e.currentTarget.value);
                                                    }}
                                                />
                                                <SError error={errors["activityName3"]} />
                                            </div>
                                            <div className="col-12">
                                                <div>
                                                    <textarea
                                                        className="form-control"
                                                        name="activityDetail3"
                                                        id="activityDetail3"
                                                        rows="8"
                                                        defaultValue={activityDetail3}
                                                        placeholder="รายละเอียดผลงาน (โดยย่อ)"
                                                        onBlur={(e) => {
                                                            SetActivityDetail3(e.currentTarget.value);
                                                        }}
                                                    ></textarea>
                                                    <SError error={errors["activityDetail3"]} />
                                                </div>
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                ประเด็นการดำเนินงานด้านสิทธิมนุษยชน
                                            </div>
                                            {TopicCheckbox3}
                                            <div className="col-12">
                                                <SError error={errors["seltopiclist3"]} />
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                                            </div>
                                            <div className="col-12">
                                                <Dropzone
                                                    className="input-dropzone dropzone"
                                                    onDrop={(acceptedFiles) => {
                                                        SetNetworkActivityFiles3([
                                                            ...networkActivityFiles3,
                                                            ...acceptedFiles,
                                                        ]);
                                                        SetNetworkActivityFiles3Preview(acceptedFiles.map(file => Object.assign(file, {
                                                            id: 0,
                                                            preview: URL.createObjectURL(file)
                                                        })));
                                                    }}
                                                    accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="container">
                                                            <SError
                                                                error={errors["networkActivityFiles3"]}
                                                            />
                                                            <div
                                                                {...getRootProps({ className: "dropzone" })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <p>
                                                                    ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                                                </p>
                                                            </div>
                                                            <aside>
                                                                <h4>Files</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ลำดับ</th>
                                                                                <th>ชื่อ</th>
                                                                                <th>ขนาดไฟล์</th>
                                                                                <th>ประเภทไฟล์</th>
                                                                                <th>จัดการ</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="your-table-body-id">
                                                                            {NetActivityFilesList3}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </aside>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>

                                            <div className="subheader col-12" role="alert">
                                                รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
                                            </div>
                                            <div className="col-12">
                                                <Dropzone
                                                    className="input-dropzone dropzone"
                                                    maxFiles={4}
                                                    onDrop={(acceptedFiles) => {
                                                        var filecount = imageFiles3.length;
                                                        if (filecount < 4) {
                                                            SetImageFiles3([
                                                                ...imageFiles3,
                                                                ...acceptedFiles,
                                                            ]);
                                                            SetImageFiles3Preview(acceptedFiles.map(file => Object.assign(file, {
                                                                id: 0,
                                                                preview: URL.createObjectURL(file)
                                                            })));
                                                        }
                                                    }}
                                                    accept=".jpg,.jpeg,.gif,.png"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section className="container">
                                                            <SError error={errors["imageFiles3"]} />
                                                            <div
                                                                {...getRootProps({ className: "dropzone" })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <p>
                                                                    ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                                                </p>
                                                            </div>
                                                            <aside>
                                                                <h4>Files</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ลำดับ</th>
                                                                                <th>ชื่อ</th>
                                                                                <th>ขนาดไฟล์</th>
                                                                                <th>ประเภทไฟล์</th>
                                                                                <th>จัดการ</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="your-table-body-id">
                                                                            {NetImageFilesList3}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </aside>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 formContent">
                                    <div className="pl-4 pr-4 text-center">
                                        <input
                                            type="submit"
                                            className="fadeIn fourth"
                                            value="ตกลง"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                saveData();
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}
