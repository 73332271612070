import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import AdminBanner from 'components/Banner/AdminBanner';
import SingleFileTable from 'components/SingleFileTable';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function NewEducationCoordinate(props) {
    const phistory = useHistory();
    //const id = props.location.state.id;
    const currentUser = authenticationService.currentUserValue;
    const [errors, setError] = useState({});
    //const [uuid, setUUID] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [history, setHistory] = useState('');
    const [objective, setObjective] = useState('');
    const [roleAndDuty, setRoleAndDuty] = useState('');
    const [performance, setPerformance] = useState('');

    const [nameEN, setNameEN] = useState('');
    const [locationEN, setLocationEN] = useState('');
    const [historyEN, setHistoryEN] = useState('');
    const [objectiveEN, setObjectiveEN] = useState('');
    const [roleAndDutyEN, setRoleAndDutyEN] = useState('');
    const [performanceEN, setPerformanceEN] = useState('');
    //const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [latlong, setLatLong] = useState('');

    const [tel, setTel] = useState('');


    const [status, setStatus] = useState(true);

    const [filespreview, setFilesPreview] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [logoimg, setLogoImg] = useState(null);

    const [performanceList, setPerformanceList] = useState([]);

    useEffect(() => {
    }, []); /* <-- add this for run once*/

    const rx_int = /^\d+$/;
    const handlePhoneChange = (e) => {
        if (rx_int.test(e.target.value)) {
            setTel(e.target.value);
        }
    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (name.trim().length === 0) {
            formIsValid = false;
            serrors["name"] = "กรุณาระบุรายละเอียด";
        }
        if (location.trim().length === 0) {
            formIsValid = false;
            serrors["location"] = "กรุณาระบุรายละเอียด";
        }
        /*if (history.trim().length === 0) {
            formIsValid = false;
            serrors["history"] = "กรุณาระบุรายละเอียด";
        }
        if (roleAndDuty.trim().length === 0) {
            formIsValid = false;
            serrors["roleAndDuty"] = "กรุณาระบุรายละเอียด";
        }
        if (objective.trim().length === 0) {
            formIsValid = false;
            serrors["objective"] = "กรุณาระบุรายละเอียด";
        }
        if (performance.trim().length === 0) {
            formIsValid = false;
            serrors["performance"] = "กรุณาระบุรายละเอียด";
        }*/
        /*if (nameEN.trim().length === 0) {
            formIsValid = false;
            serrors["nameEN"] = "กรุณาระบุรายละเอียด";
        }
        if (locationEN.trim().length === 0) {
            formIsValid = false;
            serrors["locationEN"] = "กรุณาระบุรายละเอียด";
        }
        if (historyEN.trim().length === 0) {
            formIsValid = false;
            serrors["historyEN"] = "กรุณาระบุรายละเอียด";
        }
        if (roleAndDutyEN.trim().length === 0) {
            formIsValid = false;
            serrors["roleAndDutyEN"] = "กรุณาระบุรายละเอียด";
        }
        if (objectiveEN.trim().length === 0) {
            formIsValid = false;
            serrors["objectiveEN"] = "กรุณาระบุรายละเอียด";
        }
        if (performanceEN.trim().length === 0) {
            formIsValid = false;
            serrors["performanceEN"] = "กรุณาระบุรายละเอียด";
        }*/
        /*if (website.trim().length === 0) {
            formIsValid = false;
            serrors["website"] = "กรุณาระบุรายละเอียด";
        }*/
        if (latlong.trim().length === 0) {
            formIsValid = false;
            serrors["latlong"] = "กรุณาระบุรายละเอียด";
        }
        if (tel.trim().length === 0) {
            formIsValid = false;
            serrors["tel"] = "กรุณาระบุรายละเอียด";
        }
        if (email.trim().length === 0) {
            formIsValid = false;
            serrors["email"] = "กรุณากรอกข้อมูลอีเมล ";
        }
        else {
            let lastAtPos = email.trim().lastIndexOf('@');
            let lastDotPos = email.trim().lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.trim().indexOf('@@') === -1 && lastDotPos > 2 && (email.trim().length - lastDotPos) > 2)) {
                formIsValid = false;
                serrors["email"] = "รูปแบบของอีเมลไม่ถูกต้อง";
            }
        }
        /*if (logoimg === null) {
            formIsValid = false;
            serrors["logoimg"] = "กรุณาเลือกไฟล์ภาพโลโก้";
        }*/

        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        performanceList.map((value, j) => {
            if ((value.PerformanceFile === null && value.PerformanceID === 0) || (value.PerformanceYear.trim().length === 0)) {
                formIsValid = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูลปี พ.ศ และ เอกสารประกอบผลการดำเนินงานให้ครบถ้วน',
                })
            }
        })
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            /*formData.append("id", id);
            formData.append("uuid", uuid);*/
            formData.append("name", name);
            formData.append("location", location);
            formData.append("history", history);
            formData.append("objective", objective);
            formData.append("roleAndDuty", roleAndDuty);
            formData.append("performance", performance);

            formData.append("nameEN", nameEN);
            formData.append("locationEN", locationEN);
            formData.append("historyEN", historyEN);
            formData.append("objectiveEN", objectiveEN);
            formData.append("roleAndDutyEN", roleAndDutyEN);
            formData.append("performanceEN", performanceEN);

            formData.append("website", website);
            formData.append("latlong", latlong);
            formData.append("tel", tel);
            formData.append("status", status);
            formData.append("email", email);


            for (let i = 0; i < fileList.length; i += 1) {
                formData.append("fileList[]", fileList[i]);
            }
            if (logoimg !== null)
                formData.append("logofile", logoimg.file);
            formData.append("userID", currentUser.UserID);

            for (var i = 0; i < performanceList.length; i++) {
                formData.append('performanceList[]', JSON.stringify(performanceList[i]));
            }
            let performanceFileUpdate = [];
            let loop = 0;
            for (let i = 0; i < performanceList.length; i += 1) {
                if (performanceList[i].PerformanceFile !== null) {
                    performanceFileUpdate.push(loop);
                    formData.append("performanceFileList[]", performanceList[i].PerformanceFile);
                    loop++;
                }
                else {
                    performanceFileUpdate.push(-1);
                }
            }
            formData.append("performanceFileUpdate", performanceFileUpdate.join());
            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveEducationCord', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();//props.history.push({pathname:"/admin/network/EditNetworkMOU",state: { id: mouID }});
                        }
                        else {
                            props.history.push({ pathname: "/admin/network/EducationCoordinateList" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const rx_latln = /^[0-9.,]+$/;
    const handleLatLongChange = (e) => {
        if (rx_latln.test(e.target.value)) {
            setLatLong(e.target.value);
        }
    }
    const handleLogoImgOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setLogoImg(imageFile);
    };
    const LogoImgPreview = (
        (logoimg !== null) && (<img className="preview-upload" src={logoimg.preview} onChange={(e) => { e.target.src = URL.createObjectURL(logoimg); }} alt="MOU logo" />)
    );
    const cancel = () => {
        phistory.push({ pathname: "/admin/network/EducationCoordinateList" });
    };

    const handleRemoveFile = filename => {
        setFileList(fileList.filter(item => item['name'] !== filename));
    };
    const FilesList = fileList.map((file, i) => (
        <tr key={file.path}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.path.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    const handleRemoveLogoFile = () => {
        /*const formData = new FormData();
        formData.append("folder", 'images');
        formData.append("url", LogoImgPreview.file);
        formData.append("fieldName", 'LogoImgPath');
        formData.append("id", id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteEducationCordLogoFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });*/
        setLogoImg(null);
    };
    /*const handleEdit = params => {
        props.history.push({ pathname: "/admin/network/EditNetworkMOU", state: { id: id } });
    };*/
    const handleRemovePerformanceList = (index, idxloop) => {
        const removedata = performanceList.filter((item) => Number(item.PerformanceID) === Number(index));
        //console.log(removedata);
        if (Number(removedata[0].PerformanceID) !== 0) {
            const formData = new FormData();
            formData.append("id", removedata[0].PerformanceID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/deletePerformance', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });
        }
        let lstContent = [];
        let loop = 0;
        performanceList.map(row => {
            if (loop !== idxloop) {
                lstContent.push({ PerformanceID: row.PerformanceID, PerformanceYear: row.PerformanceYear, PerformanceFile: row.PerformanceFile, preview: row.preview });
                //console.log(row)
            }
            loop++;
        });
        setPerformanceList(lstContent);

    };
    const AddPerformanceList = () => {
        let checksignimg = true;
        performanceList.map((value, j) => {
            if ((value.PerformanceFile === null && value.PerformanceID === 0) || (value.PerformanceYear.trim().length === 0)) {
                checksignimg = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ผิดพลาด',
                    text: 'กรุณากรอกข้อมูลผู้ลงนามและภาพลายเซ็นต์ให้ครบถ้วน ก่อนเพิ่มข้อมูลผู้ลงนามท่านถัดไป',
                })
            }
        })
        if (checksignimg === true) {
            setPerformanceList((prevFriends) => [
                ...prevFriends,
                {
                    PerformanceID: 0,
                    PerformanceYear: '',
                    PerformanceFile: null,
                    //preview: '',
                },
            ]);
            /*if (performanceList.length < 3) {
                //const data = [...performanceList];
                setPerformanceList((prevFriends) => [
                    ...prevFriends,
                    {
                        PerformanceID: 0,
                        PerformanceYear: '',                       
                        PerformanceFile: null,
                        //preview: '',
                    },
                ]);
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: 'แจ้งให้ทราบ',
                    text: 'ท่านสามารถเพิ่มข้อมูลผู้ลงนามได้สูงสุด 3 รายการ',
                })
            }*/
        }
    }
    const PreviewFilename = (item) => {
        //console.log(item)
        return (item.PerformanceFile)&&(
                <div key={item.PerformanceName}>
                    <a href={item.preview} rel="noreferrer" target="_blank">{item.PerformanceFile.name}</a>
                </div>
        )
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"} path={<><span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/network/EducationCoordinateList" }}>{"การจัดการศูนย์ศึกษาและประสานงานด้านสิทธิมนุษยชนในภูมิภาค"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/network/NewEducationCoordinate" }}>{"สร้าง"}</Link></>}

            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4 card" id="myTabContent">
                            <div className="tab-pane fade show mt-4 active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="name" className="required">ชื่อองค์กร</label>
                                        <input type="text" className="form-control " id="name" value={name} name="name" placeholder="กรุณากรอก" onChange={(e) => { setName(e.currentTarget.value) }} />
                                        <SError error={errors["name"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="location" className="required">ที่อยู่</label>
                                        <input type="text" className="form-control " id="location" value={location} name="location" placeholder="กรุณากรอก" onChange={(e) => { setLocation(e.currentTarget.value) }} />
                                        <SError error={errors["location"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="history">ประวัติการจัดตั้ง</label>
                                        <div>
                                            <ReactSummernote
                                                value={history}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setHistory}
                                            />
                                            <SError error={errors["history"]} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="objective">วัตถุประสงค์</label>
                                        <div>
                                            <ReactSummernote
                                                value={objective}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setObjective}
                                            />
                                            <SError error={errors["objective"]} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="roleAndDuty">บทบาทหน้าที่</label>
                                        <div>
                                            <ReactSummernote
                                                value={roleAndDuty}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setRoleAndDuty}
                                            />
                                            <SError error={errors["roleAndDuty"]} />
                                        </div>
                                    </div>
                                    {/*<div className="col-12">
                                        <label htmlFor="performance">ผลการดำเนินงานที่ผ่านมา</label>
                                        <div>
                                            <ReactSummernote
                                                value={performance}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setPerformance}
                                            />
                                            <SError error={errors["performance"]} />
                                        </div>
                                            </div>*/}
                                </div>
                            </div>
                            <div className="tab-pane fade mt-4" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="nameEN">ชื่อองค์กร</label>
                                        <input type="text" className="form-control " id="nameEN" value={nameEN} name="name" placeholder="กรุณากรอก" onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                        <SError error={errors["nameEN"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="locationEN">ที่อยู่</label>
                                        <input type="text" className="form-control " id="locationEN" value={locationEN} name="locationEN" placeholder="กรุณากรอก" onChange={(e) => { setLocationEN(e.currentTarget.value) }} />
                                        <SError error={errors["locationEN"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="historyEN">ประวัติการจัดตั้ง</label>
                                        <div>
                                            <ReactSummernote
                                                value={historyEN}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setHistoryEN}
                                            />
                                            <SError error={errors["historyEN"]} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="objectiveEN">วัตถุประสงค์</label>
                                        <div>
                                            <ReactSummernote
                                                value={objectiveEN}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setObjectiveEN}
                                            />
                                            <SError error={errors["objectiveEN"]} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="roleAndDutyEN">บทบาทหน้าที่</label>
                                        <div>
                                            <ReactSummernote
                                                value={roleAndDutyEN}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setRoleAndDutyEN}
                                            />
                                            <SError error={errors["roleAndDutyEN"]} />
                                        </div>
                                    </div>
                                    {/*<div className="col-12">
                                        <label htmlFor="performanceEN">ผลการดำเนินงานที่ผ่านมา</label>
                                        <div>
                                            <ReactSummernote
                                                value={performanceEN}
                                                options={{
                                                    //lang: 'th-TH',
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setPerformanceEN}
                                            />
                                            <SError error={errors["performanceEN"]} />
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                            <h4>เอกสารผลการดำเนินงาน</h4>
                            <div className="col-12 text-right"><a href="/" onClick={(e) => { e.preventDefault(); AddPerformanceList(); }}><i className="fas fa-plus-circle"></i> เพิ่มเอกสารผลการดำเนินงาน</a></div>
                            <div className="col-12">
                                {performanceList.map((item, i) => (
                                    <div key={i} className="row mt-2">
                                        <div className="col-4">
                                            <label htmlFor="listofContent">ปี พ.ศ.</label>
                                            <input type="text" className="form-control " id="recommendedTitle" value={item.PerformanceYear} maxLength={4} name="recommendedTitle" placeholder="กรุณากรอก" onChange={(e) => {
                                                setPerformanceList(
                                                    performanceList.map((value, j) => {
                                                        if (i === j) {
                                                            if (rx_int.test(e.target.value)) {
                                                                value.PerformanceYear = e.target.value;
                                                            }
                                                        }
                                                        return value;
                                                    })
                                                )
                                            }} />
                                        </div>
                                        <div className="col-7 mb-2">
                                            <label className="required">ไฟล์เอกสารประกอบผลการดำเนินงาน</label>
                                            <Dropzone
                                                className='dropzone mb-2'
                                                activeClassName='active-dropzone'
                                                multiple={false}
                                                //onDrop={handleSignImageOnDrop(i,)}
                                                onDrop={acceptedFiles => {
                                                    setPerformanceList(
                                                        performanceList.map((value, j) => {
                                                            if (i === j) {
                                                                value.PerformanceFile = acceptedFiles[0];
                                                                value.preview = URL.createObjectURL(acceptedFiles[0]);
                                                                value.name = acceptedFiles[0].name;
                                                                value.size = acceptedFiles[0].Filesize;
                                                            }
                                                            return value;
                                                        })
                                                    )
                                                }}
                                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className="container">
                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                            <input {...getInputProps()} />
                                                            <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                        </div>
                                                        <aside style={{}}>
                                                            {PreviewFilename(item)}
                                                        </aside>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                        {/*<div className="col-2">
                                            {
                                                (typeof item.preview !== 'undefined') && (<img className="preview-upload" src={item.preview} alt="Sign " onChange={(e) => { e.target.src = URL.createObjectURL(item.PerformanceFile); }} />)
                                            }
                                        </div>*/}
                                        <span className="col-1" style={{ marginTop: 40 }}>
                                            <a href={item.preview} className="mr-2" rel="noreferrer" target="_blank"><i className="text-primary far fa-eye"></i> ดู</a>
                                            <a onClick={(e) => { e.preventDefault(); handleRemovePerformanceList(item.PerformanceID, i) }}><i className="text-danger fas fa-minus-circle"></i> ลบ</a>
                                        </span>
                                    </div>
                                ))
                                }
                                <span className="error">{errors["performanceList"]}</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-content" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-6">
                                    <label htmlFor="tel" className="required">เบอร์โทร</label>
                                    <input type="text" className="form-control " id="tel" value={tel} name="tel" placeholder="กรุณากรอก" onChange={(e) => { handlePhoneChange(e) }} />
                                    <SError error={errors["tel"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="email" className="required">อีเมล</label>
                                    <input type="text" className="form-control " id="email" value={email} name="email" placeholder="กรุณากรอก" onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                    <SError error={errors["email"]} />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="website">เว็บลิงก์</label>
                                    <input type="text" className="form-control " id="website" value={website} name="website" placeholder="กรุณากรอก" onChange={(e) => { setWebsite(e.currentTarget.value) }} />
                                    <SError error={errors["website"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="latlong" className="required">แผนที่ (ละติจูด,ลองจิจูด)</label>
                                    <input type="text" className="form-control " id="latlong" name="latlong" placeholder="กรุณากรอก ละติจูด,ลองจิจูด" value={latlong} onChange={(e) => { handleLatLongChange(e) }} />
                                    <SError error={errors["latlong"]} />
                                </div>
                                <div className="col-12">
                                    <label>รูปภาพโลโก้</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleLogoImgOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["logoimg"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {LogoImgPreview}
                                                <SingleFileTable filedata={logoimg} removefn={handleRemoveLogoFile} />
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12">
                                    <label>เอกสารแนบ</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setFileList([...fileList, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {FilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status1" value="1" checked={status === true} onChange={(e) => { setStatus(true); }} />
                                        <label className="form-check-label" htmlFor="status1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status2" value="0" checked={status === false} onChange={(e) => { setStatus(false); }} />
                                        <label className="form-check-label" htmlFor="status2"> ไม่เผยแพร่</label>
                                    </div>
                                    <SError error={errors["status"]} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
