import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import SError from 'components/SError';
import AdminBanner from 'components/Banner/AdminBanner';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from "moment";
const swal = withReactContent(Swal)
export default function PreviewSurvey(props) {
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [nameTH, setNameTH] = useState('');
    const [nameEN, setNameEN] = useState('');
    const [detailTH, setDetailTH] = useState("");
    const [detailEN, setDetailEN] = useState("");
    const [published, setPublished] = useState(false);
    const [ispublished, setIsPublished] = useState(true);
    const [questionSet, setQuestionSet] = useState([]);
    const [answerSet, setAnswerSet] = useState([]);
    const [warningMessage, setWarningMessage] = useState('');
    const surveyID = props.location.state.id;


    useEffect(() => {
        let questionlist = [];
        let answerlist = [];
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSurveybyID', { params: { id: surveyID } })
            .then((response) => {
                setNameTH(response.data[0].SurveyNameTH);
                setNameEN(response.data[0].SurveyNameEN);
                setDetailTH(response.data[0].SurveyDetailTH);
                setDetailEN(response.data[0].SurveyDetailEN);
                setPublished(response.data[0].Published);
                let now = new Date(Date.now());
                if (moment(response.data[0].PublishedEndDate).format('YYYY-MM-DD') < moment(now).format('YYYY-MM-DD')) {
                    setIsPublished(false);
                    setWarningMessage('สิ้นสุดระยะเวลาในการทำแบบสอบถามนี้แล้ว');
                }
                if (moment(response.data[0].PublishedStartDate).format('YYYY-MM-DD') > moment(now).format('YYYY-MM-DD')) {
                    setIsPublished(false);
                    setWarningMessage('ยังไม่ถึงช่วงเวลาในการทำแบบสอบถาม');
                }
                if (response.data[0].Published === false) {
                    setIsPublished(false);
                    setWarningMessage('แบบสอบถามนี้ยังไม่ได้ทำการเผยแพร่หรือได้ยุติการเผยแพร่ไปแล้ว');
                }
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getSurveyQuestionbyID', { params: { id: surveyID } })
                    .then((response) => {
                        questionlist = response.data;

                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSurveyAnswerbyID', { params: { id: surveyID } })
                            .then((aresponse) => {
                                answerlist = aresponse.data;
                                //console.log(questionlist)
                                for (let i = 0; i < questionlist.length; i++) {
                                    let question = questionlist[i];
                                    //console.log(question)
                                    setQuestionSet((prevQuestions) => [
                                        ...prevQuestions,
                                        {
                                            SurveyQuestionID: question.SurveyQuestionID,
                                            SurveyID: question.SurveyID,
                                            QuestionType: question.QuestionType,
                                            QuestionTitle: question.QuestionTitle,
                                            AnswerNum: question.AnswerNum,
                                            AnswerList: answerlist.filter((item) => Number(item.SurveyQuestionID) === Number(question.SurveyQuestionID)),
                                        },
                                    ]);
                                    setAnswerSet((prevAnswers) => [
                                        ...prevAnswers,
                                        {
                                            SurveyQuestionID: question.SurveyQuestionID,
                                            QuestionType: question.QuestionType,
                                            SurveyID: question.SurveyID,
                                            AnswerID: 0,
                                            AnswerIDList: [],
                                            AnswerText: '',
                                        },
                                    ]);
                                }
                                setLoading(false);
                            })
                    })
            })


    }, []); /* <-- add this for run once*/

    const cancel = () => {
        props.history.push({ pathname: "/admin/survey/Survey" });
    };
    if (isLoading) {
        return (<></>);
    }
    return (
        <>
            <section className="row custom-admin-input" >

                <AdminBanner title={"การจัดการแบบสอบถาม"}
                    path={
                        <>
                            <span><i className="fas fa-angle-right"></i></span>
                            <Link id="profile-btn" to={{ pathname: "/admin/survey/Survey" }}>{"การจัดการแบบสอบถาม"}</Link>
                            <span>/</span>
                            <Link className="active" to={{ pathname: "/admin/survey/PreviewSurvey/" + surveyID, state: { id: surveyID } }} onClick={(e) => { e.preventDefault(); }}>
                                {language === "Thai" ? nameTH : nameEN}</Link>
                        </>
                    } />
                <main className="content-page">
                    <section className="edit-profile row" style={{ marginTop: 0 }}>
                        <div className="content-data">
                            <div className="row">
                                <h1 className="survey-header">{language === "Thai" ? nameTH : nameEN}</h1>
                                <div className="survey-detail" dangerouslySetInnerHTML={{ __html: language === "Thai" ? detailTH : detailEN }} />
                            </div>
                            {(ispublished === false || published === false) && (
                                <div className="row">
                                    <div className="col-12" >
                                        <h3 className="text-danger">{warningMessage}</h3>
                                    </div>
                                </div>
                            )}
                            {(ispublished === true && published === true) && (
                                <>
                                    <div className="row">
                                        <div className="col-12" >
                                            {questionSet.map((item, i) => (
                                                <div key={"question-" + item.SurveyQuestionID} className="row mt-2 question-item">
                                                    <div className="col-12">
                                                        <h2>{i + 1}. {item.QuestionTitle}</h2>
                                                    </div>

                                                    {(Number(item.QuestionType) === 1) && (<>
                                                        {item.AnswerList.map((ansitem, i) => (
                                                            <div className="col-12" key={"ans-" + ansitem.SurveyAnswerID + i}>
                                                                <div className="custom-control custom-radio">
                                                                    <input className="custom-control-input" type="radio" name={"ans-" + item.SurveyQuestionID} id={"ans-" + ansitem.SurveyAnswerID + i}
                                                                        checked={Number(answerSet[i].AnswerID) === Number(ansitem.SurveyAnswerID) ? true : false}
                                                                        value={ansitem.SurveyAnswerID}
                                                                        onChange={(e) => {
                                                                            answerSet[i].AnswerID = ansitem.SurveyAnswerID;
                                                                            setAnswerSet([...answerSet]);
                                                                            //console.log(answerSet)
                                                                        }} />
                                                                    <label className="custom-control-label" htmlFor={"ans-" + ansitem.SurveyAnswerID + i}> {ansitem.AnswerTitle}</label>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>)}
                                                    {(Number(item.QuestionType) === 2) && (
                                                        <>
                                                            {item.AnswerList.map((ansitem, j) => (

                                                                <div className="col-12" key={"ans-" + ansitem.SurveyAnswerID + j}>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input" name={"ans-" + item.SurveyQuestionID} id={"ans-" + ansitem.SurveyAnswerID + j} value={ansitem.SurveyAnswerID}
                                                                            checked={typeof answerSet[i].AnswerIDList.find((asc) => {
                                                                                return Number(asc) === ansitem.SurveyAnswerID
                                                                            }) !== 'undefined' ? true : false}
                                                                            value={ansitem.SurveyAnswerID}
                                                                            onChange={(e) => {
                                                                                setAnswerSet(
                                                                                    answerSet.map((value, k) => {
                                                                                        if (k === i) {
                                                                                            if (e.target.checked) {
                                                                                                value.AnswerIDList.push(ansitem.SurveyAnswerID);
                                                                                            }
                                                                                            else {
                                                                                                const rmorder = value.AnswerIDList.indexOf(ansitem.SurveyAnswerID);
                                                                                                //console.log(rmorder)
                                                                                                if (rmorder >= 0) {
                                                                                                    value.AnswerIDList.splice(rmorder, 1);
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        return value;
                                                                                    })
                                                                                )
                                                                                //console.log(answerSet)
                                                                            }} />
                                                                        <label className="custom-control-label" htmlFor={"ans-" + ansitem.SurveyAnswerID + j}>{ansitem.AnswerTitle} </label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                    {(Number(item.QuestionType) === 3) && (
                                                        <>
                                                            <select className="form-control mb-2 mr-2" id="QuestionType" name="QuestionType"
                                                                value={answerSet[i].AnswerID}
                                                                onChange={(e) => {
                                                                    answerSet[i].AnswerID = Number(e.currentTarget.value);
                                                                    setAnswerSet([...answerSet]);
                                                                    //console.log(answerSet)
                                                                }} >
                                                                {item.AnswerList.map((ansitem, i) => (
                                                                    <option key={"ans-" + ansitem.SurveyAnswerID + i} value={ansitem.SurveyAnswerID} >{ansitem.AnswerTitle}</option>

                                                                ))}
                                                            </select>
                                                        </>
                                                    )}
                                                    {(Number(item.QuestionType) === 4 || Number(item.QuestionType) === 5) && (

                                                        <input type="text" className="form-control " id={"ans-" + item.SurveyQuestionID} name={"ans-" + item.SurveyQuestionID}
                                                            value={answerSet[i].AnswerText}
                                                            onChange={(e) => {
                                                                setAnswerSet(
                                                                    answerSet.map((value, j) => {
                                                                        if (j === i) {
                                                                            value.AnswerText = e.target.value;
                                                                        }
                                                                        return value;
                                                                    })
                                                                )
                                                            }} />
                                                    )}
                                                </div>
                                            ))
                                            }
                                        </div>

                                    </div>

                                </>)}
                            <div className="row">
                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center" >
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </main>
            </section>
        </>
    );
}
