import React, { useState, useMemo, useEffect, useRef } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
export default function GalleryContent(props) {
  const thumnailULRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [imgcount, SetImageCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  //console.log(props);
  /*if(typeof props.fileList !== "undefined")
    setLoading(false)*/
  /*if(props.fileList!=null)
    setLoading(false)*/
    useEffect(() => {
      if (typeof thumnailULRef !== 'undefined' && thumnailULRef !=='null') {
        //var slider = document.querySelector('.list-inline-item.active');
        //slider.scrollLeft  = 256*photoIndex;
        //alert(slider.scrollLeft)
        if(thumnailULRef.current!==null)
        thumnailULRef.current.scrollLeft = 256 * (photoIndex - 1);
      }
    }, [photoIndex]);
  
  function handleChange(add) {
    var index = photoIndex+add; 
    if(index < 0)
    index = imgcount;
    if(index >= imgcount)
    index = 0;
    setPhotoIndex(index);
      //console.log(add)
      // do whatever with index
  }

  useMemo(() => {
    setLoading(false);
    SetImageCount(props.gallery.length);
    return props.fileList;
  }, []);

  if (isLoading) {
    return <></>;
  }

  const type1 = props.gallery.map((fileItem, index) => (
    <div key={index} className="controlImage">
      <img
        src={fileItem}        
        onClick={(e) => {
          setIsOpen(true);
          setPhotoIndex(0);
        }}
        className="content-cover"
        alt="Bookmark cover"
      />
    </div>
  ));
  const type2 = props.gallery.map((fileItem, index) => (
    <img
      src={fileItem}
      key={index}
      onClick={(e) => {
        setIsOpen(true);
        setPhotoIndex(index);
      }}
      className="content-cover"
      alt="Bookmark cover"
    />
  ));
  const type3 = props.gallery.map((fileItem, index) => (
    <div
      key={index}
      className={index === 0 ? "active carousel-item" : "carousel-item"}
      data-slide-number={index}
    >
      <img
        src={fileItem}
        className="img-fluid"
        onClick={(e) => {
          setIsOpen(true);
          setPhotoIndex(index);
        }}
        alt="Gallery"
      />
    </div>
  ));
  const thumbnail = props.gallery.map((fileItem, index) => (
    <li
      key={index}
      className={index === 0 ? "list-inline-item active" : "list-inline-item"}
    >
      <a
        id={"carousel-selector-" + index}
        className={index === 0 ? "selected thumbnail" : "thumbnail"}
        data-slide-to={index}
        data-target="#myCarousel"
      >
        <img
          src={fileItem}
          className="img-thumnail"
          onClick={(e) => {
            //setIsOpen(true);
            setPhotoIndex(index);
          }}
          alt="thumbnail"
        />
      </a>
    </li>
  ));
  return (
    <>
      {imgcount === 1 && type1}
      {imgcount === 2 && <div className="duo-image">{type2}</div>}
      {imgcount > 2 && (
        <>
          <div id="myCarousel" className="carousel slide">
            <div className="carousel-inner" >
              {type3}
              <a
                className="carousel-control-prev"
                href="#myCarousel"
                role="button"
                data-slide="prev"
                onClick={(e)=>{handleChange(-1)}}
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#myCarousel"
                role="button"
                data-slide="next"
                onClick={(e)=>{handleChange(1)}}
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>

            <ul className="carousel-indicators list-inline " ref={thumnailULRef}>
              {thumbnail}
              
            </ul>
          </div>
        </>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={props.gallery[photoIndex]}
          nextSrc={props.gallery[(photoIndex + 1) % props.gallery.length]}
          prevSrc={
            props.gallery[
              (photoIndex + props.gallery.length - 1) % props.gallery.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + props.gallery.length - 1) % props.gallery.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % props.gallery.length)
          }
        />
      )}
    </>
  );
}
