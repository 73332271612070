import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Steper from "components/Steper";

const swal = withReactContent(Swal)
export default function FollowComplain(props) {
    const [isLoading, setLoading] = useState(true);
    const [complainList, setComplainList] = useState([]);
    const [mode, setMode] = useState(0);
    const [activeComplain, setActiveComplain] = useState(null);
    const [userID, setUserID] = useState(null);
    const language = localStorage.getItem("language");
    const currentUser = authenticationService.currentUserValue;
    function LoginAlert() {
        Swal.fire({
            icon: 'error',
            title: 'ผิดพลาด',
            text: 'กรุณาเข้าสู่ระบบ',
        }).then((result) => {
            props.history.push({ pathname: "/" });
        })
    }
    useEffect(() => {
        if (currentUser === null) {
            LoginAlert();
        }
        else {
            let userid = 0;
            userid = currentUser.UserID;
            setUserID(currentUser.UserID);
            utils.AccessInfo('ติดตามเรื่องร้องเรียน', '/authen/complain/Complain', 'F', currentUser);
            axios.get(process.env.REACT_APP_APIURL + '/api-web/complainbyUserID', { params: { userid: userid } })
                .then((response) => {
                    /*console.log('xxxxxx')
                    console.log(response.data)*/
                    setComplainList(response.data);
                })
        }
        setLoading(false);

    }, []); /* <-- add this for run once*/

    const complain = complainList.filter((data) => {
        return data.WSComplianNo === null
    }).map((item, i) => (
        <li key={i} className="list-item" onClick={(e) => { setMode(1); setActiveComplain(item) }} style={{ cursor: 'pointer' }}><span className="title">{item.NHRCComplaintJobID} </span> <span className="detail"> {item.ComplainTitle} </span></li>
    ));
    const protest = complainList.filter((data) => {
        return data.WSComplianNo !== null
    }).map((item, i) => (
        <li key={i} className="list-item" onClick={(e) => { setMode(1); setActiveComplain(item) }} style={{ cursor: 'pointer' }}><span className="title">{item.WSComplianNo} </span> <span className="detail"> {item.ComplainTitle} </span></li>
    ));
    function getClassName(step) {
        if (activeComplain.State === 'WAIT') {
            if (step <= 1) {
                return "circle check"
            }
            else if (step == 2) {
                return "circle"
            }
            else
                return "circle gray"
        }
        else if (activeComplain.State === 'ACCEPT') {
            if (step <= 2)
                return "circle check"
            else if (step == 3)
                return "circle"
            else
                return "circle gray"
        }
    }
    function getIconStateName(step) {
        if (activeComplain.State === 'WAIT') {
            if (step === 1) {
                return true
            }
        }
        else if (activeComplain.State === 'ACCEPT') {
            if (step === 2) {
                return true
            }
        }
        return false;
    }
    if (isLoading) {
        return (<></>);
    }

    const dataStep = [
        { status: 'success', title: 'ส่งเรื่องร้องทุกข์' },
        { status: 'success', title: 'กำลังดำเนินการ / อยู่ระหว่างดำเนินการ' },
        { status: 'success', title: 'รับเป็นคำร้อง / ไม่รับเป็นคำร้อง' },
        { status: 'pending', title: ' ส่งต่อหน่วยงานภายนอก / ประสานหน่วยงานที่เกี่ยวข้อง' },
        { status: 'default', title: 'ยุติเรื่อง / ยุติการติดตาม' },
    ]
    return (
        <>
            <div id="header-wrapper">
                {/*{<Navbar />}*/}
                <ContentBanner language={language} theader={"ร้องเรียน"} eheader={"Complaint"} bannerkey="CMPFOLLOW" banner={banner}
                    path={<>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/complain/FollowComplain" }}>{language === "Thai" ? "ร้องเรียน" : "Complain"}</Link>
                    </>}
                />
            </div>
            <main className="content-page">
                <div className="container">
                    <section className="edit-profile row custom-complain" >
                        <div className="news-activity-data mb-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sub-title">
                                        <h2 className="thai-header text-center"><span className="orang content-underline">ติดตามเรื่องร้องเรียน</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(mode === 0) && (<>
                            <div className="subheader  col-12 mt-4">ร้องเรียน</div>
                            <div className="content-data">
                                <div className="row formContent">
                                    {complain}
                                </div>
                            </div>
                            <div className="subheader col-12 mt-4">คำร้อง</div>
                            <div className="content-data">
                                <div className="row formContent">
                                    {protest}
                                </div>
                            </div>
                        </>)
                        }
                        {(mode === 1) && (<>
                            <div className="subheader col-12">เลขคำร้อง : {activeComplain.NHRCComplaintJobID}</div>
                            <div className="step">
                                <Steper data={dataStep} />
                            </div>
                            <div className="subheader col-12">เรื่อง : {activeComplain.ComplainTitle}</div>
                            <div className="content-data">
                                <li className="list-item detail" >
                                    <div className="row">
                                        <span className="col-5 title" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เจ้าหน้าที่ออกเลขร้องเรียน </span> <span className="col-7 detail"> {activeComplain.UpdatedBy === null ? "-" : activeComplain.UpdatedBy} </span>
                                    </div>
                                </li>
                                <li className="list-item detail" >
                                    <div className="row">
                                        <span className="col-5 title" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เจ้าหน้าที่ผู้รับเรื่องร้องเรียน </span> <span className="col-7 detail"> {activeComplain.CreatedBy === null ? "-" : activeComplain.CreatedBy} </span>
                                    </div>
                                </li>
                                <li className="list-item detail" >
                                    <div className="row">
                                        <span className="col-5 title" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เบอร์โทรติดต่อเจ้าหน้าที่ผู้รับผิดชอบคำร้อง </span> <span className="col-7 detail"> 1377 </span>
                                    </div>
                                </li>
                                <li className="list-item detail" >
                                    <div className="row">
                                        <span className="col-5 title" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>เลขที่รายงาน </span> <span className="col-7 detail"> {activeComplain.ExaminerReportNo === null ? "-" : activeComplain.ExaminerReportNo} </span>
                                    </div>
                                </li>
                                <li className="list-item detail" >
                                    <div className="row">
                                        <span className="col-5 title" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>การปฏิบัติงาน </span> <span className="col-7 detail"> {activeComplain.ExaminerAction === null ? "-" : activeComplain.ExaminerAction} </span>
                                    </div>
                                </li>
                                <li className="list-item detail" >
                                    <div className="row">
                                        <span className="col-5 title" style={{ borderRight: '2px solid #989898', fontWeight: 'bolder' }}>ความคืบหน้าล่าสุด </span> <span className="col-7 detail"> {activeComplain.CurrentProgress === null ? "-" : activeComplain.CurrentProgress} </span>
                                    </div>
                                </li>
                            </div>
                            <div className="row formContent" style={{ width: 100 + '%' }}>
                                <div className="col-12">
                                    <input type="submit" className="fadeIn fourth" value="ย้อนกลับ" onClick={(e) => { e.preventDefault(); setMode(0); }} />
                                </div>
                            </div>
                        </>)
                        }
                    </section>
                </div>
            </main>
        </>
    );
};
