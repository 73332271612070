import * as React from "react";
const SvgHome = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18.544 20.382"
    {...props}
  >
    <path
      d="M9.041.027a1 1 0 0 0-.412.207L.357 7.178A1 1 0 0 0 0 7.943v11.44a1 1 0 0 0 1 1h5.207a1 1 0 0 0 1-1v-3.574a2.06 2.06 0 0 1 2.068-2.065h.002l.01.004a1.91 1.91 0 0 1 1.95 2.018v3.416a1 1 0 0 0 .105.447l.1.201a1 1 0 0 0 .894.553h5.209a1 1 0 0 0 1-1V7.943a1 1 0 0 0-.358-.765L9.915.234a1 1 0 0 0-.873-.207m.23 2.278 7.274 6.105v9.973h-3.309v-2.57l-.002.046c.106-2.232-1.708-4.11-3.937-4.103l-.027-.012a4.087 4.087 0 0 0-4.063 4.06v2.579H2V8.41z"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        InkscapeStroke: "none",
      }}
    />
  </svg>
);
export default SvgHome;
