import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/elearning.svg";
import utils from "utils/utils";
import { authenticationService } from "services/authentication.service.js";
import { useHistory } from "react-router-dom";

const swal = withReactContent(Swal);

export default function ElearningTest(props) {
  const currentUser = authenticationService.currentUserValue;
  const eLearningID = props.location.state.eLearningID;
  const chapterID = props.location.state.chapterID;
  const questionnaireID = props.location.state.questionnaireID;
  const [elearningChapterUser, setElearningChapterUser] = useState(0);
  const [userID, setUserID] = useState(null);
  const [chapterQuestion, setChapterQuestion] = useState(null);
  const [pageSize, setPageSize] = useState(1);
  const [numPages, setNumPages] = useState(1);
  //const [maxDuration, setMaxDuration] = useState(0);
  const [questionList, setQuestionList] = useState([]);
  //const [questionInPage, setQuestionInPage] = useState([]);
  const [usePage, setUsePage] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [duration, setDuration] = useState(0);
  const [count, setCount] = useState(0);
  const [endTest, setEndTest] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [pause, setPause] = useState(false);
  const [sendtoServer, setSendtoServer] = useState(false);
  const [examRetryCount, setExamRetryCount] = useState(0);
  const language = localStorage.getItem("language");
  const history = useHistory();

  /*const initBeforeUnLoad = (showExitPrompt) => {
        window.onbeforeunload = (event) => {
            setPause(true);
            setSendtoServer(true);
        };
      };
    window.onload = function() {
        initBeforeUnLoad(showExitPrompt);
      };*/

  useEffect(() => {
    //initBeforeUnLoad(showExitPrompt);
    window.addEventListener(
      "blur",
      function () {
        setPause(true);
        setSendtoServer(true);
      },
      false
    );

    window.addEventListener(
      "focus",
      function () {
        setPause(false);
        setSendtoServer(false);
      },
      false
    );
  }, []);

  useEffect(() => {
    //console.log(props.location.state)
    //history.listen((location)=>{dismissAllFlags(location)})
    let userid = null;
    if (currentUser !== null) {
      userid = currentUser.UserID;
    }
    setUserID(userid);
    const formData = new FormData();
    formData.append("eLearningID", eLearningID);
    formData.append("chapterID", chapterID);
    formData.append("questionnaireID", questionnaireID);
    formData.append("userID", userid);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/getQuestionnairebyID",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        setChapterQuestion(response.data);
        //console.log(response.data)
        const pageSize = response.data.questionnaire.NumDisplayQuestionPerPage;
        setPageSize(pageSize);
        const questionItemPrepair = [...response.data.questionItem];
        /*เพิ่มฟิลด์คำตอบที่ผู้ใช้เลือก*/
        questionItemPrepair.forEach(function (element) {
          element.userAnswer = null;
        });
        setNumPages(Math.ceil(questionItemPrepair.length / pageSize));
        if (Math.ceil(questionItemPrepair.length / pageSize) > 0) {
          let pagelist = [...usePage];
          pagelist.push(0);
          setUsePage(pagelist);
          setCurrentPage(0);
        }

        setDuration(Number(response.data.questionnaire.Duration));
        let questionitem = [...questionItemPrepair];
        const test = JSON.parse(localStorage.getItem("test"));

        //test = {eLearningID:eLearningID, chapterID:chapterID, questionnaireID:item.QuestionnaireID, userID:currentUser.UserID, count:count, question:sendItem}
        //console.log(test)
        let lastTest = null; //มีข้อมูลการสอบล่าสุดอยู่ในเบสแล้ว
        if (response.data.testData.length > 0) {
          setElearningChapterUser(
            response.data.testData[0].ElearningChapterUserID
          );
          lastTest = response.data.testData[0];
          setExamRetryCount(lastTest.ExamRetryCount);
          if (lastTest !== null) {
            //if (response.data.questionnaire.AllowRetry === true) {
            if (
              Number(lastTest.ExamRetryCount) >=
              Number(response.data.questionnaire.AllowRetryCount)
            ) {
              if (Number(response.data.DurationType) > 0) {
                sendEndTestToServer(
                  response.data.testData[0].ElearningChapterUserID
                );
                props.history.push({
                  pathname: "/authen/elearning/ViewElearning/" + eLearningID,
                  state: { id: eLearningID, userID: userID },
                });
                return;
              }
            }
            //})
          }
        }
        if (test === null) {
          if (response.data.questionnaire.AllowShuffle === true) {
            questionitem = shuffle(questionitem);
          }
          setQuestionList(questionitem);
          setCount(0);
        } else {
          setCount(test.count);
          //console.log(test.question)
          //console.log(questionitem)
          let sortitem = [];
          for (let i = 0; i < questionitem.length; i++) {
            const tmpdata = questionitem.find((el) => {
              return el.QuestionItemID === test.question[i].questionItemID;
            });
            if (typeof tmpdata !== "undefined") {
              tmpdata.userAnswer = Number(test.question[i].userAnswer);
              sortitem.push(tmpdata);
            } else {
              //console.log(test.question[i])
              let data = questionitem[i];
              data.userAnswer = null;
              sortitem.push(data);
            }
          }
          setQuestionList(sortitem);
        }
        //const itemList = [...questionItemPrepair];
        //const questioninpages = itemList.slice((0) * pageSize, 1 * pageSize);

        //setQuestionInPage(questioninpages)
        //console.log(questionitem)

        setLoading(false);
      });
  }, []); /* <-- add this for run once*/
  function shuffle(array) {
    var currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
  function UserAnswerQuestion(questionID, answer) {
    let tmp = [...questionList];
    let newlist = tmp.map((el) =>
      el.QuestionItemID === questionID ? { ...el, userAnswer: answer } : el
    );
    setQuestionList(newlist);
    sendTestToServer();
    //console.log(questionList);
  }
  const QuestionItemPage = () => {
    let result = <></>;
    //console.log(pageSize)
    //console.log(chapterQuestion.questionnaire.Ediable)
    for (let i = 0; i < questionList.length; i++) {
      if (i >= pageSize * currentPage && i < pageSize * (currentPage + 1)) {
        const item = questionList[i];
        //console.log(item.userAnswer)
        const subresult = (
          <div className="card mb-3" key={i}>
            <div className="card-body">
              <div
                className="subheader col-12"
                role="alert"
                style={{ paddingLeft: 20 }}
              >
                {i + 1}. {item.ItemTitle}
              </div>
              <div className="col-12 text-left">
                <div className="custom-control custom-radio custom-control-inline d-block">
                  <input
                    type="radio"
                    className="custom-control-input"
                    checked={item.userAnswer === 1}
                    onChange={(e) => {
                      if (chapterQuestion.questionnaire.Ediable === false) {
                        if (item.userAnswer === null) {
                          item.userAnswer = 1;
                          UserAnswerQuestion(item.QuestionItemID, 1);
                        }
                      } else {
                        item.userAnswer = 1;
                        UserAnswerQuestion(item.QuestionItemID, 1);
                      }
                    }}
                    id={"question" + item.QuestionItemID + "-01"}
                    name={"question" + item.QuestionItemID}
                    value="1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"question" + item.QuestionItemID + "-01"}
                    style={{ width: 100 + "%", display: "block" }}
                  >
                    {item.Answer1}
                  </label>
                </div>
              </div>
              <div className="col-12 text-left">
                <div className="custom-control custom-radio custom-control-inline d-block">
                  <input
                    type="radio"
                    className="custom-control-input"
                    checked={item.userAnswer === 2}
                    onChange={(e) => {
                      if (chapterQuestion.questionnaire.Ediable === false) {
                        if (item.userAnswer === null) {
                          item.userAnswer = 2;
                          UserAnswerQuestion(item.QuestionItemID, 2);
                        }
                      } else {
                        item.userAnswer = 2;
                        UserAnswerQuestion(item.QuestionItemID, 2);
                      }
                    }}
                    id={"question" + item.QuestionItemID + "-02"}
                    name={"question" + item.QuestionItemID}
                    value="2"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"question" + item.QuestionItemID + "-02"}
                    style={{ width: 100 + "%", display: "block" }}
                  >
                    {item.Answer2}
                  </label>
                </div>
              </div>
              <div className="col-12 text-left">
                <div className="custom-control custom-radio custom-control-inline d-block">
                  <input
                    type="radio"
                    className="custom-control-input"
                    checked={item.userAnswer === 3}
                    onChange={(e) => {
                      if (chapterQuestion.questionnaire.Ediable === false) {
                        if (item.userAnswer === null) {
                          item.userAnswer = 3;
                          UserAnswerQuestion(item.QuestionItemID, 3);
                        }
                      } else {
                        item.userAnswer = 3;
                        UserAnswerQuestion(item.QuestionItemID, 3);
                      }
                    }}
                    id={"question" + item.QuestionItemID + "-03"}
                    name={"question" + item.QuestionItemID}
                    value="3"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"question" + item.QuestionItemID + "-03"}
                    style={{ width: 100 + "%", display: "block" }}
                  >
                    {item.Answer3}
                  </label>
                </div>
              </div>
              <div className="col-12 text-left">
                <div className="custom-control custom-radio custom-control-inline d-block">
                  <input
                    type="radio"
                    className="custom-control-input"
                    checked={item.userAnswer === 4}
                    onChange={(e) => {
                      if (chapterQuestion.questionnaire.Ediable === false) {
                        if (item.userAnswer === null) {
                          item.userAnswer = 4;
                          UserAnswerQuestion(item.QuestionItemID, 4);
                        }
                      } else {
                        item.userAnswer = 4;
                        UserAnswerQuestion(item.QuestionItemID, 4);
                      }
                    }}
                    id={"question" + item.QuestionItemID + "-04"}
                    name={"question" + item.QuestionItemID}
                    value="4"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"question" + item.QuestionItemID + "-04"}
                    style={{ width: 100 + "%", display: "block" }}
                  >
                    {item.Answer4}
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
        result = (
          <>
            {result}
            {subresult}
          </>
        );
      }
    }
    return result;
  };

  const ClickPage = (page) => {
    if (chapterQuestion.questionnaire.AllowCrossPage) {
      let pagelist = [...usePage];
      if (pagelist.indexOf(page) === -1) {
        pagelist.push(page);
      }
      setUsePage(pagelist);
      setCurrentPage(page);
    }
  };
  const QuestionPageDisplay = () => {
    let pages = <></>;
    for (let i = numPages - 1; i >= 0; i--) {
      let active = "";
      active = usePage.indexOf(i) === -1 ? "" : " active";
      if (currentPage === i) {
        active = " active current";
      }

      pages = (
        <>
          <span
            className={"page-button" + active}
            onClick={(e) => {
              ClickPage(i);
            }}
          >
            {Number(i) + 1}
          </span>{" "}
          {pages}
        </>
      );
    }
    let resultDiv = <>{pages}</>;
    return resultDiv;
  };

  const QuestionTimeDeisplay = () => {
    useEffect(() => {
      let id = setInterval(() => {
        if (count > duration) {
          if (Number(chapterQuestion.questionnaire.DurationType) > 0) {
            setEndTest(true);
          }
        } else {
          if (pause === false) {
            setCount((time) => time + 1);
            CachTestData();
          }
          if (sendtoServer === true) {
            setSendtoServer(false);
            sendTestToServer();
            CachTestData();
          }
        }
      }, 1000);
      return () => clearInterval(id);
    }, [count]);
    if (Number(chapterQuestion.questionnaire.DurationType) > 0) {
      let secCount = duration - count;
      let minutes = parseInt(secCount / 60);
      let sec = secCount - 60 * parseInt(secCount / 60);
      if (sec < 10) sec = "0" + sec;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (secCount <= 0) {
        minutes = "00";
        sec = "00";
      }

      let resultDiv = <></>;
      //console.log(pages)
      resultDiv = <>{minutes + ":" + sec}</>;
      return resultDiv;
    } else {
      return <></>;
    }
  };
  const nextPageFunction = () => {
    let pagelist = [...usePage];
    let nextpage = currentPage + 1;
    if (nextpage >= numPages - 1) nextpage = numPages - 1;
    if (pagelist.indexOf(nextpage) === -1) {
      pagelist.push(nextpage);
    }
    setUsePage(pagelist);
    setCurrentPage(nextpage);
  };

  const sendEndTestToServer = (elearningChapterUserID) => {
    const formData = new FormData();
    formData.append("elearningChapterUser", elearningChapterUserID);
    formData.append("eLearningID", eLearningID);
    formData.append("chapterID", chapterID);
    formData.append("questionnaireID", questionnaireID);
    formData.append("userID", currentUser.UserID);
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/userTestEnd", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        localStorage.removeItem("test");
      });
  };
  const sendTestToServer = (complete = false) => {
    let sendItem = [];
    let maxScore = 0;
    let resultScore = 0;
    if (count > duration) {
      //count = duration;
      setCount(duration);
      complete = true;
    }
    for (let i = 0; i < questionList.length; i++) {
      let item = questionList[i];
      let answerResult = item.CorrectAnswer === item.userAnswer ? 1 : 0;
      resultScore += answerResult;
      sendItem.push({
        questionItemID: item.QuestionItemID,
        userAnswer: item.userAnswer,
        answerResult: answerResult,
      });
      maxScore += Number(item.Score);
    }
    let data = {
      eLearningID: eLearningID,
      chapterID: chapterID,
      questionnaireID: questionnaireID,
      userID: currentUser.UserID,
      count: count,
      question: sendItem,
    };

    const formData = new FormData();
    formData.append("elearningChapterUser", elearningChapterUser);
    formData.append("eLearningID", eLearningID);
    formData.append("chapterID", chapterID);
    formData.append("questionnaireID", questionnaireID);
    formData.append("userID", currentUser.UserID);
    formData.append("timeCount", count);
    formData.append("complete", complete);
    formData.append("resultScore", resultScore);
    formData.append("maxScore", maxScore);
    for (var i = 0; i < sendItem.length; i++) {
      //console.log(listofContent[i])
      formData.append("answer[]", JSON.stringify(sendItem[i]));
    }
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/userTestCache", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        if (complete === true) {
          sendEndTestToServer(elearningChapterUser);
          props.history.push({
            pathname: "/authen/elearning/ViewElearning/" + eLearningID,
            state: { id: eLearningID, userID: userID },
          });
        }
      });
  };
  const CachTestData = () => {
    //console.log(count);
    /*console.log(endTest)*/
    if (questionList.length > 0) {
      let sendItem = [];
      for (let i = 0; i < questionList.length; i++) {
        let item = questionList[i];
        let answerResult = item.CorrectAnswer === item.userAnswer ? 1 : 0;
        sendItem.push({
          questionItemID: item.QuestionItemID,
          userAnswer: item.userAnswer,
          answerResult: answerResult,
        });
      }
      let data = {
        eLearningID: eLearningID,
        chapterID: chapterID,
        questionnaireID: questionnaireID,
        userID: currentUser.UserID,
        count: count,
        question: sendItem,
      };
      //console.log(sendItem)

      const test = JSON.stringify(data);
      localStorage.setItem("test", test);
      if (count % 10 === 0 && endTest === false) {
        sendTestToServer();
      }
    }
  };
  const sendTestData = (complete = false) => {
    localStorage.removeItem("test");
    sendTestToServer(complete);
  };
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {<Navbar />}
        <ContentBanner
          language={language}
          theader={"อีเลิร์นนิง"}
          eheader={"E-learning"}
          bannerkey="ELEARN"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/elearning/ELearning",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "อีเลิร์นนิง" : "E-learning"}
              </Link>
              <span>/</span>
              {
                <Link
                  className="active"
                  to={{
                    pathname: "/authen/elearning/ViewElearning/" + eLearningID,
                    state: { id: eLearningID, userID: userID },
                  }}
                >
                  {chapterQuestion.ELearnTitle}
                </Link>
              }
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data">
              <div className="row">
                <div className="col-12">
                  <div className="sub-title">
                    <h2 className="thai-header text-center">
                      <span className="orang content-underline">
                        {chapterQuestion.ELearnTitle}
                      </span>
                    </h2>
                  </div>
                  <div className="sub-title title">
                    <h2 className="thai-header text-blue text-center mt-5">
                      <span style={{ fontSize: 2 + "rem", color: "#36A9AE" }}>
                        {chapterQuestion.ChapterTitle}
                      </span>
                    </h2>
                  </div>
                  <form className="formContent row">
                    <div className="col-8">
                      <QuestionItemPage />
                    </div>
                    <div className="col-4">
                      <div className="card">
                        <div
                          className="card-body"
                          style={{ textAlign: "left" }}
                        >
                          <QuestionPageDisplay />
                        </div>

                        <ul className="list-group list-group-flush">
                          {Number(
                            chapterQuestion.questionnaire.DurationType
                          ) !== 0 && (
                            <>
                              <li className="list-group-item">
                                <span
                                  className="text-center mt-2 mb-2"
                                  style={{ margin: "15px 0" }}
                                >
                                  เหลือเวลา
                                </span>
                                <br />
                                <span
                                  style={{ display: "block", marginTop: 15, fontSize: 3 + "rem",
                                    fontWeight: "bolder",}}
                                  className="text-warning text-center mt-2 mb-2"
                                >
                                  <QuestionTimeDeisplay />
                                </span>{" "}
                                นาที
                              </li>
                            </>
                          )}
                          {Number(
                            chapterQuestion.questionnaire.DurationType
                          ) === 0 && (
                            <>
                              <li className="list-group-item">
                                <span
                                  className="text-center mt-2 mb-2"
                                  style={{ margin: "15px 0" }}
                                >
                                  ไม่จำกัดเวลา
                                </span>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <div className="pl-4 pr-4 text-center">
                        <input
                          type="submit"
                          className="fadeIn fourth"
                          value="เสร็จสิ้นแบบทดสอบ"
                          onClick={(e) => {
                            e.preventDefault();
                            sendTestData(true);
                          }}
                        />
                        {(numPages > 1)&&(<input
                          type="button"
                          className="fadeIn fourth"
                          value="ถัดไป"
                          onClick={(e) => {
                            e.preventDefault();
                            nextPageFunction();
                          }}
                        />)}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
