import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import 'moment/locale/th';
import 'react-summernote/dist/react-summernote.css'; // import styles
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from "react-dropzone";
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function NewContactUs(props) {
    const currentUser = authenticationService.currentUserValue;
    const [isMainBranch, setIsMainBranch] = useState(0);
    const [orgName, setOrgName] = useState('');    
    const [displayAddress, setDisplayAddress] = useState('');
    const [logoImage, setLogoImage] = useState(null);
    const [orgNameEN, setOrgNameEN] = useState('');
    const [displayAddressEN, setDisplayAddressEN] = useState('');
    const [provinceID, SetProvinceID] = useState(0);
    const [districtID, setDistrictID] = useState(0);
    const [subDistricID, setSubDistricID] = useState(0);
    const [postCode, setPostCode] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [url, setURL] = useState('');
    const [facebook, setFacebook] = useState('');
    const [youtube, setYoutube] = useState('');
    const [latitude, setLatitude] = useState(0.0);
    const [longitude, setLongitude] = useState(0.0);
    const [mapImage, setMapImage] = useState(null);
    const [status, setStatus] = useState(1);

    const [provinceList, SetProvinceList] = useState([]);
    const [allDistrictList, SetAllDistrictList] = useState([]);
    const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
    const [districtList, SetDistrictList] = useState([]);
    const [subDistrictList, SetSubDistrictList] = useState([]);
    const [errors, setError] = useState({});
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getProvince')
            .then((response) => {
                SetProvinceList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getDistrict')
            .then((response) => {
                SetDistrictList(response.data);
                SetAllDistrictList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSubDistrict')
            .then((response) => {
                SetSubDistrictList(response.data);
                SetAllSubDistrictList(response.data);
            });
    }, []); /* <-- add this for run once*/
    const handleChangeProvince = ProvinceID => {
        SetDistrictList(allDistrictList.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        SetSubDistrictList(allSubDistrictList.filter(item => item['ProvinceID'] === Number(ProvinceID)));
        setDistrictID(0);
        setSubDistricID(0);
    };
    const handleChangeDistrict = DistrictID => {
        SetSubDistrictList(allSubDistrictList.filter(item => item['DistrictID'] === Number(DistrictID)));
        setSubDistricID(0);
    };
    const handleChangeSubDistrict = SubDistrictID => {
        const subdistrictitem = allSubDistrictList.filter(item => item['SubDistrictID'] === Number(SubDistrictID));
        if (subdistrictitem[0] != null) {
            setPostCode(subdistrictitem[0].PostCode);
        }
        else {
            setPostCode('');
        }
    };
    const rx_latln = /^[0-9.]+$/;
    const handleLatChange = (e) => {
        if (rx_latln.test(e.target.value)) {
            setLatitude(e.target.value);
        }
    }
    const handleLongChange = (e) => {
        if (rx_latln.test(e.target.value)) {
            setLongitude(e.target.value);
        }
    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        if (Number(isMainBranch) === -1) {
            formIsValid = false;
            serrors["isMainBranch"] = "กรุณาเลือกหมวดหมู่";
        }
        if (orgName.trim().length === 0) {
            formIsValid = false;
            serrors["orgName"] = "กรุณากรอกข้อมูล";
        }
        if (displayAddress.trim().length === 0) {
            formIsValid = false;
            serrors["displayAddress"] = "กรุณากรอกข้อมูล";
        }
        if (Number(provinceID) === 0) {
            formIsValid = false;
            serrors["provinceID"] = "กรุณาเลือกจังหวัด";
        }
        if (Number(districtID) === 0) {
            formIsValid = false;
            serrors["districtID"] = "กรุณาเลือกเขต/อำเภอ";
        }
        if (Number(subDistricID) === 0) {
            formIsValid = false;
            serrors["subDistricID"] = "กรุณาเลือกแขวง/ตำบล";
        }
        if (postCode.trim().length === 0) {
            formIsValid = false;
            serrors["postCode"] = "กรุณากรอกข้อมูล";
        }

        if (phone.trim().length === 0) {
            formIsValid = false;
            serrors["phone"] = "กรุณากรอกข้อมูล";
        }
        if (fax.trim().length === 0) {
            formIsValid = false;
            serrors["fax"] = "กรุณากรอกข้อมูล";
        }
        if (email.trim().length === 0) {
            formIsValid = false;
            serrors["email"] = "กรุณากรอกข้อมูล";
        }
        if (latitude.length === 0) {
            formIsValid = false;
            serrors["latitude"] = "กรุณากรอกข้อมูล";
        }
        if (longitude.length === 0) {
            formIsValid = false;
            serrors["longitude"] = "กรุณากรอกข้อมูล";
        }
        if (mapImage === null) {
            formIsValid = false;
            serrors["mapImage"] = "กรุณาเลือกภาพแผนที่เพื่ออัปโหลด";
        }
        if (Number(status) === -1) {
            formIsValid = false;
            serrors["status"] = "กรุณาเลือกสถานะ";
        }

        setError(serrors);
        //console.log(status)
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("isMainBranch", isMainBranch);
            formData.append("orgName", orgName);
            formData.append("displayAddress", displayAddress);
            formData.append("orgNameEN", orgNameEN);
            formData.append("displayAddressEN", displayAddressEN);
            formData.append("provinceID", provinceID);
            formData.append("districtID", districtID);
            formData.append("subDistricID", subDistricID);
            formData.append("postCode", postCode);
            formData.append("phone", phone);
            formData.append("fax", fax);
            formData.append("email", email);
            formData.append("url", url);
            formData.append("facebook", facebook);
            formData.append("youtube", youtube);
            formData.append("latitude", latitude);
            formData.append("longitude", longitude);
            formData.append("status", status);
            formData.append("mapImage", mapImage.file);
            formData.append("logoImage", logoImage.file);
            formData.append("userID", currentUser.UserID);

            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveContactUS', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/contact/ContactUs" });
                        }
                    })
                });

        } else {
            scroller.scrollTo("error", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
        }
    };
    const handleLogoimgOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setLogoImage(imageFile);
    };
    const LogoImgPreview = (
        (logoImage !== null) && (<img className="preview-upload" src={logoImage.preview} onChange={(e) => { e.target.src = URL.createObjectURL(mapImage); }} alt="Contact logo" />)
    );
    const handleMapimgOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setMapImage(imageFile);
    };
    const MapImgPreview = (
        (mapImage !== null) && (<img className="preview-upload" src={mapImage.preview} onChange={(e) => { e.target.src = URL.createObjectURL(mapImage); }} alt="Map logo" />)
    );
    const cancel = () => {
        props.history.push({ pathname: "/admin/contact/ContactUs" });
    };

    const ProvinceSelect = provinceList.map((province) => (
        <option
            key={province.ProvinceID}
            value={province.ProvinceID}
        >
            {province.ProvinceTH}
        </option>
    ));
    const DistrictSelect = districtList.map((district) => (
        <option
            key={district.DistrictID}
            value={district.DistrictID}
        >
            {district.DistrictTH}
        </option>
    ));
    const SubDistrictSelect = subDistrictList.map((subdistrict) => (
        <option
            key={subdistrict.SubDistrictID}
            value={subdistrict.SubDistrictID}
        >
            {subdistrict.SubDistrictTH}
        </option>
    ));
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"จัดการการติดต่อ"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>จัดการการติดต่อ</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/contact/ContactUs" }}>{"จัดการการติดต่อ"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/contact/NewContactUs" }}>{"สร้าง"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">

                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12">
                                            <label className="required mr-2">หมวดหมู่</label>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="isMainBranch" id="isMainBranch1" value="1" checked={Number(isMainBranch) === 1} onChange={(e) => { setIsMainBranch(1); }} />
                                                <label className="form-check-label" htmlFor="isMainBranch1">สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="isMainBranch" id="isMainBranch2" value="2" checked={Number(isMainBranch) === 2} onChange={(e) => { setIsMainBranch(2); }} />
                                                <label className="form-check-label" htmlFor="isMainBranch2">สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติพื้นที่ภาคใต้</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="isMainBranch" id="isMainBranch3" value="0" checked={Number(isMainBranch) === 0} onChange={(e) => { setIsMainBranch(0); }} />
                                                <label className="form-check-label" htmlFor="isMainBranch3">ศูนย์ศึกษาและประสานงานด้านสิทธิมนุษยชนในภูมิภาค</label>
                                            </div>
                                            <SError error={errors["isMainBranch"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="orgName" className="required">ชื่อองค์กร/หน่วยงาน</label>
                                            <input type="text" className="form-control " id="orgName" value={orgName} name="orgName" placeholder="กรุณากรอก" onChange={(e) => { setOrgName(e.currentTarget.value) }} />
                                            <SError error={errors["orgName"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="displayAddress" className="required">ที่อยู่</label>
                                            <div>
                                                <textarea className="form-control" name="displayAddress" id="displayAddress" rows="3" value={displayAddress} onChange={e => { setDisplayAddress(e.target.value) }}></textarea>
                                                <SError error={errors["displayAddress"]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12">
                                            <label className="required mr-2">หมวดหมู่</label>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="isMainBranchEN" id="isMainBranch1EN" value="1" checked={Number(isMainBranch) === 1} onChange={(e) => { setIsMainBranch(1); }} />
                                                <label className="form-check-label" htmlFor="isMainBranch1EN">สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="isMainBranchEN" id="isMainBranch2EN" value="2" checked={Number(isMainBranch) === 2} onChange={(e) => { setIsMainBranch(2); }} />
                                                <label className="form-check-label" htmlFor="isMainBranch2EN">สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติพื้นที่ภาคใต้</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="isMainBranchEN" id="isMainBranch3EN" value="0" checked={Number(isMainBranch) === 0} onChange={(e) => { setIsMainBranch(0); }} />
                                                <label className="form-check-label" htmlFor="isMainBranch3EN">ศูนย์ศึกษาและประสานงานด้านสิทธิมนุษยชนในภูมิภาค</label>
                                            </div>
                                            <SError error={errors["isMainBranch"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="orgNameEN" className="required">ชื่อองค์กร/หน่วยงาน</label>
                                            <input type="text" className="form-control " id="orgNameEN" value={orgNameEN} name="orgNameEN" placeholder="กรุณากรอก" onChange={(e) => { setOrgNameEN(e.currentTarget.value) }} />
                                            <SError error={errors["orgNameEN"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="displayAddressEN" className="required">ที่อยู่</label>
                                            <div>
                                                <textarea className="form-control" name="displayAddressEN" id="displayAddressEN" rows="3" value={displayAddressEN} onChange={e => { setDisplayAddressEN(e.target.value) }}></textarea>
                                                <SError error={errors["displayAddressEN"]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                
                                <div className="col-6">
                                    <label htmlFor="provinceID" className="required">จังหวัด</label>
                                    <select className="form-control mb-2 mr-2" id="provinceID" name="provinceID" value={provinceID} onChange={(e) => { SetProvinceID(e.currentTarget.value); handleChangeProvince(e.currentTarget.value); }} >
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {ProvinceSelect}
                                    </select>
                                    <SError error={errors["provinceID"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="districtID" className="required">เขต/อำเภอ</label>
                                    <select className="form-control mb-2 mr-2" id="districtID" name="districtID" value={districtID} onChange={(e) => { setDistrictID(e.currentTarget.value); handleChangeDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {DistrictSelect}
                                    </select>
                                    <SError error={errors["districtID"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="subDistricID" className="required">แขวง/ตำบล</label>
                                    <select className="form-control mb-2 mr-2" id="subDistricID" name="subDistricID" value={subDistricID} onChange={(e) => { setSubDistricID(e.currentTarget.value); handleChangeSubDistrict(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {SubDistrictSelect}
                                    </select>
                                    <SError error={errors["subDistricID"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="orgName" className="required">รหัสไปรษณีย์</label>
                                    <input type="text" className="form-control " id="postCode" value={postCode} name="postCode" placeholder="กรุณากรอก" onChange={(e) => { setPostCode(e.currentTarget.value) }} />
                                    <SError error={errors["postCode"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="phone" className="required">โทรศัพท์</label>
                                    <input type="text" className="form-control " id="phone" value={phone} name="phone" placeholder="กรุณากรอก" onChange={(e) => { setPhone(e.currentTarget.value) }} />
                                    <SError error={errors["phone"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="fax" className="required">โทรสาร</label>
                                    <input type="text" className="form-control " id="fax" value={fax} name="fax" placeholder="กรุณากรอก" onChange={(e) => { setFax(e.currentTarget.value) }} />
                                    <SError error={errors["fax"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="email" className="required">อีเมล</label>
                                    <input type="text" className="form-control " id="email" value={email} name="email" placeholder="กรุณากรอก" onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                    <SError error={errors["email"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="url">ลิงก์ที่เกี่ยวข้อง</label>
                                    <input type="text" className="form-control " id="url" value={url} name="url" placeholder="กรุณากรอก" onChange={(e) => { setURL(e.currentTarget.value) }} />
                                    <SError error={errors["url"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="facebook">Facebook</label>
                                    <input type="text" className="form-control " id="facebook" value={facebook} name="facebook" placeholder="กรุณากรอก" onChange={(e) => { setFacebook(e.currentTarget.value) }} />
                                    <SError error={errors["facebook"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="youtube">YouTube</label>
                                    <input type="text" className="form-control " id="youtube" value={youtube} name="youtube" placeholder="กรุณากรอก" onChange={(e) => { setYoutube(e.currentTarget.value) }} />
                                    <SError error={errors["youtube"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">โลโก้ องค์กร/หน่วยงาน</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleLogoimgOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["logoImage"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {LogoImgPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <label className="required">แผนที่ Google map</label>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="latitude" name="latitude" placeholder="กรุณากรอก ละติจูด,ลองจิจูด" value={latitude} onChange={(e) => { handleLatChange(e) }} />
                                    <SError error={errors["latitude"]} />
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " id="longitude" name="longitude" placeholder="กรุณากรอก ลองจิจูด" value={longitude} onChange={(e) => { handleLongChange(e) }} />
                                    <SError error={errors["longitude"]} />
                                </div>                                
                                <div className="col-12">
                                    <label className="required">แผนที่รูปภาพ</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleMapimgOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["mapImage"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {MapImgPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status1" value="1" checked={status === 1} onChange={(e) => { setStatus(1); }} />
                                        <label className="form-check-label" htmlFor="status1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status2" value="0" checked={status === 0} onChange={(e) => { setStatus(0); }} />
                                        <label className="form-check-label" htmlFor="status2"> ไม่เผยแพร่</label>
                                    </div>
                                    <SError error={errors["status"]} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
