import React, { Component } from 'react';
class Clock extends Component {
    state = {
      date: new Date()
    }
    
    componentDidMount() {
      this.timerId = setInterval(() => this.tick(), 1000);
    }
  
    componentWillUnmount() {
      clearInterval(this.timerId);
    }
      
    tick() {
      // this.state.date = new Date(); - wrong way
      this.setState({date: new Date()});
    }
    
    render() {
      return (<p>เวลา : {this.state.date.toLocaleTimeString()}</p>);
    }
  }
  export default Clock;