import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
//import { authenticationService } from 'services/authentication.service.js';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import 'moment/locale/th';
import moment from "moment";
//import utils from 'utils/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
// import ReactExport from 'react-data-export';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
//const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//const swal = withReactContent(Swal)


export default function NetworkReport(props) {
    //const currentUser = authenticationService.currentUserValue;
    const [publishstartDate, SetPublishStartDate] = useState(null);
    const [publishendDate, SetPublishEndDate] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    //const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [excelDataset, setExcelDataSet] = useState([]);
    useEffect(() => {
        if (moment(publishstartDate).format('YYYY-MM-DD') > moment(publishendDate).format('YYYY-MM-DD')) {
            SetPublishEndDate(publishstartDate);
        }
        if (publishendDate === null) {
            SetPublishEndDate(publishstartDate);
        }
    }, [publishstartDate]);
    useEffect(() => {
        if (moment(publishendDate).format('YYYY-MM-DD') < moment(publishstartDate).format('YYYY-MM-DD')) {
            SetPublishStartDate(publishendDate);
        }
        if (publishstartDate === null) {
            SetPublishStartDate(publishendDate);
        }
    }, [publishendDate]);
    const borders = {
        top: { style: "thin", color: { rgb: "FF000000" } },
        bottom: { style: "thin", color: { rgb: "FF000000" } },
        left: { style: "thin", color: { rgb: "FF000000" } },
        right: { style: "thin", color: { rgb: "FF000000" } }
    }
    const centerAlignment = {
        horizontal: "center",
        wrapText: true
    }
    const leftAlignment = {
        horizontal: "top",
        wrapText: true
    }
    function SetExcelData(data) {
        let multiDataset = [{ columns: [], data: [] }];
        multiDataset[0].columns = [
            { title: "ลำดับ", width: { wpx: '80' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
            { title: "หน่วยงานเครือข่าย", width: { wpx: '160' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//pixels width 
        ];
        multiDataset[0].data = [];
        data.map((item, i) => {
            let row = [];
            let col = { value: (i + 1), style: { alignment: centerAlignment, border: borders } };
            row.push(col);
            col = { value: item.DataName, style: { alignment: centerAlignment, border: borders } };
            row.push(col);
            multiDataset[0].data.push(row);
        });
        //console.log(multiDataset)
        setExcelDataSet(multiDataset)
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        const updateData = (data) => {
            setRowData(data);
        };

        axios.get(process.env.REACT_APP_APIURL + '/api-web/getNetworkReport').then((response) => {
            updateData(response.data);
            SetExcelData(response.data);
        })

    };

    const getRowHeight = (params) => {
        //console.log(params.data.rowHeight);
        //return params.data.rowHeight;
        return 50;
    };

    var hashValueGetter = function (params) {
        return params.node.rowIndex + 1;
    };
    const onPageSizeChanged = (newPageSize) => {
        var value = document.getElementById('page-size').value;
        gridApi.paginationSetPageSize(Number(value));
    };

    const searchData = () => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getNetworkReport', { params: { search: searchRef.current.value, category: typeRef.current.value, start: publishstartDate, end: publishendDate } }).then((response) => {
            setRowData(response.data);
            SetExcelData(response.data);
        })
    };
    const resetsearch = () => {
        searchRef.current.value = "";
        typeRef.current.value = "-1";
        SetPublishStartDate(null);
        SetPublishEndDate(null);

        axios.get(process.env.REACT_APP_APIURL + '/api-web/getNetworkReport').then((response) => {
            setRowData(response.data);
            SetExcelData(response.data);
        })
    };
    const searchRef = React.createRef();
    const typeRef = React.createRef();
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"รายงานและสถิติ"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={(e) => { e.preventDefault(); }}>{"รายงานและสถิติ"}</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/report/NetworkReport" }}>{"สรุปจำนวนหน่วยงานเครือข่ายประเภทต่าง ๆ"}</Link></>}

            />
            <div className="col-12">
                <form className="form-inline form-border">
                    <div className="form-group mx-sm mb-2 col-4 col-md-2 col-xl-4 col-lg-3 pr-0">
                        <input type="text" ref={searchRef} className="form-control " id="searchfield" name="searchfield" placeholder="ค้นหาที่นี่" />
                    </div>
                    <div className="col-2 mb-2 pr-0">
                        <select name="typeRef" id="typeRef" className="custom-select" defaultValue="-1" ref={typeRef} style={{ width: 100 + '%' }} /*onChange={(e) => { handleChangeSelectCoruse(); }}*/>
                            <option value="-1">ประเภทเครือข่ายสิทธิมนุษยนชนทั้งหมด</option>
                            <option value="1">องค์กรเอกชน และสภาวิชาชีพ</option>
                            <option value="2">MOU</option>
                        </select>
                    </div>
                    <div className="col-2">
                        <label className="required">วันที่เริ่มต้น</label>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                /*maxDate={publishendDate}*/
                                onChange={SetPublishStartDate} name="startpublishday" id="startpublishday" />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-2">
                        <label className="required">วันที่สิ้นสุด</label>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                /*minDate={publishstartDate}*/
                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="button-group col-2">
                        <button type="button" className="btn btn-info mb-2 mr-2" onClick={e => { e.preventDefault(); searchData(); }}>ค้นหา</button>
                        <button type="submit" className="btn btn-light mb-2" onClick={e => { e.preventDefault(); resetsearch(); }}>ล้างคำค้นหา</button>
                    </div>
                </form>
            </div>
            <div className="col-12">
                <div className="form-border">
                    <div className="col-12">
                        <div className="form-inline button-group">
                            {/* <ExcelFile filename="Network Report" element={<button type="button" className="btn btn-primary mb-2 mr-2" ><i className="far fa-file-excel"></i> Export</button>}>
                                <ExcelSheet dataSet={excelDataset} name="Network" /> */}
                                {/*<ExcelSheet data={rowData} name="Member">
                            <ExcelColumn label="ชื่อ-นามสกุล" value="FullName"  />
                            <ExcelColumn label="ตำแหน่ง" value="Position" />
                            <ExcelColumn label="หน่วยงาน" value="Department" />
                            <ExcelColumn label="หมายเลขโทรศัพท์" value="Telephone" />
                            <ExcelColumn label="อีเมล" value="Email" />
                            <ExcelColumn label="สถานะ"
                                value={(col) => col.IsCanceled ? "ไม่เข้าร่วม" : "เข้าร่วม"} />
                        </ExcelSheet>*/}
                                {/*<ExcelSheet data={dataSet2} name="Leaves">
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Total Leaves" value="total" />
                            <ExcelColumn label="Remaining Leaves" value="remaining" />
    </ExcelSheet>*/}
                            {/* </ExcelFile> */}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="ag-theme-alpine" style={{ height: 900 }}>
                            <AgGridReact
                                autoGroupColumnDef={{
                                    headerName: 'Group',
                                    minWidth: 30,
                                    field: 'DataName',
                                    valueGetter: function (params) {
                                        if (params.node.group) {
                                            return params.node.key;
                                        } else {
                                            return params.data[params.colDef.field];
                                        }
                                    },
                                    headerCheckboxSelection: true,
                                    cellRenderer: 'agGroupCellRenderer',
                                    cellRendererParams: { checkbox: true },
                                }}

                                modules={AllCommunityModules}
                                frameworkComponents={{

                                }}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    filter: false,
                                    //cellClass: "cell-border cell-vertical-align-text-left",
                                }}
                                getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                rowData={rowData}
                                rowSelection={'multiple'}
                                rowDragManaged={true}
                                pagination={true}
                                paginationPageSize={25}
                            >
                                <AgGridColumn field="" valueGetter={hashValueGetter} headerName="ลำดับ" minWidth={100} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="DataName" headerName="คำค้นหา" sortable={true} minWidth={600} />
                                <AgGridColumn field="Total" headerName="จำนวนครั้ง" minWidth={165} />
                                <AgGridColumn field="rank" headerName="อันดับ" minWidth={100} />
                            </AgGridReact>
                            {<div className="page-size formContent">
                                <p>Page size:</p>
                                <select className="custom-select" defaultValue={25} onChange={() => onPageSizeChanged()} id="page-size">
                                    <option value="10" >10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
