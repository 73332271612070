import * as React from "react";
const SvgELibrary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 17.623 20.143"
    {...props}
  >
    <path
      d="M8.68.012a7.7 7.7 0 0 0-5.8 2.082A7.3 7.3 0 0 0 .868 7.822a8.7 8.7 0 0 0 1.631 3.979q.167.264.316.51v3.2L.002 18.276l.965.98 3.222-3.167v-4.156l-.093-.162c-.131-.222-.28-.457-.434-.7A7.6 7.6 0 0 1 2.24 7.748a5.97 5.97 0 0 1 1.614-4.684 6.1 6.1 0 0 1 4.671-1.68 5.69 5.69 0 0 1 5.807 6.052l-.115 1.144 1.508 3.188-1.586.658-.309 3.035-4.705-.367-.1 1.37 2.733.214v3.465h1.375v-3.36l1.945.153.371-3.553 2.184-.9-1.93-4.143.17-.844v-.039c0-4.367-2.855-7.22-7.193-7.445m-.63 2.361a5.38 5.38 0 0 0-3.71 1.793 4.1 4.1 0 0 0-.822 1.654v.018a4.8 4.8 0 0 0-.014 2.07 1.17 1.17 0 0 0 .55 1.137 1 1 0 0 0 .452.1 1 1 0 0 0 .13-.006h.036a1.97 1.97 0 0 1 .982.576 2.12 2.12 0 0 1 .317 1.646l.677.116a2.8 2.8 0 0 0-.464-2.2 2 2 0 0 0-.301-.261l1.586-.162a2.4 2.4 0 0 0-.29 1.306 7 7 0 0 1-.107 1.4l.676.133a7.7 7.7 0 0 0 .12-1.535c0-.905.012-1.237.917-1.443l.268-.026a1.17 1.17 0 0 0 1.035-1.187l.023-.143a.66.66 0 0 1 .55-.582l1.134-.187a1.43 1.43 0 0 0 1.06-.842 1.27 1.27 0 0 0-.107-1.227 4.85 4.85 0 0 0-3.992-2.14 5 5 0 0 0-.705-.008m.391.69c.092 0 .185-.002.278.005s.162.018.244.026l-.21.443.624.293.295-.629a4.3 4.3 0 0 1 .834.28 2.2 2.2 0 0 0-.217 2.087l.602-.33a1.56 1.56 0 0 1 .216-1.422 4.1 4.1 0 0 1 1.067 1.092.59.59 0 0 1 .043.572.74.74 0 0 1-.543.432l-1.135.19a1.38 1.38 0 0 0-.81.468 2.22 2.22 0 0 0-1.063-1.01c.095-.266.257-.692.406-1.064l-.638-.254c-.207.517-.33.849-.409 1.069a6.2 6.2 0 0 0-1.783-.25v.685c1.284 0 2.948.683 3.182 1.527l-.024.143c-.044.55-.327.579-.421.588l-3.094.318a1.4 1.4 0 0 1 .476-.666 1.4 1.4 0 0 1 1.102-.207l.115-.678a2.08 2.08 0 0 0-1.615.33 2.18 2.18 0 0 0-.8 1.3l-.593.062h-.062c-.1 0-.319-.002-.319-.553l-.005-.076a4.6 4.6 0 0 1-.079-1.313 2.25 2.25 0 0 1 .868.444l.41-.553a2.4 2.4 0 0 0-1.135-.621 3.4 3.4 0 0 1 .62-1.187l.003-.002.033-.036a1.5 1.5 0 0 0 .301.391l.469-.5a.83.83 0 0 1-.219-.416 4.5 4.5 0 0 1 1.524-.77 4 4 0 0 0-.192 1.094l.69.012a2.7 2.7 0 0 1 .343-1.275 5 5 0 0 1 .621-.04"
      style={{
        color: "#000",
        fill: "#faab3e",
        InkscapeStroke: "none",
      }}
    />
  </svg>
);
export default SvgELibrary;
