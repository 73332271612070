import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/elearning.svg";
import ReactPlayer from "react-player";
import utils from "utils/utils";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  //CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { authenticationService } from "services/authentication.service.js";

const getBase64ImageFromURL = (url, left, top, width, height) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.left = left;
      canvas.top = top;
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height, // source rectangle
        0,
        0,
        canvas.width,
        canvas.height
      );
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};
const getLogoBase64ImageFromURL = (url, left, top, height) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      var ratio = ((img.naturalWidth * 1.0) / img.naturalHeight) * 1.0;
      //console.log(ratio)
      canvas.left = left;
      canvas.top = top;
      canvas.width = height / ratio;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height, // source rectangle
        0,
        0,
        canvas.width,
        canvas.height
      );
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};
let logototalWidth = [];
const calLogoBase64ImageWidthFromURL = (url, height) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var ratio = ((img.naturalWidth * 1.0) / img.naturalHeight) * 1.0;
      logototalWidth.push(height / ratio);
      resolve(logototalWidth);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};
export default function ViewElearning(props) {
  //const percentage = 66;
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [elibData, SetElibData] = useState({});
  const [chapterList, SetChapterList] = useState([]);
  const [isRegister, setIsRegister] = useState(true);
  const [visibleRegister, setVisibleRegister] = useState(true);
  const [activeChapter, setActiveChapter] = useState({});
  const [inCompleteChapter, setInCompleteChapter] = useState(null);
  const [userID, setUserID] = useState(null);
  const [elearningChapterUserID, setElearningChapterUserID] = useState(0);
  const [subjectComplete, setSubjectComplete] = useState(false);
  const [duplicateExam, setDuplicateExam] = useState(false);
  const [chapterPercent, setChapterPercent] = useState(0.0);
  const [testPercent, setTestPercent] = useState(0.0);
  const [preTestPercent, setPreTestPercent] = useState(0.0);
  const [postTestPercent, setPostTestPercent] = useState(0.0);
  const [sociallink, setSocialLink] = useState([]);
  const [recommendCourse, setRecommendCourse] = useState([]);

  const [committeeType, setCommitteeType] = useState(0);
  const [certificateType, setCertificateType] = useState(0);
  const [signList, setSignList] = useState([]);
  const [certLogoFiles, setCertLogoFiles] = useState([]);

  const eLearningID = props.match.params.id;
  let chapterlist = [];
  let subchapter = [];
  let testdata = [];

  function UpdateChapter() {
    let userid = 0;
    if (currentUser != null) {
      userid = currentUser.UserID;
      setUserID(currentUser.UserID);
    }
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/ESubjectByID", {
        params: { id: eLearningID },
      })
      .then((response) => {
        let data = response.data.results;
        //console.log(data)
        setCertificateType(data.CertificateType);
        setCommitteeType(data.CommitteeType);
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getElearningSign", {
            params: { id: props.location.state.id },
          })
          .then((response) => {
            const newFiles = [];
            let data = response.data;
            for (let i = 0; i < data.length; i += 1) {
              const filedata = {
                SignID: data[i].SignID,
                SignName: data[i].SignName,
                SignPos: data[i].SignPos,
                SignImage: data[i].SignImage,
                preview: data[i].SignImage,
              };
              newFiles[i] = filedata;
            }
            setSignList(newFiles);
          });
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getCertificateLogo", {
            params: { id: props.location.state.id },
          })
          .then((response) => {
            const newFiles = [];
            let data = response.data;
            for (let i = 0; i < data.length; i += 1) {
              const filedata = {
                LogoID: data[i].LogoID,
                name: data[i].FilenameOld,
                LogoImage: data[i].LogoImage,
                preview: data[i].LogoImage,
                size: data[i].Filesize,
              };
              newFiles[i] = filedata;
            }
            setCertLogoFiles(newFiles);
          });
      });
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/ESubjectMainChapter", {
        params: { elearnID: eLearningID },
      })
      .then((main) => {
        for (let i = 0; i < main.data.length; i++) {
          chapterlist.push(main.data[i]);
        }
      })
      .then(() => {
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getSubChapter", {
            params: { elearnID: eLearningID },
          })
          .then((sub) => {
            for (let j = 0; j < sub.data.length; j++) {
              subchapter.push(sub.data[j]);
            }
          })
          .then(() => {
            axios
              .get(process.env.REACT_APP_APIURL + "/api-web/getTestData", {
                params: { elearnID: eLearningID, userID: userid },
              })
              .then((test) => {
                const arrObjOne = [
                  ...new Map(
                    test.data.map((item) => [
                      JSON.stringify(item.ChapterID),
                      item.ChapterID,
                    ])
                  ).values(),
                ];
                for (let k = 0; k < arrObjOne.length; k++) {
                  const result = test.data.find((item) => {
                    return item.ChapterID === arrObjOne[k];
                  });
                  testdata.push(result);
                }
              })
              .then(() => {
                PrepairData();
                axios
                  .get(
                    process.env.REACT_APP_APIURL +
                      "/api-web/CheckDupplicateExam",
                    { params: { elearnID: eLearningID, userID: userid } }
                  )
                  .then((dupplicateExam) => {
                    setDuplicateExam(dupplicateExam.data.dupplicate);
                    axios
                      .get(
                        process.env.REACT_APP_APIURL +
                          "/api-web/getElearningSocial",
                        { params: { id: eLearningID } }
                      )
                      .then((social) => {
                        setSocialLink(social.data);
                        axios
                          .get(
                            process.env.REACT_APP_APIURL +
                              "/api-web/getElearningRecomendedCourse",
                            { params: { id: eLearningID } }
                          )
                          .then((course) => {
                            setRecommendCourse(course.data);
                          });
                      });
                  });
              });
          });
      });
  }
  function PrepairData() {
    for (let i = 0; i < subchapter.length; i++) {
      subchapter[i].testData = testdata.filter((item) => {
        //console.log(item);
        return item.ChapterID === subchapter[i].ChapterID;
      });
    }
    for (let i = 0; i < chapterlist.length; i++) {
      chapterlist[i].subChapter = subchapter.filter((item) => {
        return item.ParentChapterID === chapterlist[i].ChapterID;
      });
    }
    const chapterPrepair = [...chapterlist];
    let isIncompleteChaper = false;
    let subjectcomplete = true;
    let numChapter = 0;
    let passChapter = 0;
    let testscore = 0;
    let maxtestscore = 0;
    let pretestscore = 0;
    let maxpretestscore = 0;
    let posttestscore = 0;
    let maxposttestscore = 0;

    for (let i = 0; i < chapterPrepair.length; i++) {
      let chapter = chapterPrepair[i];
      let allsubchaptercomplete = true;
      chapter.active = false;
      for (let j = 0; j < chapter.subChapter.length; j++) {
        let item = chapter.subChapter[j];
        numChapter++;
        if (item.testData.length === 0) {
          allsubchaptercomplete = false;
          if (isIncompleteChaper === false) {
            isIncompleteChaper = true;
            chapter.active = true;
            setInCompleteChapter(item);
            subjectcomplete = false;
          }
        } else {
          if (item.testData[0].isCompletedChapter === false) {
            allsubchaptercomplete = false;
            item.complete = false;
            if (isIncompleteChaper === false) {
              isIncompleteChaper = true;
              chapter.active = true;
              setInCompleteChapter(item);
              subjectcomplete = false;
              console.log(item);
            }
          } else {
            item.complete = true;
            chapter.active = true;
            passChapter++;
          }
        }
        if (item.ChapterContentType === "PRE_TEST") {
          if (typeof item.testData[0] !== "undefined") {
            if (item.testData[0].isExamCompleted === true) {
              //pretestcount++;
            }
            pretestscore += item.testData[0].ExamGetScore;
          }
          maxpretestscore += item.MaxScore;
        }
        if (item.ChapterContentType === "TEST") {
          if (typeof item.testData[0] !== "undefined") {
            if (item.testData[0].isExamCompleted === true) {
              //testcount++;
            }
            testscore += item.testData[0].ExamGetScore;
          }
          maxtestscore += item.MaxScore;
        }
        if (item.ChapterContentType === "POST_TEST") {
          if (typeof item.testData[0] !== "undefined") {
            if (item.testData[0].isExamCompleted === true) {
            }
            posttestscore += item.testData[0].ExamGetScore;
          }
          maxposttestscore += item.MaxScore;
        }
      }
      if (chapter.subChapter.length > 0) {
        chapter.complete = allsubchaptercomplete;
      }
      if (chapter.complete === true) {
        chapter.active = true;
      }
    }
    if (chapterPrepair.length === 0) {
      subjectcomplete = false;
    }
    setSubjectComplete(subjectcomplete);
    SetChapterList(chapterPrepair);
    if (chapterPrepair.length > 0) {
      if (numChapter > 0)
        setChapterPercent(((passChapter * 100.0) / numChapter).toFixed(2));
      else setChapterPercent(100.0);
      if (maxpretestscore > 0)
        setPreTestPercent(
          ((pretestscore * 100.0) / maxpretestscore).toFixed(2)
        );
      else setPreTestPercent(100.0);
      if (maxtestscore > 0)
        setTestPercent(((testscore * 100.0) / maxtestscore).toFixed(2));
      else setTestPercent(100.0);
      if (maxposttestscore > 0)
        setPostTestPercent(
          ((posttestscore * 100.0) / maxposttestscore).toFixed(2)
        );
      else setPostTestPercent(100.0);
    }
    setLoading(false);
  }
  useEffect(() => {
    let userid = 0;
    if (currentUser != null) {
      userid = currentUser.UserID;
      setUserID(currentUser.UserID);
    }

    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/ESubjectByID", {
        params: { id: eLearningID, userID: userid },
      })
      .then((response) => {
        console.log(response);
        SetElibData(response.data.results);
        setIsRegister(response.data.results.isregister);
        let now = new Date(Date.now());
        let start = new Date(response.data.results.RegisteredStartDate);
        let end = new Date(response.data.results.RegisteredEndDate);
        //ยังไม่หมดช่วงลงทะเบียน
        //console.log(start+" - "+now+" - "+end)
        if (now >= start && now <= end) {
          setVisibleRegister(true);
        }
        else{
          setVisibleRegister(false);
        }
        //ลงทะเบียนไปแล้ว
        if (response.data.results.isregister) {
          setVisibleRegister(false);
        }
      })
      .then(() => {
        UpdateChapter();
      });
  }, []); /* <-- add this for run once*/
  const subjectEnroll = () => {
    const formData = new FormData();
    formData.append("id", elibData.ELearnID);
    formData.append("userID", userID);
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/subjectEnroll", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        window.location.reload();
      });
  };
  const onSelectActiveChapter = (subchapter) => {
    if (subchapter.complete === true || subchapter === inCompleteChapter) {
      setActiveChapter(subchapter);
      if (typeof subchapter.testData[0] !== "undefined") {
        let lasttest = subchapter.testData[0];
        setElearningChapterUserID(lasttest.ElearningChapterUserID);
      }
    }
  };
  const ChapterList = () => {
    if (typeof chapterList !== "undefined") {
      return chapterList.map((chapter) => (
        <li
          className={
            chapter.active === true
              ? "orange chapter-item"
              : "gray chapter-item"
          }
          key={chapter.ChapterID}
        >
          <div className="activity-content">
            <div className="activity-calendar">
              <h5 className="sub-chapter-title">{chapter.ChapterTitle}</h5>
              <ul className="sub-chapter-list">
                {chapter.subChapter.map((subchapter) => (
                  <li
                    onClick={() => {
                      onSelectActiveChapter(subchapter);
                    }}
                    key={subchapter.ChapterID}
                  >
                    <label
                      style={{ verticalAlign: "text-top", cursor: "pointer" }}
                    >
                      {subchapter.ChapterTitle}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </li>
      ));
    } else return <></>;
  };
  const beginTest = () => {
    const formData = new FormData();
    formData.append("eLearningID", activeChapter.ELearnID);
    formData.append("chapterID", activeChapter.ChapterID);
    formData.append("questionnaireID", activeChapter.QuestionnaireID);
    formData.append("maxDuration", activeChapter.Duration);
    formData.append("userID", userID);
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/userBeginTest", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        localStorage.removeItem("test");
        props.history.push({
          pathname: "/authen/elearning/ElearningTest",
          state: {
            eLearningID: activeChapter.ELearnID,
            chapterID: activeChapter.ChapterID,
            questionnaireID: activeChapter.QuestionnaireID,
          },
        });
      });
  };
  const retryTest = () => {
    const formData = new FormData();
    formData.append("elearningChapterUserID", elearningChapterUserID);
    formData.append("userID", userID);

    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/userRetryTest", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        props.history.push({
          pathname: "/authen/elearning/ElearningTest",
          state: {
            eLearningID: activeChapter.ELearnID,
            chapterID: activeChapter.ChapterID,
            questionnaireID: activeChapter.QuestionnaireID,
          },
        });
      });
  };
  const cancleTest = () => {
    setActiveChapter({});
  };
  const displayTestResult = (lasttest) => {
    props.history.push({
      pathname: "/authen/elearning/ElearningTestResult",
      state: {
        eLearningID: lasttest.ELearnID,
        chapterID: lasttest.ChapterID,
        questionnaireID: lasttest.QuestionnaireID,
        userID: userID,
        elearningChapterUserID: lasttest.ElearningChapterUserID,
      },
    });
  };
  /*const CheckCanTest = (canTest) => {
    console.log(Number(activeChapter.DurationType)+" - "+canTest)
    if (Number(activeChapter.DurationType) === 0 || canTest === true)
      return true;
    return false;
  }*/
  const ResponseChapterClick = () => {
    if (typeof activeChapter.ChapterContentType !== "undefined") {
      if (
        activeChapter.ChapterContentType !== "PRE_TEST" &&
        activeChapter.ChapterContentType !== "POST_TEST"
      ) {
        const formData = new FormData();
        formData.append("eLearningID", activeChapter.ELearnID);
        formData.append("chapterID", activeChapter.ChapterID);
        formData.append("userID", userID);

        axios
          .post(
            process.env.REACT_APP_APIURL + "/api-web/userCompleteChapter",
            formData,
            {
              headers: {
                "Content-Type": `multipart/form-data`,
              },
            }
          )
          .then((response) => {
            UpdateChapter();
          });
      }
    }
    if (typeof activeChapter.ChapterContentType === "undefined") return <></>;
    if (activeChapter.ChapterContentType === "VDO") {
      return (
        <>
          <div className="subject-cover">
            <div className="player-wrapper">
              <ReactPlayer
                url={activeChapter.ContentPath}
                width="100%"
                height="260px"
                controls={true}
              />
            </div>
          </div>
        </>
      );
    } else if (
      activeChapter.ChapterContentType === "PRE_TEST" ||
      activeChapter.ChapterContentType === "TEST" ||
      activeChapter.ChapterContentType === "POST_TEST"
    ) {
      let now = new Date(Date.now());
      let startquestiondate = new Date(activeChapter.QuestionStartDate);
      let endquestiondate = new Date(activeChapter.QuestionEndDate);
      let cantest = now >= startquestiondate && now <= endquestiondate;
      /*if (Number(activeChapter.DurationType) === 0)
        cantest = true;*/
      let seconds = Number(activeChapter.Duration);
      let weeks = parseInt(seconds / (7 * 24 * 60 * 60));
      let days = parseInt(seconds / (24 * 60 * 60) - 7 * weeks);
      let hours = parseInt(seconds / (60 * 60) - 7 * 24 * weeks - 24 * days);
      let minutes = parseInt(
        seconds / 60 - 7 * 24 * 60 * weeks - 24 * 60 * days - 60 * hours
      );
      seconds = parseInt(
        seconds -
          7 * 24 * 60 * 60 * weeks -
          24 * 60 * 60 * days -
          60 * 60 * hours -
          60 * minutes
      );

      let lasttest = null;
      let teststep = 0;
      if (activeChapter.testData.length > 0) {
        lasttest = activeChapter.testData[0];
        teststep = 1;
        lasttest.QuestionnaireID = activeChapter.QuestionnaireID;
        if (lasttest.isExamCompleted === true) {
          teststep = 2;
        }
        if (cantest === false) {
          teststep = 2;
        }
      }
      let duration = "";
      if (weeks > 0) {
        duration =
          weeks +
          " สัปดาห์ " +
          days +
          " วัน " +
          hours +
          " ชั่วโมง " +
          minutes +
          " นาที " +
          seconds +
          " วินาที";
        if (language !== "Thai") {
          duration =
            weeks +
            " Week " +
            days +
            " Days " +
            hours +
            " Hour " +
            minutes +
            " Minute " +
            seconds +
            " Second";
        }
      } else if (days > 0) {
        duration =
          days +
          " วัน " +
          hours +
          " ชั่วโมง " +
          minutes +
          " นาที " +
          seconds +
          " วินาที";
        if (language !== "Thai") {
          duration =
            days +
            " Days " +
            hours +
            " Hour " +
            minutes +
            " Minute " +
            seconds +
            " Second";
        }
      } else if (hours > 0) {
        duration =
          hours + " ชั่วโมง " + minutes + " นาที " + seconds + " วินาที";
        if (language !== "Thai") {
          duration =
            hours + " Hour " + minutes + " Minute " + seconds + " Second";
        }
      } else if (minutes > 0) {
        duration = minutes + " นาที " + seconds + " วินาที";
        if (language !== "Thai") {
          duration = minutes + " Minute " + seconds + " Second";
        }
      } else {
        duration = seconds + " วินาที";
        if (language !== "Thai") {
          duration = seconds + " Second";
        }
      }
      return (
        <>
          <div className="question-card">
            <h2 className="text-white text-center">
              {activeChapter.ChapterTitle}
            </h2>
            <label className="col-12 text-white text-center">
              {language === "Thai" ? "แบบทดสอบนี้เปิด" : "This quiz is open"}
            </label>
            <h1 className="text-warning text-center">
              {utils.toThaiDateString(
                new Date(activeChapter.QuestionStartDate)
              )}
            </h1>
            {(Number(activeChapter.DurationType) !== 0) && (<><label className="col-12 text-white text-center">
              {language === "Thai" ? "แบบทดสอบนี้ปิด" : "This quiz is close"}
            </label>
              <h1 className="text-warning text-center">
                {utils.toThaiDateString(new Date(activeChapter.QuestionEndDate))}
              </h1></>)
            }
            {Number(activeChapter.DurationType) > 0 && (
              <label className="col-12 text-white text-center">
                {language === "Thai" ? "คุณมีเวลา" : "You have time"}
                <h1 className="text-warning text-center">{duration}</h1>
              </label>
            )}
            {Number(activeChapter.DurationType) === 0 && (
              <label className="col-12 text-white text-center">
                {language === "Thai" ? "ไม่จำกัดเวลา" : "No time limit"}
              </label>
            )}
            <label className="col-12 text-white text-center">
              {language === "Thai"
                ? "เกณฑ์เปอร์เซ็นต์การผ่านการทดสอบ"
                : "Test Percentage Criteria"}{" "}
              {Number(activeChapter.ScoreCriteria)}%
            </label>
          </div>
          {teststep === 1 && (
            <>
              <div className="row mt-5 text-primary">
                <div className="col-6 text-center">
                  {language === "Thai" ? "สถานะ" : "Status"}
                </div>
                <div className="col-2 text-center">
                  {language === "Thai" ? "คะแนน" : "Max Score"}
                </div>
                <div className="col-2 text-center">
                  {language === "Thai" ? "คะแนนที่ได้" : "You Score"}
                </div>
                <div className="col-2 text-center">
                  {language === "Thai" ? "แสดงผล" : "Result"}
                </div>
              </div>
              <div className="card">
                <div className="row p-2">
                  <div className="col-6 text-center text-warning">
                    {language === "Thai" ? "อยู่ระหว่างการทดสอบ" : "In Testing"}
                  </div>
                  <div className="col-2 text-center">-</div>
                  <div className="col-2 text-center">-</div>
                  <div className="col-2 text-center">-</div>
                </div>
              </div>
            </>
          )}
          {teststep === 2 && (
            <>
              <div className="row mt-5 text-primary">
                <div className="col-6 text-center">
                  {language === "Thai" ? "สถานะ" : "Status"}
                </div>
                <div className="col-2 text-center">
                  {language === "Thai" ? "คะแนน" : "Max Score"}
                </div>
                <div className="col-2 text-center">
                  {language === "Thai" ? "คะแนนที่ได้" : "You Score"}
                </div>
                <div className="col-2 text-center">
                  {language === "Thai" ? "แสดงผล" : "Result"}
                </div>
              </div>
              <div className="card">
                <div className="row p-2">
                  <div className="col-6 text-center text-success">
                    {language === "Thai" ? "เสร็จสิ้น" : "Finish"}
                    <br />
                    <span
                      className="text-gray"
                      style={{ fontSize: 1.0 + "rem" }}
                    >
                      {utils.MariatoThaiDateString(
                        new Date(lasttest.ExamCompletedDate)
                      )}
                    </span>
                  </div>
                  <div className="col-2 text-center">
                    {lasttest.ExamMaxScore}
                  </div>
                  <div className="col-2 text-center">
                    {lasttest.ExamGetScore}
                  </div>
                  <div className="col-2 text-center">
                    <input
                      type="button"
                      className="orange-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        displayTestResult(lasttest);
                      }}
                      value={language === "Thai" ? "แสดงผล" : "Result"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {teststep === 0 && (
            <div className="formContent mt-5">
              {(cantest===true) && (
                <input
                  type="button"
                  className="fadeIn fourth"
                  onClick={(e) => {
                    e.preventDefault();
                    beginTest();
                  }}
                  value={language === "Thai" ? "เริ่มทำแบบทดสอบ" : "Begin Test"}
                />
              )}
              <input
                type="button"
                className="fadeIn fourth"
                onClick={(e) => {
                  e.preventDefault();
                  cancleTest();
                }}
                value={language === "Thai" ? "ยกเลิก" : "Cancle"}
              />
            </div>
          )}
          {teststep === 1 && (
            <div className="formContent mt-5">
              {(cantest===true) && (
                <input
                  type="button"
                  className="fadeIn fourth"
                  onClick={(e) => {
                    e.preventDefault();
                    retryTest();
                  }}
                  value={language === "Thai" ? "เริ่มทำแบบทดสอบ" : "Begin Test"}
                />
              )}
              <input
                type="button"
                className="fadeIn fourth"
                onClick={(e) => {
                  e.preventDefault();
                  cancleTest();
                }}
                value={language === "Thai" ? "ยกเลิก" : "Cancle"}
              />
            </div>
          )}
          {teststep === 2 && (
            <div
              className="card mt-5"
              style={{ backgroundColor: "#fba017", padding: 3 + "rem" }}
            >
              <div className="row p-2">
                <div
                  className="col-12 text-center text-white"
                  style={{ fontSize: 4 + "rem" }}
                >
                  {language === "Thai" ? "คะแนนที่ได้" : "You Score"} :{" "}
                  {lasttest.ExamGetScore} / {lasttest.ExamMaxScore}
                </div>
              </div>
            </div>
          )}
        </>
      );
    } else if (activeChapter.ChapterContentType === "CONTENT") {
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: activeChapter.Content }} />
        </>
      );
    } else if (activeChapter.ChapterContentType === "LINK") {
      return (
        <>
          <div className="question-card">
            <a
              className="text-white text-center"
              href={activeChapter.ContentPath}
              target="_blank"
              rel="noreferrer"
            >
              {activeChapter.ContentPath}
            </a>
          </div>
        </>
      );
    } else return <></>;
  };
  async function printCertificate() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
      },
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
      },
      FontAwesome: {
        normal: "fontawesome-webfont.ttf",
      },
      Symbol: {
        normal: "Symbol.ttf",
      },
    };
    let bg = await getBase64ImageFromURL(
      process.env.REACT_APP_SITEURL + "/images/certificate.jpg",
      0,
      0,
      841,
      595
    );
    let logoArray = [];
    let height = 115;
    if (certLogoFiles.length === 2) height = 105;
    else if (certLogoFiles.length === 3) height = 82;
    logototalWidth = [];

    for (let i = 0; i < certLogoFiles.length; i++) {
      await calLogoBase64ImageWidthFromURL(certLogoFiles[i].LogoImage, height);
    }
    let allwidth = 0;
    for (let i = 0; i < logototalWidth.length; i++) {
      allwidth += logototalWidth[i];
    }

    let left = (841 - (allwidth + 25 * (logototalWidth.length - 1))) / 2;

    for (let i = 0; i < certLogoFiles.length; i++) {
      logoArray.push({
        image: await getLogoBase64ImageFromURL(
          certLogoFiles[i].LogoImage,
          left,
          10,
          height
        ),
        absolutePosition: { x: left, y: 40 },
      });
      left += 25 + logototalWidth[i];
    }
    let signArray = [];
    left =
      (841 - (signList.length * 200 + 50 * (logototalWidth.length - 1))) / 2;
    for (let i = 0; i < signList.length; i++) {
      signArray.push({
        image: await getLogoBase64ImageFromURL(
          signList[i].SignImage,
          left,
          10,
          120
        ),
        absolutePosition: { x: left, y: 380 },
      });
      let ofx = (signList[i].SignName.length - signList[i].SignPos.length) * 5;
      signArray.push({
        text: signList[i].SignName,
        absolutePosition: { x: left - ofx, y: 480, width: 200 },
        fontSize: 20,
        bold: true,
        alignment: "left",
      });
      signArray.push({
        text: signList[i].SignPos,
        absolutePosition: { x: left, y: 505, width: 200 },
        fontSize: 20,
        bold: false,
        alignment: "left",
      });
      left += 50 + 200;
    }
    let certifydate = activeChapter.QuestionEndDate;

    if (typeof certifydate === "undefined") {
      certifydate = new Date();
    } else {
      certifydate = new Date(activeChapter.QuestionEndDate);
    }
    let committeetxt = "คณะกรรมการสิทธิมนุษยชนแห่งชาติ";
    if (Number(committeeType) === 2) {
      committeetxt = "สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ";
    }
    let certificateTypetxt = "ประกาศนียบัตร";
    if (Number(certificateType) === 2) {
      certificateTypetxt = "เกียรติบัตร";
    }
    if (Number(certificateType) === 3) {
      certificateTypetxt = "วุฒิบัตร";
    }
    var docDefinition = {
      pageSize: {
        width: 841,
        height: 595,
      },
      pageMargins: [0, 0, 0, 0],
      background: [
        {
          image: bg,
          width: 841,
        },
      ],
      content: [
        logoArray,
        {
          text: committeetxt,
          absolutePosition: { x: 0, y: 150 },
          style: "header",
        },
        {
          text: "ขอมอบ" + certificateTypetxt + "ฉบับนี้เพื่อแสดงว่า",
          absolutePosition: { x: 0, y: 190 },
          style: "subheader",
        },
        {
          text: currentUser.FirstnameTH + " " + currentUser.LastnameTH,
          absolutePosition: { x: 0, y: 220 },
          style: "username",
        },
        {
          text: "ได้เข้ารับการอบรม " + elibData.ELearnTitle + "\n",
          absolutePosition: { x: 0, y: 290 },
          style: "normal",
        },
        {
          text:
            "ให้ไว้ ณ วันที่ " +
            utils.MariatoThaiDateOnly(certifydate, true) +
            "\n",
          absolutePosition: { x: 0, y: 350 },
          style: "normal",
        },
        signArray,
      ],
      defaultStyle: {
        font: "THSarabunNew",
      },
      styles: {
        header: {
          fontSize: 37,
          bold: true,
          color: "#003176",
          alignment: "center",
        },
        subheader: {
          fontSize: 30,
          bold: false,
          color: "#003176",
          alignment: "center",
        },
        username: {
          fontSize: 35,
          bold: true,
          color: "#003176",
          alignment: "center",
        },
        normal: {
          fontSize: 20,
          bold: true,
          color: "#595959",
          alignment: "center",
        },
        left: {
          fontSize: 20,
          bold: true,
          color: "#595959",
          alignment: "left",
        },
      },
    };
    pdfMake.createPdf(docDefinition).open();
  }
  function getChapter(chapter) {
    let chapterstatus = "";
    if (chapter.complete === true) {
      chapterstatus = language === "Thai" ? "เสร็จสิ้น" : "Finish";
    } else {
      chapterstatus = language === "Thai" ? "ยังไม่เสร็จสิ้น" : "Not finish";
    }
    return chapterstatus;
  }
  const ChapterResultList = () => {
    if (typeof chapterList !== "undefined") {
      return chapterList.map((chapter, index) => (
        <div key={chapter.ChapterID}>
          <div className="subheader col-12" role="alert">
            <span className="chapter-number">
              {String(index + 1).padStart(2, "0")}
            </span>
            <span className="chapter-title">{chapter.ChapterTitle}</span>
            <span className="chapter-status">
              {language === "Thai"
                ? "สถานะ : "
                : "Status : " + getChapter(chapter)}
            </span>
          </div>
          <ul className="sub-chapterlist">
            {chapter.subChapter.map((subchapter) => (
              <li
                style={{ lineHeight: "1rem", height: "auto" }}
                key={subchapter.ChapterID}
              >
                <label
                  className="col-9 col-lg-10"
                  style={{ verticalAlign: "text-top" }}
                >
                  {subchapter.ChapterTitle}
                </label>
                <span className="col-3 col-lg-2 chapter-result">
                  <i
                    className={
                      subchapter.complete === true
                        ? "far fa-check-circle text-success"
                        : "far fa-times-circle text-danger"
                    }
                  ></i>
                  {(subchapter.ChapterContentType === "PRE_TEST" ||
                    subchapter.ChapterContentType === "TEST" ||
                    subchapter.ChapterContentType === "POST_TEST") && (
                    <>
                      {
                        <>
                          {subchapter.complete === true && (
                            <p className="chapter-result-info">
                              {language === "Thai" ? "ทำแล้ว" : "Done"}
                            </p>
                          )}
                          {(subchapter.complete === false ||
                            typeof subchapter.complete === "undefined") && (
                            <p className="chapter-result-info">
                              {language === "Thai"
                                ? "ยังไม่ได้ทำ"
                                : "Not done yet"}
                            </p>
                          )}
                        </>
                      }
                    </>
                  )}
                  {subchapter.ChapterContentType !== "PRE_TEST" &&
                    subchapter.ChapterContentType !== "TEST" &&
                    subchapter.ChapterContentType !== "POST_TEST" && (
                      <>
                        {
                          <>
                            {subchapter.complete === true && (
                              <p className="chapter-result-info">
                                {language === "Thai" ? "เรียนแล้ว" : "Studied"}
                              </p>
                            )}
                            {(subchapter.complete === false ||
                              typeof subchapter.complete === "undefined") && (
                              <p className="chapter-result-info">
                                {language === "Thai"
                                  ? "ยังไม่ได้เรียน"
                                  : "Not studied"}
                              </p>
                            )}
                          </>
                        }
                      </>
                    )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ));
    } else return <></>;
  };
  const socialList = sociallink.map((item, i) => (
    <li key={"social-" + i} className="list-item">
      <a href={item.SocialURL} target="_blank" rel="noopener noreferrer">
        {item.SocialTitle}
      </a>
    </li>
  ));
  const recommendCourseList = recommendCourse.map((item, i) => (
    <li key={"course-" + i} className="list-item">
      <a
        href={item.RecommendedCourseURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.RecommendedTitle}
      </a>
    </li>
  ));
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {/*{<Navbar />}*/}
        <ContentBanner
          language={language}
          theader={"อีเลิร์นนิง"}
          eheader={"E-learning"}
          bannerkey="ELEARN"
          banner={banner}
          path={
            <>
              <span>/</span>
              <Link
                to={{
                  pathname: "/authen/elearning/ELearning",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "อีเลิร์นนิง" : "E-learning"}
              </Link>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname:
                    "/authen/elearning/ViewElearning/" + elibData.ELearnID,
                  state: { id: elibData.ELearnID, userID: userID },
                }}
              >
                {elibData.ELearnTitle}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data">
              <div className="row">
                <div className="col-12">
                  <div className="sub-title">
                    <h2 className="thai-header text-center">
                      <span className="orang content-underline">
                        {elibData.ELearnTitle}
                      </span>
                    </h2>
                  </div>

                  <div className="tabbable-panel">
                    <div className="tabbable-line">
                      <div className="nav nav-tabs ">
                        <a
                          href="#tab_default_1"
                          className="active"
                          data-toggle="tab"
                        >
                          {" "}
                          {language === "Thai" ? "รายละเอียด" : "Detail"}{" "}
                        </a>
                        {isRegister === true && (
                          <>
                            <a href="#tab_default_2" data-toggle="tab">
                              {" "}
                              {language === "Thai"
                                ? "เข้าเรียน"
                                : "Attend"}{" "}
                            </a>
                            <a href="#tab_default_3" data-toggle="tab">
                              {" "}
                              {language === "Thai"
                                ? "ผลการเรียน"
                                : "Result"}{" "}
                            </a>
                          </>
                        )}
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tab_default_1">
                          <div className="card bg-transparent shadow-none border-0">
                            <div className="card-body">
                              <div className="subject-card">
                                <div className="row">
                                  <div className="col-6">
                                    <div
                                      className="card-body"
                                      style={{ marginLeft: -10 }}
                                    >
                                      {Number(elibData.CoverType) === 1 && (
                                        <div className="subject-content-cover">
                                          <img
                                            src={elibData.ThumnailWeb}
                                            className="cover"
                                            alt="Subject cover"
                                          />
                                        </div>
                                      )}
                                      {Number(elibData.CoverType) === 2 && (
                                        <div className="subject-content-cover">
                                          <div className="player-wrapper">
                                            <ReactPlayer
                                              url={elibData.ThumnailWeb}
                                              width="100%"
                                              height="350px"
                                              controls={true}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6 formContent">
                                    <ul className="content-list">
                                      <li className="list-item">
                                        <span className="title">
                                          {language === "Thai"
                                            ? "ช่วงเวลาเรียน"
                                            : "Learning Period"}
                                        </span>
                                        <span className="detail">
                                          {language === "Thai"
                                            ? "เริ่มลงทะเบียน"
                                            : "Start Register Date"}{" "}
                                          {utils.MariatoThaiDateStringShort(
                                            elibData.RegisteredStartDate
                                          )}
                                          {language === "Thai"
                                            ? "เริ่มเรียนได้"
                                            : "Start Learning Date"}{" "}
                                          {utils.MariatoThaiDateStringShort(
                                            elibData.LearningStartDate
                                          )}
                                          {language === "Thai"
                                            ? "สิ้นสุดการเรียน"
                                            : "End Learning Date"}{" "}
                                          {utils.MariatoThaiDateStringShort(
                                            elibData.LearningEndDate
                                          )}
                                        </span>
                                      </li>
                                      <li className="list-item">
                                        <span className="title">
                                          {language === "Thai"
                                            ? "เนื้อหา"
                                            : "Content"}
                                        </span>
                                        <span className="detail">
                                          {" "}
                                          {language === "Thai"
                                            ? elibData.Chapter
                                            : elibData.ChapterEN.trim()
                                                .length === 0
                                            ? elibData.Chapter
                                            : elibData.ChapterEN}
                                        </span>
                                      </li>
                                      <li className="list-item">
                                        <span className="title">
                                          {language === "Thai"
                                            ? "กลุ่มเป้าหมาย"
                                            : "Target Group"}
                                        </span>
                                        <span className="detail">
                                          {" "}
                                          {language === "Thai"
                                            ? elibData.TargetGroup
                                            : elibData.TargetGroupEN.trim()
                                                .length === 0
                                            ? elibData.TargetGroup
                                            : elibData.TargetGroupEN}
                                        </span>
                                      </li>
                                      <li className="list-item">
                                        <span className="title">
                                          {language === "Thai"
                                            ? "เกณฑ์การเรียนจบ"
                                            : "Graduation Criteria"}{" "}
                                        </span>
                                        <span className="detail">
                                          {" "}
                                          {language === "Thai"
                                            ? elibData.Condition
                                            : elibData.ConditionEN.trim()
                                                .length === 0
                                            ? elibData.Condition
                                            : elibData.ConditionEN}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                    {(userID !== null &&
                                      visibleRegister === true) && (
                                        <input
                                          type="button"
                                          className="fadeIn fourth"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            subjectEnroll();
                                          }}
                                          value={
                                            language === "Thai"
                                              ? "เข้าเรียน"
                                              : "Attend Class"
                                          }
                                        />
                                      )}
                                    {(userID !== null &&
                                      visibleRegister === false) && (
                                        <div className="col-12 thai-header text-center">
                                          {language === "Thai"
                                            ? "เลยกำหนดการลงทะเบียน"
                                            : "The registration deadline has already passed"}
                                        </div>
                                      )}

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="subheader col-12" role="alert">
                              {language === "Thai"
                                ? "รายละเอียดรายวิชา"
                                : "Course Detail"}
                            </div>
                            <div
                              className="subject-content-detail"
                              dangerouslySetInnerHTML={{
                                __html:
                                  language === "Thai"
                                    ? elibData.CourseDetail
                                    : elibData.CourseDetailEN.trim().length ===
                                      0
                                    ? elibData.CourseDetail
                                    : elibData.CourseDetailEN,
                              }}
                            />
                            <div className="subheader col-12" role="alert">
                              {" "}
                              {language === "Thai"
                                ? "วัตถุประสงค์"
                                : "Objective"}
                            </div>
                            <div
                              className="subject-content-detail"
                              dangerouslySetInnerHTML={{
                                __html:
                                  language === "Thai"
                                    ? elibData.CourseObjective
                                    : elibData.CourseObjectiveEN.trim()
                                        .length === 0
                                    ? elibData.CourseObjective
                                    : elibData.CourseObjectiveEN,
                              }}
                            />
                            <div className="subheader col-12" role="alert">
                              {" "}
                              {language === "Thai"
                                ? "เกณฑ์การวัดและประเมินผล"
                                : "Measurement and Evaluation Criteria"}
                            </div>
                            <div
                              className="subject-content-detail"
                              dangerouslySetInnerHTML={{
                                __html:
                                  language === "Thai"
                                    ? elibData.CourseCriterion
                                    : elibData.CourseCriterionEN.trim()
                                        .length === 0
                                    ? elibData.CourseCriterion
                                    : elibData.CourseCriterionEN,
                              }}
                            />
                            <div className="subheader col-12" role="alert">
                              {" "}
                              {language === "Thai"
                                ? "Link Social"
                                : "Social link"}
                            </div>
                            <div id="social">{socialList}</div>
                            <div className="subheader col-12" role="alert">
                              {" "}
                              {language === "Thai"
                                ? "Link แนะนำหลักสูตร"
                                : "Course referral link"}
                            </div>
                            <div id="course">{recommendCourseList}</div>
                            <div className="subheader col-12" role="alert">
                              {" "}
                              {language === "Thai" ? "หมายเหตุ" : "Remark"}
                            </div>
                            <div
                              className="subject-content-detail"
                              dangerouslySetInnerHTML={{
                                __html:
                                  language === "Thai"
                                    ? elibData.CourseRemark
                                    : elibData.CourseRemarkEN.trim().length ===
                                      0
                                    ? elibData.CourseRemark
                                    : elibData.CourseRemarkEN,
                              }}
                            />
                            <div className="subheader col-12" role="alert">
                              {" "}
                              {language === "Thai"
                                ? "อาจารย์ผู้สอน"
                                : "Lecturer"}
                            </div>
                            <span className="subject-content-detail">
                              {" "}
                              <img
                                className="avatar"
                                src={elibData.LectureImg}
                                alt="Lecturer Avatar"
                              ></img>
                              {elibData.LectureName}
                            </span>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab_default_2">
                          <div className="row">
                            <div className="col-3">
                              <div className="card">
                                <div className="card-body">
                                  <ul className="chapter-list">
                                    <ChapterList />
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-9">
                              <ResponseChapterClick />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab_default_3">
                          {userID !== null && (
                            <>
                              <div className="question-card">
                                <h2 className="text-white text-center">
                                  {language === "Thai"
                                    ? "สถานะการเรียน คุณ"
                                    : "Learning Status "}{" "}
                                  {currentUser.FirstnameTH +
                                    " " +
                                    currentUser.LastnameTH}
                                </h2>
                                <div className="row">
                                  <div className="col-3">
                                    <div className="card">
                                      <div className="card-body">
                                        <h3 className="text-center">
                                          {language === "Thai"
                                            ? "บทเรียน"
                                            : "Chapter"}
                                        </h3>
                                        <CircularProgressbarWithChildren
                                          value={chapterPercent}
                                          styles={buildStyles({
                                            textColor: "#000",
                                            pathColor: "#013E84",
                                            trailColor: "#CBD7E6",
                                            pathTransitionDuration: 0.5,
                                          })}
                                        >
                                          <div style={{ fontSize: 50 }}>
                                            <strong>{chapterPercent}%</strong>
                                          </div>
                                        </CircularProgressbarWithChildren>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-3">
                                    <div className="card">
                                      <div className="card-body">
                                        <h3 className="text-center">
                                          {language === "Thai"
                                            ? "แบบทดสอบวัดความรู้"
                                            : "Test"}
                                        </h3>
                                        <CircularProgressbarWithChildren
                                          value={testPercent}
                                          styles={buildStyles({
                                            textColor: "#000",
                                            pathColor: "#013E84",
                                            trailColor: "#CBD7E6",
                                            pathTransitionDuration: 0.5,
                                          })}
                                        >
                                          <div style={{ fontSize: 50 }}>
                                            <strong>{testPercent}%</strong>
                                          </div>
                                        </CircularProgressbarWithChildren>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="card">
                                      <div className="card-body">
                                        <h3 className="text-center">
                                          {language === "Thai"
                                            ? "แบบทดสอบก่อนและหลังเรียน"
                                            : "Pre/Post Test"}
                                        </h3>
                                        <div
                                          className="progress"
                                          style={{ height: 20 }}
                                        >
                                          <div
                                            className="progress-bar progress-bar-striped active"
                                            role="progressbar"
                                            aria-valuenow="40"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            style={{
                                              width: preTestPercent + "%",
                                              backgroundColor: "#2095D3",
                                              fontSize: 1.2 + "rem",
                                            }}
                                          >
                                            Pre-Test {preTestPercent}%
                                          </div>
                                        </div>

                                        <div
                                          className="progress mt-4 mb-5"
                                          style={{ height: 20 }}
                                        >
                                          <div
                                            className="progress-bar progress-bar-striped active"
                                            role="progressbar"
                                            aria-valuenow="50"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            style={{
                                              width: postTestPercent + "%",
                                              fontSize: 1.2 + "rem",
                                            }}
                                          >
                                            Post-Test {postTestPercent}%
                                          </div>
                                        </div>
                                        {subjectComplete === true &&
                                          duplicateExam === false && (
                                            <div className="col-12 text-center">
                                              <button
                                                type="button"
                                                style={{
                                                  borderRadius: 22,
                                                  lineHeight: 1.5 + "rem",
                                                  fontSize: 1.5 + "rem",
                                                  padding: "0.5rem 3rem",
                                                }}
                                                className="btn btn-info"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  printCertificate();
                                                }}
                                              >
                                                <h2> ดาวน์โหลด Certificate </h2>
                                              </button>
                                            </div>
                                          )}
                                        {subjectComplete === true &&
                                          duplicateExam === true && (
                                            <h2 className="text-center text-info">
                                              {language === "Thai"
                                                ? "ไม่สามารถออกใบรับรองให้ได้ เนื่องจากท่านทำแบบทดสอบมากกว่า 1 ครั้ง"
                                                : "The certificate could not be issued. because you have taken the exam more than once"}
                                            </h2>
                                          )}
                                        {subjectComplete === false && (
                                          <div className="col-12">
                                            <h2 className="text-center text-primary">
                                              {language === "Thai"
                                                ? "ยังไม่จบหลักสูตร"
                                                : "Not finished the course"}
                                            </h2>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <ChapterResultList></ChapterResultList>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
