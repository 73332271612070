import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { authenticationService } from 'services/authentication.service.js';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function ChapterLink(props) {
    const currentUser = authenticationService.currentUserValue;
    const chapterID = props.location.state.chapterID;
    const [errors, setError] = useState({});
    //const [eLearnTitle, setELearnTitle] = useState('');
    const [parentChapter, setParentChapter] = useState([]);
    const [chapterTitle, setChapterTitle] = useState('');
    const [description, setDescription] = useState('');
    const [showDetail, setShowDetail] = useState(false);
    const [url, setURL] = useState('');

    function reLoadData() {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/ESubjectChapter', { params: { id: chapterID } }).then((response) => {
            let data = response.data[0];
            //console.log(data)
            setParentChapter(data)
            //setELearnTitle(data.ELearnTitle);
        })
    }
    useEffect(() => {
        reLoadData();
    }, []); /* <-- add this for run once*/
    const saveData = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (chapterTitle.trim().length === 0) {
            formIsValid = false;
            serrors["chapterTitle"] = "กรุณากรอกข้อมูล";
        }
        if (showDetail === true) {
            if (description.trim().length === 0) {
                formIsValid = false;
                serrors["description"] = "กรุณากรอกข้อมูล";
            }
        }
        if (url.trim().length === 0) {
            formIsValid = false;
            serrors["url"] = "กรุณากรอกข้อมูล";
        }
        setError(serrors);
        if (formIsValid) {
            const formData = new FormData();
            formData.append("eLearnID", parentChapter.ELearnID);
            formData.append("chapterTitle", chapterTitle);
            formData.append("chapterContentType", "LINK");
            formData.append("contentPath", url);
            formData.append("description", description);
            formData.append("displayDetail", showDetail);
            formData.append("parentChapterID", chapterID);
            formData.append("userID", currentUser.UserID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveChapter', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        reLoadData();
                        props.history.push({ pathname: "/admin/elearning/ESubjectChapter", state: { id: parentChapter.ELearnID } });
                    })
                });
        }
    }
    const cancel = () => {
        props.history.push({ pathname: "/admin/elearning/ESubjectChapter", state: { id: parentChapter.ELearnID } });
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการอีเลิร์นนิง (E-learning)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/elearning/ELearningList" }}>{"จัดการวิชา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/elearning/ESubjectChapter", state: { id: parentChapter.ELearnID } }}>วิชา {parentChapter.ELearnTitle}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/elearning/ChapterLink", state: { id: chapterID } }}>ลิงก์</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h1>{parentChapter.ELearnTitle} (<span style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: 1.5 + 'rem' }}>{parentChapter.ChapterTitle}</span>)</h1>
                                    <div className="alert alert-dark col-12" role="alert">ทั่วไป</div>
                                    <div className="col-12">
                                        <label htmlFor="chapterTitle" className="required">ชื่อ</label>
                                        <input type="text" className="form-control " id="chapterTitle" value={chapterTitle} name="chapterTitle" placeholder="กรุณากรอก" onChange={(e) => { setChapterTitle(e.currentTarget.value) }} />
                                        <SError error={errors["chapterTitle"]} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="description" >คำอธิบาย</label>
                                        <div>
                                            <textarea className="form-control" name="completeDocRemark" id="description" rows="3" value={description} onChange={e => { setDescription(e.target.value) }}></textarea>
                                            <SError error={errors["description"]} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="showDetail" name="showDetail" checked={showDetail === true} onChange={(e) => { setShowDetail(!showDetail) }} />
                                            <label className="custom-control-label" htmlFor="showDetail">แสดงคำอธิบายในหน้ารายวิชา</label>
                                        </div>
                                    </div>
                                    <div className="alert alert-dark col-12" role="alert">URL</div>
                                    <div className="col-12">
                                        <label htmlFor="url" className="required">URL</label>
                                        <input type="text" className="form-control " id="url" value={url} name="url" placeholder="กรุณากรอก" onChange={(e) => { setURL(e.currentTarget.value) }} />
                                        <SError error={errors["url"]} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
