import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/network.svg";
import AttatchmentList from "components/AttatchmentList";
import utils from "utils/utils";

export default function ViewEducationCord(props) {
  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [educationData, SetEducationData] = useState({});
  const [educationPerformance, setEducationPerformance] = useState([]);
  const [performanceYear, setPerformanceYear] = useState([]);
  const [activeEducationPerformance, setActiveEducationPerformance] = useState(
    []
  );
  const [activeYear, setActiveYear] = useState(0);

  const educationID = props.location.state.educationID;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/educationCoordinatebyID", {
        params: { id: educationID },
      })
      .then((response) => {
        SetEducationData(response.data[0]);
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/educationPerformance", {
            params: { id: response.data[0].EducationCoordinationUUID },
          })
          .then((performance) => {
            setEducationPerformance(performance.data);

            let year = [];
            let performanceData = [];
            for (let i = 0; i < performance.data.length; i++) {
              if (year.indexOf(performance.data[i].PerformanceYear) === -1)
              {
                year.push(performance.data[i].PerformanceYear);
              }
              let data = performance.data[i];
              if(data.FilenameOld === null){
                const pathArray = data.PerformanceFile.split("/");
                const lastIndex = pathArray.length - 1;
                //console.log(pathArray)
                data.FilenameNew = data.FilenameOld = pathArray[lastIndex];
              }

              performanceData.push(data);
            }
            setPerformanceYear(year);
            setActiveEducationPerformance(performanceData);
            setLoading(false);
          });
      });
  }, []); /* <-- add this for run once*/

  function ViewsMap() {
    /*props.history.push({
      pathname: "/authen/network/EducationsMap",
      state: {
        id: educationID,
        Lat: educationData.Lat,
        Lng: educationData.Lng,
        Name: educationData.Name,
      },
    });*/
    window.open('https://www.google.com/maps/search/?api=1&query=' + educationData.Lat + ',' + educationData.Lng, '_blank');
  }
  const YearSelect = performanceYear.map((year, index) => (
    <option key={index} value={year}>
      {year}
    </option>
  ));
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {/*{<Navbar />}*/}
        <ContentBanner
          language={language}
          theader={"เครือข่ายด้านสิทธิมนุษยชน"}
          eheader={"Network"}
          bannerkey="NETWORK"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {language === "Thai" ? "เครือข่าย" : "Network"}
              </a>
              <span>/</span>
              <Link
                to={{
                  pathname: "/authen/network/EducationCoordinate",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? " ศูนย์ศึกษาและประสานงานด้านสิทธิมนุษยชนในภูมิภาค"
                  : "Education Cooperation Network"}
              </Link>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/network/ViewEducationCord",
                  state: { educationID: educationID },
                }}
              >
                {educationData.Name}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="edit-profile ViewEducationCord">
            <div className="news-activity-data">
              <div className="row">
                <div className="col-12">
                  <div className="sub-title">
                    <h2 className="thai-header text-center">
                      <span className="orang content-underline">
                        {language === "Thai"
                          ? " ศูนย์ศึกษาและประสานงานด้านสิทธิมนุษยชนในภูมิภาค"
                          : "Education Cooperation Network"}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-data">
              <div className="row">
                <div className="col-5 logo-img">
                  {educationData.LogoImgPath !== null && (
                    <img src={educationData.LogoImgPath} alt="logo" />
                  )}
                </div>
                <div className="col-7">
                  <h2 className="text-left text-dark-blue header">
                    {language === "Thai"
                      ? educationData.Name
                      : educationData.NameEN.trim().length === 0
                        ? educationData.Name
                        : educationData.NameEN}
                  </h2>
                  <ul className="list-icon listnoMargin">
                    <li className="list-icon">
                      <i className="fas fa-map-marker-alt text-dark-blue mr-2"></i>
                      <span className="title">
                        {language === "Thai" ? "ที่อยู่" : "Address"} :
                      </span>{" "}
                      <span className="detail">{educationData.Location}</span>
                    </li>
                    <li className="list-icon">
                      <i className="fas fa-phone-alt text-dark-blue mr-2"></i>
                      <span className="title">
                        {language === "Thai" ? "โทรศัพท์" : "Phone Number"} :
                      </span>{" "}
                      <span className="detail">{educationData.Tel}</span>
                    </li>
                    <li className="list-icon">
                      <i className="far fa-envelope text-dark-blue mr-2"></i>
                      <span className="title">
                        {language === "Thai" ? "อีเมล" : "Email"} :
                      </span>{" "}
                      <span className="detail">{educationData.Email}</span>
                    </li>
                    <li className="list-icon">
                      <i className="fas fa-external-link-alt text-dark-blue mr-2"></i>
                      <span className="title">
                        {language === "Thai" ? "ลิงก์ที่เกี่ยวข้อง" : "Link"} :{" "}
                      </span>
                      <span className="detail">
                        {" "}
                        <Link
                          className="active"
                          to={{ pathname: educationData.Web }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {educationData.Web}
                        </Link>
                      </span>
                    </li>
                    <li className="list-icon">
                      <i className="far fa-map text-dark-blue mr-2"></i>
                      <span className="title">
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            ViewsMap();
                          }}
                        >
                          {language === "Thai" ? "แผนที่" : "Map"}{" "}
                        </a>
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="subheader mt-4">
                {language === "Thai"
                  ? "ประวัติการจัดตั้ง"
                  : "Establishment History"}
              </div>
              <div className="formMaxwidth">
                <div
                  className="detail"
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "Thai"
                        ? educationData.History
                        : educationData.HistoryEN.trim().length === 0
                          ? educationData.History
                          : educationData.HistoryEN,
                  }}
                ></div>
              </div>
              <div className="subheader mt-4">
                {language === "Thai" ? "วัตถุประสงค์" : "Objective"}
              </div>
              <div className="formMaxwidth">
                <div
                  className="detail"
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "Thai"
                        ? educationData.Objective
                        : educationData.ObjectiveEN.trim().length === 0
                          ? educationData.Objective
                          : educationData.ObjectiveEN,
                  }}
                ></div>
              </div>
              <div className="subheader mt-4">
                {language === "Thai" ? "บทบาทหน้าที่" : "Role and Duty"}
              </div>
              <div className="formMaxwidth">
                <div
                  className="detail"
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "Thai"
                        ? educationData.RoleAndDuty
                        : educationData.RoleAndDutyEN.trim().length === 0
                          ? educationData.RoleAndDuty
                          : educationData.RoleAndDutyEN,
                  }}
                ></div>
              </div>
              <div className="subheader mt-4">
                {language === "Thai"
                  ? "ผลการดำเนินงานที่ผ่านมา"
                  : "Performance"}
              </div>
              <div className="formMaxwidth">
                <div className="detail" style={{ width: "100%" }}>
                  <div className="formContent mb-3" style={{ maxWidth: 300 }}>
                    <select
                      id="yearSelect"
                      name="yearSelect"
                      className="custom-select"
                      defaultValue="0"
                      onChange={(e) => {
                        setActiveYear(e.currentTarget.value);
                        if (e.currentTarget.value === "0") {
                          setActiveEducationPerformance(educationPerformance);
                        } else {
                          const result = educationPerformance.filter((item) => {
                            return (
                              item.PerformanceYear === e.currentTarget.value
                            );
                          });
                          setActiveEducationPerformance(result);
                        }
                      }}
                    >
                      <option value="0">
                        {language === "Thai"
                          ? "ผลดำเนินงานทั้งหมด"
                          : "All years"}
                      </option>
                      {YearSelect}
                    </select>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>ปี พ.ศ.</th>
                          <th>ชื่อเอกสาร</th>
                          <th>วันที่บันทึกเอกสาร</th>
                          <th>ดาวน์โหลด</th>
                        </tr>
                      </thead>
                      <tbody id="your-table-body-id">
                        {activeEducationPerformance.map((file, i) => (
                          <tr key={i}>
                            <td>{Number(i) + 1}</td>
                            <td>{file.PerformanceYear}</td>
                            <td>{file.FilenameOld.split(".")[0]}</td>
                            <td>
                              {utils.MariatoThaiDateString(file.CreatedDate)}
                            </td>
                            <td>
                              <a
                                href={file.PerformanceFile}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <i className="fas fa-download"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="subheader mt-4">
                {language === "Thai" ? "เอกสารแนบ" : "Attachment"}
              </div>
              <div className="formMaxwidth">
                <div className="tab-pane whyNotSeeDesign">
                  <AttatchmentList fileList={educationData.filelist} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
