import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import AdminBanner from 'components/Banner/AdminBanner';
import SingleFileTable from 'components/SingleFileTable';
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function EditBestPersonReward(props) {
    const history = useHistory();
    const id = props.location.state.id;
    const currentUser = authenticationService.currentUserValue;
    const [errors, setError] = useState({});
    const [uuid, setUUID] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [position, setPosition] = useState('');
    const [department, setDepartment] = useState('');
    const [typeOfRewardDesc, setTypeOfRewardDesc] = useState('');
    const [portfolio, setPortfolio] = useState('');

    const [nameEN, setNameEN] = useState('');
    const [locationEN, setLocationEN] = useState('');
    const [positionEN, setPositionEN] = useState('');
    const [departmentEN, setDepartmentEN] = useState('');
    const [typeOfRewardDescEN, setTypeOfRewardDescEN] = useState('');
    const [portfolioEN, setPortfolioEN] = useState('');

    const [isPersonType, setIsPersonType] = useState('');
    const [rewardYear, setRewardYear] = useState(2564);
    //const [toppicTypeID, setToppicTypeID] = useState('');

    //const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');


    const [tel, setTel] = useState('');


    const [status, setStatus] = useState(false);

    const [filespreview, setFilesPreview] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [mediaList, setMediaList] = useState([]);
    const [logoimg, setLogoImg] = useState(null);
    const [topicList, setTopicList] = useState([]);
    const [selecttopicList, setSelectTopicList] = useState([]);
    let seltopiclist = [];
    useEffect(() => {
        //console.log(props.location)
        //console.log(mouID)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchMemberInterestTopic', { params: { status: 1 } })
            .then((response) => {
                setTopicList(response.data)
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/bestPersonRewardbyID', { params: { id: id } }).then((response) => {
            let data = response.data[0];
            //console.log(data)
            setUUID(data.BestPersonRewardUUID);
            setIsPersonType(data.IsPersonType);
            let budyear = Number(data.RewardYear) + 543;
            setRewardYear(budyear);
            //setToppicTypeID(data.ToppicTypeID);

            setName(data.Name);
            setLocation(data.Location);
            setDepartment(data.Department);
            setPosition(data.Position);
            setTypeOfRewardDesc(data.TypeOfRewardDesc);
            setPortfolio(data.Portfolio);

            if (data.NameEN !== null)
                setNameEN(data.NameEN);
            if (data.LocationEN !== null)
                setLocationEN(data.LocationEN);
            if (data.DepartmentEN !== null)
                setDepartmentEN(data.DepartmentEN);
            if (data.PositionEN !== null)
                setPositionEN(data.PositionEN);
            if (data.TypeOfRewardDescEN !== null)
                setTypeOfRewardDescEN(data.TypeOfRewardDescEN);
            if (data.PortfolioEN !== null)
                setPortfolioEN(data.PortfolioEN);

            setStatus(data.Status);
            setTel(data.Tel);
            setEmail(data.Email);

            if (data.ToppicTypeID !== null) {
                var array = data.ToppicTypeID.split(",").map(Number);
                //console.log(array)
                /*if (seltopiclist.indexOf(item.InterestTopicID) < 0) {
                    seltopiclist.push(item.InterestTopicID);
                }*/
                for (let i = 0; i < array.length; i++) {
                    seltopiclist.push(array[i]);
                }
                setSelectTopicList(seltopiclist);
            }

            const webimageFile = {
                file: data.PersonImgPath,
                name: data.PersonImgPath.substring(data.PersonImgPath.lastIndexOf('/') + 1),
                preview: data.PersonImgPath,
                size: utils.getFileSize(data.PersonImgPath)
            };
            setLogoImg(webimageFile);

            const newFiles = [...fileList];
            const newMedia = [...mediaList];
            let fileloop = 0;
            let medialoop = 0;
            for (let i = 0; i < data.filelist.length; i += 1) {
                if (data.filelist[i].AttachmentType !== 'VDO') {
                    const filedata = {
                        id: data.filelist[i].AttachmentID,
                        path: data.filelist[i].FilenameNew,
                        name: data.filelist[i].FilenameNew.substring(data.filelist[i].FilenameNew.lastIndexOf('/') + 1),
                        preview: data.filelist[i].AttachmentPath,
                        size: data.filelist[i].Filesize
                    };
                    newFiles[fileloop] = filedata;
                    fileloop++;
                }
                else {
                    const filedata = {
                        id: data.filelist[i].AttachmentID,
                        path: data.filelist[i].FilenameNew,
                        name: data.filelist[i].FilenameNew.substring(data.filelist[i].FilenameNew.lastIndexOf('/') + 1),
                        preview: data.filelist[i].AttachmentPath,
                        size: data.filelist[i].Filesize
                    };
                    newMedia[medialoop] = filedata;
                    medialoop++;
                }
            }
            setFileList(newFiles);
            setMediaList(newMedia);
        });

    }, []); /* <-- add this for run once*/

    const TopicSelect = topicList.map((item) => (
        <option
            key={item.InterestTopicID}
            value={item.InterestTopicID}
        >
            {item.TextTH}
        </option>
    ));
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;

        if (Number(isPersonType) === 0) {
            formIsValid = false;
            serrors["isPersonType"] = "กรุณาระบุรายละเอียด";
        }
        if (Number(rewardYear) < 2500) {
            formIsValid = false;
            serrors["rewardYear"] = "กรุณาระบุปี พ.ศ. ให้ถูกต้อง";
        }
        /*if (Number(toppicTypeID) === 0) {
            formIsValid = false;
            serrors["toppicTypeID"] = "กรุณาระบุประเด็นสิทธิ";
        }*/
        if (name.trim().length === 0) {
            formIsValid = false;
            serrors["name"] = "กรุณาระบุรายละเอียด";
        }
        if (typeOfRewardDesc.trim().length === 0) {
            formIsValid = false;
            serrors["typeOfRewardDesc"] = "กรุณาระบุรายละเอียด";
        }
        if (portfolio.trim().length === 0) {
            formIsValid = false;
            serrors["portfolio"] = "กรุณาระบุรายละเอียด";
        }
        /*if (nameEN.trim().length === 0) {
            formIsValid = false;
            serrors["nameEN"] = "กรุณาระบุรายละเอียด";
        }
        if (typeOfRewardDescEN.trim().length === 0) {
            formIsValid = false;
            serrors["typeOfRewardDescEN"] = "กรุณาระบุรายละเอียด";
        }
        if (portfolioEN.trim().length === 0) {
            formIsValid = false;
            serrors["portfolioEN"] = "กรุณาระบุรายละเอียด";
        }*/
        if (Number(isPersonType) === 2) {
            if (location.trim().length === 0) {
                formIsValid = false;
                serrors["location"] = "กรุณาระบุรายละเอียด";
            }
            /*if (locationEN.trim().length === 0) {
                formIsValid = false;
                serrors["locationEN"] = "กรุณาระบุรายละเอียด";
            }*/
            if (tel.trim().length === 0) {
                formIsValid = false;
                serrors["tel"] = "กรุณาระบุรายละเอียด";
            }
            if (email.trim().length === 0) {
                formIsValid = false;
                serrors["email"] = "กรุณาระบุรายละเอียด";
            }
        }
        if (Number(isPersonType) === 1) {
            if (department.trim().length === 0) {
                formIsValid = false;
                serrors["department"] = "กรุณาระบุรายละเอียด";
            }
            if (position.trim().length === 0) {
                formIsValid = false;
                serrors["position"] = "กรุณาระบุรายละเอียด";
            }
            /*if (departmentEN.trim().length === 0) {
                formIsValid = false;
                serrors["departmentEN"] = "กรุณาระบุรายละเอียด";
            }
            if (positionEN.trim().length === 0) {
                formIsValid = false;
                serrors["positionEN"] = "กรุณาระบุรายละเอียด";
            }*/
        }
        if (selecttopicList.length === 0) {
            formIsValid = false;
            //console.log(selecttopicList)
            serrors["seltopiclist"] = "โปรดเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชนอย่างน้อยหนึ่งรายการ";
        }
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }

        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", id);
            formData.append("uuid", uuid);
            formData.append("isPersonType", isPersonType);
            formData.append("rewardYear", rewardYear);
            //formData.append("toppicTypeID", toppicTypeID);
            formData.append("name", name);
            formData.append("location", location);
            formData.append("department", department);
            formData.append("position", position);
            formData.append("typeOfRewardDesc", typeOfRewardDesc);
            formData.append("portfolio", portfolio);
            formData.append("nameEN", nameEN);
            formData.append("locationEN", locationEN);
            formData.append("departmentEN", departmentEN);
            formData.append("positionEN", positionEN);
            formData.append("typeOfRewardDescEN", typeOfRewardDescEN);
            formData.append("portfolioEN", portfolioEN);
            formData.append("tel", tel);
            formData.append("status", status);
            formData.append("email", email);
            formData.append("seltopiclist", selecttopicList);

            for (let i = 0; i < fileList.length; i += 1) {
                formData.append("fileList[]", fileList[i]);
            }
            for (let i = 0; i < mediaList.length; i += 1) {
                //formData.append("mediaList[]", mediaList[i]);
                formData.append("fileList[]", mediaList[i]);
            }
            formData.append("logofile", logoimg.file);
            formData.append("userID", currentUser.UserID);
            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updatePersonBestReward', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();//props.typeOfRewardDesc.push({pathname:"/admin/network/EditNetworkMOU",state: { id: mouID }});
                        }
                        else {
                            props.history.push({ pathname: "/admin/network/BestPersonRewardList" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const cancel = () => {
        history.push({ pathname: "/admin/network/BestPersonRewardList" });
    };
    const rx_int = /^\d+$/;
    const handleYearChange = (e) => {
        if (rx_int.test(e.target.value)) {
            setRewardYear(parseInt(e.target.value));
        }
    }
    const handleRemoveFile = filename => {
        for (let file of fileList) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched completeDocFilesList
        }
        setFileList(fileList.filter(item => item['name'] !== filename));
    };
    const FilesList = fileList.map((file, i) => (
        <tr key={file.path}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.path.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));

    const handleRemoveMediaFile = filename => {
        for (let file of mediaList) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched completeDocFilesList
        }
        setMediaList(mediaList.filter(item => item['name'] !== filename));
    };
    const MediaList = mediaList.map((file, i) => (
        <tr key={file.path}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.path.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveMediaFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    const handleLogoImgOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setLogoImg(imageFile);
    };
    const LogoImgPreview = (
        (logoimg !== null) && (<img className="preview-upload" src={logoimg.preview} onChange={(e) => { e.target.src = URL.createObjectURL(logoimg); }} alt="MOU logo" />)
    );
    const handleRemoveLogoFile = () => {
        const formData = new FormData();
        formData.append("folder", 'images');
        formData.append("url", LogoImgPreview.file);
        formData.append("fieldName", 'LogoImgPath');
        formData.append("id", id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteEducationCordLogoFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setLogoImg(null);
    };
    const handleEdit = params => {
        props.typeOfRewardDesc.push({ pathname: "/admin/network/EditBestPersonReward", state: { id: id } });
    };
    const TopicCheckbox = topicList.map((item) => (
        <div className="col-12" key={item.InterestTopicID}>
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id={"operation" + item.InterestTopicID} name={"operation" + item.InterestTopicID} checked={selecttopicList.indexOf(Number(item.InterestTopicID)) > -1}
                    onChange={(e) => {
                        var checked = e.target.checked;
                        seltopiclist = [...selecttopicList];
                        if (checked) {
                            if (seltopiclist.indexOf(item.InterestTopicID) < 0) {
                                seltopiclist.push(item.InterestTopicID);
                            }
                        }
                        else {
                            if (seltopiclist.indexOf(item.InterestTopicID) > -1) {
                                let index = seltopiclist.indexOf(item.InterestTopicID);
                                seltopiclist = seltopiclist.slice(0, index).concat(seltopiclist.slice(index + 1));
                            }
                        }
                        setSelectTopicList(seltopiclist);
                        //console.log(seltopiclist)
                    }} />
                <label className="custom-control-label" htmlFor={"operation" + item.InterestTopicID}>{item.TextTH}</label>
            </div>
        </div>
    ));
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)"} path={<><span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการเครือข่ายด้านสิทธิมนุษยชน (Networks)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/network/BestPersonRewardList" }}>{"การจัดการรางวัลนักสิทธิมนุษยชนดีเด่น"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link onClick={e => { e.preventDefault(); handleEdit(); }} id="profile-btn" className="active" to={{ pathname: "/admin/network/EditBestPersonReward" }}>{"แก้ไข"}</Link></>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="card">
                                    <div className="card-body row">
                                        <div className="col-6">
                                            <label htmlFor="name" className="required">กลุ่ม</label>
                                            <select className="form-control mb-2 mr-2" value={isPersonType} onChange={(e) => { setIsPersonType(e.currentTarget.value) }}>
                                                <option value="0">กลุ่ม</option>
                                                <option value="1">บุคคล</option>
                                                <option value="2">องค์กร</option>
                                            </select>
                                            <SError error={errors["isPersonType"]} />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="rewardYear" className="required">ปี พ.ศ.</label>
                                            <input type="text" className="form-control " id="rewardYear" value={rewardYear} name="rewardYear" placeholder="กรุณากรอก" onChange={(e) => { handleYearChange(e) }} />
                                            <SError error={errors["rewardYear"]} />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="toppicTypeID" className="required">ประเด็นสิทธิ</label>
                                            {TopicCheckbox}
                                            <SError error={errors["toppicTypeID"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="name" className="required">ชื่อ</label>
                                            <input type="text" className="form-control " id="name" value={name} name="name" placeholder="กรุณากรอก" onChange={(e) => { setName(e.currentTarget.value) }} />
                                            <SError error={errors["name"]} />
                                        </div>
                                        {Number(isPersonType) === 2 && (
                                            <>
                                                <div className="col-12">
                                                    <label htmlFor="location" className="required">ที่อยู่</label>
                                                    <input type="text" className="form-control " id="location" value={location} name="location" placeholder="กรุณากรอก" onChange={(e) => { setLocation(e.currentTarget.value) }} />
                                                    <SError error={errors["location"]} />
                                                </div>

                                                <div className="col-6">
                                                    <label htmlFor="tel" className="required">เบอร์โทร</label>
                                                    <input type="text" className="form-control " id="tel" value={tel} name="tel" placeholder="กรุณากรอก" onChange={(e) => { setTel(e.currentTarget.value) }} />
                                                    <SError error={errors["tel"]} />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="email" className="required">อีเมล</label>
                                                    <input type="text" className="form-control " id="email" value={email} name="email" placeholder="กรุณากรอก" onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                                    <SError error={errors["email"]} />
                                                </div>
                                            </>
                                        )}

                                        {Number(isPersonType) === 1 && (
                                            <>
                                                <div className="col-6">
                                                    <label htmlFor="position" className="required">ตำแหน่ง</label>
                                                    <input type="text" className="form-control " id="position" name="position" placeholder="กรุณากรอก" value={position} onChange={(e) => { setPosition(e.currentTarget.value) }} />
                                                    <SError error={errors["position"]} />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="department" className="required">หน่วยงาน/องค์กร</label>
                                                    <input type="text" className="form-control " id="department" value={department} name="department" placeholder="กรุณากรอก" onChange={(e) => { setDepartment(e.currentTarget.value) }} />
                                                    <SError error={errors["department"]} />
                                                </div>
                                            </>
                                        )}
                                        <div className="col-12">
                                            <label htmlFor="typeOfRewardDesc" className="required">ประเภทรางวัล</label>
                                            <div>
                                                <ReactSummernote
                                                    value={typeOfRewardDesc}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setTypeOfRewardDesc}
                                                />
                                                <SError error={errors["typeOfRewardDesc"]} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="portfolio" className="required">ผลงาน</label>
                                            <div>
                                                <ReactSummernote
                                                    value={portfolio}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setPortfolio}
                                                />
                                                <SError error={errors["portfolio"]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="card">
                                    <div className="card-body row">
                                        <div className="col-6">
                                            <label htmlFor="name">กลุ่ม</label>
                                            <select className="form-control mb-2 mr-2" value={isPersonType} onChange={(e) => { setIsPersonType(e.currentTarget.value) }}>
                                                <option value="0">กลุ่ม</option>
                                                <option value="1">บุคคล</option>
                                                <option value="2">องค์กร</option>
                                            </select>
                                            <SError error={errors["isPersonType"]} />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="rewardYear">ปี พ.ศ.</label>
                                            <input type="text" className="form-control " id="rewardYear" value={rewardYear} name="rewardYear" placeholder="กรุณากรอก" onChange={(e) => { handleYearChange(e) }} />
                                            <SError error={errors["rewardYear"]} />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="toppicTypeID">ประเด็นสิทธิ</label>
                                            {TopicCheckbox}
                                            <SError error={errors["toppicTypeID"]} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="nameEN">ชื่อ</label>
                                            <input type="text" className="form-control " id="nameEN" value={nameEN} name="nameEN" placeholder="กรุณากรอก" onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                            <SError error={errors["nameEN"]} />
                                        </div>
                                        {Number(isPersonType) === 2 && (
                                            <>
                                                <div className="col-12">
                                                    <label htmlFor="locationEN">ที่อยู่</label>
                                                    <input type="text" className="form-control " id="locationEN" value={locationEN} name="locationEN" placeholder="กรุณากรอก" onChange={(e) => { setLocationEN(e.currentTarget.value) }} />
                                                    <SError error={errors["locationEN"]} />
                                                </div>

                                                <div className="col-6">
                                                    <label htmlFor="tel">เบอร์โทร</label>
                                                    <input type="text" className="form-control " id="tel" value={tel} name="tel" placeholder="กรุณากรอก" onChange={(e) => { setTel(e.currentTarget.value) }} />
                                                    <SError error={errors["tel"]} />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="email">อีเมล</label>
                                                    <input type="text" className="form-control " id="email" value={email} name="email" placeholder="กรุณากรอก" onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                                    <SError error={errors["email"]} />
                                                </div>
                                            </>
                                        )}

                                        {Number(isPersonType) === 1 && (
                                            <>
                                                <div className="col-6">
                                                    <label htmlFor="positionEN">ตำแหน่ง</label>
                                                    <input type="text" className="form-control " id="positionEN" name="positionEN" placeholder="กรุณากรอก" value={positionEN} onChange={(e) => { setPositionEN(e.currentTarget.value) }} />
                                                    <SError error={errors["positionEN"]} />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="departmentEN">หน่วยงาน/องค์กร</label>
                                                    <input type="text" className="form-control " id="departmentEN" value={departmentEN} name="departmentEN" placeholder="กรุณากรอก" onChange={(e) => { setDepartmentEN(e.currentTarget.value) }} />
                                                    <SError error={errors["departmentEN"]} />
                                                </div>
                                            </>
                                        )}
                                        <div className="col-12">
                                            <label htmlFor="typeOfRewardDescEN">ประเภทรางวัล</label>
                                            <div>
                                                <ReactSummernote
                                                    value={typeOfRewardDescEN}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setTypeOfRewardDescEN}
                                                />
                                                <SError error={errors["typeOfRewardDescEN"]} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="portfolioEN">ผลงาน</label>
                                            <div>
                                                <ReactSummernote
                                                    value={portfolioEN}
                                                    options={{
                                                        //lang: 'th-TH',
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'italic', 'underline', 'clear']],
                                                            ['color', ['color']],
                                                            /*['fontname', ['fontname']],*/
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link', 'video']],
                                                            ['view', ['fullscreen']]
                                                        ]
                                                    }}
                                                    onChange={setPortfolioEN}
                                                />
                                                <SError error={errors["portfolioEN"]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <label className="required">{Number(isPersonType) === 1 ? 'ภาพบุคคล' : 'ภาพโลโก้องค์กร'}</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleLogoImgOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <SError error={errors["logoimg"]} />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {LogoImgPreview}
                                                <SingleFileTable filedata={logoimg} removefn={handleRemoveLogoFile} />
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>


                                <div className="col-12">
                                    <label>เอกสารแนบ</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setFileList([...fileList, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {FilesList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12">
                                    <label >วิดีโอ</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setMediaList([...mediaList, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".mp3,.mp4,.avi,.fla,.wma,.wmv,.mpeg,.mpeg4"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {MediaList}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>

                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status1" value="1" checked={status === true} onChange={(e) => { setStatus(true); }} />
                                        <label className="form-check-label" htmlFor="status1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="status" id="status2" value="0" checked={status === false} onChange={(e) => { setStatus(false); }} />
                                        <label className="form-check-label" htmlFor="status2"> ไม่เผยแพร่</label>
                                    </div>
                                    <SError error={errors["status"]} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    );
}
