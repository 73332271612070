import React from 'react';
import {Route} from 'react-router-dom';
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";
import useWindowSize from "../utils/UseWindowSize";

const MainLayout = ({component: Component, ...rest}) => {
  const [width] = useWindowSize();
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Navbar/>
          <Component {...props} />
          {width <= 991 ? <MobileFooter /> : <Footer />}
        </>
      )}
    />
  );
};

export default MainLayout;
