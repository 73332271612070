import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function EditNHRCTopic(props) {
    //console.log("Edit ID = "+props.location.state.id);
    const currentUser = authenticationService.currentUserValue;
    const [nameth, setNameTH] = useState('');
    const [nameen, setNameEN] = useState('');
    const [status, setStatus] = useState(-1);

    const [errors, setError] = useState({});
    const nhrcID = props.location.state.id;
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getNHRCTopicbyID', { params: { id: nhrcID } }).then((response) => {
            setNameTH(response.data[0].TextTH);
            setNameEN(response.data[0].TextEN);
            setStatus(response.data[0].Status);
        })
    }, []); /* <-- add this for run once*/

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (nameth.trim().length === 0) {
            formIsValid = false;
            serrors["nameth"] = "กรุณาระบุชื่อประเด็นสิทธิภาษาไทย";
        }
        /*if (nameen.trim().length === 0) {
            formIsValid = false;
            serrors["nameen"] = "กรุณาระบุชื่อประเด็นสิทธิภาษาอังกฤษ";
        }*/
        if (status === -1) {
            formIsValid = false;
            serrors["status"] = "กรุณาเลือกสถานะ";
        }
        setError(serrors);
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("id", props.location.state.id);
            formData.append("titleth", nameth);
            formData.append("titleen", nameen);
            formData.append("status", status);
            formData.append("userID", currentUser.UserID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateNHRCTopic', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/setting/NHRCTopic" });
                        }
                    })
                });

        } else {
            //alert("Form has errors.")
        }
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/setting/NHRCTopic" });
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการการประชาสัมพันธ์"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการคลังความรู้ด้านสิทธิมนุษยชน</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/setting/NHRCTopic" }}>{"การจัดการประเด็นสิทธิ"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/setting/EditNHRCTopic", state: { id: nhrcID } }}>{"แก้ไข"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="form-group">
                                    <label htmlFor="nameth" className="required">ชื่อประเด็นสิทธิ (ภาษาไทย)</label>
                                    <input type="text" className="form-control" id="nameth" name="nameth" placeholder="กรุณากรอก" value={nameth} onChange={(e) => { setNameTH(e.currentTarget.value) }} />
                                    <SError error={errors["nameth"]} />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="form-group">
                                    <label htmlFor="nameen">ชื่อประเด็นสิทธิ (ภาษาอังกฤษ)</label>
                                    <input type="text" className="form-control" id="nameen" name="nameen" placeholder="กรุณากรอก" value={nameen} onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                    <SError error={errors["nameen"]} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4" >
                            <label htmlFor="status" className="required mr-2">สถานะ</label>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="status" id="published1" value="1" checked={status === true} onChange={(e) => { setStatus(true); }} />
                                <label className="form-check-label" htmlFor="published1">เผยแพร่</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="status" id="published2" value="0" checked={status === false} onChange={(e) => { setStatus(false); }} />
                                <label className="form-check-label" htmlFor="published2">ไม่เผยแพร่</label>
                            </div>
                            <SError error={errors["status"]} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
