/*eslint-disable*/
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { authenticationService } from 'services/authentication.service.js';
import { Link } from "react-router-dom";
import Clock from 'components/Clock';
import avatar from '../assets/images/icon/user.svg';
export default function Sidebar() {
  const language = localStorage.getItem("language");
  const currentUser = authenticationService.currentUserValue;
  const [menuList, setMenuList] = useState([]);
  const [userMenu, setUserMenu] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [activeMenuid, setActiveMenuID] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("menu");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  //console.log(process.env.REACT_APP_SITEURL)
  function getSubmenu(item, allmenu, level) {
    const childmenu = allmenu.filter(x => x.ParentID === item.MenuID);
    //console.log(childmenu)
    if (childmenu.length > 0) {
        for (let i = 0; i < childmenu.length; i++) {
            childmenu[i].submenu = getSubmenu(childmenu[i], allmenu, level + 1);
        }
        //console.log(childmenu)
        return childmenu;
    }
    else {
        return [];
    }
  }
  useEffect(() => {
    //console.log(activeMenuid);
    axios.get(process.env.REACT_APP_APIURL + '/api-web/getbackendsMenus').then((response) => {
      let data = response.data;
      let menu = [];
      for (let i = 0; i < data.length; i++) {
        if (Number(data[i].ParentID) === 0) {
          let mainmenu = data[i];
          mainmenu.submenu = getSubmenu(mainmenu, data, 0);
          //console.log(mainmenu)
          menu.push(mainmenu)
        }
      }
      setMenuList(menu);
    }).then(() => {
      if (currentUser !== null) {
        axios.post(process.env.REACT_APP_APIURL + '/api-web/getUserMenu', null, { params: { userID: currentUser.UserID } }).then((response) => {
          //console.log(response.data)
          setUserMenu(response.data);
        }).then(() => { setLoading(false) })
      }
    })

  }, []); /* <-- add this for run once*/
  function HasMenu(menuItem) {
    //console.log(userMenu)
    const result = userMenu.find((item) => {
      return Number(item.MenuID) === Number(menuItem.MenuID)
    })
    //console.log(result)
    if (typeof result === 'undefined')
      return false;
    return true;
  }
  function ActiveMenuIsChild(menuItem, id) {
    //console.log(menuItem)
    if (typeof menuItem.submenu === 'undefined')
      return false;
    const result = menuItem.submenu.find((item) => {
      return Number(item.MenuID) === Number(id)
    })
    //console.log(result)
    if (typeof result === 'undefined')
      return false;
      //console.log('xxxxx')
    return true;
  }
  function SetActiveMenu(id){
    setActiveMenuID(id);
    localStorage.setItem("menu", JSON.stringify(id));
  }
  const MenuList = () => {
    //console.log(isLoading)
    let menu = <></>;
    //console.log(menuList)
    for (let i = 0; i < menuList.length; i++) {
      let item = menuList[i];
      if (HasMenu(item)) {
        let haschild = false;
        if (item.submenu.length > 0)
          haschild = true;
        if (haschild) {
          let subdiv = (
            <li key={item.MenuID}>
              <a href="#" className={Number(activeMenuid)===Number(item.MenuID)?'active':''} onClick={(e)=>{SetActiveMenu(item.MenuID);}} data-toggle="collapse" data-target={"#submenu-" + item.MenuID}><i className={item.Icon}></i>{item.NameMenuTH} <i className="fa fa-fw fa-angle-down" style={{position:'absolute',right:-5,top:7}}></i></a>
              <ul id={"submenu-" + item.MenuID} className={ActiveMenuIsChild(item, activeMenuid)===true?"collapse show":"collapse"}>
                {item.submenu.map((submenu, index) => (
                  <li key={submenu.MenuID}><Link className={Number(activeMenuid)===Number(submenu.MenuID)?'active':''} to={{ pathname: submenu.Link, state: { language: language } }} onClick={(e)=>{SetActiveMenu(submenu.MenuID);}} >{/*<i className="fa fa-angle-double-right"></i>*/} {submenu.NameMenuTH}</Link></li>
                ))}
              </ul>
            </li>)
          menu = (<>{menu}{subdiv}
          </>)
        }
        else {
          let subdiv = (<li><Link className={Number(activeMenuid)===Number(item.MenuID)?'active':''} to={{ pathname: item.Link, state: { language: language } }} onClick={(e)=>{SetActiveMenu(item.MenuID);}} ><i className={item.Icon}></i> {item.NameMenuTH}</Link></li>)
          menu = (<>{menu}{subdiv}</>)
        }
      }
    }
    return menu;
  }
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <>
      <div className="collapse navbar-collapse navbar-ex1-collapse show">
        <ul className="nav navbar-nav side-nav">
          <li className="sidebar-avatar">
            <div className="avtar-content">
              <div className="avatar-circle"><img src={currentUser.Avatar===null?avatar:currentUser.Avatar.trim().length==0?avatar:currentUser.Avatar} alt="Avatar" /></div>
              <h3 style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}} title={currentUser.FirstnameTH+" "+currentUser.LastnameTH}>{currentUser.FirstnameTH+" "+currentUser.LastnameTH}</h3>
              <p>เทคโนโลยีสารสนเทศ</p>
              <Clock/>
            </div>
          </li>
          <li>
            <a href="/"><i className="fa fa-home"></i>  หน้าหลัก</a>
          </li>
          <MenuList/>          
        </ul>
      </div>
    </>
  );
}
