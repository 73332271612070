import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
//import { authenticationService } from 'services/authentication.service.js';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import utils from 'utils/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
// import ReactExport from 'react-data-export';
//import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
//const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const swal = withReactContent(Swal)

export default function ActivityMember(props) {
    //const currentUser = authenticationService.currentUserValue;
    const [gridApi, setGridApi] = useState(null);
    //const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [activityCat, setActivityCat] = useState([]);
    const [excelDataset, setExcelDataSet] = useState([]);
    const borders = {
        top: { style: "thin", color: { rgb: "FF000000" } },
        bottom: { style: "thin", color: { rgb: "FF000000" } },
        left: { style: "thin", color: { rgb: "FF000000" } },
        right: { style: "thin", color: { rgb: "FF000000" } }
    }
    const centerAlignment = {
        horizontal: "center",
        wrapText: true
    }
    const leftAlignment = {
        horizontal: "top",
        wrapText: true
    }
    function SetExcelData(data) {
        let multiDataset = [{ columns: [], data: [] }];
        multiDataset[0].columns = [
            { title: "ลำดับ", width: { wpx: '80' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
            { title: "กิจกรรม", width: { wpx: '160' }, style: { alignment: leftAlignment, border: borders, font: { bold: true } } },//pixels width 
            { title: "ชื่อ-นามสกุล", width: { wpx: '160' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//pixels width 
            { title: "ตำแหน่ง", width: { wpx: '160' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//char width 
            { title: "หน่วยงาน", width: { wpx: '160' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
            { title: "หมายเลขโทรศัพท์", width: { wpx: '160' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
            { title: "อีเมล", width: { wpx: '200' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
            { title: "สถานะ", width: { wpx: '100' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
            { title: "พิจารณาคุณสมบัติ", width: { wpx: '160' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
        ];
        multiDataset[0].data = [];
        data.map((item, i) => {
            let row = [];
            let col = { value: (i + 1), style: { alignment: centerAlignment, border: borders } };
            row.push(col);
            col = { value: item.FullName, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.Position === null ? '' : item.Position, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.Department, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.Telephone, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.Email, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.IsCanceled ? "ไม่เข้าร่วม" : "เข้าร่วม", style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            multiDataset[0].data.push(row);
            return item;
        });
        //console.log(multiDataset)
        setExcelDataSet(multiDataset)
    }
    useEffect(() => {
        //console.log(tmpmultiDataSet);
        axios.get(process.env.REACT_APP_APIURL + '/api-web/activityCat')
            .then((response) => {
                setActivityCat(response.data);
            });
    }, []); /* <-- add this for run once*/

    const handleDelete = (params) => {
        let timerInterval;
        swal.fire({
            title: "ยืนยันการลบข้อมูล",
            showDenyButton: true,
            /*showCancelButton: true,*/
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                swal.fire({
                    title: "ลบข้อมูลเรียบร้อย", showConfirmButton: false,
                    timer: 1000, timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    deleteSingleRow(params);
                })

            } else if (result.isDenied) {
                //swal.fire('Changes are not saved', '', 'info')
            }
        })
    };
    const handleDeleteAll = (params) => {
        let selectedNodes = gridApi.getSelectedNodes();
        if (selectedNodes.length > 0) {
            let timerInterval;
            swal.fire({
                title: "ยืนยันการลบข้อมูลทั้งหมดที่เลือก",
                showDenyButton: true,
                /*showCancelButton: true,*/
                confirmButtonText: "ยืนยัน",
                denyButtonText: "ยกเลิก",
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    swal.fire({
                        title: "ลบข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        deleteRowData();
                    })

                } else if (result.isDenied) {
                    //swal.fire('Changes are not saved', '', 'info')
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณาเลือกข้อมูลอย่างน้อยหนึ่งชุด',
            })
        }
    };
    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        const updateData = (data) => {
            setRowData(data);
        };

        axios.get(process.env.REACT_APP_APIURL + '/api-web/getMemberActivityEnroll').then((response) => {
            updateData(response.data);
            SetExcelData(response.data);
            //console.log(response.data)
        })

    };
    var checkboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
    };
    var headerCheckboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
    };
    const getRowHeight = (params) => {
        //console.log(params.data.rowHeight);
        //return params.data.rowHeight;
        return 80;
    };
    const statusRenderer = params => {
        if (params.value === true)
            return 'ไม่เข้าร่วม';
        return 'เข้าร่วม';
    };
    const approveRenderer = params => {
        if (params.value === 0)
            return 'ไม่อนุมัติคุณสมบัติ';
        else if (params.value === 1)
            return 'อนุมัติคุณสมบัติ';
        else
            return 'รอการพิจารณา';
    };
    const createYearCellRenderer = params => {
        if (params.value == null)
            return '<span style="line-height:0.75rem;"><p style="margin-top:16px;">' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p></span>';
        return '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' + utils.MariatoThaiDateString(params.data.CreatedDate) + '</p><p style="line-height:20px;margin-bottom: -4px;">' + params.value + '<p></span>';
    };
    const updateYearCellRenderer = params => {
        if (params.value == null)
            return '';
        return '<span style="line-height:0.75rem;"><p style="margin-top:16px;margin-bottom:4px;">' + utils.MariatoThaiDateString(params.data.UpdatedDate) + '</p><p style="line-height:20px;margin-bottom: -4px;">' + params.value + '<p></span>';
    };
    const RowTool = params => {
        let tool = null;
        tool = (
            <span className="row-tool">
                <button type="button" className="btn btn-primary btn-sm wd-40" onClick={() => { handleEdit(params); }}>แก้ไข</button>
                <button type="button" className="btn btn-danger btn-sm wd-40" onClick={() => { handleDelete(params); }} >ลบ</button>
            </span>
        );
        return tool;
    };
    var hashValueGetter = function (params) {
        return params.node.rowIndex + 1;
    };
    const onPageSizeChanged = (newPageSize) => {
        var value = document.getElementById('page-size').value;
        gridApi.paginationSetPageSize(Number(value));
    };
    const searchRef = React.createRef();
    const activityCatRef = React.createRef();
    const statusRef = React.createRef();

    const searchData = () => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchMemberActivity', { params: { search: searchRef.current.value, category: activityCatRef.current.value, status: statusRef.current.value } }).then((response) => {
            setRowData(response.data);
            SetExcelData(response.data);
        })
    };
    const resetsearch = () => {
        searchRef.current.value = "";
        statusRef.current.value = "-1";
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getMemberActivityEnroll')
            .then((response) => {
                setRowData(response.data);
                SetExcelData(response.data);
            })
    };

    const deleteSingleRow = params => {
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteActivityMember', null, { params: { id: params.data.EnID } })
            .then((response) => {
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getMemberActivityEnroll')
                    .then((response) => {
                        setRowData(response.data);
                        SetExcelData(response.data);
                    })
            })
    };
    const deleteRowData = () => {
        let selectedNodes = gridApi.getSelectedNodes();
        selectedNodes.map((row, i) => {
            axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteActivityMember', null, { params: { id: row.data.EnID } })
                .then((response) => {
                    //setRowData(response.data);
                })
            return row;
        });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getMemberActivityEnroll')
            .then((response) => {
                setRowData(response.data);
                SetExcelData(response.data);
            })
    };
    const handleCreate = () => {
        props.history.push("/admin/activity/NewActivityEnroll");
        //window.location.reload();
    };
    const handleEdit = params => {
        props.history.push({ pathname: "/admin/activity/EditActivityEnroll", state: { id: params.data.EnID } });
        //window.location.reload();
    };

    const ActivityCatSelect = activityCat.map((item) => (
        <option
            key={item.ActivityCatID}
            value={item.ActivityCatID}
        >
            {item.TitleTH}
        </option>
    ));
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<><span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการกิจกรรม</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/activity/ActivityMember" }}>{"จัดการรายชื่อผู้เข้าร่วมกิจกรรม"}</Link></>}
            />
            <div className="col-12">
                <form className="form-inline form-border">
                    <div className="form-group mx-sm mb-2 col-5 col-lg-4 pr-0 col-md-3">
                        <input type="text" ref={searchRef} className="form-control " id="searchfield" name="searchfield" placeholder="ค้นหาที่นี่" />
                    </div>
                    <div className="form-group mx-sm mb-2 col-3 pr-0 col-md-2 col-lg-3">
                        <select className="form-control" ref={activityCatRef}>
                            <option value="-1"> หมวดหมู่</option>
                            {ActivityCatSelect}
                        </select>
                    </div>
                    <div className="form-group mx-sm mb-2 col-2 pr-0">
                        <select className="form-control" ref={statusRef}>
                            <option value="-1">สถานะทั้งหมด</option>
                            <option value="0">เข้าร่วม</option>
                            <option value="1">ไม่เข้าร่วม</option>
                        </select>
                    </div>
                    <div className="button-group col-2">
                        <button type="button" className="btn btn-info mb-2 mr-2" onClick={e => { e.preventDefault(); searchData(); }}>ค้นหา</button>
                        <button type="submit" className="btn btn-light mb-2" onClick={e => { e.preventDefault(); resetsearch(); }}>ล้างคำค้นหา</button>
                    </div>
                </form>
            </div>
            <div className="col-12">
                <div className="form-border">
                    <div className="col-12">
                        <div className="form-inline button-group">
                            {/* <ExcelFile filename="Member Activity" element={<button type="button" className="btn btn-primary mb-2 mr-2 wd-80" ><i className="far fa-file-excel"></i> Export</button>}>
                                <ExcelSheet dataSet={excelDataset} name="Activities" />
                            </ExcelFile> */}
                            <button type="button" className="btn btn-success mb-2 mr-2 wd-150" onClick={e => { e.preventDefault(); handleCreate(); }}><i className="far fa-save"></i> ลงทะเบียน</button>
                            <button type="button" className="btn btn-secondary mb-2 wd-80" onClick={e => { e.preventDefault(); handleDeleteAll(); }}><i className="fas fa-trash-alt"></i> ลบ</button>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="ag-theme-alpine" style={{ height: 900 }}>
                            <AgGridReact
                                autoGroupColumnDef={{
                                    headerName: 'Group',
                                    minWidth: 30,
                                    field: 'EnID',
                                    valueGetter: function (params) {
                                        if (params.node.group) {
                                            return params.node.key;
                                        } else {
                                            return params.data[params.colDef.field];
                                        }
                                    },
                                    headerCheckboxSelection: true,
                                    cellRenderer: 'agGroupCellRenderer',
                                    cellRendererParams: { checkbox: true },
                                }}

                                modules={AllCommunityModules}
                                frameworkComponents={{
                                    rowTool: RowTool,
                                }}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    filter: false,
                                    //cellClass: "cell-border cell-vertical-align-text-left",
                                }}
                                getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                rowData={rowData}
                                rowSelection={'multiple'}
                                rowDragManaged={false}
                                pagination={true}
                                paginationPageSize={10}
                            >
                                <AgGridColumn
                                    field=""
                                    headerName=""
                                    width={50}
                                    checkboxSelection={checkboxSelection}
                                    headerCheckboxSelection={headerCheckboxSelection}
                                    sortable={true} resizable={false}
                                />
                                <AgGridColumn field="" valueGetter={hashValueGetter} headerName="ลำดับ" minWidth={100} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="TitleTH" headerName="กิจกรรม" sortable={true} minWidth={200} />
                                <AgGridColumn field="FullName" headerName="ชื่อ-นามสกุล" sortable={true} minWidth={160} />
                                <AgGridColumn field="Position" headerName="ตำแหน่ง" sortable={true} minWidth={160} />
                                <AgGridColumn field="Department" headerName="หน่วยงาน" sortable={true} minWidth={160} />
                                <AgGridColumn field="Telephone" headerName="หมายเลขโทรศัพท์" sortable={true} minWidth={160} />
                                <AgGridColumn field="Email" headerName="อีเมล" sortable={true} minWidth={200} />
                                <AgGridColumn field="IsCanceled" headerName="สถานะ" cellRenderer={statusRenderer} sortable={true} minWidth={100} />
                                <AgGridColumn field="Approve" headerName="การอนุมัติ" cellRenderer={approveRenderer} sortable={true} minWidth={100} />
                                <AgGridColumn field="CreateFullname" headerName="ผู้สร้าง" cellRenderer={createYearCellRenderer} minWidth={210} wrapText={true} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="UpdateFullname" headerName="ผู้แก้ไข" cellRenderer={updateYearCellRenderer} minWidth={210} wrapText={true} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="EnID" headerName="จัดการ" cellRenderer="rowTool" minWidth={150} sortable={false} filter={false} resizable={false} />
                            </AgGridReact>
                            <div className="page-size formContent">
                                <p>Page size:</p>
                                <select className="custom-select" defaultValue={10} onChange={() => onPageSizeChanged()} id="page-size">
                                    <option value="10" >10</option>
                                    <option value="100">25</option>
                                    <option value="500">50</option>
                                    <option value="1000">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
