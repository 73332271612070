import React, { useState,useEffect,ReactDOM } from 'react'
import { createPortal } from 'react-dom'

export default function PDFFlipIframe({ children, title, ...props }) {
  const [contentRef, setContentRef] = useState(null)
  const mountNode =
    contentRef?.contentWindow?.document?.body

  return (
    <iframe title={title} {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  )
}