import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import { authenticationService } from 'services/authentication.service.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import docicon from 'assets/images/icon/docicon.svg';
import Dropzone from "react-dropzone";
import utils from "pizzip/utils";
import SingleFileTable from 'components/SingleFileTable';
import myutils from 'utils/utils'
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function HRCPO(props) {
    
    const language = localStorage.getItem("language");
    const history = useHistory();
    const currentUser = authenticationService.currentUserValue;
    const [count, setCount] = useState(props.count);
    const [networkList, setNetworkList] = useState([]);
    const [viewMode, setViewMode] = useState({ mode: 'normal', item: null });
    const [viewModePro, setViewModePro] = useState({ mode: 'normal', item: null });
    //const newValue = count + 1;
    //
    if (viewMode.mode !== 'normal'||viewModePro.mode !== 'normal') {
        if(count !== props.count)
        setCount(props.count);
    }
    //console.log(count);
    useEffect(() => {
        //setCount();
        axios.get(process.env.REACT_APP_APIURL + '/api-web/userNetwork', { params: { userID: currentUser.UserID } }).then((response) => {
            let data = response.data;
            setNetworkList(data)
            if (data.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: language === 'Thai' ? 'แจ้งให้ทราบ' : 'Information',
                    text: language === "Thai" ? " ไม่พบข้อมูล " : " No information found ",
                })
            }
            //console.log(data)
        })

    }, []); /* <-- add this for run once*/
    useEffect(() => {
        setViewMode({ mode: props.mode, item: null });
        setViewModePro({ mode: props.mode, item: null });
    }, [count]);

    function CheckAppealDate(updateDate, networkID) {
        let now = new Date(Date.now());
        let endDate = moment(updateDate, 'YYYY-MM-DD').add(30, 'days');
        if (moment(now, 'YYYY-MM-DD') > endDate) {
            //console.log('xxxxxx')
            return (<span className="text-danger float-right" style={{ lineHeight: 50 + 'px', fontWeight: 'bold' }}>{language === "Thai" ? "เลยกำหนดการยื่นอุทธรณ์" : "Timeout for appeal"}</span>)
        }
        else {
            return (<Link to={{ pathname: "/authen/network/NetworkAppeal", state: { networkID: networkID } }} className="networkbtn" >{language === "Thai" ? "อุทธรณ์ผลการพิจารณา" : "Appeal"}</Link>)
        }
    }
    const getExpireDate = (item) => {
        let endDate = moment(item.MemberExpiredDate, 'YYYY-MM-DD').toDate();        
        return (<span style={{color:'#F009',fontWeight:800}}>{myutils.toThaiDateString(endDate, true)}</span>)
    }
    const getLastActionDate = (item) => {
        let lastaction = item.ActionList[0];
        if (lastaction !== null) {
            let actionDate = moment(lastaction.ActionDate, 'YYYY-MM-DD').toDate();
            return (<span style={{ color: '#F009', fontWeight: 800 }}>{myutils.toThaiDateString(actionDate, true)}</span>)
        }

    }
    const getLastActionRemark = (item)=> {
        let lastaction = item.ActionList[0];
        if (lastaction !== null) {
            let actionDate = moment(lastaction.ActionDate, 'YYYY-MM-DD').toDate();
            return (<div className="col-12" style={{ marginLeft:'5px',color: '#F009', fontWeight: 800 }}>{lastaction.Remark}</div>)
        }

    }
    const Resubmit = (item)=>{
        console.log(item)
        if(item.NetType=== "ORG")
        {
            history.push({
                pathname: '/authen/network/CoorperationRegister',
                state: { language: language, netType: "ORG" }
              })
        }
        else{
            history.push({
                pathname: '/authen/network/CoorperationRegister',
                state: { language: language, netType: "PROCOUN" }
              })
        }
    }
    function isShowRenew(item){
        let endDate = moment(item.MemberExpiredDate, 'YYYY-MM-DD').toDate();
        let now = new Date();
        let dateDiff = Math.ceil(Math.abs(now - endDate) / (1000 * 60 * 60 * 24));
        if (dateDiff >= 0 && dateDiff <= 60)
            return true;
        return false;
    }
    function Terminate(item) {
        swal.fire({
            icon: 'warning',
            title: "ยืนยันการยุติ!",
            html: "<p style='text-align: left;font-size: 25px;'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อท่านกดปุ่มยืนยัน สถานะภาพขององค์กรเอกชนด้าน สิทธิมนุษยชนและสภาวิชาชีพของท่าน<span style='color:#B00'> จะสิ้นสุดโดยทันที</sapn></p>",
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
            
            const formData = new FormData();
            formData.append("id", item.NetworkID);
            formData.append("userID", currentUser.UserID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/userTerminate', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลการขอยุติสถานภาพเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
    
                        }
                    }).then((result) => {
                        window.location.reload();
                    })
                });
                //console.log('xxxxx')
            }
        });
       
    }
    const getUploadFileData = (needDocName, item) => {
        //console.log(item)
        const result = item.Attatchment.find((filelist) => {
            return filelist.OptionName == needDocName;
        })
        if (typeof result !== 'undefined') {
            return (<div className="col-12"><Link to={{ pathname: result.AttachmentPath }} target="_blank" rel="noopener noreferrer">{"ไฟล์ "+needDocName+" ถูกอัปโหลดแล้ว"}</Link></div>);
            //return <div style={{color:'#2196f3', fontWeight:'800'}}>{needDocName}</div>
        }
        return (<div className="col-12" style={{color:'#ff5722', fontWeight:'800'}}>ไม่พบข้อมูลการอัปโหลดไฟล์</div>)
    }
    const handleUnCompleteDocFilesOnDrop = (newFile, needDocName, item) => {
        /*const dataFile = {
            file: newFile[0],
            name: newFile[0].name,
            preview: URL.createObjectURL(newFile[0]),
            size: newFile[0].size
        };
        SetUnCompleteDocFiles(dataFile);*/
        const formData = new FormData();
        formData.append("networkid", item.NetworkID);
        formData.append("needdocfile", newFile[0]);
        formData.append("needDocName", needDocName);
        formData.append("userID", currentUser.UserID);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/uploadneedDocFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                let timerInterval;
                swal.fire({
                    title: "อัปโหลดข้อมูลเรียบร้อย", showConfirmButton: false,
                    timer: 1000, timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    /*if (open) {
                        window.location.reload();
                    }
                    else {
                        if ((netType === 'ORG')) {
                            props.history.push({ pathname: "/admin/network/NetworkORGList" });
                        }
                        else if (netType === 'PROCOUN') {
                            props.history.push({ pathname: "/admin/network/NetworkPROCOUNList" });
                        }
                    }*/
                })
            });

    };

    const OrgNetworkList = (<>
        {(viewMode.mode === 'normal') && networkList.map((item, i) => (
            (item.NetType === 'ORG') && (<li key={i}>
                <div className="card" style={{ height: 'auto' }}>
                    <div className="card-body">
                        <div className="hrcpo-content">
                            <h4 className="card-content-title">{item.NetOrganization}</h4>
                            {item.State === 'WAITING' && (<span className="info"><i className="fas fa-spinner" aria-hidden="true"></i><p>{language === "Thai" ? "กำลังพิจารณา" : "In Progress"}</p></span>)}
                            {item.State === 'DECLINE' && (<><span className="danger"><i className="fas fa-times-circle" aria-hidden="true"></i><p>{language === "Thai" ? "ไม่รับพิจารณาคำขอ" : "Request not accepted"}</p></span>{getLastActionRemark(item)}</>)}
                            {item.State === 'TERMINATE' && (<span className="danger"><i className="fas fa-times-circle" aria-hidden="true"></i><p>{language === "Thai" ? "ยุติ" : "Terminate"}</p></span>)}
                            {item.State === 'COMPLETE_DOC' && (<span className="success"><i className="fas fa-check-circle" aria-hidden="true"></i><p>{language === "Thai" ? "เอกสารครบถ้วน" : "Complete documents"}<br />อยู่ระหว่างการพิจารณาคำขอภายใน 30 วัน <br />
                                เมื่อสานักงาน กสม. พิจารณาแล้วเสร็จ แจ้งผลภายใน 7 วันทำการ</p></span>)}
                            {item.State === 'NEED_DOC' && (<span className="info"><i className="far fa-file-alt" aria-hidden="true"></i><p>{language === "Thai" ? "เอกสารไม่ครบถ้วน" : "Incomplete documents"}</p></span>)}
                            {item.State === 'NOT_CERTIFY' && (<span className="danger"><i className="fas fa-times-circle" aria-hidden="true"></i><p>{language === "Thai" ? "ไม่ให้การรับรอง" : "Disapprove"}</p></span>)}
                            {item.State === 'CERTIFY' && (<span className="success"><i className="fas fa-check-circle" aria-hidden="true"></i><p>{language === "Thai" ? "ให้การรับรอง" : "Approve"}</p></span>)}
                            {item.State === 'REVOKE' && (<span className="danger"><i className="fas fa-times-circle" aria-hidden="true"></i><p>{language === "Thai" ? "เพิกถอน" : "Revoke"}</p></span>)}
                        </div>
                        {item.State === 'DECLINE' && (
                            <div className="buttonlist">
                                <Link to={{ pathname: "/authen/network/NetworkList" }} onClick={(e) => { e.preventDefault(); Resubmit(item) }} className="networkbtn" >{language === "Thai" ? "ยื่นใหม่" : "Resubmit"}</Link>
                            </div>)}
                        {item.State === 'COMPLETE_DOC' && (
                            <div className="buttonlist">
                                <div className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewMode({ mode: 'viewCompleteDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</div>
                            </div>)}
                        {item.State === 'NEED_DOC' && (
                            <div className="buttonlist">
                                <div className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewMode({ mode: 'viewNeedDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</div>
                                <div className="networkbtn" onClick={(e) => { e.preventDefault(); setViewMode({ mode: 'viewNeedDocUploadFile', item: item }) }}>{language === "Thai" ? "แนบไฟล์เพิ่ม" : "Attach more files"}</div>
                            </div>)}
                        {item.State === 'NOT_CERTIFY' && (
                            <div className="buttonlist">
                                <Link to={{ pathname: item.NotCertifyResultFilePath }} className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewMode({ mode: 'viewNotCertifyDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</Link>
                                {CheckAppealDate(item.ActionDate, item.NetworkID)}
                            </div>)}
                        {(item.State === 'CERTIFY' || item.State === 'RENEW') && (
                            <div className="buttonlist">
                                <div className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewMode({ mode: 'viewCertDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</div>
                                <div className="networkbtn darkblue" onClick={(e) => { window.open('/authen/network/AddNetworkActivity');localStorage.setItem("networkID", item.NetworkID); }}>{language === "Thai" ? "เพิ่มผลงาน" : "Add Activity"}</div>
                                {(isShowRenew(item))&&(<div className="networkbtn gray" onClick={(e) => { window.open('/authen/network/RenewNetwork');localStorage.setItem("networkID", item.NetworkID); }}>{language === "Thai" ? "ต่ออายุ" : "Renew"}</div>)}
                                <div className="networkbtn red" onClick={(e) => { e.preventDefault(); Terminate(item) }}>{language === "Thai" ? "ยุติ" : "Terminate"}</div>

                            </div>)}
                        {item.State === 'REVOKE' && (
                            <div className="buttonlist">                                
                                <div className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewMode({ mode: 'viewRevokeDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</div>
                                {
                                    CheckAppealDate(item.ActionDate, item.NetworkID)
                                }
                            </div>)}
                    </div>
                </div>
            </li>)
        ))        
        }
        {
            (viewMode.mode === 'viewCertDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. ประกาศรับจดแจ้ง</span><Link to={{ pathname: viewMode.item.MemberAnnounceFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
                <li><img src={docicon} className="mr-2 bookmarkbutton" />2. หนังสือแจ้งผล<Link to={{ pathname: viewMode.item.MemberResultFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
                <li style={{ marginTop: '50px' }}>1) ได้รับการรับรองการจดแจ้งองค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ มีอายุคราวละ 5 ปี และหมดอายุในวันที่ {getExpireDate(viewMode.item)}</li>
                <li>2) ท่านอาจถูกเพิกถอนการจดแจ้งได้ด้วยเหตุใดเหตุหนึ่งตาม ข้อ 15 แห่งระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไขการจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ</li>
                <li>3) ทั้งนี้ท่านต้องส่งรายงานประจำปี ทุกสิ้นปีเพื่อรักษาสภาพองค์กรที่ได้รับจดแจ้ง</li>
            </ul>)
        }
        {
            (viewMode.mode === 'viewCompleteDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. หนังสือแจ้งผล</span><Link to={{ pathname: viewMode.item.CompleteDocFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
            </ul>)
        }
        {
            (viewMode.mode === 'viewNeedDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. หนังสือแจ้งผล</span><Link to={{ pathname: viewMode.item.UncompleteDocFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
            </ul>)
        }
        {
            ((viewMode.mode === 'viewNeedDocUploadFile') && (viewMode.item.ActionList.length > 0))&& viewMode.item.ActionList[0].needDoc.map((doc, i)=>(
            <div key={"DropZone-needDoc"+i}>                
                <span>{(i+1)+". "+doc}</span>{getUploadFileData(doc,viewMode.item)}
                <div className="col-12">
                    <Dropzone
                        className="input-dropzone dropzone"
                        onDrop={acceptedFiles => handleUnCompleteDocFilesOnDrop(acceptedFiles, doc,viewMode.item)}
                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div
                                    {...getRootProps({ className: "dropzone" })}
                                >
                                    <input {...getInputProps()} />
                                    <p>
                                        ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                    </p>
                                </div>
                              </section>
                        )}
                    </Dropzone>
                </div>                
            </div>))
        }
        {
            (viewMode.mode === 'viewNotCertifyDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. หนังสือแจ้งผล</span><Link to={{ pathname: viewMode.item.NotCertifyResultFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
                <li style={{ marginTop: '50px' }}><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ไม่ได้รับการรับรองการจดแจ้งองค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ
เนื่องจากหน่วยงานของท่านมีคุณสมบัติและหลักเกณฑ์ไม่เป็นไปตาม ข้อ 7 แห่งระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไขการจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ พ.ศ. 2561 และที่แก้ไขเพิ่มเติม
ทั้งนี้ท่านสามารถอุทธรณ์ผลการพิจารณาได้ ภายใน 30 วัน นับตั้งแต่วันที่ได้รับข้อความนี้ คือ {getLastActionDate(viewMode.item)}</p></li>
            </ul>)
        }
        {
            (viewMode.mode === 'viewRevokeDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. หนังสือแจ้งผล</span><Link to={{ pathname: viewMode.item.RevokeFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
            </ul>)
        }
    </>);

    const PRONetworkList = (<>
        {(viewModePro.mode === 'normal') && networkList.map((item, i) => (
            (item.NetType === 'PROCOUN') && (<li key={i}>
                <div className="card" style={{ height: 'auto' }}>
                    <div className="card-body">
                        <div className="hrcpo-content">
                            <h4 className="card-content-title">{item.NetOrganization}</h4>
                            {item.State === 'WAITING' && (<span className="info"><i className="fas fa-spinner" aria-hidden="true"></i><p>{language === "Thai" ? "กำลังพิจารณา" : "In Progress"}</p></span>)}
                            {item.State === 'DECLINE' && (<><span className="danger"><i className="fas fa-times-circle" aria-hidden="true"></i><p>{language === "Thai" ? "ไม่รับพิจารณาคำขอ" : "Request not accepted"}</p></span>{getLastActionRemark(item)}</>)}
                            {item.State === 'TERMINATE' && (<span className="danger"><i className="fas fa-times-circle" aria-hidden="true"></i><p>{language === "Thai" ? "ยุติ" : "Terminate"}</p></span>)}
                            {item.State === 'COMPLETE_DOC' && (<span className="success"><i className="fas fa-check-circle" aria-hidden="true"></i><p>{language === "Thai" ? "เอกสารครบถ้วน" : "Complete documents"}<br />อยู่ระหว่างการพิจารณาคำขอภายใน 30 วัน <br />
                                เมื่อสานักงาน กสม. พิจารณาแล้วเสร็จ แจ้งผลภายใน 7 วันทำการ</p></span>)}
                            {item.State === 'NEED_DOC' && (<span className="info"><i className="far fa-file-alt" aria-hidden="true"></i><p>{language === "Thai" ? "เอกสารไม่ครบถ้วน" : "Incomplete documents"}</p></span>)}
                            {item.State === 'NOT_CERTIFY' && (<span className="danger"><i className="fas fa-times-circle" aria-hidden="true"></i><p>{language === "Thai" ? "ไม่ให้การรับรอง" : "Disapprove"}</p></span>)}
                            {item.State === 'CERTIFY' && (<span className="success"><i className="fas fa-check-circle" aria-hidden="true"></i><p>{language === "Thai" ? "ให้การรับรอง" : "Approve"}</p></span>)}
                            {item.State === 'REVOKE' && (<span className="danger"><i className="fas fa-times-circle" aria-hidden="true"></i><p>{language === "Thai" ? "เพิกถอน" : "Revoke"}</p></span>)}
                        </div>
                        {item.State === 'DECLINE' && (
                            <div className="buttonlist">
                                <Link to={{ pathname: "/authen/network/NetworkList" }} onClick={(e) => { e.preventDefault(); Resubmit(item) }} className="networkbtn" >{language === "Thai" ? "ยื่นใหม่" : "Resubmit"}</Link>
                            </div>)}
                        {item.State === 'COMPLETE_DOC' && (
                            <div className="buttonlist">
                                <div className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewModePro({ mode: 'viewCompleteDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</div>
                            </div>)}
                        {item.State === 'NEED_DOC' && (
                            <div className="buttonlist">
                                <div className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewModePro({ mode: 'viewNeedDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</div>
                                <div className="networkbtn" onClick={(e) => { e.preventDefault(); setViewModePro({ mode: 'viewNeedDocUploadFile', item: item }) }}>{language === "Thai" ? "แนบไฟล์เพิ่ม" : "Attach more files"}</div>
                            </div>)}
                        {item.State === 'NOT_CERTIFY' && (
                            <div className="buttonlist">
                                <Link to={{ pathname: item.NotCertifyResultFilePath }} className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewModePro({ mode: 'viewNotCertifyDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</Link>
                                {CheckAppealDate(item.ActionDate, item.NetworkID)}
                            </div>)}
                        {(item.State === 'CERTIFY' || item.State === 'RENEW') && (
                            <div className="buttonlist">
                                <div className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewModePro({ mode: 'viewCertDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</div>
                                <div className="networkbtn darkblue" onClick={(e) => { window.open('/authen/network/AddNetworkActivity');localStorage.setItem("networkID", item.NetworkID); }}>{language === "Thai" ? "เพิ่มผลงาน" : "Add Activity"}</div>
                                {(isShowRenew(item))&&(<div className="networkbtn gray" onClick={(e) => { window.open('/authen/network/RenewNetwork');localStorage.setItem("networkID", item.NetworkID); }}>{language === "Thai" ? "ต่ออายุ" : "Renew"}</div>)}
                                <div className="networkbtn red" onClick={(e) => { e.preventDefault(); Terminate(item) }}>{language === "Thai" ? "ยุติ" : "Terminate"}</div>

                            </div>)}
                        {item.State === 'REVOKE' && (
                            <div className="buttonlist">                                
                                <div className="networkbtn blue" onClick={(e) => { e.preventDefault(); setViewModePro({ mode: 'viewRevokeDoc', item: item }) }}>{language === "Thai" ? "ดูเอกสาร" : "View Document"}</div>
                                {
                                    CheckAppealDate(item.ActionDate, item.NetworkID)
                                }
                            </div>)}
                    </div>
                </div>
            </li>)
        ))        
        }
        {
            (viewModePro.mode === 'viewCertDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. ประกาศรับจดแจ้ง</span><Link to={{ pathname: viewModePro.item.MemberAnnounceFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
                <li><img src={docicon} className="mr-2 bookmarkbutton" />2. หนังสือแจ้งผล<Link to={{ pathname: viewModePro.item.MemberResultFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
                <li style={{ marginTop: '50px' }}>1) ได้รับการรับรองการจดแจ้งองค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ มีอายุคราวละ 5 ปี และหมดอายุในวันที่ {getExpireDate(viewModePro.item)}</li>
                <li>2) ท่านอาจถูกเพิกถอนการจดแจ้งได้ด้วยเหตุใดเหตุหนึ่งตาม ข้อ 15 แห่งระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไขการจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ</li>
                <li>3) ทั้งนี้ท่านต้องส่งรายงานประจำปี ทุกสิ้นปีเพื่อรักษาสภาพองค์กรที่ได้รับจดแจ้ง</li>
            </ul>)
        }
        {
            (viewModePro.mode === 'viewCompleteDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. หนังสือแจ้งผล</span><Link to={{ pathname: viewModePro.item.CompleteDocFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
            </ul>)
        }
        {
            (viewModePro.mode === 'viewNeedDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. หนังสือแจ้งผล</span><Link to={{ pathname: viewModePro.item.UncompleteDocFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
            </ul>)
        }
        {
            ((viewModePro.mode === 'viewNeedDocUploadFile') && (viewModePro.item.ActionList.length > 0))&& viewModePro.item.ActionList[0].needDoc.map((doc, i)=>(
            <div key={"DropZone-needDoc"+i}>                
                <span>{(i+1)+". "+doc}</span>{getUploadFileData(doc,viewModePro.item)}
                <div className="col-12">
                    <Dropzone
                        className="input-dropzone dropzone"
                        onDrop={acceptedFiles => handleUnCompleteDocFilesOnDrop(acceptedFiles, doc,viewModePro.item)}
                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div
                                    {...getRootProps({ className: "dropzone" })}
                                >
                                    <input {...getInputProps()} />
                                    <p>
                                        ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                    </p>
                                </div>
                              </section>
                        )}
                    </Dropzone>
                </div>                
            </div>))
        }
        {
            (viewModePro.mode === 'viewNotCertifyDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. หนังสือแจ้งผล</span><Link to={{ pathname: viewModePro.item.NotCertifyResultFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
                <li style={{ marginTop: '50px' }}><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ไม่ได้รับการรับรองการจดแจ้งองค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ
เนื่องจากหน่วยงานของท่านมีคุณสมบัติและหลักเกณฑ์ไม่เป็นไปตาม ข้อ 7 แห่งระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์ วิธีการ และเงื่อนไขการจดแจ้ง และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ พ.ศ. 2561 และที่แก้ไขเพิ่มเติม
ทั้งนี้ท่านสามารถอุทธรณ์ผลการพิจารณาได้ ภายใน 30 วัน นับตั้งแต่วันที่ได้รับข้อความนี้ คือ {getLastActionDate(viewModePro.item)}</p></li>
            </ul>)
        }
        {
            (viewModePro.mode === 'viewRevokeDoc') && (<ul className="network-list">
                <li><img src={docicon} className="mr-2 bookmarkbutton" /><span>1. หนังสือแจ้งผล</span><Link to={{ pathname: viewModePro.item.RevokeFilePath }} className="networkbtn darkblue float-right" target="_blank" rel="noopener noreferrer">{language === "Thai" ? "ดาวน์โหลด" : "Download"}</Link></li>
            </ul>)
        }
    </>);
    return (
        <div id="hrcpo-list" className="row" >
            <div className="col-12">
                <ul className="tab nav">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#now-hrcpo">{language === "Thai" ? "องค์กรเอกชน" : "Organization"}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#old-hrcpo">{language === "Thai" ? "สภาวิชาชีพ" : "Professional Council"}</a>
                    </li>
                </ul>
                <div className="tab tab-content">
                    <div className="tab-pane active" id="now-hrcpo">
                        <ul className="hrcpo-list">
                            {OrgNetworkList}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="old-hrcpo">
                        <ul className="hrcpo-list">
                        {PRONetworkList}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}