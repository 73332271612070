// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonNetwork {
    margin-left: 0;
    margin-right: 0;
}

.PersonNetwork .content-data {
    /*padding: 0 68px;*/
  max-width: 850px;
  margin: 0 auto;
}

.PersonNetwork .someInput {
    padding: 0 18px !important;
    text-align: center !important;
}

.PersonNetwork h2.thai-header {
    font-size: 40px !important;
}

.SearchNetwork {
    margin-left: 0;
    margin-right: 0;
    padding-left: 26px;
    padding-right: 26px;
}

.SearchMOU {
    padding-left: 26px;
    padding-right: 26px;
}

.CoorperationRegister .container.file {
    padding: 0;
}

.ViewBestPerson {
    font-size: 25px;
    margin-left: 0;
    margin-right: 0;
}
.ViewBestPerson .subheader {
    padding: 0 68px;
}
.ViewBestPerson .text-title {
    font-size: 30px;
    padding-left: 32px;
}

.ViewBestPerson .logo-img {
    display: flex;
    padding-left: 95px;
}
.ViewBestPerson .detail.text {
    padding: 0 68px;
    width: 100%;
}

.ViewBestPerson .tab-pane {
    padding: 0 68px;
}

@media screen and (max-width: 991px) {
    .ViewBestPerson .logo-img {
        padding-left: unset;
    }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/custompage-network.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,mBAAmB;EACrB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,cAAc;IACd,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".PersonNetwork {\n    margin-left: 0;\n    margin-right: 0;\n}\n\n.PersonNetwork .content-data {\n    /*padding: 0 68px;*/\n  max-width: 850px;\n  margin: 0 auto;\n}\n\n.PersonNetwork .someInput {\n    padding: 0 18px !important;\n    text-align: center !important;\n}\n\n.PersonNetwork h2.thai-header {\n    font-size: 40px !important;\n}\n\n.SearchNetwork {\n    margin-left: 0;\n    margin-right: 0;\n    padding-left: 26px;\n    padding-right: 26px;\n}\n\n.SearchMOU {\n    padding-left: 26px;\n    padding-right: 26px;\n}\n\n.CoorperationRegister .container.file {\n    padding: 0;\n}\n\n.ViewBestPerson {\n    font-size: 25px;\n    margin-left: 0;\n    margin-right: 0;\n}\n.ViewBestPerson .subheader {\n    padding: 0 68px;\n}\n.ViewBestPerson .text-title {\n    font-size: 30px;\n    padding-left: 32px;\n}\n\n.ViewBestPerson .logo-img {\n    display: flex;\n    padding-left: 95px;\n}\n.ViewBestPerson .detail.text {\n    padding: 0 68px;\n    width: 100%;\n}\n\n.ViewBestPerson .tab-pane {\n    padding: 0 68px;\n}\n\n@media screen and (max-width: 991px) {\n    .ViewBestPerson .logo-img {\n        padding-left: unset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
