import {NavLink} from "react-router-dom";
import {PiBellThin, PiBooksThin, PiHouseThin, PiPhoneThin, PiUserThin} from "react-icons/pi";
import {authenticationService} from "../services/authentication.service";

export default function MobileFooter() {
  const language = localStorage.getItem("language");
  const currentUser = authenticationService.currentUserValue;

  const navHeight = 80;
  const navClassNames = 'd-flex flex-column justify-content-center align-items-center';
  const navStyle = {
    color: '#fff',
    fontSize: '14px',
    // fontWeight: 'bold',
    textDecoration: 'none',
    letterSpacing: '.06em',
    width: '65px',
    height: '100%',
    lineHeight: '1'
  };
  const navActiveStyle = {
    color: '#fbb617',
  }
  const labelStyle = {
    height: '1em',
    textAlign: 'center',
  }

  return (
    <>
      <div style={{height: `${navHeight}px`}}></div>
      <div
        className="position-fixed bg-darkblue px-3 overflow-hidden"
        style={{
          bottom: 0,
          left: 0,
          right: 0,
          paddingBottom: 'env(safe-area-inset-bottom)',
          backgroundColor: '#013f87',
          borderTopLeftRadius: '32px',
          boxShadow: '0px 0px 0px 1px rgba(255,255,255,0.45)',
          zIndex: 10
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{height: `${navHeight}px`}}
        >
          <NavLink
            to="/"
            className={navClassNames}
            style={navStyle}
            activeStyle={navActiveStyle}
            exact
          >
            <PiHouseThin size={32}/>
            <div style={labelStyle}>{language === 'Thai' ? 'หน้าหลัก' : 'Home'}</div>
          </NavLink>
          {currentUser ? (
            <>
              <NavLink
                to="/authen/Notifications"
                className={navClassNames}
                style={navStyle}
                activeStyle={navActiveStyle}
              >
                <PiBellThin size={32}/>
                <div style={labelStyle}>{language === 'Thai' ? 'แจ้งเตือน' : 'Notification'}</div>
              </NavLink>
              <NavLink
                to="/authen/Profile"
                className={navClassNames}
                style={navStyle}
                activeStyle={navActiveStyle}
              >
                <PiUserThin size={32}/>
                <div style={labelStyle}>{language === 'Thai' ? 'โปรไฟล์' : 'Profile'}</div>
              </NavLink>
            </>
          ) : (
            <NavLink
              to={{
                pathname: "/authen/login",
                state: {language: language}
              }}

              className={navClassNames}
              style={navStyle}
              activeStyle={navActiveStyle}
              isActive={(match, location) => {
                return [
                  '/authen/login',
                  '/authen/Register',
                  '/authen/ForgotPassword',
                ].includes(location.pathname)
                // return !!location.pathname.startsWith('/authen');
              }}
            >
              <PiUserThin size={32}/>
              <div style={labelStyle}>{language === 'Thai' ? 'เข้าใช้งาน/สมัครสมาชิก' : 'Login/Register'}</div>
            </NavLink>
          )}
          {/*<NavLink
            to="/menu"
            className={navClassNames}
            style={navStyle}
            activeStyle={navActiveStyle}
          >
            <PiListThin size={32}/>
            <div style={labelStyle}>{language === 'Thai' ? 'เมนูเพิ่มเติม' : 'Other Menu'}</div>
          </NavLink>*/}
          <NavLink
            to="/authen/elearning/ELearning"
            className={navClassNames}
            style={navStyle}
            activeStyle={navActiveStyle}
          >
            <PiBooksThin size={32}/>
            <div style={labelStyle}>{language === 'Thai' ? 'อีเลิร์นนิง' : 'E-learning'}</div>
          </NavLink>
          <a
            id="mobile-tel-link"
            href="tel:1377"
            className={navClassNames}
            style={{
              ...navStyle,
              color: '#013f87',
              fontSize: '20px',
              fontWeight: 'bold',
              zIndex: 1,
            }}
          >
            <PiPhoneThin size={28}/>
            <div style={labelStyle}>1377</div>
          </a>
        </div>
      </div>
    </>
  )
}
