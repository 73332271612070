import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import utils from "utils/utils";
import wordIcon from "assets/images/icon/word.svg";
import excelIcon from "assets/images/icon/excel.svg";
import pptIcon from "assets/images/icon/ppt.svg";
import pdfIcon from "assets/images/icon/pdf.svg";
import zipIcon from "assets/images/icon/zip.svg";
import unknowIcon from "assets/images/icon/unknowfile.svg";

export default function AttatchmentList(props) {
  //const [isLoading, setLoading] = useState(true);
  //console.log(props);
  /*if(typeof props.fileList !== "undefined")
    setLoading(false)*/
  /*if(props.fileList!=null)
    setLoading(false)*/
  /*const fileListData = useMemo(() => {
        setLoading(false)
        return props.fileList;
    }, []);*/

  /*if (isLoading) {
        return (<></>);
    }*/
    const [attatchCount, setAttachCount] = useState(0);
    useEffect(() => {
      const vdolist = props.fileList.filter((member) => {
        return member.AttachmentType !== "VDO" && member.AttachmentType !== "IMG"
      })
      setAttachCount(vdolist.length);
    }, []);
  const FileList = props.fileList?.map(
    (fileItem) =>
      fileItem.AttachmentType !== "VDO" &&
      fileItem.AttachmentType !== "IMG" && (
        <div className="cardItem" key={fileItem.AttachmentID}>
          <div>
            {" "}
            <img
              src={
                fileItem.AttachmentType === "PDF"
                  ? pdfIcon
                  : fileItem.AttachmentType === "ZIP"
                    ? zipIcon
                    : fileItem.AttachmentType === "WORD"
                      ? wordIcon
                      : fileItem.AttachmentType === "PPT"
                        ? pptIcon
                        : fileItem.AttachmentType === "XLS"
                          ? excelIcon
                          : unknowIcon
              }
              className="icon"
              alt="file icon"
            />
            <span className="file-name">{fileItem.FilenameOld}</span>
          </div>
          <div className="mt-2 mb-2 float-right">
            <span className="time-ago">
              <i className="far fa-clock" />
              <p> {utils.MariatoThaiDateStringShort(fileItem.CreatedDate)}</p>
            </span>
            |{" "}
            <a
              href={fileItem.AttachmentPath}
              className="view-download text-gray"
              target={"_blank"}
            >
              <i className="fas fa-download" />
              {/* <p className="text-gray"> </p> */}
            </a>
          </div>
        </div>
      )
  );
  return (
    <div className="cardOut">
      <div className="cardIn">{attatchCount==0?'ไม่พบข้อมูล':FileList}</div>
    </div>
  );
}
