import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
const swal = withReactContent(Swal)
export default function Bookmark(props) {
    const currentUser = authenticationService.currentUserValue;
    const language = localStorage.getItem("language");
    const [bookmark, setBookmark] = useState([]);
    const history = useHistory();
    useEffect(() => {
        const formData = new FormData();
        formData.append("userID", currentUser.UserID);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/userBookmark', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                setBookmark(response.data)
                if (response.data.length === 0) {
                    Swal.fire({
                        icon: 'info',
                        title: language==='Thai'?'แจ้งให้ทราบ':'Information',
                        text: language === "Thai" ? " ไม่พบข้อมูล " : " No information found ",
                    })
                }
                //console.log(response.data)
            });           
    }, []); /* <-- add this for run once*/
    const readData = (id) => {
        history.push({ pathname: "/authen/elibrary/Read/" + id, state: { id: id, userID: currentUser.UserID } });

    }
    const BookmarkList = bookmark.map((item, i) => (
        <div className="col-md-4" key={item.BookID}>
            <div className="card">
                <div className="card-body">
                    <div className="bookmark-image">
                        <img src={item.ThumnailWeb} className="bookmark-cover" alt="Bookmark cover" />
                    </div>
                    <div className="card-content-title" style={{cursor:'pointer'}} onClick={(e)=>{readData(item.BookID)}}>{item.BookName}</div>
                    <span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateStringShort(item.PublishedStartDate)}</p></span>
                    <span className="view-total float-right"><i className="fas fa-eye" /><p> {item.Views}</p></span>
                </div>
            </div>
        </div>
    ));
    return (
        <div id="bookmark-list" className="row same-height">
            {BookmarkList}
        </div>
    );
}