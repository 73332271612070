import React from 'react';
import { Link } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import GoogleMapReact from 'google-map-react';
/*import notifyicon from '../../assets/images/icon/notifyico.png';
import alerticon from '../../assets/images/icon/alertico.png';
import docicon from '../../assets/images/icon/docico.png';*/
//import banner from '../../assets/images/banner/advertise.svg';
export default function MOUMap(props) {
    console.log(props);
    const language = localStorage.getItem("language");
    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: { lat: props.location.state.Lat, lng: props.location.state.Lng },
            map,
            title: props.location.state.Name
        });
        return marker;

    };
    function createMarkup() {
        return { __html: props.location.state.path };
    }
    return (
        <>
            <div id="header-wrapper">
                {<Navbar />}
                <ContentBanner language={language} theader={"เครือข่ายด้านสิทธิมนุษยชน"} eheader={"Network"} bannerkey="NETWORK" banner={banner}
                    path={<>
                        <span>/</span><a href="/" onClick={(e) => { e.preventDefault() }}>{language === "Thai" ? "เครือข่าย" : "Network"}</a>
                        <span>/</span><Link to={{ pathname: "/authen/network/SearchMOU", state: { language: language } }}>{language === "Thai" ? " หน่วยงานตามบันทึกข้อตกลงความร่วมมือ (MOU)" : "Agencies under MOU"}</Link>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/network/ViewMOU", state: { mouID: props.location.state.id } }}>{props.location.state.Name}</Link>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/network/MOUMap", state: { id: props.location.state.id, Lat: props.location.state.Lat, Lng: props.location.state.Lng, Name: props.location.state.Name } }}>แผนที่</Link>
                    </>}
                />
            </div>
            <main className="content-page">
                <div className='container'>
                    <section className="edit-profile row" >
                        <div className="content-data">
                            <div className="col-12" style={{ height: '100vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyBmCzwy3dIFgheFGeSr8jKOVcxF-x2jQ5w" }}
                                    center={{
                                        lat: props.location.state.Lat,
                                        lng: props.location.state.Lng
                                    }}
                                    defaultZoom={11}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                >
                                </GoogleMapReact>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}
