import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/advertise.svg';
import Footer from "components/Footer.js";
import { Rating } from '@material-ui/lab';
import utils from 'utils/utils';
import AttatchmentList from "components/AttatchmentList";
import VDOFileList from "components/VDOFileList";
import CommentList from "components/CommentList";
import GalleryContent from "components/GalleryContent";
import { authenticationService } from 'services/authentication.service.js';
import {
    FacebookShareButton,
    EmailShareButton,
    LineShareButton,
    FacebookIcon,
    EmailIcon,
    LineIcon,
} from "react-share";
import ReactPlayer from 'react-player'
export default function Previews(props) {
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [newsdata, SetNewsData] = useState({});
    const [canRating, setCanRating] = useState(true);
    const [gallery, setGallery] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [ratingavg, setRatingAVG] = useState(0);
    const newsid= props.match.params.id;
    const shareUrl = /*process.env.REACT_APP_SITEURL*/'http://118.174.14.124/news/Previews/'+newsid;
    const [userID, setUserID] = useState(null);
    let editNewsID =null;
    useEffect(() => {
        let userid = null;
        if (currentUser !== null) {
            userid = currentUser.UserID;
        }
        setUserID(userid);
        let newfileList = [...fileList];
        let newgallery = [...gallery];
        axios.get(process.env.REACT_APP_APIURL+'/api-web/newsPreviewbyID', { params: { id: newsid, userID: userid} }).then((response) => {
            SetNewsData(response.data[0])
            setCanRating(response.data[0].canRate);
            setRatingAVG(response.data[0].Rating);
            editNewsID =response.data[0].EditNewsID;
            //console.log(response.data[0])


            /*newgallery[0] = response.data[0].ThumbnailWeb;
            setGallery(newgallery);*/
            //console.log(newgallery)
            //newgallery = [...gallery];
            let loop = 1;
            for (let i = 0; i < response.data[0].filelist.length; i += 1) {
                let item = response.data[0].filelist[i];
                if (item.AttachmentType === 'IMG') {
                    newgallery[loop] = response.data[0].filelist[i].AttachmentPath;
                    setGallery(newgallery);
                    loop++;
                }
            }
            loop = 0;
            for (let i = 0; i < response.data[0].filelist.length; i += 1) {
                newfileList[loop] = response.data[0].filelist[i];
                setFileList(newfileList);
                loop++;
            }
            //console.log(newfileList);
            //setLoading(false);
        }).then(()=>{
            //console.log(editNewsID)
            if (editNewsID != null) {
                axios.get(process.env.REACT_APP_APIURL + '/api-web/newsbyID', { params: { id: editNewsID, userID: userid } }).then((response) => {
                    //SetNewsData(response.data[0])
                    setCanRating(response.data[0].canRate);
                    setRatingAVG(response.data[0].Rating);

                    let loop = newgallery.length;
                    for (let i = 0; i < response.data[0].filelist.length; i += 1) {
                        let item = response.data[0].filelist[i];
                        if (item.AttachmentType === 'IMG') {
                            newgallery[loop] = response.data[0].filelist[i].AttachmentPath;
                            setGallery(newgallery);
                            loop++;
                        }
                    }
                    loop = newfileList.length;
                    for (let i = 0; i < response.data[0].filelist.length; i += 1) {
                        newfileList[loop] = response.data[0].filelist[i];
                        setFileList(newfileList);
                        loop++;
                    }
                    setLoading(false);
                })
            }
            else {
                setLoading(false);
            }
        })

    }, []); /* <-- add this for run once*/

    const handleRating = (e, id, rating)=>{
        /*const formData = new FormData();
        formData.append("id", id);
        formData.append("userID", userID);
        formData.append("rating", rating);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/updateNewsRating', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                //console.log(response)
                setCanRating(false);
                setRatingAVG(response.data.avg);
            });*/
    };
    const VDOList = () => {
        let data = <></>;
        data = fileList.map((fileItem) => (
            (fileItem.AttachmentType === 'VDO') && <div className="vdo-player" key={fileItem.AttachmentID}>
                <div className='player-wrapper'>
                    <ReactPlayer url={fileItem.AttachmentPath} width='100%' height='460px' controls={true} />
                </div>
            </div>
        ));
        return data;
    }
    if (isLoading) {
        return (<></>);
    }
    return (
        <>
            <div id="header-wrapper">
                {/*{<Navbar />*/}
                <ContentBanner
                    language={language}
                    theader={"ประชาสัมพันธ์"}
                    eheader={"Information news"}
                    bannerkey="NEWS"
                    banner={banner}
                    path={
                        <>
                            <span>/</span>
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                {" "}
                                {language === "Thai"
                                    ? " ประชาสัมพันธ์และกิจกรรม "
                                    : " Information news and Activities "}
                            </a>
                            <span>/</span>
                            <Link
                                id="profile-btn"
                                className="active"
                                to={{
                                    pathname: "/news/NewsList",
                                    state: { language: language },
                                }}
                            >
                                {language === "Thai" ? "ประชาสัมพันธ์" : "Information news"}
                            </Link>
                        </>
                    }
                />
            </div>
            <main className="content-page">
                <div className="container">
                    <section className="edit-profile row" >
                        <GalleryContent gallery={gallery} />
                        <div className="news-activity-data">
                            <div className="mt-2 mb-2">
                                <span className="time-ago">
                                    <i className="far fa-clock" />
                                    <p>
                                        {" "}
                                        {utils.MariatoThaiDateStringShort(
                                            newsdata.PublishedStartDate
                                        )}
                                    </p>
                                </span>
                                <span className="view-total ">
                                    <i className="fas fa-eye text-gray" />
                                    <p className="text-gray"> {newsdata.Views}</p>
                                </span>
                            </div>
                            <h1>
                                {language === "Thai"
                                    ? newsdata.TitleTH
                                    : newsdata.TitleEN.trim().length === 0
                                        ? newsdata.TitleTH
                                        : newsdata.TitleEN}
                            </h1>
                            <div className="mb-2 customUIPro">
                                {language === "Thai"
                                    ? newsdata.ShortDetailTH
                                    : newsdata.ShortDetailEN.trim().length === 0
                                        ? newsdata.ShortDetailTH
                                        : newsdata.ShortDetailEN}
                            </div>
                            <div className="customUIPro"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        language === "Thai"
                                            ? newsdata.DescriptionTH
                                            : newsdata.DescriptionEN.trim().length === 0
                                                ? newsdata.DescriptionTH
                                                : newsdata.DescriptionEN,
                                }}
                            />
                            {/*<div className="col-12">
                                <VDOList />
                </div>*/}
                        </div>
                        <div className="content-data">
                            <div className="sub-title">
                                <h2 className="thai-header">
                                    <span className="orang content-underline">
                                        {language === "Thai" ? " VDO แนบ " : " Video attachments"}
                                    </span>
                                </h2>
                            </div>
                            <VDOFileList fileList={newsdata.filelist} />
                            <hr />

                            <div className="sub-title">
                                <h2 className="thai-header">
                                    <span className="orang content-underline">
                                        {language === "Thai" ? " เอกสารแนบ " : " Attachments"}
                                    </span>
                                </h2>
                            </div>
                            <AttatchmentList fileList={newsdata.filelist} />
                            <hr />

                            <span className="rating-text">Ratings :</span>
                            <Rating
                                name="half-rating-read"
                                value={ratingavg}
                                precision={1.0}
                                readOnly={currentUser == null || canRating === false}
                                onChange={(event, newValue) => {
                                    handleRating(event, newsdata.NewsID, newValue);
                                }}
                            />
                            <div className="rating-text" style={{ color: '#ffb400', marginLeft: '15px', fontSize: 20 }}>{ratingavg === 0 ? ' - ' : ratingavg.toFixed(2).toString()}</div>
                            <div className="share-tool float-right">
                                <FacebookShareButton
                                    url={shareUrl}
                                    quote={
                                        language === "Thai"
                                            ? newsdata.TitleTH
                                            : newsdata.TitleEN.trim().length === 0
                                                ? newsdata.TitleTH
                                                : newsdata.TitleEN
                                    }
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={25} round />
                                </FacebookShareButton>
                                <LineShareButton
                                    url={shareUrl}
                                    quote={
                                        language === "Thai"
                                            ? newsdata.TitleTH
                                            : newsdata.TitleEN.trim().length === 0
                                                ? newsdata.TitleTH
                                                : newsdata.TitleEN
                                    }
                                    className="Demo__some-network__share-button"
                                >
                                    <LineIcon size={25} round />
                                </LineShareButton>
                                <EmailShareButton
                                    url={shareUrl}
                                    quote={
                                        language === "Thai"
                                            ? newsdata.TitleTH
                                            : newsdata.TitleEN.trim().length === 0
                                                ? newsdata.TitleTH
                                                : newsdata.TitleEN
                                    }
                                    className="Demo__some-network__share-button"
                                >
                                    <EmailIcon size={25} round />
                                </EmailShareButton>
                            </div>
                            {newsdata.Comment !== 2 && (
                                <>
                                    <div className="sub-title mt-5">
                                        <h2 className="thai-header">
                                            <span className="orang content-underline">
                                                {language === "Thai" ? " ความคิดเห็น " : " Comments"}
                                            </span>
                                        </h2>
                                    </div>
                                    <CommentList
                                        sourceid={newsdata.NewsID}
                                        sourcetype={"NEWS"}
                                        mode={newsdata.Comment}
                                    />
                                </>
                            )}
                        </div>

                        {/*<div className="news-activity-data">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mt-2 mb-2"><span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateStringShort(newsdata.PublishedStartDate)}</p></span>
                                        <span className="view-total "><i className="fas fa-eye text-gray" /><p className="text-gray"> {newsdata.Views}</p></span>
                                    </div>
                                    <h1>{language === "Thai" ? newsdata.TitleTH : newsdata.TitleEN.trim().length === 0 ? newsdata.TitleTH : newsdata.TitleEN}</h1>
                                    <div dangerouslySetInnerHTML={{ __html: language === "Thai" ? newsdata.DescriptionTH : newsdata.DescriptionEN.trim().length === 0 ? newsdata.DescriptionTH : newsdata.DescriptionEN }} />
                                </div>
                                <div className="col-12">
                                    <VDOList />
                                </div>
                            </div>
                        </div>
                        <div className="content-data">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sub-title">
                                        <h2 className="thai-header"><span className="orang content-underline">เอกสารแนบ</span></h2>
                                    </div>
                                    <AttatchmentList fileList={fileList} />
                                    <hr />
                                    <span className="rating-text">Ratings  :</span>
                                    <Rating name="half-rating-read" value={ratingavg} precision={1.0} readOnly={currentUser == null || canRating === false} onChange={(event, newValue) => {
                                        handleRating(event, newsdata.NewsID, newValue);
                                    }} />
                                    <div className="share-tool float-right">
                                        <FacebookShareButton
                                            url={shareUrl}
                                            quote={language === "Thai" ? newsdata.TitleTH : newsdata.TitleEN.trim().length === 0 ? newsdata.TitleTH : newsdata.TitleEN}
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookIcon size={25} round />
                                        </FacebookShareButton>
                                        <LineShareButton
                                            url={shareUrl}
                                            quote={language === "Thai" ? newsdata.TitleTH : newsdata.TitleEN.trim().length === 0 ? newsdata.TitleTH : newsdata.TitleEN}
                                            className="Demo__some-network__share-button"
                                        >
                                            <LineIcon size={25} round />
                                        </LineShareButton>
                                        <EmailShareButton
                                            url={shareUrl}
                                            quote={language === "Thai" ? newsdata.TitleTH : newsdata.TitleEN.trim().length === 0 ? newsdata.TitleTH : newsdata.TitleEN}
                                            className="Demo__some-network__share-button"
                                        >
                                            <EmailIcon size={25} round />
                                        </EmailShareButton>
                                    </div>
                                    {
                                        (newsdata.Comment !== 2) && (<><div className="sub-title mt-5">
                                            <h2 className="thai-header"><span className="orang content-underline">ความคิดเห็น</span></h2>
                                        </div>
                                            <CommentList sourceid={newsdata.NewsID} sourcetype={"PREVIEWSNEWS"} mode={newsdata.Comment} /></>)
                                    }
                                </div>
                            </div>
                                </div>*/}
                    </section>
                </div>
            </main>
            {/*<Footer />*/}
        </>
    );
}
