//import { useHistory } from "react-router-dom";
//import AdminNavbar from "components/Navbar/AdminNavbar.js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { authenticationService } from 'services/authentication.service.js';
import utils from 'utils/utils';
import 'assets/styles/admin.css';
import avatar from 'assets/images/icon/user.svg';
const swal = withReactContent(Swal)

let newitem = false;
let olditem = false;
function ReadNotify(notificationID) {
  alert(notificationID);
  const formData = new FormData();
  formData.append("notificationID", notificationID);
  axios
    .post(
      process.env.REACT_APP_APIURL + "/api-web/ReadNotify",
      formData,
      {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      }
    )
    .then((response) => {
      window.location.reload();
    });
};
function NotificationItem({ item }) {
  let notidate = new Date(item.NotificationDate).valueOf();
  //console.log(item)
  if (Number(item.NotificationStatus) === 0) {
    if (newitem === false) {
      newitem = true;
      return (
        <><li className="notifications-part">ใหม่</li>
          <li className="notify-item">
            {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar" />)}
            {item.Avatar === null && (<img src={avatar} alt="Avatar" />)}
            <div className="notify-item-content" onClick={() =>ReadNotify(item.NotificationID)}>
              <h4 className="title">{item.Description}</h4>
              <span className="time-ago"><i className="far fa-clock" /><p> {utils.getTimeAgo(notidate)}</p></span>
            </div>
          </li></>);

    }
    else {
      return (
        <li className="notify-item">
          {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar" />)}
          {item.Avatar === null && (<img src={avatar} alt="Avatar" />)}
          <div className="notify-item-content" onClick={() =>ReadNotify(item.NotificationID)}>
            <h4 className="title">{item.Description}</h4>
            <span className="time-ago"><i className="far fa-clock" /><p> {utils.getTimeAgo(notidate)}</p></span>
          </div>
        </li>);
    }
  }
  else {
    if (olditem === false) {
      olditem = true;
      return (
        <><li className="notifications-part">ก่อนหน้านี้</li>
          <li className="notify-item">
            {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar" />)}
            {item.Avatar === null && (<img src={avatar} alt="Avatar" />)}
            <div className="notify-item-content">
              <h4 className="title">{item.Description}</h4>
              <span className="time-ago"><i className="far fa-clock" /><p> {utils.getTimeAgo(notidate)}</p></span>
            </div>
          </li></>);

    }
    else {
      return (
        <li className="notify-item">
          {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar" />)}
          {item.Avatar === null && (<img src={avatar} alt="Avatar" />)}
          <div className="notify-item-content">
            <h4 className="title">{item.Description}</h4>
            <span className="time-ago"><i className="far fa-clock" /><p> {utils.getTimeAgo(notidate)}</p></span>
          </div>
        </li>);
    }
  }
}
function Notifypopup(props) {
  //let user;// = JSON.parse(localStorage.getItem('user'));
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    utils.asyncLocalStorage.getItem('user').then(function (value) {
      let user = JSON.parse(value);
      //console.log(user);
      //console.log(user.UserID);
      if (user !== null) {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/notify', { params: { userid: user.UserID } }).then((response) => {
          setNotifications(response.data);
          setLoading(false);
        })
      }
      else {
        //setNotifications()
        setLoading(false);
      }
    })
  }, []);

  if (isLoading) {
    return <div className="App">...</div>;
  }

  const newnotifyitem = notifications.filter(item => Number(item.NotificationStatus) === 0);
  const newnotiCount = newnotifyitem.length;
  return (
    <a id="dLabel" role="button" data-toggle="dropdown" style={{ marginTop: 9 }} title="แจ้งเตือน" data-target="#" href="#" onClick={(e) => { e.preventDefault(); }}>
      <i className="far fa-bell"></i>
      <div className="icon_count"><span>{newnotiCount}</span></div>

      <ul className="dropdown-menu notifications" role="menu" aria-labelledby="dLabel">
        <div className="notification-heading">
          <h3 className="notification-heading-title">การแจ้งเตือน</h3>
        </div>
        <div className="notifications-wrapper scrollbar">
          {
            notifications.map(i => (
              <NotificationItem item={i} key={i.NotificationID} />
            ))
          }
        </div>
      </ul>
      <span className="admin-popup-menu" style={{ left: 30 }}>แจ้งเตือน</span>
    </a>

  );
}

export default function AdminHeader(props) {
  const history = useHistory();
  //const language = localStorage.getItem("language");
  const handleLogout = () => {
    let timerInterval;
    swal.fire({
      title: "ท่านต้องการออกจากระบบหรือไม่?",
      showDenyButton: true,
      /*showCancelButton: true,*/
      confirmButtonText: "ยืนยัน",
      denyButtonText: "ยกเลิก",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        swal.fire({
          title: "ท่านออกจากระบบเรียบร้อยแล้ว", showConfirmButton: false,
          timer: 2000, timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
              const content = Swal.getHtmlContainer()
              if (content) {
                const b = content.querySelector('b')
                if (b) {
                  b.textContent = Swal.getTimerLeft()
                }
              }
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          //localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          authenticationService.logout();
          window.location.href = "/";
        })

      } else if (result.isDenied) {
        //swal.fire('Changes are not saved', '', 'info')
      }
    })
  };
  return (
    /*<div id="admin-header" className="navbar navbar-inverse navbar-fixed-top">
            {
                <AdminNavbar />
            }

        </div>*/
    <div id="admin-header" className="navbar navbar-inverse navbar-fixed-top">
      <div className="navbar-header nhrc-brand">
        <a className="navbar-brand" href="http://118.174.14.124/">
          <img src="/images/logo/nhrclogo.png" alt="LOGO" />
        </a>
        <span style={{ fontSize: 2 + 'rem', display: 'flex', alignItems: 'center' }}>สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ</span>
      </div>
      <ul className="nav navbar-right top-nav">
        <li className="dropdown">
          <a href="/" onClick={(e) => { e.preventDefault(); }} className="dropdown-toggle" data-toggle="dropdown"><i className="fa fa-fw fa-cog"></i></a>
          <ul id="user-popup-menu" className="dropdown-menu">
            <li><a href="/" onClick={(e) => {
              e.preventDefault();
              history.push({ pathname: "/admin/Profile" });
            }}><i className="fas fa-user"></i> <span className="admin-popup-menu">โปรไฟล์</span></a></li>
            <li className="nav-item">
              <div style={{
                display: 'inline-flex',
                textAlign: 'center',
                marginLeft: 15,
                marginRight: 15,
                position: 'relative',
                fontSize: 29
              }}>
                <Notifypopup />
              </div>
            </li>
            <li className="divider"></li>

            <li><a href="/" onClick={(e) => { e.preventDefault(); handleLogout(); }}><i className="fa fa-fw fa-power-off"></i> <span className="admin-popup-menu">ออกจากระบบ</span></a></li>
          </ul>
        </li>
      </ul>
    </div>
  );
}