import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'moment/locale/th';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AdminBanner from 'components/Banner/AdminBanner';

export default function ViewContactMessage(props) {
    const [contactMessage, setContactMessage] = useState({});
    const contactMessageID = props.location.state.id;
    useEffect(() => {
        //console.log(props.location.state.id)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getContactUsMessagebyID', { params: { id: contactMessageID } })
            .then((response) => {
                let data = response.data[0];
                console.log(data)
                setContactMessage(data);
            });
    }, []); /* <-- add this for run once*/

    const cancel = () => {
        props.history.push({ pathname: "/admin/contact/ContactMessage" });
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"จัดการการติดต่อ"} path={
                <>
                    <span><i className="fas fa-angle-right"></i></span>
                    <a href="/" onClick={e => { e.preventDefault(); }}>จัดการการติดต่อ</a>
                    <span><i className="fas fa-angle-right"></i></span>
                    <Link to={{ pathname: "/admin/contact/ContactMessage" }}>{"จัดการผู้มาติดต่อ"}</Link>
                    <span><i className="fas fa-angle-right"></i></span>
                    <Link className="active" to={{ pathname: "/admin/contact/ViewContactMessage", state: { id: contactMessageID } }}>{"ข้อมูลผู้มาติดต่อ"}</Link>
                </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h4>รายละเอียดการติดต่อ</h4>
                                    <p><strong>หน่วยงาน: </strong> {contactMessage.OrgName} </p>
                                    <p><strong>ประเภทการติดต่อ: </strong> {contactMessage.ContactTypeNameTH} </p>
                                    <p><strong>เรื่องติดต่อ: </strong> {contactMessage.Title} </p>
                                    <p><strong>รายละเอียด: </strong> {contactMessage.Description} </p>
                                    <p><strong>ชื่อ-นามสกุล: </strong> {contactMessage.Name} </p>
                                    <p><strong>email: </strong> {contactMessage.Email} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="pl-4 pr-4 text-center" >
                                <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ย้อนกลับ</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
