import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import $ from 'jquery';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
const swal = withReactContent(Swal)
export default function EditMember(props) {
    //console.log("Edit ID = "+props.location.state.id);
    const currentUser = authenticationService.currentUserValue;
    const [errors, setError] = useState({});
    const [regType, setRegType] = useState(0);
    const [isNoIDCard, setIsNoIDCard] = useState(false);
    const [titleList, SetTitleList] = useState([]);
    const [titleID, setTitleID] = useState(0);
    const [interestTopicID, setInterestTopicID] = useState(0);
    const [otherTitle, setOtherTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('F');
    const [phone, SetPhone] = useState('');
    const [email, setEmail] = useState('');
    const [idData, setIDData] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [selectQuestionID, setSelectQuestionID] = useState(0);
    const [vertifyQuestion, setVertifyQuestion] = useState([]);
    const [answer, setAnswer] = useState('');
    const [active, setActive] = useState(-1);
    const [interrestTopic, SetInterrestTopic] = useState([]);
    const memberID = props.location.state.id;
    useEffect(() => {
        let selectVertifyQuestion = '';
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getMemberInterestTopic')
            .then((response) => {
                SetInterrestTopic(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/memberbyID', { params: { id: memberID } }).then((response) => {
            let data = response.data[0];
            setIsNoIDCard(data.IsNoIDCard);
            /*if (data.IsNoIDCard === true) {
                setIDData(data.CitizenID);
                setRegType(1);
            }*/
            if (data.PassportNo !== null) {
                if (data.PassportNo.trim().length > 0) {
                    setIDData(data.PassportNo);
                    setRegType(2);
                }
            }
            if (data.CitizenID !== null) {
                if (data.CitizenID.trim().length > 0) {
                    setIDData(data.CitizenID);
                    setRegType(1);
                }
            }
            SetPhone(data.Phone);
            setGender(data.Gender);
            setEmail(data.Email);
            setPassword(data.Password);
            setConfirmPassword(data.Password);
            selectVertifyQuestion = data.VerifyQuestion;
            setTitleID(data.TitleID);
            setInterestTopicID(data.InterestTopicID);
            setFirstName(data.FirstnameTH);
            setLastName(data.LastnameTH);
            setSelectQuestionID(data.VerifyQuestion);
            setAnswer(data.VerifyAnswer);
            setActive(data.Active)
        });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getTitle')
            .then((response) => {
                SetTitleList(response.data);
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getMemberVertifyQuestion')
            .then((response) => {
                setVertifyQuestion(response.data);
                /*const questionselect = response.data.find(c => c.VerifyQuestionID === selectVertifyQuestion);
                if(typeof questionselect!=='undefined')
                setSelectQuestionID(questionselect.VerifyQuestionID);*/
                //selectVertifyQuestion
            });
        $(function () {
            $("#show_hide_password a").on('click', function (event) {
                //alert('click');
                event.preventDefault();
                if ($('#show_hide_password input').attr("type") === "text") {
                    $('#show_hide_password input').attr('type', 'password');
                    $('#show_hide_password i').addClass("fa-eye-slash");
                    $('#show_hide_password i').removeClass("fa-eye");
                }
                else if ($('#show_hide_password input').attr("type") === "password") {
                    $('#show_hide_password input').attr('type', 'text');
                    $('#show_hide_password i').removeClass("fa-eye-slash");
                    $('#show_hide_password i').addClass("fa-eye");
                }
            });
        });
        return () => {
            //cleanup
        }
    }, []); /* <-- add this for run once*/
    const TopicSelect = interrestTopic.map((item) => (
        <option
            key={item.InterestTopicID}
            value={item.InterestTopicID}
        >
            {item.TextTH}
        </option>
    ));
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;

        if (Number(active) === -1) {
            formIsValid = false;
            serrors["active"] = "กรุณาระบุการใช้งาน";
        }
        if (password.trim().length === 0) {
            formIsValid = false;
            serrors["password"] = "กรุณาระบุรายละเอียด";
        }
        if (confirmPassword.trim().length === 0) {
            formIsValid = false;
            serrors["confirmPassword"] = "กรุณาระบุรายละเอียด";
        }
        if (password !== confirmPassword) {
            formIsValid = false;
            serrors["confirmPassword"] = "รหัสผ่านและการยืนยันรหัสผ่านไม่ตรงกัน";
        }
        if (Number(regType) === 0) {
            formIsValid = false;
            serrors["regType"] = "กรุณาเลือกประเภทสมาชิก";
        }
        //confirmPassword
        if (gender.trim().length === 0) {
            formIsValid = false;
            serrors["gender"] = "กรุณาเลือกเพศ";
        }
        if (phone.trim().length === 0) {
            formIsValid = false;
            serrors["phone"] = "กรุณาระบุรายละเอียด";
        }
        if (answer.trim().length === 0) {
            formIsValid = false;
            serrors["answer"] = "กรุณาระบุรายละเอียด";
        }
        if (Number(selectQuestionID) === 0) {
            formIsValid = false;
            serrors["selectQuestionID"] = "กรุณาเลือกคำถามยืนยันตน";
        }
        if (Number(interestTopicID) === 0) {
            formIsValid = false;
            serrors["interestTopicID"] = "กรุณาเลือกประเด็นสิทธิมนุษยชนที่สนใจ";
        }
        //fax
        if (Number(titleID) === 0) {
            if (otherTitle.trim().length === 0) {
                formIsValid = false;
                serrors["titleID"] = "กรุณาเลือกคำนำหน้าชื่อ";
                serrors["otherTitle"] = "กรุณาระบุคำนำหน้าชื่อที่ต้องการ หากไม่มีในตัวเลือก";
            }
        }
        if (firstName.trim().length === 0) {
            formIsValid = false;
            serrors["firstName"] = "กรุณาระบุรายละเอียด";
        }
        if (lastName.trim().length === 0) {
            formIsValid = false;
            serrors["lastName"] = "กรุณาระบุรายละเอียด";
        }
        //coFax
        if (email.trim().length === 0) {
            formIsValid = false;
            serrors["email"] = "กรุณาระบุรายละเอียด";
        }
        else {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                formIsValid = false;
                serrors["email"] = "รูปแบบของอีเมลไม่ถูกต้อง";
            }
        }
        if (Number(regType === 0)) {
            formIsValid = false;
            serrors["regType"] = "กรุณาเลือกประเภทสมาชิก";
        }
        else {
            if (isNoIDCard === false) {
                if (idData.trim().length === 0) {
                    formIsValid = false;
                    serrors["idData"] = "กรุณาระบุรายละเอียด";
                }
                if (Number(regType) === 1) {
                    if (!isFinite(String(idData))) {
                        formIsValid = false;
                        serrors["idData"] = "หมายเลขบัตรประชาชนต้องประกอบไปด้วยตัวเลขเท่านั้น";
                    }
                    if (idData.trim().length !== 13) {
                        formIsValid = false;
                        serrors["idData"] = "กรุณากรอกหมายเลข 13 หลัก";
                    }
                    /*axios.get(process.env.REACT_APP_APIURL + '/api-web/checkIDCard', { params: { idCard: idData, memberID: memberID } }).then((response) => {                        
                        //console.log(response.data)
                        if(response.data.valid===false)
                        {
                            formIsValid = false;
                            serrors["idData"] = "มีหมายเลขบัตรประชาชนนี้อยู่แล้วในระบบ";
                        }
                    })*/
                }
            }
        }
        //formIsValid = false;
        setError(serrors);
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("memberID", memberID);
            formData.append("regType", regType);
            formData.append("active", active);
            formData.append("idData", idData);
            formData.append("password", password);
            formData.append("confirmPassword", confirmPassword);
            formData.append("phone", phone);
            formData.append("titleID", titleID);
            formData.append("gender", gender);
            formData.append("otherTitle", otherTitle);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("email", email);
            formData.append("isNoIDCard", isNoIDCard);
            formData.append("questionID", selectQuestionID);
            formData.append("answer", answer);
            formData.append("interestTopicID", interestTopicID);
            formData.append("userID", currentUser.UserID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateMember', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    console.log(response.data.success)
                    if (response.data.success === false) {
                        Swal.fire({
                            icon: 'error',
                            title: 'ผิดพลาด',
                            text: response.data.message,
                        })
                    }
                    else {
                        let timerInterval;
                        swal.fire({
                            title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                            timer: 1000, timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer()
                                    if (content) {
                                        const b = content.querySelector('b')
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft()
                                        }
                                    }
                                }, 100)
                            },
                            willClose: () => {
                                clearInterval(timerInterval);

                            }
                        }).then((result) => {
                            if (open) {
                                window.location.reload();
                            }
                            else {
                                props.history.push({ pathname: "/admin/members/MemberList" });
                            }
                        })
                    }
                });

        } else {
            //alert("Form has errors.")
        }
    };
    const TitleSelect = titleList.map((title) => (
        <option
            key={title.TitleID}
            value={title.TitleID}
        >
            {title.TitleNameTH}
        </option>
    ));
    const QuestionSelect = vertifyQuestion.map((item) => (
        <option
            key={item.VerifyQuestionID}
            value={item.VerifyQuestionID}
        >
            {item.TextTH}
        </option>
    ));
    const cancel = () => {
        props.history.push({ pathname: "/admin/members/MemberList" });
    };
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"จัดการสมาชิก"} path={<><span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>จัดการสมาชิก</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/members/MemberList" }}>{"จัดการสมาชิก"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/members/EditMember", state: { id: memberID } }}>{"แก้ไข"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-6">
                                    <select className="form-control mb-2 mr-2" id="regType" name="regType" value={regType} disabled={true} onChange={(e) => { setRegType(e.currentTarget.value); }} >
                                        <option value="0" >กรุณาเลือก</option>
                                        <option value="1" >บัตรประชาชน</option>
                                        <option value="2" >หนังสือเดินทาง</option>
                                    </select>
                                    <SError error={errors["regType"]} />
                                </div>
                                <div className="col-6">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="isNoIDCard" name="isNoIDCard" checked={isNoIDCard === true} disabled={true} onChange={(e) => { setIsNoIDCard(!isNoIDCard) }} />
                                        <label className="custom-control-label" htmlFor="isNoIDCard">ผู้ใช้งานไม่มีบัตรประชาชน</label>
                                    </div>
                                    <SError error={errors["isNoIDCard"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="idData" className="required">บัตรประชาชน/หนังสือเดินทาง </label>
                                    <input type="text" className="form-control " id="idData" value={idData} name="idData" placeholder="กรุณากรอก" maxLength="13" onChange={(e) => { setIDData(e.currentTarget.value) }} />
                                    <SError error={errors["idData"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="titleID" className="required">คำนำหน้า</label>
                                    <select className="form-control mb-2 mr-2" id="titleID" name="titleID" value={titleID} onChange={(e) => { setTitleID(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {TitleSelect}
                                    </select>
                                    <SError error={errors["titleID"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="otherTitle" className="required">ระบุคำนำหน้าอื่น ๆ</label>
                                    <input type="text" className="form-control " id="otherTitle" value={otherTitle} name="otherTitle" placeholder="โปรดระบุเมื่อไม่มีคำนำหน้าที่ต้องการให้เลือก" onChange={(e) => { setOtherTitle(e.currentTarget.value) }} />
                                    <SError error={errors["otherTitle"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="firstName" className="required">ชื่อ</label>
                                    <input type="text" className="form-control " id="firstName" value={firstName} name="firstName" placeholder="กรุณากรอก" onChange={(e) => { setFirstName(e.currentTarget.value) }} />
                                    <SError error={errors["firstName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="lastName" className="required">นามสกุล</label>
                                    <input type="text" className="form-control " id="lastName" value={lastName} name="lastName" placeholder="กรุณากรอก" onChange={(e) => { setLastName(e.currentTarget.value) }} />
                                    <SError error={errors["lastName"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="phone" className="required">หมายเลขโทรศัพท์</label>
                                    <input type="text" className="form-control " id="phone" value={phone} name="phone" placeholder="กรุณากรอก" onChange={(e) => { SetPhone(e.currentTarget.value) }} />
                                    <SError error={errors["phone"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="password" className="required">ประเด็นสิทธิมนุษยชนที่สนใจ</label>
                                    <select id="interestTopicID" name="interestTopicID" className="custom-select" value={interestTopicID} onChange={(e) => { setInterestTopicID(e.currentTarget.value); }}>
                                        <option value="0">ประเด็นสิทธิมนุษยชนที่สนใจ</option>
                                        {TopicSelect}
                                    </select>
                                    <SError error={errors["interestTopicID"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="password" className="required">เพศ</label>
                                    <select id="gender" name="gender" className="custom-select" value={gender} onChange={(e) => { setGender(e.currentTarget.value); }}>
                                        <option value="">เลือกเพศ</option>
                                        <option value="M" >ชาย</option>
                                        <option value="F" >หญิง</option>
                                        <option value="O" >อื่น ๆ</option>
                                    </select>
                                    <SError error={errors["gender"]} />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="email" className="required">อีเมล</label>
                                    <input type="text" className="form-control " id="email" value={email} name="email" placeholder="กรุณากรอก" onChange={(e) => { setEmail(e.currentTarget.value) }} />
                                    <SError error={errors["email"]} />
                                </div>
                                <div id="show_hide_password" className="col-6">
                                    <label htmlFor="password" className="required">รหัสผ่าน</label>
                                    <input type="password" className="form-control " id="password" value={password} name="password" placeholder="กรุณากรอก" onChange={(e) => { setPassword(e.currentTarget.value) }} />
                                    <div className="input-addon" style={{ position: 'absolute', top: 38 + 'px' }}>
                                        <a href="/"><i className="fa fa-eye-slash" aria-hidden="true"></i> </a>
                                    </div>
                                    <SError error={errors["password"]} />
                                </div>
                                <div id="show_hide_password" className="col-6">
                                    <label htmlFor="confirmPassword" className="required">ยืนยันรหัสผ่าน</label>
                                    <input type="password" className="form-control " id="confirmPassword" value={confirmPassword} name="password" placeholder="กรุณากรอก" onChange={(e) => { setConfirmPassword(e.currentTarget.value) }} />
                                    <div className="input-addon" style={{ position: 'absolute', top: 38 + 'px' }}>
                                        <a href="/"><i className="fa fa-eye-slash" aria-hidden="true"></i> </a>
                                    </div>
                                    <SError error={errors["confirmPassword"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="selectQuestionID" className="required">คำถามยืนยันตัวตน</label>
                                    <select id="selectQuestionID" name="selectQuestionID" className="custom-select" value={selectQuestionID} onChange={(e) => { setSelectQuestionID(e.currentTarget.value); }}>
                                        <option value="0">คำถามยืนยันตัวตน</option>
                                        {QuestionSelect}
                                    </select>
                                    <SError error={errors["selectQuestionID"]} />
                                </div>

                                <div className="col-6">
                                    <label htmlFor="idData" className="required">คำตอบ</label>
                                    <input type="text" className="form-control " id="answer" name="answer" placeholder="คำตอบ" value={answer} autoComplete="off" onChange={(e) => { setAnswer(e.currentTarget.value) }} />
                                    <SError error={errors["answer"]} />
                                </div>

                                <div className="col-12">
                                    <label htmlFor="active" className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={Number(active) === 1} onChange={(e) => { setActive(1); }} />
                                        <label className="form-check-label" htmlFor="active1">ใช้งาน</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={Number(active) === 0} onChange={(e) => { setActive(0); }} />
                                        <label className="form-check-label" htmlFor="active2">ไม่ใช้งาน</label>
                                    </div>
                                    <SError error={errors["active"]} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="pl-4 pr-4 text-center" >
                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                            <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
