import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/advertise.svg";
import Footer from "components/Footer.js";
import { Rating } from "@material-ui/lab";
import utils from "utils/utils";
import AttatchmentList from "components/AttatchmentList";
import VDOFileList from "components/VDOFileList";
import CommentList from "components/CommentList";
import GalleryContent from "components/GalleryContent";
import { authenticationService } from "services/authentication.service.js";

import {
  FacebookShareButton,
  EmailShareButton,
  LineShareButton,
  FacebookIcon,
  EmailIcon,
  LineIcon,
} from "react-share";
//import ReactPlayer from 'react-player'
export default function ReadNews(props) {
  const currentUser = authenticationService.currentUserValue;

  const [isLoading, setLoading] = useState(true);
  const language = localStorage.getItem("language");
  const [newsdata, SetNewsData] = useState({});
  const [canRating, setCanRating] = useState(true);
  const [gallery, setGallery] = useState([]);
  const [ratingavg, setRatingAVG] = useState(0);
  const newsid = props.match.params.id;
  const shareUrl =
    /*process.env.REACT_APP_SITEURL*/ "http://118.174.14.124/news/ReadNews/" +
    newsid;
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    let userid = null;
    if (currentUser !== null) {
      userid = currentUser.UserID;
    }
    setUserID(userid);
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/newsbyID", {
        params: { id: newsid, userID: userid },
      })
      .then((response) => {
        SetNewsData(response.data[0]);
        setCanRating(response.data[0].canRate);
        /*const rattinglist = response.data[0].ratingList;
        const five = rattinglist.filter(element => Number(element.Rating) == 5);
        const four = rattinglist.filter(element => Number(element.Rating) == 4);
        const tree = rattinglist.filter(element => Number(element.Rating) == 3);
        const two = rattinglist.filter(element => Number(element.Rating) == 2);
        const one = rattinglist.filter(element => Number(element.Rating) == 1);
        var sumscore = 0.0;
        if (typeof five != 'undefined') {
          sumscore += parseFloat(five.length) * 5;
        }
        if (typeof four != 'undefined') {
          sumscore += parseFloat(four.length) * 4;
        }
        if (typeof tree != 'undefined') {
          sumscore += parseFloat(tree.length) * 3;
        }
        if (typeof two != 'undefined') {
          sumscore += parseFloat(two.length) * 2;
        }
        if (typeof one != 'undefined') {
          sumscore += parseFloat(one.length) * 1;
        }
        if (rattinglist.length > 0) {
          setRatingAVG(parseFloat(sumscore) / parseFloat(rattinglist.length));
          response.data[0].Rating =parseFloat(sumscore) / parseFloat(rattinglist.length);
        }
        else{
          setRatingAVG(0);
          response.data[0].Rating =parseFloat(0);
        }*/
        setRatingAVG(response.data[0].Rating);
        /*console.log(sumscore);
        console.log(response.data[0])*/

        const newgallery = [...gallery];
        newgallery[0] = response.data[0].ThumbnailWeb;
        setGallery(newgallery);
        //newgallery = [...gallery];
        let loop = 1;
        for (let i = 0; i < response.data[0].filelist.length; i += 1) {
          let item = response.data[0].filelist[i];
          if (item.AttachmentType === "IMG") {
            newgallery[loop] = response.data[0].filelist[i].AttachmentPath;
            setGallery(newgallery);
            loop++;
          }
        }
        //console.log(gallery);
        setLoading(false);
      });
  }, []); /* <-- add this for run once*/

  const handleRating = (e, id, rating) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("userID", userID);
    formData.append("rating", rating);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateNewsRating",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        //console.log(response)
        setCanRating(false);
        setRatingAVG(response.data.avg);
      });
  };
  /*const VDOList = () => {
        let data = <></>;
        data = newsdata.filelist.map((fileItem) => (
            (fileItem.AttachmentType === 'VDO') && <div className="vdo-player" key={fileItem.AttachmentID}>
                <div className='player-wrapper'>
                    <ReactPlayer url={fileItem.AttachmentPath} width='100%' height='460px' controls={true} />
                </div>
            </div>
        ));
        return data;
    }*/
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {/*<Navbar />*/}
        <ContentBanner
          language={language}
          theader={"ประชาสัมพันธ์"}
          eheader={"Information news"}
          bannerkey="NEWS"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {" "}
                {language === "Thai"
                  ? " ประชาสัมพันธ์และกิจกรรม "
                  : " Information news and Activities "}
              </a>
              <span>/</span>
              <Link
                id="profile-btn"
                className="active"
                to={{
                  pathname: "/news/NewsList",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "ประชาสัมพันธ์" : "Information news"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="edit-profile row ReadNews">
            <GalleryContent gallery={gallery} />
            <div className="news-activity-data">
              <div className="mt-2 mb-2">
                <span className="time-ago">
                  <i className="far fa-clock" />
                  <p>
                    {" "}
                    {utils.MariatoThaiDateStringShort(
                      newsdata.PublishedStartDate
                    )}
                  </p>
                </span>
                <span className="view-total ">
                  <i className="fas fa-eye text-gray" />
                  <p className="text-gray"> {newsdata.Views}</p>
                </span>
              </div>
              <h1>
                {language === "Thai"
                  ? newsdata.TitleTH
                  : newsdata.TitleEN.trim().length === 0
                    ? newsdata.TitleTH
                    : newsdata.TitleEN}
              </h1>
              <div className="mb-2 customUIPro">
                {language === "Thai"
                  ? newsdata.ShortDetailTH
                  : newsdata.ShortDetailEN.trim().length === 0
                    ? newsdata.ShortDetailTH
                    : newsdata.ShortDetailEN}
              </div>
              <div className="customUIPro"
                dangerouslySetInnerHTML={{
                  __html:
                    language === "Thai"
                      ? newsdata.DescriptionTH
                      : newsdata.DescriptionEN.trim().length === 0
                        ? newsdata.DescriptionTH
                        : newsdata.DescriptionEN,
                }}
              />
              {/*<div className="col-12">
                                <VDOList />
                </div>*/}
            </div>
            <div className="content-data">
              <div className="sub-title">
                <h2 className="thai-header">
                  <span className="orang content-underline">
                    {language === "Thai" ? " VDO แนบ " : " Video attachments"}
                  </span>
                </h2>
              </div>
              <VDOFileList fileList={newsdata.filelist} />
              <hr />

              <div className="sub-title">
                <h2 className="thai-header">
                  <span className="orang content-underline">
                    {language === "Thai" ? " เอกสารแนบ " : " Attachments"}
                  </span>
                </h2>
              </div>
              <AttatchmentList fileList={newsdata.filelist} />
              <hr />

              <span className="rating-text">Ratings :</span>
              <Rating
                name="half-rating-read"
                value={ratingavg}
                precision={1.0}
                readOnly={currentUser == null || canRating === false}
                onChange={(event, newValue) => {
                  handleRating(event, newsdata.NewsID, newValue);
                }}
              />
              <div className="rating-text" style={{color:'#ffb400',marginLeft:'15px',fontSize:20}}>{ratingavg===0?' - ':ratingavg.toFixed(2).toString()}</div>
              <div className="share-tool float-right">
                <FacebookShareButton
                  url={shareUrl}
                  quote={
                    language === "Thai"
                      ? newsdata.TitleTH
                      : newsdata.TitleEN.trim().length === 0
                        ? newsdata.TitleTH
                        : newsdata.TitleEN
                  }
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={25} round />
                </FacebookShareButton>
                <LineShareButton
                  url={shareUrl}
                  quote={
                    language === "Thai"
                      ? newsdata.TitleTH
                      : newsdata.TitleEN.trim().length === 0
                        ? newsdata.TitleTH
                        : newsdata.TitleEN
                  }
                  className="Demo__some-network__share-button"
                >
                  <LineIcon size={25} round />
                </LineShareButton>
                <EmailShareButton
                  url={shareUrl}
                  quote={
                    language === "Thai"
                      ? newsdata.TitleTH
                      : newsdata.TitleEN.trim().length === 0
                        ? newsdata.TitleTH
                        : newsdata.TitleEN
                  }
                  className="Demo__some-network__share-button"
                >
                  <EmailIcon size={25} round />
                </EmailShareButton>
              </div>
              {newsdata.Comment !== 2 && (
                <>
                  <div className="sub-title mt-5">
                    <h2 className="thai-header">
                      <span className="orang content-underline">
                        {language === "Thai" ? " ความคิดเห็น " : " Comments"}
                      </span>
                    </h2>
                  </div>
                  <CommentList
                    sourceid={newsdata.NewsID}
                    sourcetype={"NEWS"}
                    mode={newsdata.Comment}
                  />
                </>
              )}
            </div>
          </section>
        </div>
      </main>
      {/*<Footer />*/}
    </>
  );
}
