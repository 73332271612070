import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'moment/locale/th';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { authenticationService } from 'services/authentication.service.js';
import AdminBanner from 'components/Banner/AdminBanner';
//import SError from 'components/SError';
//import EditQuestionItem from "./EditQuestionItem";
const swal = withReactContent(Swal)

export default function ManageQuestionItem(props) {
    //const currentUser = authenticationService.currentUserValue;
    const elerningID = props.location.state.elerningID;
    const chapterID = props.location.state.chapterID;
    const topParentChapterID = props.location.state.parentChapterID;
    const questionnaireID = props.location.state.QuestionnaireID;
    const questionType = props.location.state.questionType;
    const [topParentTitle, setTopParentTitle] = useState('');
    const [eLearnTitle, setELearnTitle] = useState('');
    const [parentChapter, setParentChapter] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [shuffle, setShuffle] = useState(false);
    //console.log(props.location.state)
    function reLoadData() {
        console.log(topParentChapterID)
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getChapterbyID', { params: { chapterID: topParentChapterID } })
            .then((response) => {
                let data = response.data[0];
                //console.log(data)
                setTopParentTitle(data.ChapterTitle);
                axios.get(process.env.REACT_APP_APIURL + '/api-web/ESubjectChapter', { params: { id: chapterID } })
                    .then((response) => {
                        let data = response.data[0];
                        //console.log(data)
                        setParentChapter(data)
                        setShuffle(data.AllowShuffle)
                        setQuestionList(data.questionItem)
                        setELearnTitle(data.ELearnTitle);
                    })
            })

    }
    useEffect(() => {
        reLoadData();
    }, []); /* <-- add this for run once*/
    const editQuestion = (id) => {
        props.history.push({ pathname: "/admin/elearning/EditChapterQuestion", state: { questionID: id, parentChapterID: topParentChapterID, elerningID: elerningID, chapterID: chapterID, questionnaireID: questionnaireID, questionType: questionType } });
    }
    const newQuestionItem = () => {
        props.history.push({ pathname: "/admin/elearning/NewQuestionItem", state: { elerningID: elerningID, parentChapterID: topParentChapterID, chapterID: chapterID, questionnaireID: questionnaireID, questionType: questionType } });
    }
    const editQuestionItem = (id) => {
        props.history.push({ pathname: "/admin/elearning/EditQuestionItem", state: { questionID: id, parentChapterID: topParentChapterID, elerningID: elerningID, chapterID: chapterID, questionnaireID: questionnaireID, questionType: questionType } });
    }
    const deleteQuestionItem = (id) => {
        const formData = new FormData();
        formData.append("questionID", id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteQuestionItem', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        }).then((response) => {
            reLoadData();
        });
    }
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const onShuffle = () => {

        const formData = new FormData();
        formData.append("shuffle", !shuffle);
        formData.append('questionnaireID', questionnaireID);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/UpdateQuestionnaireShuffle', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        }).then((response) => {
            setShuffle(!shuffle);
        });
    }
    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            questionList,
            result.source.index,
            result.destination.index
        );
        //console.log(items)
        setQuestionList(items);
        const formData = new FormData();
        //formData.append("chapterlist", items);
        for (var i = 0; i < items.length; i++) {
            //console.log(listofContent[i])
            formData.append('questionList[]', JSON.stringify(items[i]));

        }
        axios.post(process.env.REACT_APP_APIURL + '/api-web/reOrderQuestionItem', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        }).then((response) => {

        });
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการกิจกรรม"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการอีเลิร์นนิง (E-learning)</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/ELearningList" }}>{"จัดการวิชา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/ESubjectChapter", state: { id: elerningID } }}>วิชา {eLearnTitle}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link to={{ pathname: "/admin/elearning/SubChapterList", state: { chapterID: topParentChapterID } }}>{topParentTitle}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/elearning/ManageQuestionItem", state: { elerningID: elerningID, parentChapterID: topParentChapterID, chapterID: chapterID, QuestionnaireID: questionnaireID, questionType: questionType } }}> {parentChapter.ChapterTitle}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <h1 className="col-12" >({parentChapter.ChapterTitle})</h1 >
                                <div className="col-12">
                                    <div className="custom-control custom-checkbox float-right">
                                        <input type="checkbox" className="custom-control-input" id="shuffle" name="shuffle" checked={shuffle === true} onChange={(e) => { onShuffle() }} />
                                        <label className="custom-control-label mr-2" htmlFor="shuffle">สลับตำแหน่งคำถามเมื่อแสดงผล</label>
                                        <a href="/" onClick={(e) => { e.preventDefault(); editQuestion(questionnaireID) }} className="text-dark mr-2"><i className="fas fa-edit"></i> แก้ไข</a>
                                        {/*<a href="/" onClick={(e) => { e.preventDefault(); deleteQuestion(questionnaireID) }} className="text-dark mr-2"><i className="far fa-trash-alt"></i> ลบ</a>*/}
                                    </div>
                                </div>
                                <div className="col-12">

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {provided => (
                                                <div
                                                    className="list-content"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {(
                                                        <div className="subject-list-item">
                                                            {questionList.map((item, index) => (
                                                                <Draggable
                                                                    key={item.QuestionItemID}
                                                                    draggableId={'item-' + item.QuestionItemID}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className="list-item col-12"
                                                                        >
                                                                            <div
                                                                                className="col-12"
                                                                            >
                                                                                {item.ItemTitle}

                                                                                <div className="float-right">
                                                                                    <a href="/" onClick={(e) => { e.preventDefault(); editQuestionItem(item.QuestionItemID) }} className="text-dark mr-2"><i className="fas fa-edit"></i> แก้ไข</a>
                                                                                    <a href="/" onClick={(e) => { e.preventDefault(); deleteQuestionItem(item.QuestionItemID) }} className="text-dark mr-2"><i className="far fa-trash-alt"></i> ลบ</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <div className="col-12">
                                        <div className="pl-4 pr-4 text-right" >
                                            <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); newQuestionItem(); }}>เพิ่มคำถาม</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}