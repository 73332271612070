import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
//import 'react-summernote/lang/summernote-th-TH'; // you can import any other locale
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SError from 'components/SError';
import noimage from 'assets/images/no_image.svg'
import moment from "moment";
import SingleFileTable from 'components/SingleFileTable';
const swal = withReactContent(Swal)

export default function NewPopup(props) {
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const [popupType,setPopUpType] = useState(0);
    //const [fields, setFields] = useState({});
    const [errors, setError] = useState({});

    const [imgWebCover, setIMGWebCover] = useState([]);
    const [imgMobileCover, setIMGMobileCover] = useState([]);
 
    const [thname, setNameTH] = useState('');
    const [enname, setNameEN] = useState('');

    const [thcontent, setTHContent] = useState("");
    const [encontent, setENContent] = useState("");

    const [publishstartDate, SetPublishStartDate] = useState(new Date());
    const [publishendDate, SetPublishEndDate] = useState(new Date());

    const [urlLink, setURLLink] = useState('');
    const [published, setPublished] = useState(false);

    useEffect(() => {
        let poptype = Number(props.location.state.popupType);
        setPopUpType(poptype);
        setLoading(false);
    }, []); /* <-- add this for run once*/


    useEffect(() => {
        if (moment(publishstartDate).format('YYYY-MM-DD') > moment(publishendDate).format('YYYY-MM-DD')) {
            SetPublishEndDate(publishstartDate);
        }
    }, [publishstartDate]);
    useEffect(() => {
        if (moment(publishendDate).format('YYYY-MM-DD') < moment(publishstartDate).format('YYYY-MM-DD')) {
            SetPublishStartDate(publishendDate);
        }
    }, [publishendDate]);

    const WebCoverPreview = (
        <img className="preview-upload" src={typeof imgWebCover.preview === 'undefined' ? noimage : imgWebCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgWebCover); }} alt="Web Cover Preview" />
    );

    const MobileCoverPreview = (
        <img className="preview-upload" src={typeof imgMobileCover.preview === 'undefined' ? noimage : imgMobileCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgMobileCover); }} alt="Mobile Cover Preview" />
    );

    const handleImgWebCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGWebCover(imageFile);
    };

    const handleImgMobileCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGMobileCover(imageFile);
    };

    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (thname.trim().length === 0) {
            formIsValid = false;
            serrors["thname"] = "กรุณาระบุชื่อภาษาไทย";
        }
        if (thcontent.trim().length === 0) {
            formIsValid = false;
            serrors["thcontent"] = "กรุณาระบุรายละเอียดภาษาไทย";
        }
        if (imgWebCover.length === 0) {
            formIsValid = false;
            serrors["webcover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับเว็บ";
        }
        if (imgMobileCover.length === 0) {
            formIsValid = false;
            serrors["mobilecover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับมือถือ";
        }

        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("popupType", popupType);
            formData.append("thname", thname);
            formData.append("thcontent", thcontent);
            formData.append("enname", enname);
            formData.append("encontent", encontent);            
            formData.append("urlLink", urlLink);
            formData.append("published", published);
            formData.append("publishstartDate", publishstartDate);
            formData.append("publishendDate", publishendDate);
            formData.append("webcoverfile", imgWebCover.file);
            formData.append("mobilecoverfile", imgMobileCover.file);
            formData.append("createby", currentUser.UserID);

            axios.post(process.env.REACT_APP_APIURL + '/api-web/savePopup', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: (popupType==1?"/admin/setting/PopupBeforeLogin":"/admin/setting/PopupAfterLogin").toString() });
                        }
                    })
                });

        }
        else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);

        }
    };
    const cancel = () => {
        props.history.push({ pathname: (popupType==1?"/admin/setting/PopupBeforeLogin":"/admin/setting/PopupAfterLogin").toString() });
    };
    const handleRemoveWebCover = () => {
        setIMGWebCover('undefined');
    };
    const handleRemoveMobileCover = () => {
        setIMGMobileCover('undefined');
    };
    if (isLoading) {
        return <></>;
      }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการป๊อปอัพ"+(popupType==1?"ก่อนล็อกอิน":"หลังล็อกอิน").toString()} path={<>
                <span><i className="fas fa-angle-right"></i></span>                
                <Link id="profile-btn" to={{ pathname: (popupType==1?"/admin/setting/PopupBeforeLogin":"/admin/setting/PopupAfterLogin").toString() }}>{"การจัดการป๊อปอัพ"+(popupType==1?"ก่อนล็อกอิน":"หลังล็อกอิน").toString()}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/setting/NewPopup" }}>{"สร้าง"}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data" className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                            </li>
                        </ul>
                        <div className="tab-content pl-4 pr-4" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="thname" className="required">ชื่อป๊อปอัพ (ภาษาไทย)</label>
                                            <input type="text" className="form-control" id="thname" name="thname" placeholder="กรุณากรอก" value={thname} onChange={(e) => { setNameTH(e.currentTarget.value) }} />
                                            <SError error={errors["thname"]} />
                                        </div>
                                        <div className="form-group">
                                            <label className="required">รายละเอียด (ภาษาไทย)</label>
                                            <ReactSummernote
                                                value={thcontent}
                                                options={{
                                                    //lang: 'th-TH',
                                                    placeholder: "กรุณากรอก",
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setTHContent}
                                            />
                                            <SError error={errors["thcontent"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="enname">ชื่อป๊อปอัพ (ภาษาอังกฤษ)</label>
                                            <input type="text" className="form-control" id="enname" name="enname" placeholder="กรุณากรอก" value={enname} onChange={(e) => { setNameEN(e.currentTarget.value) }} />
                                            <SError error={errors["enname"]} />
                                        </div>
                                        <div className="form-group">
                                            <label>รายละเอียด (ภาษาอังกฤษ)</label>
                                            <ReactSummernote
                                                value={encontent}
                                                options={{
                                                    //lang: 'th-TH',
                                                    placeholder: "กรุณากรอก",
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['style']],
                                                        ['font', ['bold', 'italic', 'underline', 'clear']],
                                                        ['color', ['color']],
                                                        /*['fontname', ['fontname']],*/
                                                        ['para', ['ul', 'ol', 'paragraph']],
                                                        ['table', ['table']],
                                                        ['insert', ['link', 'video']],
                                                        ['view', ['fullscreen']]
                                                    ]
                                                }}
                                                onChange={setENContent}
                                            />
                                            <SError error={errors["encontent"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">

                                <div className="col-5">
                                    <label htmlFor="urlLink">แหล่งเชื่อมโยง</label>
                                    <input type="text" className="form-control " id="urlLink" name="urlLink" placeholder="Link แหล่งเชื่อมโยง" value={urlLink} onChange={(e) => { setURLLink(e.currentTarget.value); }} />
                                    <span className="error">{errors["urlLink"]}</span>
                                </div>
                                <div className="col-7"></div>                                

                                <div className="col-12">
                                    <label className="required">รูปภาพสำหรับเว็บไซต์</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgWebCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {WebCoverPreview}
                                                <SingleFileTable filedata={imgWebCover} removefn={handleRemoveWebCover} />
                                            </section>
                                        )}
                                    </Dropzone>
                                    <span className="error">{errors["webcover"]}</span>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="enname" className="required">รูปภาพสำหรับมือถือ</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgMobileCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {MobileCoverPreview}
                                                <SingleFileTable filedata={imgMobileCover} removefn={handleRemoveMobileCover} />
                                            </section>
                                        )}
                                    </Dropzone>
                                    <span className="error">{errors["mobilecover"]}</span>
                                </div>    

                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published1" value="1" checked={published === true} onChange={(e) => { setPublished(true); }} />
                                        <label className="form-check-label" htmlFor="published1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published2" value="0" checked={published === false} onChange={(e) => { setPublished(false); }} />
                                        <label className="form-check-label" htmlFor="published2"> ไม่เผยแพร่</label>
                                    </div>
                                    <span className="error">{errors["published"]}</span>
                                </div>
                                {published === true && <>
                                    <div className="col-5">
                                        <label className="required">วันที่เผยแพร่</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                                {SetPublishStartDate} name="startpublishday" id="startpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-5">
                                        <label className="required">วันที่สิ้นสุด</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-2"></div>
                                </>}                            

                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}