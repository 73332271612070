import * as React from "react";
const SvgTrackComplaint = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20.043 20.043"
    {...props}
  >
    <g data-name="Group 25312">
      <g data-name="Group 25311">
        <g data-name="Group 25310">
          <g data-name="Group 25309">
            <g data-name="Group 25308">
              <path
                d="M1568.351 1925.846a.835.835 0 0 1-.835-.835v-.464a2.926 2.926 0 0 0-5.821-.371h4.151a.835.835 0 0 1 0 1.67h-5.011a.835.835 0 0 1-.835-.835v-.464a4.593 4.593 0 0 1 9.186 0v.464a.835.835 0 0 1-.835.835"
                data-name="Group 25307"
                style={{
                  color: "#000",
                  fill: "#faab3f",
                  InkscapeStroke: "none",
                }}
                transform="translate(-1557.495 -1905.803)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g data-name="Group 25318">
      <g data-name="Group 25317">
        <g data-name="Group 25316">
          <g data-name="Group 25315">
            <g data-name="Group 25314">
              <path
                d="M1611.626 1746.681c-1.426 0-2.544-1.467-2.544-3.341s1.117-3.34 2.544-3.34 2.544 1.467 2.544 3.34-1.118 3.341-2.544 3.341m0-5.011c-.356 0-.874.651-.874 1.67s.517 1.67.874 1.67.873-.651.873-1.67-.517-1.67-.873-1.67"
                data-name="Group 25313"
                style={{
                  color: "#000",
                  fill: "#faab3f",
                  InkscapeStroke: "none",
                }}
                transform="translate(-1604.527 -1733.319)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g data-name="Group 25324">
      <g data-name="Group 25323">
        <g data-name="Group 25322">
          <g data-name="Group 25321">
            <g data-name="Group 25320">
              <path
                d="M1516.7 1595.867a.83.83 0 0 1-.59-.245l-3.931-3.931h-.907a.835.835 0 1 1 0-1.67h1.253a.84.84 0 0 1 .59.245l2.75 2.75v-2.16a.835.835 0 0 1 .835-.835h1.67v-8.351h-16.7v8.351h1.253a.835.835 0 0 1 0 1.67h-2.088a.835.835 0 0 1-.835-.835v-10.022a.835.835 0 0 1 .835-.835h18.373a.835.835 0 0 1 .835.835v10.022a.835.835 0 0 1-.835.835h-1.67v3.34a.83.83 0 0 1-.516.771.8.8 0 0 1-.322.065"
                data-name="Group 25319"
                style={{
                  color: "#000",
                  fill: "#faab3f",
                  InkscapeStroke: "none",
                }}
                transform="translate(-1500 -1580)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g data-name="Group 25330">
      <g data-name="Group 25329">
        <g data-name="Group 25328">
          <g data-name="Group 25327">
            <g data-name="Group 25326">
              <path
                d="M1592.527 1661.67h-11.692a.835.835 0 0 1 0-1.67h11.692a.835.835 0 1 1 0 1.67"
                data-name="Group 25325"
                style={{
                  color: "#000",
                  fill: "#faab3f",
                  InkscapeStroke: "none",
                }}
                transform="translate(-1576.659 -1656.659)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g data-name="Group 25336">
      <g data-name="Group 25335">
        <g data-name="Group 25334">
          <g data-name="Group 25333">
            <g data-name="Group 25332">
              <path
                d="M1745.846 1721.67h-5.011a.835.835 0 0 1 0-1.67h5.011a.835.835 0 1 1 0 1.67"
                data-name="Group 25331"
                style={{
                  color: "#000",
                  fill: "#faab3f",
                  InkscapeStroke: "none",
                }}
                transform="translate(-1729.979 -1714.154)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgTrackComplaint;
