import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
//import { authenticationService } from 'services/authentication.service.js';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
//import utils from 'utils/utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
// import ReactExport from 'react-data-export';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const swal = withReactContent(Swal)

export default function ContactMessage(props) {
    //const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const [gridApi, setGridApi] = useState(null);
    //const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [contactType, setContactType] = useState([]);
    const [contactUs, setContactUs] = useState([]);
    const [excelDataset, setExcelDataSet] = useState([]);
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getContactUsType')
            .then((response) => {
                setContactType(response.data);
                //console.log(response.data)
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getContactUs')
                    .then((response) => {
                        setContactUs(response.data);
                        setLoading(false);
                    })
            })

    }, []); /* <-- add this for run once*/
    const handleDelete = (params) => {
        let timerInterval;
        swal.fire({
            title: "ยืนยันการลบข้อมูล",
            showDenyButton: true,
            /*showCancelButton: true,*/
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                swal.fire({
                    title: "ลบข้อมูลเรียบร้อย", showConfirmButton: false,
                    timer: 1000, timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    deleteSingleRow(params);
                })

            } else if (result.isDenied) {
                //swal.fire('Changes are not saved', '', 'info')
            }
        })
    };
    const handleDeleteAll = (params) => {
        let selectedNodes = gridApi.getSelectedNodes();
        if (selectedNodes.length > 0) {
            let timerInterval;
            swal.fire({
                title: "ยืนยันการลบข้อมูลทั้งหมดที่เลือก",
                showDenyButton: true,
                /*showCancelButton: true,*/
                confirmButtonText: "ยืนยัน",
                denyButtonText: "ยกเลิก",
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    swal.fire({
                        title: "ลบข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        deleteRowData();
                    })

                } else if (result.isDenied) {
                    //swal.fire('Changes are not saved', '', 'info')
                }
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณาเลือกข้อมูลอย่างน้อยหนึ่งชุด',
            })
        }
    };
    const borders = {
        top: { style: "thin", color: { rgb: "FF000000" } },
        bottom: { style: "thin", color: { rgb: "FF000000" } },
        left: { style: "thin", color: { rgb: "FF000000" } },
        right: { style: "thin", color: { rgb: "FF000000" } }
    }
    const centerAlignment = {
        horizontal: "center",
        wrapText: true
    }
    const leftAlignment = {
        horizontal: "top",
        wrapText: true
    }
    function SetExcelData(data) {
        //console.log(data)
        let multiDataset = [{ columns: [], data: [] }];
        multiDataset[0].columns = [
            { title: "ลำดับ", width: { wpx: '80' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },
            { title: "หน่วยงาน", width: { wpx: '300' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//pixels width 
            { title: "ชื่อ - นามสกุล", width: { wpx: '260' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//char width             
            { title: "ประเภทการติดต่อ", width: { wpx: '160' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//char width 
            { title: "เรื่องติดต่อ", width: { wpx: '300' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//char width 
            { title: "รายละเอียด", width: { wpx: '450' }, style: { alignment: centerAlignment, border: borders, font: { bold: true } } },//char width 
        ];
        multiDataset[0].data = [];
        data.map((item, i) => {
            let row = [];
            let col = { value: (i + 1), style: { alignment: centerAlignment, border: borders } };
            row.push(col);
            const contact = contactUs.find(c => Number(c.ContactID) == Number(item.ContactID));
            let OrgName = '';
            if (typeof contact !== 'undefined')
                OrgName = contact.OrgName;
            col = { value: OrgName, style: { alignment: centerAlignment, border: borders } };
            row.push(col);
            col = { value: item.Name, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            const contacttype = contactType.find(c => Number(c.ContactTypeID) == Number(item.ContactTypeID));
            let typename = '';
            if (typeof contacttype !== 'undefined')
                typename = contacttype.ContactTypeNameTH;
            col = { value: typename, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.Title, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            col = { value: item.Description, style: { alignment: leftAlignment, border: borders } };
            row.push(col);
            multiDataset[0].data.push(row);
        });
        //console.log(multiDataset)
        setExcelDataSet(multiDataset)
    }
    const onGridReady = (params) => {
        setGridApi(params.api);
        //setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        const updateData = (data) => {
            setRowData(data);
        };

        axios.get(process.env.REACT_APP_APIURL + '/api-web/getContactUsMessage').then((response) => {
            updateData(response.data);
            SetExcelData(response.data);
            //console.log(response.data)
        })

    };
    var checkboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
    };
    var headerCheckboxSelection = function (params) {
        return params.columnApi.getRowGroupColumns().length === 0;
    };
    const getRowHeight = (params) => {
        //console.log(params.data.rowHeight);
        //return params.data.rowHeight;
        return 80;
    };
    const handleView = params => {
        props.history.push({ pathname: "/admin/contact/ViewContactMessage", state: { id: params.data.ContactMessageID } });
        //window.location.reload();
    };

    const contactRender = params => {
        const contact = contactUs.find(c => Number(c.ContactID) == Number(params.data.ContactID));
        if (typeof contact !== 'undefined')
            return contact.OrgName;
        return '';
    }
    const contactTypeRender = params => {
        const contact = contactType.find(c => Number(c.ContactTypeID) == Number(params.data.ContactTypeID));
        if (typeof contact !== 'undefined')
            return contact.ContactTypeNameTH;
        return '';
    }
    const RowTool = params => {
        let tool = null;
        tool = (
            <span className="row-tool">
                <button type="button" className="btn btn-primary btn-sm wd-40" onClick={() => { handleView(params); }}>ดู</button>
                <button type="button" className="btn btn-danger btn-sm wd-40" onClick={() => { handleDelete(params); }} >ลบ</button>
            </span>
        );
        return tool;
    };
    var hashValueGetter = function (params) {
        return params.node.rowIndex + 1;
    };
    const onPageSizeChanged = (newPageSize) => {
        var value = document.getElementById('page-size').value;
        gridApi.paginationSetPageSize(Number(value));
    };
    const searchRef = React.createRef();
    const detailRef = React.createRef();
    const contactTypeRef = React.createRef();
    const contactRef = React.createRef();

    const searchData = () => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchContactUsMessage', { params: { search: searchRef.current.value, detail: detailRef.current.value, contactType: contactTypeRef.current.value, contact: contactRef.current.value } }).then((response) => {
            setRowData(response.data);
            SetExcelData(response.data);
        })
    };
    const resetsearch = () => {
        searchRef.current.value = "";
        contactTypeRef.current.value = "-1";
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getContactUsMessage').then((response) => {
            setRowData(response.data);
            SetExcelData(response.data);
            //console.log(response.data)
        })
    };

    const deleteSingleRow = params => {
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteContactMessage', null, { params: { id: params.data.ContactMessageID } })
            .then((response) => {
                axios.get(process.env.REACT_APP_APIURL + '/api-web/getContactUsMessage').then((response) => {
                    setRowData(response.data);
                    //console.log(response.data)
                })
            })
    };
    const deleteRowData = () => {
        let selectedNodes = gridApi.getSelectedNodes();
        selectedNodes.map((row, i) => {
            axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteContactMessage', null, { params: { id: row.data.ContactMessageID } })
                .then((response) => {
                    //setRowData(response.data);
                })
        });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getContactUsMessage').then((response) => {
            setRowData(response.data);
            //console.log(response.data)
        })
    };

    const ContactTypeSelect = contactType.map((item) => (
        <option
            key={item.ContactTypeID}
            value={item.ContactTypeID}
        >
            {item.ContactTypeNameTH}
        </option>
    ));

    const ContactSelect = contactUs.map((item) => (
        <option
            key={item.ContactID}
            value={item.ContactID}
        >
            {item.OrgName}
        </option>
    ));
    if (isLoading) {
        return (<></>);
    }
    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"จัดการการติดต่อ"} path={
                <>
                    <span><i className="fas fa-angle-right"></i></span>
                    <a href="/" onClick={e => { e.preventDefault(); }}>จัดการการติดต่อ</a>
                    <span><i className="fas fa-angle-right"></i></span>
                    <Link className="active" to={{ pathname: "/admin/contact/ContactMessage" }}>{"จัดการผู้มาติดต่อ"}</Link>
                </>}
            />
            <div className="col-12">
                <form className="form-inline form-border">

                    <div className="form-group mx-sm mb-2 col-3 col-lg-3 col-md-2 pr-0 mt-2">
                        <input type="text" ref={searchRef} className="form-control mr-0" id="searchfield" name="searchfield" placeholder="ชื่อ- นามสกุล" />
                    </div>
                    <div className="form-group mx-sm mb-2 col-2 pr-0 mt-2">
                        <select className="form-control" ref={contactTypeRef} >
                            <option value="-1">ประเภทการติดต่อ</option>
                            {ContactTypeSelect}
                        </select>
                    </div>
                    <div className="form-group mx-sm mb-2 col-3 col-lg-2 col-md-2 pr-0 mt-2 pr-0" >
                        <input type="text" ref={detailRef} className="form-control mr-0" id="searchnumber" name="searchnumber" placeholder="รายละเอียด" />
                    </div>
                    <div className="form-group mx-sm mb-2 col-2  mt-2">
                        <select className="form-control" ref={contactRef} >
                            <option value="-1">หน่วยงาน</option>
                            {ContactSelect}
                        </select>
                    </div>
                    <div className="button-group col-2">
                        <button type="button" className="btn btn-info mr-2" onClick={e => { e.preventDefault(); searchData(); }}>ค้นหา</button>
                        <button type="submit" className="btn btn-light" onClick={e => { e.preventDefault(); resetsearch(); }}>ล้างคำค้นหา</button>
                    </div>

                </form>
            </div>
            <div className="col-12">
                <div className="form-border">
                    <div className="col-12">
                        <div className="form-inline button-group">
                            {/* <ExcelFile filename="Contact Message Report" element={<button type="button" className="btn btn-success mb-2 mr-2" ><i className="far fa-file-excel"></i> ส่งออก</button>}> */}
                                {/* <ExcelSheet dataSet={excelDataset} name="Contact Message" /> */}
                                {/*<ExcelSheet data={rowData} name="Member">
                            <ExcelColumn label="ชื่อ-นามสกุล" value="FullName"  />
                            <ExcelColumn label="ตำแหน่ง" value="Position" />
                            <ExcelColumn label="หน่วยงาน" value="Department" />
                            <ExcelColumn label="หมายเลขโทรศัพท์" value="Telephone" />
                            <ExcelColumn label="อีเมล" value="Email" />
                            <ExcelColumn label="สถานะ"
                                value={(col) => col.IsCanceled ? "ไม่เข้าร่วม" : "เข้าร่วม"} />
                        </ExcelSheet>*/}
                                {/*<ExcelSheet data={dataSet2} name="Leaves">
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Total Leaves" value="total" />
                            <ExcelColumn label="Remaining Leaves" value="remaining" />
    </ExcelSheet>*/}
                            {/* </ExcelFile> */}
                            <button type="button" className="btn btn-secondary mb-2" onClick={e => { e.preventDefault(); handleDeleteAll(); }}><i className="fas fa-trash-alt"></i> ลบ</button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="ag-theme-alpine" style={{ height: 900 }}>
                            <AgGridReact
                                autoGroupColumnDef={{
                                    headerName: 'Group',
                                    minWidth: 30,
                                    field: 'CategoriesID',
                                    valueGetter: function (params) {
                                        if (params.node.group) {
                                            return params.node.key;
                                        } else {
                                            return params.data[params.colDef.field];
                                        }
                                    },
                                    headerCheckboxSelection: true,
                                    cellRenderer: 'agGroupCellRenderer',
                                    cellRendererParams: { checkbox: true },
                                }}

                                modules={AllCommunityModules}
                                frameworkComponents={{
                                    rowTool: RowTool,
                                    /*activityRenderer:activityRenderer,
                                    appealRenderer:appealRenderer,
                                    terminateRenderer:terminateRenderer,*/
                                }}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    filter: false,
                                    //cellClass: "cell-border cell-vertical-align-text-left",
                                }}
                                getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                rowData={rowData}
                                rowSelection={'multiple'}
                                rowDragManaged={true}
                                pagination={true}
                                paginationPageSize={10}
                            >
                                <AgGridColumn
                                    field=""
                                    headerName=""
                                    width={50}
                                    checkboxSelection={checkboxSelection}
                                    headerCheckboxSelection={headerCheckboxSelection}
                                    sortable={true} resizable={false}
                                />
                                <AgGridColumn field="" valueGetter={hashValueGetter} headerName="ลำดับ" minWidth={70} cellClass="cell-border cell-vertical-align-text-left" />
                                <AgGridColumn field="ContactID" headerName="หน่วยงาน" cellRenderer={contactRender} minWidth={300} />
                                <AgGridColumn field="Name" headerName="ชื่อ - นามสกุล" minWidth={200} />
                                <AgGridColumn field="ContactType" headerName="ประเภทการติดต่อ" cellRenderer={contactTypeRender} sortable={true} minWidth={200} />
                                <AgGridColumn field="Title" headerName="เรื่องติดต่อ" sortable={true} minWidth={200} />
                                <AgGridColumn field="ContactMessageID" headerName="จัดการ" cellRenderer="rowTool" minWidth={120} sortable={false} filter={false} resizable={false} />
                            </AgGridReact>
                            {<div className="page-size formContent">
                                <p>Page size:</p>
                                <select className="custom-select" defaultValue={10} onChange={() => onPageSizeChanged()} id="page-size">
                                    <option value="10" >10</option>
                                    <option value="100">25</option>
                                    <option value="500">50</option>
                                    <option value="1000">100</option>
                                </select>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
