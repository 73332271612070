import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/network.svg";
import Dropzone from "react-dropzone";
import utils from "utils/utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Scroll from "react-scroll";
import { authenticationService } from "services/authentication.service.js";
import SingleFileTable from "components/SingleFileTable";
//import RegNetworkActivity from 'components/RegNetworkActivity.js'
import SError from "components/SError";
const swal = withReactContent(Swal);
export default function CoorperationRegister(props) {
  const currentUser = authenticationService.currentUserValue;
  const [isLoading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(false);
  const language = localStorage.getItem("language");
  const [errors, setError] = useState({});
  const [netType, SetNetType] = useState("");
  const [organization, SetOrganization] = useState("");
  const [netHouseNo, SetNetHouseNo] = useState("");
  const [provinceCode, SetProvinceCode] = useState(0);
  const [districtCode, SetDistrictCode] = useState(0);
  const [subDistricCode, SetSubDistricCode] = useState(0);
  const [postCode, SetPostCode] = useState("");
  const [phone, SetPhone] = useState("");
  const [fax, SetFax] = useState("");
  const [coTitleID, SetCoTitleID] = useState(0);
  const [coFirstName, SetCoFirstName] = useState("");
  const [coLastName, SetCoLastName] = useState("");
  const [coPosition, SetCoPosition] = useState("");
  const [coPhone, SetCoPhone] = useState("");
  const [coFax, SetCoFax] = useState("");
  const [coEmail, SetCoEmail] = useState("");

  const [coProperty1, SetCoProperty1] = useState(false);
  const [coProperty2, SetCoProperty2] = useState(false);
  const [coProperty3, SetCoProperty3] = useState(false);
  const [coProperty4, SetCoProperty4] = useState(false);

  const [coProperty1Extra, SetCoProperty1Extra] = useState("");
  const [titleList, SetTitleList] = useState([]);
  const [coOtherTitleTH, SetCoOtherTitleTH] = useState("");
  const [coOtherTitleEN, SetCoOtherTitleEN] = useState("");
  const [provinceList, SetProvinceList] = useState([]);
  const [allDistrictList, SetAllDistrictList] = useState([]);
  const [allSubDistrictList, SetAllSubDistrictList] = useState([]);
  const [districtList, SetDistrictList] = useState([]);
  const [subDistrictList, SetSubDistrictList] = useState([]);

  //const [filespreview, setFilesPreview] = useState([]);
  var scroll = Scroll.animateScroll;
  var scroller = Scroll.scroller;
  /*const [networkActivities, setnetworkActivities] = useState({
        organization: '',
        operation1: false, operation2: false, operation3: false, operation4: false, operation5: false, operation6: false, operation7: false,
        year1: '', activityName1: '', activityDetail1: '', networkActivityFiles1: [], imageFiles1: [], accept: false
    });*/
  /*const [actOrganization, SetActOrganization] = useState('');
    const [operation1, SetOperation1] = useState(false);
    const [operation2, SetOperation2] = useState(false);
    const [operation3, SetOperation3] = useState(false);
    const [operation4, SetOperation4] = useState(false);
    const [operation5, SetOperation5] = useState(false);
    const [operation6, SetOperation6] = useState(false);
    const [operation7, SetOperation7] = useState(false);*/
  const [year1, SetYear1] = useState("");
  const [activityName1, SetActivityName1] = useState("");
  const [activityDetail1, SetActivityDetail1] = useState("");
  const [networkActivityFiles1, SetNetworkActivityFiles1] = useState([]);
  const [imageFiles1, SetImageFiles1] = useState([]);
  const [networkActivityFiles1preview, SetNetworkActivityFiles1Preview] = useState([]);
  const [imageFiles1preview, SetImageFiles1Preview] = useState([]);

  const [year2, SetYear2] = useState("");
  const [activityName2, SetActivityName2] = useState("");
  const [activityDetail2, SetActivityDetail2] = useState("");
  const [networkActivityFiles2, SetNetworkActivityFiles2] = useState([]);
  const [imageFiles2, SetImageFiles2] = useState([]);
  const [networkActivityFiles2preview, SetNetworkActivityFiles2Preview] = useState([]);
  const [imageFiles2preview, SetImageFiles2Preview] = useState([]);

  const [year3, SetYear3] = useState("");
  const [activityName3, SetActivityName3] = useState("");
  const [activityDetail3, SetActivityDetail3] = useState("");
  const [networkActivityFiles3, SetNetworkActivityFiles3] = useState([]);
  const [imageFiles3, SetImageFiles3] = useState([]);
  const [networkActivityFiles3preview, SetNetworkActivityFiles3Preview] = useState([]);
  const [imageFiles3preview, SetImageFiles3Preview] = useState([]);

  const [logoFile, SetLogoFile] = useState([]);
  const [activitylist, setActivityList] = useState([]);
  let activitytmplist = [];
  //const [filespreview, setFilesPreview] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [selecttopicList1, setSelectTopicList1] = useState([]);
  const [selecttopicList2, setSelectTopicList2] = useState([]);
  const [selecttopicList3, setSelectTopicList3] = useState([]);

  const [copyOrganizeDoc, setCopyOrganizeDoc] = useState([]);
  const [copyOrganizeTarget, setCopyOrganizeTarget] = useState([]);
  const [copyOrganizeCommittee, setCopyOrganizeCommittee] = useState([]);
  const [copyOrganizeReport, setCopyOrganizeReport] = useState([]);

  const [copyOrganizeDocpreview, setCopyOrganizeDocPreview] = useState([]);
  const [copyOrganizeTargetPreview, setCopyOrganizeTargetPreview] = useState([]);
  const [copyOrganizeCommitteePreview, setCopyOrganizeCommitteePreview] = useState([]);
  const [copyOrganizeReportPreview, setCopyOrganizeReportPreview] = useState([]);

  const [copyPROEvidence, setCopyPROEvidence] = useState([]);
  const [copyPROAppoint, setCopyPROAppoint] = useState([]);
  const [copyPROApprove, setCopyPROApprove] = useState([]);

  let seltopiclist1 = [];
  let seltopiclist2 = [];
  let seltopiclist3 = [];

  //const [member, setMember] = useState({});
  //const [actID, SetActID] = useState(0);
  useMemo(() => { });
  useEffect(() => {
    SetNetType(props.location.state.netType);
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/memberbyID", {
        params: { id: currentUser.UserID },
      })
      .then((response) => {
        //console.log(response.data)
        SetCoTitleID(response.data[0].TitleID);
        SetCoFirstName(response.data[0].FirstnameTH);
        SetCoLastName(response.data[0].LastnameTH);
        SetCoPhone(response.data[0].Phone);
        SetCoEmail(response.data[0].Email);
        //setMember(response.data);
        axios
          .get(
            process.env.REACT_APP_APIURL + "/api-web/searchMemberInterestTopic",
            { params: { status: 1 } }
          )
          .then((response) => {
            setTopicList(response.data);
          });
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getTitle")
          .then((response) => {
            SetTitleList(response.data);
          });
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getProvince")
          .then((response) => {
            SetProvinceList(response.data);
          });
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getDistrict")
          .then((response) => {
            SetDistrictList(response.data);
            SetAllDistrictList(response.data);
          });
        axios
          .get(process.env.REACT_APP_APIURL + "/api-web/getSubDistrict")
          .then((response) => {
            //console.log(response.data)
            SetSubDistrictList(response.data);
            SetAllSubDistrictList(response.data);
          });
        let getallData = {
          selecttopicList1: selecttopicList1,
          year1: year1,
          activityName1: activityName1,
          activityDetail1: activityDetail1,
          networkActivityFiles1: networkActivityFiles1,
          imageFiles1: imageFiles1,
        };
        activitytmplist.push(getallData);
        setActivityList(activitytmplist);
        setLoading(false);
      });
  }, []); /* <-- add this for run once*/

  const handleChangeProvince = (ProvinceID) => {
    SetDistrictList(
      allDistrictList.filter(
        (item) => item["ProvinceID"] === Number(ProvinceID)
      )
    );
    SetSubDistrictList(
      allSubDistrictList.filter(
        (item) => item["ProvinceID"] === Number(ProvinceID)
      )
    );
    SetDistrictCode(0);
    SetSubDistricCode(0);
  };
  const handleChangeDistrict = (DistrictID) => {
    SetSubDistrictList(
      allSubDistrictList.filter(
        (item) => item["DistrictID"] === Number(DistrictID)
      )
    );
    SetSubDistricCode(0);
  };
  const handleChangeSubDistrict = (SubDistrictID) => {
    const subdistrictitem = allSubDistrictList.filter(
      (item) => item["SubDistrictID"] === Number(SubDistrictID)
    );
    if (subdistrictitem[0] != null) {
      SetPostCode(subdistrictitem[0].PostCode);
    } else {
      SetPostCode("");
    }
  };
  const rx_int = /^\d+$/;

  const handlepostCodeChange = (e) => {
    if (rx_int.test(e.target.value)) {
      SetPostCode(e.target.value);
    }
  };
  const rx_phone = /(\d{1})+(\-)?$/;
  const handlePhoneChange = (e) => {
    if (e.target.value === '') {
      SetPhone('');
    }
    else if (rx_phone.test(e.target.value)) {
      SetPhone(e.target.value);
    }
  };
  const handleFaxChange = (e) => {
    if (e.target.value === '') {
      SetFax('');
    }
    else if (rx_phone.test(e.target.value)) {
      SetFax(e.target.value);
    }
  };
  const handleCoPhoneChange = (e) => {
    if (e.target.value === '') {
      SetCoPhone('');
    }
    else if (rx_phone.test(e.target.value)) {
      SetCoPhone(e.target.value);
    }
  };
  const handleCoFaxChange = (e) => {
    if (e.target.value === '') {
      SetCoFax('');
    }
    else if (rx_phone.test(e.target.value)) {
      SetCoFax(e.target.value);
    }
  };

  const TitleSelect = titleList.map((title) => (
    <option key={title.TitleID} value={title.TitleID}>
      {title.TitleNameTH}
    </option>
  ));
  const ProvinceSelect = provinceList.map((province) => (
    <option key={province.ProvinceID} value={province.ProvinceID}>
      {province.ProvinceTH}
    </option>
  ));
  const DistrictSelect = districtList.map((district) => (
    <option key={district.DistrictID} value={district.DistrictID}>
      {district.DistrictTH}
    </option>
  ));
  const SubDistrictSelect = subDistrictList.map((subdistrict) => (
    <option key={subdistrict.SubDistrictID} value={subdistrict.SubDistrictID}>
      {subdistrict.SubDistrictTH}
    </option>
  ));

  const handleLogoOnDrop = (newImageFile, onChange) => {
    const imageFile = {
      file: newImageFile[0],
      name: newImageFile[0].name,
      preview: URL.createObjectURL(newImageFile[0]),
      size: newImageFile[0].size,
    };
    SetLogoFile(imageFile);
  };
  const LogoPreview = typeof logoFile.preview !== "undefined" && (
    <img
      className="preview-upload"
      src={logoFile.preview}
      onChange={(e) => {
        e.target.src = URL.createObjectURL(logoFile);
      }}
      alt="Web Cover"
    />
  );
  const nextPageFunction = (isnext) => {
    let serrors = {};
    let formIsValid = true;
    if (organization.trim().length === 0) {
      formIsValid = false;
      serrors["organization"] = "กรุณาระบุชื่อ";
    }
    if (netHouseNo.trim().length === 0) {
      formIsValid = false;
      serrors["netHouseNo"] = "กรุณาระบุรายละเอียด";
    }
    if (Number(provinceCode) === 0) {
      formIsValid = false;
      serrors["provinceCode"] = "กรุณาเลือกจังหวัด";
    }
    if (Number(districtCode) === 0) {
      formIsValid = false;
      serrors["districtCode"] = "กรุณาเลือกเขต/อำเภอ";
    }
    if (Number(subDistricCode) === 0) {
      formIsValid = false;
      serrors["subDistricCode"] = "กรุณาเลือกแขวง/ตำบล";
    }
    if (postCode.trim().length === 0) {
      formIsValid = false;
      serrors["postCode"] = "กรุณากรอกรหัสไปรษณีย์";
    }
    if (phone.trim().length === 0) {
      formIsValid = false;
      serrors["phone"] = "กรุณาระบุรายละเอียด";
    }
    //fax
    if (Number(coTitleID) === 0) {
      formIsValid = false;
      serrors["coTitleID"] = "กรุณาเลือกคำนำหน้าชื่อ";
    }
    if (Number(coTitleID) === -1) {
      if (coOtherTitleTH.trim().length === 0) {
        formIsValid = false;
        serrors["coOtherTitleTH"] = "กรุณาระบุคำนำหน้าชื่อภาษาไทยที่ต้องการ";
      }
      if (coOtherTitleEN.trim().length === 0) {
        formIsValid = false;
        serrors["coOtherTitleEN"] = "กรุณาระบุคำนำหน้าชื่อภาษาอังกฤษที่ต้องการ";
      }
    }
    if (coFirstName.trim().length === 0) {
      formIsValid = false;
      serrors["coFirstName"] = "กรุณาระบุรายละเอียด";
    }
    if (coLastName.trim().length === 0) {
      formIsValid = false;
      serrors["coLastName"] = "กรุณาระบุรายละเอียด";
    }
    if (coPosition.trim().length === 0) {
      formIsValid = false;
      serrors["coPosition"] = "กรุณาระบุรายละเอียด";
    }
    if (coPhone.trim().length === 0) {
      formIsValid = false;
      serrors["coPhone"] = "กรุณาระบุรายละเอียด";
    }
    //coFax
    if (coEmail.trim().length === 0) {
      formIsValid = false;
      serrors["coEmail"] = "กรุณาระบุรายละเอียด";
    } else {
      let lastAtPos = coEmail.lastIndexOf("@");
      let lastDotPos = coEmail.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          coEmail.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          coEmail.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        serrors["coEmail"] = "รูปแบบของอีเมลไม่ถูกต้อง";
      }
    }
    if (
      coProperty1 === false &&
      coProperty2 === false &&
      coProperty3 === false &&
      coProperty4 === false
    ) {
      formIsValid = false;
      serrors["coProperty"] =
        "กรุณายอมรับการรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร";
    }
    if (coProperty1 === true && netType === "ORG") {
      if (coProperty1Extra.trim().length === 0) {
        serrors["coProperty1Extra"] = "โปรดระบุกฎหมายและมาตราที่เกี่ยวข้อง";
      }
    }
    if (logoFile.length === 0) {
      formIsValid = false;
      serrors["logoFile"] = "กรุณาเลือกไฟล์ภาพโลโก้สำหรับองค์กร";
    }
    if (copyOrganizeDoc.length === 0) {
      formIsValid = false;
      serrors["copyOrganizeDoc"] =
        "กรุณาเลือกไฟล์สำเนาหนังสือรับรองหรือหนังสือสำคัญแสดงการจัดตั้ง";
    }
    if (copyOrganizeTarget.length === 0) {
      formIsValid = false;
      serrors["copyOrganizeTarget"] =
        "กรุณาเลือกไฟล์สำเนาข้อบังคับหรือระเบียบขององค์กรที่ระบุวัตถุประสงค์ และโครงสร้างขององค์กร";
    }
    if (copyOrganizeCommittee.length === 0) {
      formIsValid = false;
      serrors["copyOrganizeCommittee"] =
        "กรุณาเลือกไฟล์หลักฐานแสดงการแต่งตั้งกรรมการขององค์กร";
    }
    if (copyOrganizeReport.length === 0) {
      formIsValid = false;
      serrors["copyOrganizeReport"] =
        "กรุณาเลือกไฟล์รายงานผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้อง";
    }
    setError(serrors);
    //console.log(selecttopicList1)
    if (formIsValid === true) {
      if (isnext) {
        setNextPage(true);
      } else {
        setNextPage(false);
      }
      scroll.scrollToTop({duration: 800,
        delay: 0,
        offset: -50,
        smooth: "easeInOutQuart",})
    } else {
      setTimeout(() => {
        scroller.scrollTo("error", {
          duration: 800,
          delay: 0,
          offset: -50,
          smooth: "easeInOutQuart",
        });
      }, 100);
    }
  };

  const handleValidation = () => {
    let serrors = {};
    let formIsValid = true;
    if (organization.trim().length === 0) {
      formIsValid = false;
      serrors["organization"] = "กรุณาระบุชื่อ";
    }
    if (netHouseNo.trim().length === 0) {
      formIsValid = false;
      serrors["netHouseNo"] = "กรุณาระบุรายละเอียด";
    }
    if (Number(provinceCode) === 0) {
      formIsValid = false;
      serrors["provinceCode"] = "กรุณาเลือกจังหวัด";
    }
    if (Number(districtCode) === 0) {
      formIsValid = false;
      serrors["districtCode"] = "กรุณาเลือกเขต/อำเภอ";
    }
    if (Number(subDistricCode) === 0) {
      formIsValid = false;
      serrors["subDistricCode"] = "กรุณาเลือกแขวง/ตำบล";
    }
    if (postCode.trim().length === 0) {
      formIsValid = false;
      serrors["postCode"] = "กรุณากรอกรหัสไปรษณีย์";
    }
    if (phone.trim().length === 0) {
      formIsValid = false;
      serrors["phone"] = "กรุณาระบุรายละเอียด";
    }
    if (coFirstName.trim().length === 0) {
      formIsValid = false;
      serrors["coFirstName"] = "กรุณาระบุรายละเอียด";
    }
    if (coLastName.trim().length === 0) {
      formIsValid = false;
      serrors["coLastName"] = "กรุณาระบุรายละเอียด";
    }
    if (coPosition.trim().length === 0) {
      formIsValid = false;
      serrors["coPosition"] = "กรุณาระบุรายละเอียด";
    }
    if (coPhone.trim().length === 0) {
      formIsValid = false;
      serrors["coPhone"] = "กรุณาระบุรายละเอียด";
    }
    //coFax
    if (coEmail.trim().length === 0) {
      formIsValid = false;
      serrors["coEmail"] = "กรุณาระบุรายละเอียด";
    } else {
      let lastAtPos = coEmail.lastIndexOf("@");
      let lastDotPos = coEmail.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          coEmail.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          coEmail.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        serrors["coEmail"] = "รูปแบบของอีเมลไม่ถูกต้อง";
      }
    }
    if (logoFile.length === 0) {
      formIsValid = false;
      serrors["logoFile"] = "กรุณาเลือกไฟล์ภาพโลโก้สำหรับองค์กร";
    }
    if (netType === "ORG") {
      if (
        coProperty1 === false &&
        coProperty2 === false &&
        coProperty3 === false &&
        coProperty4 === false
      ) {
        formIsValid = false;
        serrors["coProperty"] =
          "กรุณารับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร";
      }
      if (copyOrganizeDoc === null) {
        formIsValid = false;
        serrors["copyOrganizeDoc"] =
          "กรุณาเลือกไฟล์สำเนาหนังสือรับรองหรือหนังสือสำคัญแสดงการจัดตั้ง";
      } else if (copyOrganizeDoc.length === 0) {
        formIsValid = false;
        serrors["copyOrganizeDoc"] =
          "กรุณาเลือกไฟล์สำเนาหนังสือรับรองหรือหนังสือสำคัญแสดงการจัดตั้ง";
      }
      if (copyOrganizeTarget === null) {
        formIsValid = false;
        serrors["copyOrganizeTarget"] =
          "กรุณาเลือกไฟล์สำเนาข้อบังคับหรือระเบียบขององค์กรที่ระบุวัตถุประสงค์ และโครงสร้างขององค์กร";
      } else if (copyOrganizeTarget.length === 0) {
        formIsValid = false;
        serrors["copyOrganizeTarget"] =
          "กรุณาเลือกไฟล์สำเนาข้อบังคับหรือระเบียบขององค์กรที่ระบุวัตถุประสงค์ และโครงสร้างขององค์กร";
      }
      if (copyOrganizeCommittee === null) {
        formIsValid = false;
        serrors["copyOrganizeCommittee"] =
          "กรุณาเลือกไฟล์หลักฐานแสดงการแต่งตั้งกรรมการขององค์กร";
      } else if (copyOrganizeCommittee.length === 0) {
        formIsValid = false;
        serrors["copyOrganizeCommittee"] =
          "กรุณาเลือกไฟล์หลักฐานแสดงการแต่งตั้งกรรมการขององค์กร";
      }
      if (copyOrganizeReport === null) {
        formIsValid = false;
        serrors["copyOrganizeReport"] =
          "กรุณาเลือกไฟล์รายงานผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้อง";
      } else if (copyOrganizeReport.length === 0) {
        formIsValid = false;
        serrors["copyOrganizeReport"] =
          "กรุณาเลือกไฟล์รายงานผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้อง";
      }
    } else {
      if (
        coProperty1 === false &&
        coProperty2 === false &&
        coProperty3 === false
      ) {
        formIsValid = false;
        serrors["coProperty"] =
          "กรุณารับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร";
      }
      if (
        coProperty1 === false &&
        coProperty2 === false &&
        coProperty3 === false &&
        coProperty4 === false
      ) {
        formIsValid = false;
        serrors["accept"] =
          "กรุณายอมรับการรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร";
      }
      if (copyPROEvidence === null) {
        formIsValid = false;
        serrors["copyPROEvidence"] =
          "กรุณาเลือกไฟล์หลักฐานการเป็นสภาวิชาชีพตามกฎหมายไทย";
      } else if (copyPROEvidence.length === 0) {
        formIsValid = false;
        serrors["copyPROEvidence"] =
          "กรุณาเลือกไฟล์หลักฐานการเป็นสภาวิชาชีพตามกฎหมายไทย";
      }
      if (copyPROAppoint === null) {
        formIsValid = false;
        serrors["copyPROAppoint"] =
          "กรุณาเลือกไฟล์สำเนาหนังสือรับรองคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ";
      } else if (copyPROAppoint.length === 0) {
        formIsValid = false;
        serrors["copyPROAppoint"] =
          "กรุณาเลือกไฟล์สำเนาหนังสือรับรองคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ";
      }
      if (copyPROApprove === null) {
        formIsValid = false;
        serrors["copyPROApprove"] =
          "กรุณาเลือกไฟล์หลักฐานการให้ความเห็นชอบในการยื่นคำขอจด";
      } else if (copyPROApprove.length === 0) {
        formIsValid = false;
        serrors["copyPROApprove"] =
          "กรุณาเลือกไฟล์หลักฐานการให้ความเห็นชอบในการยื่นคำขอจด";
      }
    }
    if (coProperty1 === true && netType === "ORG") {
      if (coProperty1Extra.trim().length === 0) {
        serrors["coProperty1Extra"] = "โปรดระบุกฎหมายและมาตราที่เกี่ยวข้อง";
      }
    }
    if (topicList.length === 0) {
      formIsValid = false;
      //console.log(seltopiclist1)
      serrors["seltopiclist1"] =
        "โปรดเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชนอย่างน้อยหนึ่งรายการ";
    }

    setError(serrors);
    //console.log(serrors)
    return formIsValid;
  };
  const saveData = () => {
    if (netType === "ORG") {
      if (checkActivity() === false) {
        setTimeout(() => {
          scroller.scrollTo("error", {
            duration: 800,
            delay: 0,
            offset: -50,
            smooth: "easeInOutQuart",
          });
        }, 100);
        return;
      }
    }

    if (handleValidation()) {
      const formData = new FormData();
      formData.append("netType", netType);
      formData.append("userID", currentUser.UserID);
      formData.append("organization", organization);
      formData.append("netHouseNo", netHouseNo);
      formData.append("provinceCode", provinceCode);
      formData.append("districtCode", districtCode);
      formData.append("subDistricCode", subDistricCode);
      formData.append("postCode", postCode);
      formData.append("phone", phone);
      formData.append("fax", fax);
      formData.append("coTitleID", coTitleID);
      formData.append("coOtherTitleTH", coOtherTitleTH);
      formData.append("coOtherTitleEN", coOtherTitleEN);
      formData.append("coFirstName", coFirstName);
      formData.append("coLastName", coLastName);
      formData.append("coPosition", coPosition);
      formData.append("coPhone", coPhone);
      formData.append("coFax", coFax);
      formData.append("coEmail", coEmail);
      formData.append("coProperty1", coProperty1);
      formData.append("coProperty2", coProperty2);
      formData.append("coProperty3", coProperty3);
      formData.append("coProperty4", coProperty4);
      formData.append("coProperty1Extra", coProperty1Extra);
      formData.append("logoFile", logoFile.file);
      if (netType === "ORG") {
        formData.append("copyOrganizeDoc", copyOrganizeDoc.file);
        formData.append("copyOrganizeTarget", copyOrganizeTarget.file);
        formData.append("copyOrganizeCommittee", copyOrganizeCommittee.file);
        formData.append("copyOrganizeReport", copyOrganizeReport.file);
      } else {
        formData.append("copyPROEvidence", copyPROEvidence.file);
        formData.append("copyPROAppoint", copyPROAppoint.file);
        formData.append("copyPROApprove", copyPROApprove.file);
      }
      //formData.append("seltopiclist1", selecttopicList1);
      for (var i = 0; i < activitytmplist.length; i++) {
        formData.append(
          "networkActivities[]",
          JSON.stringify(activitytmplist[i])
        );
        for (
          let j = 0;
          j < activitytmplist[i].networkActivityFiles.length;
          j += 1
        ) {
          formData.append(
            "activityfiles" + i + "[]",
            activitytmplist[i].networkActivityFiles[j]
          );
        }
        for (let j = 0; j < activitytmplist[i].imageFiles.length; j += 1) {
          formData.append(
            "imagefiles" + i + "[]",
            activitytmplist[i].imageFiles[j]
          );
        }
      }
      axios
        .post(
          process.env.REACT_APP_APIURL + "/api-web/saveCorporateNetwork",
          formData,
          {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          }
        )
        .then((response) => {
          let timerInterval;
          swal
            .fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              props.history.push({ pathname: "/authen/network/NetworkList" });
            });
        });
    } else {
      setTimeout(() => {
        scroller.scrollTo("error", {
          duration: 800,
          delay: 0,
          offset: -50,
          smooth: "easeInOutQuart",
        });
      }, 100);
    }
  };
  const cancel = () => {
    props.history.push({ pathname: "/authen/network/NetworkList" });
  };

  function checkActivity() {
    //activitytmplist = [...activitylist];
    activitytmplist = [];
    let serrors = {};
    let formIsValid = true;
    if (organization.trim().length === 0) {
      formIsValid = false;
      serrors["organization"] = "กรุณาระบุชื่อองค์กร";
    }
    if (
      selecttopicList1.length <= 0 &&
      year1.trim().length < 4 &&
      activityName1.trim().length === 0 &&
      activityDetail1.trim().length === 0 &&
      selecttopicList2.length <= 0 &&
      year2.trim().length < 4 &&
      activityName2.trim().length === 0 &&
      activityDetail2.trim().length === 0 &&
      selecttopicList3.length <= 0 &&
      year3.trim().length < 4 &&
      activityName3.trim().length === 0 &&
      activityDetail3.trim().length === 0
    ) {
      formIsValid = false;
      swal.fire({
        icon: "error",
        title: "Failed",
        text: "กรุณากรอกข้อมูลผลการดำเนินงานอย่างน้อยหนึ่งรายการ",
        //footer: '<a href="">Why do I have this issue?</a>'
      });
    }
    /*1*/
    if (
      selecttopicList1.length <= 0 &&
      year1.trim().length < 4 &&
      activityName1.trim().length === 0 &&
      activityDetail1.trim().length === 0
    ) {
    } else {
      if (selecttopicList1.length <= 0) {
        formIsValid = false;
        serrors["seltopiclist1"] =
          "กรุณาเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชน อย่างน้อยหนึ่งรายการ";
      }
      if (year1.trim().length < 4) {
        formIsValid = false;
        serrors["year1"] = "กรุณาระบุรายละเอียด";
      }
      if (activityName1.trim().length === 0) {
        formIsValid = false;
        serrors["activityName1"] = "กรุณาระบุรายละเอียด";
      }
      if (activityDetail1.trim().length === 0) {
        formIsValid = false;
        serrors["activityDetail1"] = "กรุณาระบุรายละเอียด";
      }
      if (formIsValid === true) {
        let getallData = {
          selecttopicList: selecttopicList1,
          year: year1,
          activityName: activityName1,
          activityDetail: activityDetail1,
          networkActivityFiles: networkActivityFiles1,
          imageFiles: imageFiles1,
        };
        activitytmplist.push(getallData);
        setActivityList(activitytmplist);
      }
    }
    /*2*/
    if (
      selecttopicList2.length <= 0 &&
      year2.trim().length < 4 &&
      activityName2.trim().length === 0 &&
      activityDetail2.trim().length === 0
    ) {
    } else {
      if (selecttopicList2.length <= 0) {
        formIsValid = false;
        serrors["seltopiclist2"] =
          "กรุณาเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชน อย่างน้อยหนึ่งรายการ";
      }
      if (year2.trim().length < 4) {
        formIsValid = false;
        serrors["year2"] = "กรุณาระบุรายละเอียด";
      }
      if (activityName2.trim().length === 0) {
        formIsValid = false;
        serrors["activityName2"] = "กรุณาระบุรายละเอียด";
      }
      if (activityDetail2.trim().length === 0) {
        formIsValid = false;
        serrors["activityDetail2"] = "กรุณาระบุรายละเอียด";
      }
      if (formIsValid === true) {
        let getallData = {
          selecttopicList: selecttopicList2,
          year: year2,
          activityName: activityName2,
          activityDetail: activityDetail2,
          networkActivityFiles: networkActivityFiles2,
          imageFiles: imageFiles2,
        };
        activitytmplist.push(getallData);
        setActivityList(activitytmplist);
      }
    }
    /*3*/
    if (
      selecttopicList3.length <= 0 &&
      year3.trim().length < 4 &&
      activityName3.trim().length === 0 &&
      activityDetail3.trim().length === 0
    ) {
    } else {
      if (selecttopicList3.length <= 0) {
        formIsValid = false;
        serrors["seltopiclist3"] =
          "กรุณาเลือกประเด็นการดำเนินงานด้านสิทธิมนุษยชน อย่างน้อยหนึ่งรายการ";
      }
      if (year3.trim().length < 4) {
        formIsValid = false;
        serrors["year3"] = "กรุณาระบุรายละเอียด";
      }
      if (activityName3.trim().length === 0) {
        formIsValid = false;
        serrors["activityName3"] = "กรุณาระบุรายละเอียด";
      }
      if (activityDetail3.trim().length === 0) {
        formIsValid = false;
        serrors["activityDetail3"] = "กรุณาระบุรายละเอียด";
      }
      if (formIsValid === true) {
        let getallData = {
          selecttopicList: selecttopicList3,
          year: year3,
          activityName: activityName3,
          activityDetail: activityDetail3,
          networkActivityFiles: networkActivityFiles3,
          imageFiles: imageFiles3,
        };
        activitytmplist.push(getallData);
        setActivityList(activitytmplist);
      }
    }
    setError(serrors);
    return formIsValid;
  }

  const TopicCheckbox1 = topicList.map((item) => (
    <div className="col-12" key={item.InterestTopicID}>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={"operation1-" + item.InterestTopicID}
          name={"operation1-" + item.InterestTopicID}
          onChange={(e) => {
            var checked = e.target.checked;
            seltopiclist1 = [...selecttopicList1];
            if (checked) {
              if (seltopiclist1.indexOf(item.InterestTopicID) < 0) {
                seltopiclist1.push(item.InterestTopicID);
              }
            } else {
              if (seltopiclist1.indexOf(item.InterestTopicID) > -1) {
                let index = seltopiclist1.indexOf(item.InterestTopicID);
                seltopiclist1 = seltopiclist1
                  .slice(0, index)
                  .concat(seltopiclist1.slice(index + 1));
              }
            }
            setSelectTopicList1(seltopiclist1);
            //console.log(seltopiclist1)
          }}
          defaultChecked={(selecttopicList1.indexOf(item.InterestTopicID) > -1)?true:false}
        />
        <label
          className="custom-control-label"
          htmlFor={"operation1-" + item.InterestTopicID}
        >
          {language === "Thai" ? item.TextTH : item.TextEN}
        </label>
      </div>
    </div>
  ));

  const handleYearChange1 = (e) => {
    if (e.target.value === '') {
      SetYear1('');
    }
    else if (rx_int.test(e.target.value)) {
      if (e.target.value.length < 5) {
        SetYear1(e.target.value);
        //setnetworkActivities({ ...networkActivities, 'year1': year1 })
      }
    }
  };
  const handleRemoveActivityFile1 = (filename) => {
    SetNetworkActivityFiles1(
      networkActivityFiles1.filter((item) => item["name"] !== filename)
    );
  };
  const handleRemoveActivityImageFile1 = (filename) => {
    SetImageFiles1(imageFiles1.filter((item) => item["name"] !== filename));
  };
  const NetActivityFilesList1 = networkActivityFiles1.map((file, i) => (
    <tr key={i}>
      <td>{Number(i) + 1}</td>
      <td>{file.path.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.path.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveActivityFile1(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const NetImageFilesList1 = imageFiles1.map((file, i) => (
    <tr key={i}>
      <td>{Number(i) + 1}</td>
      <td>{file.name.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveActivityImageFile1(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const TopicCheckbox2 = topicList.map((item) => (
    <div className="col-12" key={item.InterestTopicID}>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={"operation2-" + item.InterestTopicID}
          name={"operation2-" + item.InterestTopicID}
          onChange={(e) => {
            var checked = e.target.checked;
            seltopiclist2 = [...selecttopicList2];
            if (checked) {
              if (seltopiclist2.indexOf(item.InterestTopicID) < 0) {
                seltopiclist2.push(item.InterestTopicID);
              }
            } else {
              if (seltopiclist2.indexOf(item.InterestTopicID) > -1) {
                let index = seltopiclist2.indexOf(item.InterestTopicID);
                seltopiclist2 = seltopiclist2
                  .slice(0, index)
                  .concat(seltopiclist2.slice(index + 1));
              }
            }
            setSelectTopicList2(seltopiclist2);
          }}
          defaultChecked={(selecttopicList2.indexOf(item.InterestTopicID) > -1)?true:false}
        />
        <label
          className="custom-control-label"
          htmlFor={"operation2-" + item.InterestTopicID}
        >
          {language === "Thai" ? item.TextTH : item.TextEN}
        </label>
      </div>
    </div>
  ));
  const handleYearChange2 = (e) => {
    if (e.target.value === '') {
      SetYear2('');
    }
    else if (rx_int.test(e.target.value)) {
      if (e.target.value.length < 5) {
        SetYear2(e.target.value);
      }
    }
  };
  const handleRemoveActivityFile2 = (filename) => {
    SetNetworkActivityFiles2(
      networkActivityFiles2.filter((item) => item["name"] !== filename)
    );
  };
  const handleRemoveActivityImageFile2 = (filename) => {
    SetImageFiles2(imageFiles2.filter((item) => item["name"] !== filename));
  };
  const NetActivityFilesList2 = networkActivityFiles2.map((file, i) => (
    <tr key={i}>
      <td>{Number(i) + 1}</td>
      <td>{file.path.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.path.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveActivityFile2(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const NetImageFilesList2 = imageFiles2.map((file, i) => (
    <tr key={i}>
      <td>{Number(i) + 1}</td>
      <td>{file.name.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveActivityImageFile2(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const TopicCheckbox3 = topicList.map((item) => (
    <div className="col-12" key={item.InterestTopicID}>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={"operation3-" + item.InterestTopicID}
          name={"operation3-" + item.InterestTopicID}
          onChange={(e) => {
            var checked = e.target.checked;
            seltopiclist3 = [...selecttopicList3];
            if (checked) {
              if (seltopiclist3.indexOf(item.InterestTopicID) < 0) {
                seltopiclist3.push(item.InterestTopicID);
              }
            } else {
              if (seltopiclist3.indexOf(item.InterestTopicID) > -1) {
                let index = seltopiclist3.indexOf(item.InterestTopicID);
                seltopiclist3 = seltopiclist3
                  .slice(0, index)
                  .concat(seltopiclist3.slice(index + 1));
              }
            }
            setSelectTopicList3(seltopiclist3);
          }}
          defaultChecked={(selecttopicList3.indexOf(item.InterestTopicID) > -1)?true:false}
        />
        <label
          className="custom-control-label"
          htmlFor={"operation3-" + item.InterestTopicID}
        >
          {language === "Thai" ? item.TextTH : item.TextEN}
        </label>
      </div>
    </div>
  ));
  const handleYearChange3 = (e) => {
    if (e.target.value === '') {
      SetYear3('');
    }
    else if (rx_int.test(e.target.value)) {
      if (e.target.value.length < 5) {
        SetYear3(e.target.value);
      }
    }
  };
  const handleRemoveActivityFile3 = (filename) => {
    SetNetworkActivityFiles3(
      networkActivityFiles3.filter((item) => item["name"] !== filename)
    );
  };
  const handleRemoveActivityImageFile3 = (filename) => {
    SetImageFiles3(imageFiles3.filter((item) => item["name"] !== filename));
  };
  const NetActivityFilesList3 = networkActivityFiles3.map((file, i) => (
    <tr key={i}>
      <td>{Number(i) + 1}</td>
      <td>{file.path.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.path.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveActivityFile3(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));

  const NetImageFilesList3 = imageFiles3.map((file, i) => (
    <tr key={i}>
      <td>{Number(i) + 1}</td>
      <td>{file.name.split(".")[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split(".").pop()}</td>
      <td>
        <a
          className="btn btn-sm btn-primary mr-2"
          href={file.preview}
          rel="noreferrer"
          target="_blank"
        >
          ดู
        </a>
        <button
          className="btn btn-sm btn-danger"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveActivityImageFile3(file.name);
          }}
        >
          ลบ
        </button>
      </td>
    </tr>
  ));
  /*const handleCopyOrganizeDocOnDrop = (newFile, onChange) => {
    const dropFile = {
      file: newFile[0],
      name: newFile[0].name,
      preview: URL.createObjectURL(newFile[0]),
      size: newFile[0].size,
    };
    setCopyOrganizeDoc(dropFile);
  };
  const handleRemoveCopyOrganizeDoc = () => {
    setCopyOrganizeDoc(null);
  };*/
  const handleRemoveCopyOrganizeDoc = filename => {
    for (let file of copyOrganizeDoc) {
      if (file.name === filename) {
        if (file.id !== 0) {
          const formData = new FormData();
          formData.append("id", file.id);
          axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
            headers: {
              'Content-Type': `multipart/form-data`
            }
          })
            .then((response) => {

            });
        }
      } // Add only file name not matched files
    }
    setCopyOrganizeDoc(copyOrganizeDoc.filter(item => item['name'] !== filename));
  };
  /*const handleCopyOrganizeTargetOnDrop = (newFile, onChange) => {
    const dropFile = {
      file: newFile[0],
      name: newFile[0].name,
      preview: URL.createObjectURL(newFile[0]),
      size: newFile[0].size,
    };
    setCopyOrganizeTarget(dropFile);
  };
  const handleRemoveCopyOrganizeTarget = () => {
    setCopyOrganizeTarget(null);
  };*/
  const handleRemoveCopyOrganizeTarget = filename => {
    for (let file of copyOrganizeTarget) {
      if (file.name === filename) {
        if (file.id !== 0) {
          const formData = new FormData();
          formData.append("id", file.id);
          axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
            headers: {
              'Content-Type': `multipart/form-data`
            }
          })
            .then((response) => {

            });
        }
      } // Add only file name not matched files
    }
    setCopyOrganizeTarget(copyOrganizeTarget.filter(item => item['name'] !== filename));
  };
  /*const handleCopyOrganizeCommitteeOnDrop = (newFile, onChange) => {
    const dropFile = {
      file: newFile[0],
      name: newFile[0].name,
      preview: URL.createObjectURL(newFile[0]),
      size: newFile[0].size,
    };
    setCopyOrganizeCommittee(dropFile);
  };
  const handleRemoveCopyOrganizeCommittee = () => {
    setCopyOrganizeCommittee(null);
  };*/
  const handleRemoveCopyOrganizeCommittee = filename => {
    for (let file of copyOrganizeCommittee) {
      if (file.name === filename) {
        if (file.id !== 0) {
          const formData = new FormData();
          formData.append("id", file.id);
          axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
            headers: {
              'Content-Type': `multipart/form-data`
            }
          })
            .then((response) => {

            });
        }
      } // Add only file name not matched files
    }
    setCopyOrganizeCommittee(copyOrganizeCommittee.filter(item => item['name'] !== filename));
  };
  /*const handleCopyOrganizeReportOnDrop = (newFile, onChange) => {
    const dropFile = {
      file: newFile[0],
      name: newFile[0].name,
      preview: URL.createObjectURL(newFile[0]),
      size: newFile[0].size,
    };
    setCopyOrganizeReport(dropFile);
  };
  const handleRemoveCopyOrganizeReport = () => {
    setCopyOrganizeReport(null);
  };*/
  const handleRemoveCopyOrganizeReport = filename => {
    for (let file of copyOrganizeReport) {
      if (file.name === filename) {
        if (file.id !== 0) {
          const formData = new FormData();
          formData.append("id", file.id);
          axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
            headers: {
              'Content-Type': `multipart/form-data`
            }
          })
            .then((response) => {

            });
        }
      } // Add only file name not matched files
    }
    setCopyOrganizeReport(copyOrganizeReport.filter(item => item['name'] !== filename));
  };
  const handleCopyPROEvidenceOnDrop = (newFile, onChange) => {
    const dropFile = {
      file: newFile[0],
      name: newFile[0].name,
      preview: URL.createObjectURL(newFile[0]),
      size: newFile[0].size,
    };
    setCopyPROEvidence(dropFile);
  };
  const handleRemoveCopyPROEvidence = () => {
    setCopyPROEvidence(null);
  };
  const handleCopyPROAppointOnDrop = (newFile, onChange) => {
    const dropFile = {
      file: newFile[0],
      name: newFile[0].name,
      preview: URL.createObjectURL(newFile[0]),
      size: newFile[0].size,
    };
    setCopyPROAppoint(dropFile);
  };
  const handleRemoveCopyPROAppoint = () => {
    setCopyPROAppoint(null);
  };
  const handleCopyPROApproveOnDrop = (newFile, onChange) => {
    const dropFile = {
      file: newFile[0],
      name: newFile[0].name,
      preview: URL.createObjectURL(newFile[0]),
      size: newFile[0].size,
    };
    setCopyPROApprove(dropFile);
  };
  const handleRemoveCopyPROApprove = () => {
    setCopyPROApprove(null);
  };
  const copyOrganizeDocTable = copyOrganizeDoc.map((file, i) => (
    <tr key={file.path}>
      <td>{(Number(i) + 1)}</td>
      <td>{file.name.split('.')[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split('.').pop()}</td>
      <td>
        <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
        <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveCopyOrganizeDoc(file.name); }}>ลบ</button>
      </td>
    </tr>
  ));
  const copyOrganizeTargetTable = copyOrganizeTarget.map((file, i) => (
    <tr key={file.path}>
      <td>{(Number(i) + 1)}</td>
      <td>{file.name.split('.')[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split('.').pop()}</td>
      <td>
        <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
        <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveCopyOrganizeTarget(file.name); }}>ลบ</button>
      </td>
    </tr>
  ));
  const copyOrganizeCommitteeTable = copyOrganizeCommittee.map((file, i) => (
    <tr key={file.path}>
      <td>{(Number(i) + 1)}</td>
      <td>{file.name.split('.')[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split('.').pop()}</td>
      <td>
        <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
        <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveCopyOrganizeCommittee(file.name); }}>ลบ</button>
      </td>
    </tr>
  ));
  const copyOrganizeReportTable = copyOrganizeReport.map((file, i) => (
    <tr key={file.path}>
      <td>{(Number(i) + 1)}</td>
      <td>{file.name.split('.')[0]}</td>
      <td>{utils.Bytes2Size(file.size)}</td>
      <td>{file.name.split('.').pop()}</td>
      <td>
        <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
        <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveCopyOrganizeReport(file.name); }}>ลบ</button>
      </td>
    </tr>
  ));
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <div id="header-wrapper">
        {/*{<Navbar />}*/}
        <ContentBanner
          language={language}
          theader={"เครือข่ายด้านสิทธิมนุษยชน"}
          eheader={"Network"}
          bannerkey="NETWORK"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {language === "Thai" ? "เครือข่าย" : "Network"}
              </a>
              <span>/</span>
              <Link
                to={{
                  pathname: "/authen/network/NetworkList",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? " องค์กรเอกชนด้านสิทธิมนุษยชน และสภาวิชาชีพ"
                  : "Cooperationnetwork and Profesional Council"}
              </Link>
              <span>/</span>
              <Link
                className="active"
                to={{
                  pathname: "/authen/network/CoorperationRegister",
                  state: { language: language },
                }}
              >
                {language === "Thai"
                  ? "ยื่นเรื่องขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน"
                  : "Cooperationnetwork Register"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section>
            <div className="content-data CoorperationRegister">
              <form method="post" encType="multipart/form-data" className="row">
                {nextPage === false && (
                  <div className="col-12">
                    <div className="col-12">
                      <div className="sub-title">
                        <h2 className="thai-header text-center">
                          <span className="orang content-underline">
                            {netType === "ORG"
                              ? "ยื่นเรื่องขอรับการจดแจ้งองค์กรเอกชนด้านสิทธิมนุษยชน"
                              : "ยื่นเรื่องขอรับการจดแจ้งสภาวิชาชีพ"}
                          </span>
                        </h2>
                      </div>
                    </div>
                    <div className="col-12">
                      {netType === "ORG" && (
                        <>
                          <h4 className="text-center text-gray">
                            คำขอรับการจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน
                            ตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ
                            พ.ศ. 2560
                          </h4>
                        </>
                      )}
                      {netType === "PROCOUN" && (
                        <>
                          <h4 className="text-center text-gray">
                            คำขอรับการจดแจ้งเป็นสภาวิชาชีพ
                            ตามพระราชบัญญัติประกอบรัฐธรรมนูญว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ
                            พ.ศ. 2560
                          </h4>
                        </>
                      )}
                    </div>
                    <div className="subheader col-12" role="alert">
                      ข้อมูลทั่วไป
                    </div>
                    <div className="formContent">
                      <div className="formMaxwidth">
                        <div className="row">
                          <div className="col-12 title text-blue">
                            <h2>
                              <span>
                                {netType === "ORG"
                                  ? "ด้วยองค์กร"
                                  : "ด้วยสภาวิชาชีพ"}
                              </span>
                            </h2>
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control1 "
                              id="organization"
                              defaultValue={organization}
                              name="organization"
                              placeholder="ชื่อองค์กร"
                              autoComplete="off"
                              onBlur={(e) => {
                                SetOrganization(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["organization"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control1 "
                              id="netHouseNo"
                              defaultValue={netHouseNo}
                              name="netHouseNo"
                              placeholder="ที่ตั้งเลขที่"
                              autoComplete="off"
                              onBlur={(e) => {
                                SetNetHouseNo(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["netHouseNo"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <select
                              className="custom-select "
                              id="provinceCode"
                              name="provinceCode"
                              value={provinceCode}
                              onChange={(e) => {
                                SetProvinceCode(e.currentTarget.value);
                                handleChangeProvince(e.currentTarget.value);
                              }}
                            >
                              <option key="0" value="0">
                                จังหวัด
                              </option>
                              {ProvinceSelect}
                            </select>
                            <SError error={errors["provinceCode"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <select
                              className="custom-select "
                              id="districtCode"
                              name="districtCode"
                              value={districtCode}
                              onChange={(e) => {
                                SetDistrictCode(e.currentTarget.value);
                                handleChangeDistrict(e.currentTarget.value);
                              }}
                            >
                              <option key="0" value="0">
                                เขต/อำเภอ
                              </option>
                              {DistrictSelect}
                            </select>
                            <SError error={errors["districtCode"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <select
                              className="custom-select "
                              id="subDistricCode"
                              name="subDistricCode"
                              value={subDistricCode}
                              onChange={(e) => {
                                SetSubDistricCode(e.currentTarget.value);
                                handleChangeSubDistrict(e.currentTarget.value);
                              }}
                            >
                              <option key="0" value="0">
                                แขวง/ตำบล
                              </option>
                              {SubDistrictSelect}
                            </select>
                            <SError error={errors["subDistricCode"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="postCode"
                              value={postCode}
                              name="postCode"
                              placeholder="รหัสไปรษณีย์"
                              autoComplete="off"
                              maxLength={5}
                              onChange={(e) => {
                                handlepostCodeChange(e);
                              }}
                            />
                            <SError error={errors["postCode"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="phone"
                              value={phone}
                              name="phone"
                              placeholder="หมายเลขโทรศัพท์"
                              autoComplete="off"
                              onChange={(e) => {
                                handlePhoneChange(e);
                              }}
                            />
                            <SError error={errors["phone"]} />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control "
                              id="fax"
                              value={fax}
                              name="fax"
                              placeholder="โทรสาร"
                              autoComplete="off"
                              onChange={(e) => {
                                handleFaxChange(e);
                              }}
                            />
                            <SError error={errors["fax"]} />
                          </div>
                          <div className="col-12">
                            <label className="required mt-4 mb-3">
                              รูปภาพโลโก้สำหรับองค์กร
                            </label>
                            <div className="mb-3">
                              <Dropzone
                                className="dropzone"
                                activeClassName="active-dropzone"
                                multiple={false}
                                onDrop={handleLogoOnDrop}
                                accept="image/*"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div>
                                    <SError error={errors["logoFile"]} />
                                    <div
                                      {...getRootProps({
                                        className: "dropzone",
                                      })}
                                    >
                                      <input {...getInputProps()} />
                                      <div>
                                        ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                      </div>
                                    </div>
                                    {LogoPreview}
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                          </div>
                          <div className="col-12">
                            <label className="mb-4">
                              มีความประสงค์ให้สำนักงานคณะกรรมการสิทธิมนุษยชนแห่งชาติ
                              รับจดแจ้งเป็นองค์กรเอกชนด้านสิทธิมนุษยชน
                              ตามพระราชบัญญัติประกอบรัฐธรรมนูญ
                              ว่าด้วยคณะกรรมการสิทธิมนุษยชนแห่งชาติ พ.ศ. 2560
                              และระเบียบคณะกรรมการสิทธิมนุษยชนแห่งชาติว่าด้วยหลักเกณฑ์
                              วิธีการ และเงื่อนไข การจดแจ้ง การรับจดแจ้ง
                              และการเลือกกันเองขององค์กรเอกชนด้านสิทธิมนุษยชน
                              และสภาวิชาชีพ พ.ศ. 2561 และที่แก้ไขเพิ่มเติม
                              (ฉบับที่ 2) พ.ศ. 2563
                            </label>
                          </div>
                          <div className="col-12 title text-blue mb-4">
                            <h2>
                              <span>
                                {netType === "ORG"
                                  ? "โดยมีผู้ยื่นคำขอ คือ"
                                  : "โดยมีผู้ยื่นคำขอ คือ"}
                              </span>
                            </h2>
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <select
                              className="custom-select "
                              id="coTitleID"
                              name="coTitleID"
                              value={coTitleID}
                              onChange={(e) => {
                                SetCoTitleID(e.currentTarget.value);
                              }}
                            >
                              <option key="0" value="0">
                                คำนำหน้า
                              </option>
                              {TitleSelect}
                              <option key="-1" value="-1">
                                อื่น ๆ
                              </option>
                            </select>
                            <SError error={errors["coTitleID"]} />
                          </div>
                          {Number(coTitleID) === -1 && (
                            <>
                              <div className="col-3">
                                <input
                                  type="text"
                                  className="form-control "
                                  id="coOtherTitleTH"
                                  value={coOtherTitleTH}
                                  name="coOtherTitleTH"
                                  placeholder="คำนำหน้าอื่น ๆ ภาษาไทย"
                                  onChange={(e) => {
                                    SetCoOtherTitleTH(e.currentTarget.value);
                                  }}
                                />
                                <SError error={errors["coOtherTitleTH"]} />
                              </div>
                              <div className="col-3">
                                <input
                                  type="text"
                                  className="form-control "
                                  id="coOtherTitleEN"
                                  value={coOtherTitleEN}
                                  name="coOtherTitleEN"
                                  placeholder="คำนำหน้าอื่น ๆ ภาษาอังกฤษ"
                                  onChange={(e) => {
                                    SetCoOtherTitleEN(e.currentTarget.value);
                                  }}
                                />
                                <SError error={errors["coOtherTitleEN"]} />
                              </div>
                            </>
                          )}
                          {Number(coTitleID) !== -1 && (
                            <>
                              <div className="col-6"></div>
                            </>
                          )}
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="coFirstName"
                              defaultValue={coFirstName}
                              autoComplete="off"
                              name="coFirstName"
                              placeholder="ชื่อ"
                              readOnly={true}
                              onBlur={(e) => {
                                SetCoFirstName(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["coFirstName"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="coLastName"
                              defaultValue={coLastName}
                              autoComplete="off"
                              name="coLastName"
                              placeholder="นามสกุล"
                               readOnly={true}
                              onBlur={(e) => {
                                SetCoLastName(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["coLastName"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="coPosition"
                              defaultValue={coPosition}
                              autoComplete="off"
                              name="coPosition"
                              placeholder="ตำแหน่ง"
                              onBlur={(e) => {
                                SetCoPosition(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["coPosition"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="coPhone"
                              value={coPhone}
                              name="coPhone"
                              autoComplete="off"
                              placeholder="โทรศัพท์"
                              onChange={(e) => {
                                handleCoPhoneChange(e);
                              }}
                            />
                            <SError error={errors["coPhone"]} />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control "
                              id="coFax"
                              value={coFax}
                              name="coFax"
                              autoComplete="off"
                              placeholder="โทรสาร"
                              onChange={(e) => {
                                handleCoFaxChange(e);
                              }}
                            />
                            <SError error={errors["coFax"]} />
                          </div>
                          <div className="col-md-6">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="coEmail"
                              defaultValue={coEmail}
                              autoComplete="off"
                              name="coEmail"
                              placeholder="อีเมล"
                              onBlur={(e) => {
                                SetCoEmail(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["coEmail"]} />
                          </div>
                        </div>
                      </div>
                      {/*
                      <label className="col-12 mt-2">
                        {netType === "ORG"
                          ? ""
                          : "โดยเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพที่จัดตั้งขึ้นตามกฎหมายไทย"}
                      </label> */}
                      <div className="subheader col-12" role="alert">
                        การรับรองคุณสมบัติและไม่มีลักษณะต้องห้ามขององค์กร
                      </div>
                      <div className="formMaxwidth">
                        <div className="row">
                          <div className="col-12">
                            <label className="mb-4">
                              ( โปรดใส่เครื่องหมาย √ ในช่อง{" "}
                              <i className="far fa-square"></i>{" "}
                              ตามความเป็นจริงเกี่ยวกับองค์กร )
                            </label>
                          </div>
                          <div className="col-12 title text-blue mb-3">
                            <h2>
                              <span>
                                {netType === "ORG"
                                  ? "ขอรับรองว่า"
                                  : "ขอรับรองว่าสภาวิชาชีพ"}
                              </span>
                            </h2>
                          </div>
                          <div className="col-lg-12 mb-2">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="organization"
                              defaultValue={organization}
                              name="organization"
                              placeholder={
                                netType === "ORG"
                                  ? "ชื่อองค์กร"
                                  : "ชื่อสภาวิชาชีพ"
                              }
                              autoComplete="off"
                              onBlur={(e) => {
                                SetOrganization(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["organization"]} />
                          </div>
                          <div className="col-12 my-bottom">
                            <label>
                              {netType === "ORG"
                                ? "มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 7 แห่งระเบียบนี้"
                                : "มีคุณสมบัติครบถ้วนและไม่มีลักษณะต้องห้ามทุกประการ ตามข้อ 8 แห่งระเบียบนี้"}
                            </label>
                          </div>
                          {netType === "ORG" && (
                            <>
                              <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="coProperty1"
                                    name="coProperty1"
                                    checked={coProperty1 === true}
                                    onChange={(e) => {
                                      SetCoProperty1(!coProperty1);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coProperty1"
                                  >
                                    เป็นองค์กรที่จัดตั้งขึ้นตามกฎหมายไทย
                                    โปรดระบุกฎหมายและมาตราที่เกี่ยวข้อง
                                  </label>
                                </div>
                              </div>
                              <div className="col-6">
                                <input
                                  type="text"
                                  className="form-control "
                                  id="coProperty1Extra"
                                  defaultValue={coProperty1Extra}
                                  name="coProperty1Extra"
                                  placeholder="กฎหมายและมาตราที่เกี่ยวข้อง"
                                  onBlur={(e) => {
                                    SetCoProperty1Extra(e.currentTarget.value);
                                  }}
                                />
                                <SError error={errors["coProperty1Extra"]} />
                              </div>
                              <div className="col-6"></div>
                              <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="coProperty2"
                                    name="coProperty2"
                                    checked={coProperty2 === true}
                                    onChange={(e) => {
                                      SetCoProperty2(!coProperty2);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coProperty2"
                                  >
                                    มีวัตถุประสงค์
                                    และการดําเนินการเกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชน
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="coProperty3"
                                    name="coProperty3"
                                    checked={coProperty3 === true}
                                    onChange={(e) => {
                                      SetCoProperty3(!coProperty3);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coProperty3"
                                  >
                                    มีการดำเนินการอย่างต่อเนื่องเป็นระยะเวลาไม่น้อยกว่า
                                    2 ปี นับแต่วันที่จัดตั้ง
                                    และมีผลการดำเนินงานเป็นที่ประจักษ์
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="coProperty4"
                                    name="coProperty4"
                                    checked={coProperty4 === true}
                                    onChange={(e) => {
                                      SetCoProperty4(!coProperty4);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coProperty4"
                                  >
                                    ไม่มีวัตถุประสงค์ในทางการเมือง
                                    หรือแสวงหากําไรจากการดําเนินการ
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <SError error={errors["coProperty"]} />
                              </div>
                              <div className="col-12 mt-4">
                                <label className="required">
                                  สำเนาหนังสือรับรองหรือหนังสือสำคัญแสดงการจัดตั้ง
                                </label>
                                <Dropzone
                                  className="dropzone"
                                  activeClassName="active-dropzone"
                                  multiple={true}
                                  /*onDrop={handleCopyOrganizeDocOnDrop}*/
                                  onDrop={acceptedFiles => {
                                    setCopyOrganizeDoc([...copyOrganizeDoc, ...acceptedFiles]);
                                    setCopyOrganizeDocPreview(acceptedFiles.map(file => Object.assign(file, {
                                      id: 0,
                                      preview: URL.createObjectURL(file)
                                    })));
                                  }}
                                  accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section className="container file">
                                      <SError
                                        error={errors["copyOrganizeDoc"]}
                                      />
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div>
                                          ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                        </div>
                                      </div>
                                      <aside>
                                        <h4>Files</h4>
                                        <div className="table-responsive">
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>ลำดับ</th>
                                                <th>ชื่อ</th>
                                                <th>ขนาดไฟล์</th>
                                                <th>ประเภทไฟล์</th>
                                                <th>จัดการ</th>
                                              </tr>
                                            </thead>
                                            <tbody id="your-table-body-id">
                                              {copyOrganizeDocTable}
                                            </tbody>
                                          </table>
                                        </div>
                                        {/*<SingleFileTable
                                          filedata={copyOrganizeDoc}
                                          removefn={handleRemoveCopyOrganizeDoc}
                                      />*/}
                                      </aside>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                              <div className="col-12 mt-4">
                                <label className="required">
                                  สำเนาข้อบังคับหรือระเบียบขององค์กรที่ระบุวัตถุประสงค์
                                  และโครงสร้างขององค์กร
                                </label>
                                <Dropzone
                                  className="dropzone"
                                  activeClassName="active-dropzone"
                                  multiple={true}
                                  /*onDrop={handleCopyOrganizeTargetOnDrop}*/
                                  onDrop={acceptedFiles => {
                                    setCopyOrganizeTarget([...copyOrganizeTarget, ...acceptedFiles]);
                                    setCopyOrganizeTargetPreview(acceptedFiles.map(file => Object.assign(file, {
                                      id: 0,
                                      preview: URL.createObjectURL(file)
                                    })));
                                  }}
                                  accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div>
                                      <SError
                                        error={errors["copyOrganizeTarget"]}
                                      />
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div>
                                          ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                        </div>
                                      </div>
                                      <aside>
                                        <h4>Files</h4>
                                        <div className="table-responsive">
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>ลำดับ</th>
                                                <th>ชื่อ</th>
                                                <th>ขนาดไฟล์</th>
                                                <th>ประเภทไฟล์</th>
                                                <th>จัดการ</th>
                                              </tr>
                                            </thead>
                                            <tbody id="your-table-body-id">
                                              {copyOrganizeTargetTable}
                                            </tbody>
                                          </table>
                                        </div>
                                        {/*<SingleFileTable
                                          filedata={copyOrganizeTarget}
                                          removefn={
                                            handleRemoveCopyOrganizeTarget
                                          }
                                        />*/}
                                      </aside>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                              <div className="col-12 mt-4">
                                <label className="required">
                                  หลักฐานแสดงการแต่งตั้งกรรมการขององค์กร
                                </label>
                                <Dropzone
                                  className="dropzone"
                                  activeClassName="active-dropzone"
                                  multiple={true}
                                  /*onDrop={handleCopyOrganizeCommitteeOnDrop}*/
                                  onDrop={acceptedFiles => {
                                    setCopyOrganizeCommittee([...copyOrganizeCommittee, ...acceptedFiles]);
                                    setCopyOrganizeCommitteePreview(acceptedFiles.map(file => Object.assign(file, {
                                      id: 0,
                                      preview: URL.createObjectURL(file)
                                    })));
                                  }}
                                  accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div>
                                      <SError
                                        error={errors["copyOrganizeCommittee"]}
                                      />
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div>
                                          ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                        </div>
                                      </div>
                                      <aside>
                                        <h4>Files</h4>
                                        <div className="table-responsive">
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>ลำดับ</th>
                                                <th>ชื่อ</th>
                                                <th>ขนาดไฟล์</th>
                                                <th>ประเภทไฟล์</th>
                                                <th>จัดการ</th>
                                              </tr>
                                            </thead>
                                            <tbody id="your-table-body-id">
                                              {copyOrganizeCommitteeTable}
                                            </tbody>
                                          </table>
                                        </div>
                                        {/*<SingleFileTable
                                          filedata={copyOrganizeCommittee}
                                          removefn={
                                            handleRemoveCopyOrganizeCommittee
                                          }
                                        />*/}
                                      </aside>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                              <div className="col-12 mt-4">
                                <label className="required">
                                  รายงานผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชนอย่างต่อเนื่องเป็นระยะเวลาไม่น้อยกว่า
                                  2 ปี นับแต่วันที่จัดตั้ง
                                </label>
                                <Dropzone
                                  className="dropzone"
                                  activeClassName="active-dropzone"
                                  multiple={true}
                                  /*onDrop={handleCopyOrganizeReportOnDrop}*/
                                  onDrop={acceptedFiles => {
                                    setCopyOrganizeReport([...copyOrganizeReport, ...acceptedFiles]);
                                    setCopyOrganizeReportPreview(acceptedFiles.map(file => Object.assign(file, {
                                      id: 0,
                                      preview: URL.createObjectURL(file)
                                    })));
                                  }}
                                  accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section className="container file">
                                      <SError
                                        error={errors["copyOrganizeReport"]}
                                      />
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div>
                                          ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                        </div>
                                      </div>
                                      <aside>
                                        <h4>Files</h4>
                                        <div className="table-responsive">
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>ลำดับ</th>
                                                <th>ชื่อ</th>
                                                <th>ขนาดไฟล์</th>
                                                <th>ประเภทไฟล์</th>
                                                <th>จัดการ</th>
                                              </tr>
                                            </thead>
                                            <tbody id="your-table-body-id">
                                              {copyOrganizeReportTable}
                                            </tbody>
                                          </table>
                                        </div>
                                        {/*<SingleFileTable
                                          filedata={copyOrganizeReport}
                                          removefn={
                                            handleRemoveCopyOrganizeReport
                                          }
                                        />*/}
                                      </aside>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            </>
                          )}
                          {netType === "PROCOUN" && (
                            <>
                              <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="coProperty1"
                                    name="coProperty1"
                                    checked={coProperty1 === true}
                                    onChange={(e) => {
                                      SetCoProperty1(!coProperty1);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coProperty1"
                                  >
                                    เป็นสภาวิชาชีพตามกฎหมายไทย
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="coProperty2"
                                    name="coProperty2"
                                    checked={coProperty2 === true}
                                    onChange={(e) => {
                                      SetCoProperty2(!coProperty2);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coProperty2"
                                  >
                                    มีหลักฐานการรับรองหรือมีคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="coProperty3"
                                    name="coProperty3"
                                    checked={coProperty3 === true}
                                    onChange={(e) => {
                                      SetCoProperty3(!coProperty3);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coProperty3"
                                  >
                                    สภาวิชาชีพนี้มีการให้ความเห็นชอบในการยื่นคำขอจดแจ้งสภาวิชาชีพ
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <SError error={errors["coProperty"]} />
                              </div>
                              <div className="col-12">
                                <hr />
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="coProperty4"
                                    name="coProperty4"
                                    checked={coProperty4 === true}
                                    onChange={(e) => {
                                      SetCoProperty4(!coProperty4);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coProperty4"
                                  >
                                    ข้าพเจ้าขอยอมรับว่าข้อมูลดังกล่าวเป็นความจริงทุกประการ
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <SError error={errors["accept"]} />
                              </div>
                              <div className="col-12">
                                <label className="required mb-3">
                                  หลักฐานการเป็นสภาวิชาชีพตามกฎหมายไทย
                                </label>
                                <Dropzone
                                  className="dropzone"
                                  activeClassName="active-dropzone"
                                  multiple={false}
                                  onDrop={handleCopyPROEvidenceOnDrop}
                                  accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div>
                                      <SError
                                        error={errors["copyPROEvidence"]}
                                      />
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div>
                                          ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                        </div>
                                      </div>
                                      <aside>
                                        <SingleFileTable
                                          filedata={copyPROEvidence}
                                          removefn={handleRemoveCopyPROEvidence}
                                        />
                                      </aside>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                              <div className="col-12">
                                <label className="required mb-3 mt-3">
                                  สำเนาหนังสือรับรองหรือคำสั่งแต่งตั้งเป็นผู้มีอำนาจทำการแทนสภาวิชาชีพ
                                </label>
                                <Dropzone
                                  className="dropzone"
                                  activeClassName="active-dropzone"
                                  multiple={false}
                                  onDrop={handleCopyPROAppointOnDrop}
                                  accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div>
                                      <SError
                                        error={errors["copyPROAppoint"]}
                                      />
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div>
                                          ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                        </div>
                                      </div>
                                      <aside>
                                        <SingleFileTable
                                          filedata={copyPROAppoint}
                                          removefn={handleRemoveCopyPROAppoint}
                                        />
                                      </aside>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                              <div className="col-12">
                                <label className="required mb-3 mt-3">
                                  หลักฐานการให้ความเห็นชอบในการยื่นคาขอจดแจ้งสภาวิชาชีพ
                                </label>
                                <Dropzone
                                  className="dropzone"
                                  activeClassName="active-dropzone"
                                  multiple={false}
                                  onDrop={handleCopyPROApproveOnDrop}
                                  accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div>
                                      <SError
                                        error={errors["copyPROApprove"]}
                                      />
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div>
                                          ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                        </div>
                                      </div>
                                      <aside>
                                        <SingleFileTable
                                          filedata={copyPROApprove}
                                          removefn={handleRemoveCopyPROApprove}
                                        />
                                      </aside>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="controlBtn">
                      {netType === "ORG" && (
                        <input
                          type="button"
                          className="fadeIn fourth btnSecondary"
                          value="ถัดไป"
                          onClick={(e) => {
                            e.preventDefault();
                            nextPageFunction(true);
                          }}
                        />
                      )}
                      {netType === "PROCOUN" && (
                        <input
                          type="submit"
                          className="fadeIn fourth btnSecondary"
                          value="ตกลง"
                          onClick={(e) => {
                            e.preventDefault();
                            saveData();
                          }}
                        />
                      )}
                      <input
                        type="button"
                        className="fadeIn fourth btnPimary"
                        onClick={(e) => {
                          e.preventDefault();
                          cancel();
                        }}
                        value="ยกเลิก"
                      />
                    </div>
                  </div>
                )}
                {/*<div className="row formContent" style={{width:'100%'}}>
                                {activitylist.map((item, i) => (
                                    <RegNetworkActivity organization={organization} index={i} data={item} activitylist={activitylist} key={"activity-"+i} onAddActivity={onAddActivity} ></RegNetworkActivity>
                                ))}
                            </div>
                            <div className="col-12 formContent scroll-end">
                                <div className="pl-4 pr-4 text-center" >
                                    <input type="button" className="fadeIn fourth" value="ย้อนกลับ" onClick={(e) => { e.preventDefault(); nextPageFunction(false); }} />
                                    <input type="submit" className="fadeIn fourth" value="ตกลง" onClick={(e) => { e.preventDefault(); saveData(); }} />
                                    <input type="button" className="fadeIn fourth" onClick={e => { e.preventDefault(); cancel(); }} value="ยกเลิก" />
                                </div>
                                </div>*/}
                {nextPage === true && (
                  <>
                    <div className="row formContent" style={{ width: "100%" }}>
                      <div className="subheader col-12" role="alert">
                        ผลการดำเนินการตามวัตถุประสงค์ที่เกี่ยวข้องโดยตรงกับการส่งเสริมหรือคุ้มครองสิทธิมนุษยชนอย่างต่อเนื่อง
                        เป็นระยะเวลาไม่น้อยกว่า 2 ปี นับแต่วันที่จัดตั้ง
                        และมีผลการดำเนินงานเป็นที่ประจักษ์ (ไม่เกิน 3 เรื่อง)
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control "
                          id="organization"
                          defaultValue={organization}
                          name="organization"
                          placeholder="ชื่อองค์กร"
                          onBlur={(e) => {
                            SetOrganization(e.currentTarget.value);
                          }}
                        />
                        <span className="error">{errors["organization"]}</span>
                      </div>
                      <div className="col-6"></div>
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link subheader active"
                          id="tab1-tab"
                          data-toggle="tab"
                          href="#tab1"
                          role="tab"
                          aria-controls="tab1"
                          aria-selected="true"
                        >
                          ผลงานที่ 1
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link subheader"
                          id="tab2-tab"
                          data-toggle="tab"
                          href="#tab2"
                          role="tab"
                          aria-controls="tab2"
                          aria-selected="false"
                        >
                          ผลงานที่ 2
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link subheader"
                          id="tab3-tab"
                          data-toggle="tab"
                          href="#tab3"
                          role="tab"
                          aria-controls="tab3"
                          aria-selected="false"
                        >
                          ผลงานที่ 3
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content pl-4 pr-4" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="tab1"
                        role="tabpanel"
                        aria-labelledby="tab1-tab"
                      >
                        <div className="row formContent">
                          <div className="col-3">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="year1"
                              maxLength={4}
                              defaultValue={year1}
                              name="year1"
                              placeholder="พ.ศ."
                              onBlur={(e) => {
                                handleYearChange1(e);
                              }}
                            />
                            <SError error={errors["year1"]} />
                          </div>
                          <div className="col-9">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control "
                              id="activityName1"
                              defaultValue={activityName1}
                              name="activityName1"
                              placeholder="ชื่อผลการดำเนินงาน"
                              onBlur={(e) => {
                                SetActivityName1(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["activityName1"]} />
                          </div>
                          <div className="col-12">
                            <div className="input-addon">
                              <span className="require">*</span>
                            </div>
                            <div>
                              <textarea
                                className="form-control"
                                name="activityDetail1"
                                id="activityDetail1"
                                rows="8"
                                defaultValue={activityDetail1}
                                placeholder="รายละเอียดผลงาน (โดยย่อ)"
                                onBlur={(e) => {
                                  SetActivityDetail1(e.currentTarget.value);
                                }}
                              ></textarea>
                              <SError error={errors["activityDetail1"]} />
                            </div>
                          </div>

                          <div className="subheader col-12" role="alert">
                            ประเด็นการดำเนินงานด้านสิทธิมนุษยชน
                          </div>
                          {TopicCheckbox1}
                          <div className="col-12">
                            <SError error={errors["seltopiclist1"]} />
                          </div>

                          <div className="subheader col-12" role="alert">
                            เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                          </div>
                          <div className="col-12">
                            <Dropzone
                              className="input-dropzone dropzone"
                              onDrop={(acceptedFiles) => {
                                //setnetworkActivities({curActivity,['networkActivityFiles1']: acceptedFiles});
                                SetNetworkActivityFiles1([
                                  ...networkActivityFiles1,
                                  ...acceptedFiles,
                                ]);
                                SetNetworkActivityFiles1Preview(acceptedFiles.map(file => Object.assign(file, {
                                  id: 0,
                                  preview: URL.createObjectURL(file)
                                })));
                              }}
                              accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                  <SError
                                    error={errors["networkActivityFiles1"]}
                                  />
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    <p>
                                      ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                    </p>
                                  </div>
                                  <aside>
                                    <h4>Files</h4>
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>ลำดับ</th>
                                            <th>ชื่อ</th>
                                            <th>ขนาดไฟล์</th>
                                            <th>ประเภทไฟล์</th>
                                            <th>จัดการ</th>
                                          </tr>
                                        </thead>
                                        <tbody id="your-table-body-id">
                                          {NetActivityFilesList1}
                                        </tbody>
                                      </table>
                                    </div>
                                  </aside>
                                </section>
                              )}
                            </Dropzone>
                          </div>

                          <div className="subheader col-12" role="alert">
                            รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
                          </div>
                          <div className="col-12">
                            <Dropzone
                              className="input-dropzone dropzone"
                              maxFiles={4}
                              onDrop={(acceptedFiles) => {
                                var filecount = imageFiles1.length;
                                if (filecount < 4) {
                                  SetImageFiles1([
                                    ...imageFiles1,
                                    ...acceptedFiles,
                                  ]);
                                  SetImageFiles1Preview(acceptedFiles.map(file => Object.assign(file, {
                                    id: 0,
                                    preview: URL.createObjectURL(file)
                                  })));
                                }
                              }}
                              accept=".jpg,.jpeg,.gif,.png"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                  <SError error={errors["imageFiles1"]} />
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    <p>
                                      ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                    </p>
                                  </div>
                                  <aside>
                                    <h4>Files</h4>
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>ลำดับ</th>
                                            <th>ชื่อ</th>
                                            <th>ขนาดไฟล์</th>
                                            <th>ประเภทไฟล์</th>
                                            <th>จัดการ</th>
                                          </tr>
                                        </thead>
                                        <tbody id="your-table-body-id">
                                          {NetImageFilesList1}
                                        </tbody>
                                      </table>
                                    </div>
                                  </aside>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="tab2"
                        role="tabpanel"
                        aria-labelledby="tab2-tab"
                      >
                        <div className="row formContent">
                          <div className="col-3">
                            <input
                              type="text"
                              className="form-control "
                              id="year2"
                              maxLength={4}
                              defaultValue={year2}
                              name="year2"
                              placeholder="พ.ศ."
                              onBlur={(e) => {
                                handleYearChange2(e);
                              }}
                            />
                            <SError error={errors["year2"]} />
                          </div>
                          <div className="col-9">
                            <input
                              type="text"
                              className="form-control "
                              id="activityName2"
                              defaultValue={activityName2}
                              name="activityName2"
                              placeholder="ชื่อผลการดำเนินงาน"
                              onBlur={(e) => {
                                SetActivityName2(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["activityName2"]} />
                          </div>
                          <div className="col-12">
                            <div>
                              <textarea
                                className="form-control"
                                name="activityDetail2"
                                id="activityDetail2"
                                rows="8"
                                defaultValue={activityDetail2}
                                placeholder="รายละเอียดผลงาน (โดยย่อ)"
                                onBlur={(e) => {
                                  SetActivityDetail2(e.currentTarget.value);
                                }}
                              ></textarea>
                              <SError error={errors["activityDetail2"]} />
                            </div>
                          </div>

                          <div className="subheader col-12" role="alert">
                            ประเด็นการดำเนินงานด้านสิทธิมนุษยชน
                          </div>
                          {TopicCheckbox2}
                          <div className="col-12">
                            <SError error={errors["seltopiclist2"]} />
                          </div>

                          <div className="subheader col-12" role="alert">
                            เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                          </div>
                          <div className="col-12">
                            <Dropzone
                              className="input-dropzone dropzone"
                              onDrop={(acceptedFiles) => {
                                SetNetworkActivityFiles2([
                                  ...networkActivityFiles2,
                                  ...acceptedFiles,
                                ]);
                                SetNetworkActivityFiles2Preview(acceptedFiles.map(file => Object.assign(file, {
                                  id: 0,
                                  preview: URL.createObjectURL(file)
                                })));
                              }}
                              accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                  <SError
                                    error={errors["networkActivityFiles2"]}
                                  />
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    <p>
                                      ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                    </p>
                                  </div>
                                  <aside>
                                    <h4>Files</h4>
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>ลำดับ</th>
                                            <th>ชื่อ</th>
                                            <th>ขนาดไฟล์</th>
                                            <th>ประเภทไฟล์</th>
                                            <th>จัดการ</th>
                                          </tr>
                                        </thead>
                                        <tbody id="your-table-body-id">
                                          {NetActivityFilesList2}
                                        </tbody>
                                      </table>
                                    </div>
                                  </aside>
                                </section>
                              )}
                            </Dropzone>
                          </div>

                          <div className="subheader col-12" role="alert">
                            รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
                          </div>
                          <div className="col-12">
                            <Dropzone
                              className="input-dropzone dropzone"
                              maxFiles={4}
                              onDrop={(acceptedFiles) => {
                                var filecount = imageFiles2.length;
                                if (filecount < 4) {
                                  SetImageFiles2([
                                    ...imageFiles2,
                                    ...acceptedFiles,
                                  ]);
                                  SetImageFiles2Preview(acceptedFiles.map(file => Object.assign(file, {
                                    id: 0,
                                    preview: URL.createObjectURL(file)
                                  })));
                                }
                              }}
                              accept=".jpg,.jpeg,.gif,.png"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                  <SError error={errors["imageFiles2"]} />
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    <p>
                                      ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                    </p>
                                  </div>
                                  <aside>
                                    <h4>Files</h4>
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>ลำดับ</th>
                                            <th>ชื่อ</th>
                                            <th>ขนาดไฟล์</th>
                                            <th>ประเภทไฟล์</th>
                                            <th>จัดการ</th>
                                          </tr>
                                        </thead>
                                        <tbody id="your-table-body-id">
                                          {NetImageFilesList2}
                                        </tbody>
                                      </table>
                                    </div>
                                  </aside>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="tab3"
                        role="tabpanel"
                        aria-labelledby="tab3-tab"
                      >
                        <div className="row formContent">
                          <div className="col-3">
                            <input
                              type="text"
                              className="form-control "
                              id="year2"
                              maxLength={4}
                              defaultValue={year3}
                              name="year3"
                              placeholder="พ.ศ."
                              onBlur={(e) => {
                                handleYearChange3(e);
                              }}
                            />
                            <SError error={errors["year3"]} />
                          </div>
                          <div className="col-9">
                            <input
                              type="text"
                              className="form-control "
                              id="activityName3"
                              defaultValue={activityName3}
                              name="activityName3"
                              placeholder="ชื่อผลการดำเนินงาน"
                              onBlur={(e) => {
                                SetActivityName3(e.currentTarget.value);
                              }}
                            />
                            <SError error={errors["activityName3"]} />
                          </div>
                          <div className="col-12">
                            <div>
                              <textarea
                                className="form-control"
                                name="activityDetail3"
                                id="activityDetail3"
                                rows="8"
                                defaultValue={activityDetail3}
                                placeholder="รายละเอียดผลงาน (โดยย่อ)"
                                onBlur={(e) => {
                                  SetActivityDetail3(e.currentTarget.value);
                                }}
                              ></textarea>
                              <SError error={errors["activityDetail3"]} />
                            </div>
                          </div>

                          <div className="subheader col-12" role="alert">
                            ประเด็นการดำเนินงานด้านสิทธิมนุษยชน
                          </div>
                          {TopicCheckbox3}
                          <div className="col-12">
                            <SError error={errors["seltopiclist3"]} />
                          </div>

                          <div className="subheader col-12" role="alert">
                            เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา
                          </div>
                          <div className="col-12">
                            <Dropzone
                              className="input-dropzone dropzone"
                              onDrop={(acceptedFiles) => {
                                SetNetworkActivityFiles3([
                                  ...networkActivityFiles3,
                                  ...acceptedFiles,
                                ]);
                                SetNetworkActivityFiles3Preview(acceptedFiles.map(file => Object.assign(file, {
                                  id: 0,
                                  preview: URL.createObjectURL(file)
                                })));
                              }}
                              accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                  <SError
                                    error={errors["networkActivityFiles3"]}
                                  />
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    <p>
                                      ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                    </p>
                                  </div>
                                  <aside>
                                    <h4>Files</h4>
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>ลำดับ</th>
                                            <th>ชื่อ</th>
                                            <th>ขนาดไฟล์</th>
                                            <th>ประเภทไฟล์</th>
                                            <th>จัดการ</th>
                                          </tr>
                                        </thead>
                                        <tbody id="your-table-body-id">
                                          {NetActivityFilesList3}
                                        </tbody>
                                      </table>
                                    </div>
                                  </aside>
                                </section>
                              )}
                            </Dropzone>
                          </div>

                          <div className="subheader col-12" role="alert">
                            รูปถ่ายประกอบการพิจารณา (รูปถ่ายไม่เกิน 4 รูป)
                          </div>
                          <div className="col-12">
                            <Dropzone
                              className="input-dropzone dropzone"
                              maxFiles={4}
                              onDrop={(acceptedFiles) => {
                                var filecount = imageFiles3.length;
                                if (filecount < 4) {
                                  SetImageFiles3([
                                    ...imageFiles3,
                                    ...acceptedFiles,
                                  ]);
                                  SetImageFiles3Preview(acceptedFiles.map(file => Object.assign(file, {
                                    id: 0,
                                    preview: URL.createObjectURL(file)
                                  })));
                                }
                              }}
                              accept=".jpg,.jpeg,.gif,.png"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className="container">
                                  <SError error={errors["imageFiles3"]} />
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                  >
                                    <input {...getInputProps()} />
                                    <p>
                                      ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์
                                    </p>
                                  </div>
                                  <aside>
                                    <h4>Files</h4>
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>ลำดับ</th>
                                            <th>ชื่อ</th>
                                            <th>ขนาดไฟล์</th>
                                            <th>ประเภทไฟล์</th>
                                            <th>จัดการ</th>
                                          </tr>
                                        </thead>
                                        <tbody id="your-table-body-id">
                                          {NetImageFilesList3}
                                        </tbody>
                                      </table>
                                    </div>
                                  </aside>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 formContent">
                      <div className="pl-4 pr-4 text-center">
                        <input
                          type="button"
                          className="fadeIn fourth"
                          value="ย้อนกลับ"
                          onClick={(e) => {
                            e.preventDefault();
                            nextPageFunction(false);
                          }}
                        />
                        <input
                          type="submit"
                          className="fadeIn fourth"
                          value="ตกลง"
                          onClick={(e) => {
                            e.preventDefault();
                            saveData();
                          }}
                        />
                        <input
                          type="button"
                          className="fadeIn fourth"
                          onClick={(e) => {
                            e.preventDefault();
                            cancel();
                          }}
                          value="ยกเลิก"
                        />
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
