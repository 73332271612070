import React, {useEffect, useRef, useState} from "react";
import utils from "../../utils/utils";
import axios from "axios";
import avatar from "../../assets/images/icon/user.svg";

let newitem = false;
let olditem = false;

const ReadNotify = (notificationID) => {
  //alert(notificationID);
  const formData = new FormData();
  formData.append("notificationID", notificationID);
  axios
    .post(
      process.env.REACT_APP_APIURL + "/api-web/ReadNotify",
      formData,
      {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      }
    )
    .then((response) => {
      //window.location.reload();
    });
};

function CickNotifyItem(initialIsVisible, NotificationStatus, newcount, parentCallback) {
  const [notiID, setNotiID] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  /*const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };*/

  const handleClickOutside = event => {
    //
    if (ref.current && !ref.current.contains(event.target)) {
      //console.log('yyyyy')
      //ReadNotify(false);
    } else {
      //setID();

      ReadNotify(notiID);
      if (Number(NotificationStatus) === 0) {
        if (newcount > 0) {
          parentCallback(newcount - 1);
          newitem = false;
          olditem = false;
        }
      }
      //console.log('xxxxx')
    }
  };

  useEffect(() => {
    //document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      //document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return {ref, notiID, setNotiID};
}

function NotificationItem({item, newcount, parentCallback}) {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = CickNotifyItem(item.NotificationID, item.NotificationStatus, newcount, parentCallback);
  let notidate = new Date(item.NotificationDate).valueOf();

  if (Number(item.NotificationStatus) === 0) {
    if (newitem === false) {
      newitem = true;
      //console.log(newitem)
      return (
        <>
          <li className="notifications-part">ใหม่</li>
          <li ref={ref} className="notify-item">
            {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar"/>)}
            {item.Avatar === null && (<img src={avatar} alt="Avatar"/>)}
            <div className="notify-item-content">
              <h4 className="title">{item.Title}</h4>
              <div dangerouslySetInnerHTML={{__html: item.Description}}></div>
              <span className="time-ago"><i className="far fa-clock"/><p> {utils.getTimeAgo(notidate)}</p></span>
            </div>
          </li>
        </>);

    } else {
      return (
        <li ref={ref} className="notify-item">
          {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar"/>)}
          {item.Avatar === null && (<img src={avatar} alt="Avatar"/>)}
          <div className="notify-item-content">
            <h4 className="title">{item.Title}</h4>
            <div dangerouslySetInnerHTML={{__html: item.Description}}></div>
            <span className="time-ago"><i className="far fa-clock"/><p> {utils.getTimeAgo(notidate)}</p></span>
          </div>
        </li>);
    }
  } else {
    if (olditem === false) {
      olditem = true;
      return (
        <>
          <li className="notifications-part">ก่อนหน้านี้</li>
          <li ref={ref} className="notify-item">
            {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar"/>)}
            {item.Avatar === null && (<img src={avatar} alt="Avatar"/>)}
            <div className="notify-item-content">
              <h4 className="title">{item.Title}</h4>
              <div dangerouslySetInnerHTML={{__html: item.Description}}></div>
              <span className="time-ago"><i className="far fa-clock"/><p> {utils.getTimeAgo(notidate)}</p></span>
            </div>
          </li>
        </>);

    } else {
      return (
        <li ref={ref} className="notify-item">
          {item.Avatar !== null && (<img src={item.Avatar} alt="Avatar"/>)}
          {item.Avatar === null && (<img src={avatar} alt="Avatar"/>)}
          <div className="notify-item-content">
            <h4 className="title">{item.Title}</h4>
            <div dangerouslySetInnerHTML={{__html: item.Description}}></div>
            <span className="time-ago"><i className="far fa-clock"/><p> {utils.getTimeAgo(notidate)}</p></span>
          </div>
        </li>);
    }
  }
}

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [newnotiCount, setNewNotiCount] = useState(0);

  const callback = (count) => {
    setNewNotiCount(count);
    newitem = false;
    olditem = false;
  };
  useEffect(() => {
    utils.asyncLocalStorage.getItem('user').then(function (value) {
      let user = JSON.parse(value);
      //console.log(user);
      //console.log(user.UserID);
      if (user !== null) {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/notify', {params: {userid: user.UserID}})
          .then((response) => {
            setNotifications(response.data);
            const newnotifyitem = response.data.filter(item => Number(item.NotificationStatus) === 0);
            setNewNotiCount(newnotifyitem.length);

            //console.log(newnotifyitem.length)
            setLoading(false);
          })
      } else {
        //setNotifications()
        setLoading(false);
      }
      //props.onChange(true)
    })
  }, [newnotiCount]);

  return (
    <div className="notifications-wrapper">
      <ul style={{listStyle: 'none', padding: 0}}>
        {
          notifications.length > 0 ? notifications.map((i, index) => (
            <NotificationItem item={i} newcount={newnotiCount} parentCallback={callback} key={i.NotificationID}/>
          )) : <div className="d-flex justify-content-center align-items-center" style={{ height: 300 }}>ไม่มีรายการแจ้งเตือน</div>
        }
      </ul>
    </div>
  )
}
