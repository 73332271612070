import * as React from "react";
const SvgContact = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18.367 18.382"
    {...props}
  >
    <path
      d="M4.092-.006a2.5 2.5 0 0 0-.615.045 2.87 2.87 0 0 0-1.364.695A5.66 5.66 0 0 0 .246 3.506 6.7 6.7 0 0 0 .456 7.8a12.7 12.7 0 0 0 1.868 3.353 24.7 24.7 0 0 0 3.412 3.678 17.8 17.8 0 0 0 3.432 2.455.67.67 0 0 0 .96-.23.656.656 0 0 0-.312-.934 14 14 0 0 1-1.443-.889 23 23 0 0 1-4.643-4.447 11.6 11.6 0 0 1-2.1-3.738 4.98 4.98 0 0 1 .2-3.89 4.6 4.6 0 0 1 1.246-1.477 1.28 1.28 0 0 1 1.807.048q.885.878 1.763 1.762a1.254 1.254 0 0 1 0 1.85c-.487.49-.98.977-1.466 1.467a.693.693 0 0 0 0 1.125l5.273 5.273a.683.683 0 0 0 1.117-.008c.5-.495 1-1 1.5-1.494a1.255 1.255 0 0 1 1.791 0q.927.91 1.836 1.838a1.294 1.294 0 0 1 .125 1.605 5 5 0 0 1-.412.5 4.25 4.25 0 0 1-3.379 1.372l-.513.001a.66.66 0 0 0-.112 1.317 5.8 5.8 0 0 0 2.4-.19 5.9 5.9 0 0 0 2.964-2.05 2.685 2.685 0 0 0-.149-3.506q-.905-.919-1.822-1.822a2.61 2.61 0 0 0-3.695 0c-.328.323-.656.645-.977.976-.106.1-.177.113-.29 0q-2.105-2.115-4.222-4.22c-.117-.117-.093-.19.012-.29q.518-.504 1.023-1.021a2.59 2.59 0 0 0 .014-3.582 80 80 0 0 0-1.91-1.91 2.54 2.54 0 0 0-1.662-.729"
      style={{
        color: "#000",
        fill: "#faab3e",
        InkscapeStroke: "none",
      }}
    />
  </svg>
);
export default SvgContact;
