import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { scroller } from "react-scroll";
import 'moment/locale/th';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from "react-dropzone";
import AdminBanner from 'components/Banner/AdminBanner';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import noimage from 'assets/images/no_image.svg'
import SError from 'components/SError';
const swal = withReactContent(Swal)

export default function EditBanner(props) {
    const currentUser = authenticationService.currentUserValue;

    const [bannerName, setBannerName] = useState('');
    const [bannerLink, setBannerLink] = useState('');
    const [bannerImageTH, setBannerImageTH] = useState(null);
    const [bannerImageEN, setBannerImageEN] = useState(null);
    const [publishstartDate, SetPublishStartDate] = useState(new Date());
    const [publishendDate, SetPublishEndDate] = useState(new Date());
    const [status, setStatus] = useState(false);

    const [errors, setError] = useState({});
    const bannerID = props.location.state.id;
    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getBannerbyID', { params: { id: props.location.state.id } })
            .then((response) => {
                let data = response.data[0];
                setBannerName(data.BannerName);
                setBannerLink(data.BannerLink);
                setStatus(data.Status);
                SetPublishStartDate(new Date(data.PublishedStartDate))
                SetPublishEndDate(new Date(data.PublishedEndDate))
                if (data.BannerImageTH !== null) {
                    const thimageFile = {
                        file: null,
                        name: null,
                        preview: data.BannerImageTH,
                        size: null
                    };
                    setBannerImageTH(thimageFile);
                }
                if (data.BannerImageEN !== null) {
                    const enimageFile = {
                        file: null,
                        name: null,
                        preview: data.BannerImageEN,
                        size: null
                    };
                    setBannerImageEN(enimageFile);
                }
            });

    }, []); /* <-- add this for run once*/


    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (bannerName.trim().length === 0) {
            formIsValid = false;
            serrors["thname"] = "กรุณาระบุชื่อภาษาไทย";
        }
        if (bannerLink.trim().length === 0) {
            formIsValid = false;
            serrors["bannerLink"] = "กรุณาระบุลิงก์ป้ายโฆษณา";
        }
        if (bannerImageTH === null) {
            formIsValid = false;
            serrors["bannerImageTH"] = "กรุณาเลือกไฟล์ภาพสำหรับป้ายโฆษณาภาษาไทย";
        }
        if (bannerImageEN === null) {
            formIsValid = false;
            serrors["bannerImageEN"] = "กรุณาเลือกไฟล์ภาพสำหรับป้ายโฆษณาภาษาอังกฤษ";
        }
        setError(serrors);
        return formIsValid;
    };
    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();
            formData.append("bannerID", bannerID);
            formData.append("bannerName", bannerName);
            formData.append("bannerLink", bannerLink);
            formData.append("status", status);
            formData.append("publishstartDate", publishstartDate);
            formData.append("publishendDate", publishendDate);

            formData.append("bannerImageTH", bannerImageTH.file);
            formData.append("bannerImageEN", bannerImageEN.file);
            formData.append("userID", currentUser.UserID);

            axios.post(process.env.REACT_APP_APIURL + '/api-web/updateBanner', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/setting/Banner" });
                        }
                    })
                });

        } else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };

    const WebCoverPreview = (
        <img className="preview-banner" src={bannerImageTH === null ? noimage : bannerImageTH.preview} onChange={(e) => { e.target.src = URL.createObjectURL(bannerImageTH); }} alt="Web Cover Preview" />
    );

    const MobileCoverPreview = (
        <img className="preview-banner" src={bannerImageEN === null ? noimage : bannerImageEN.preview} onChange={(e) => { e.target.src = URL.createObjectURL(bannerImageEN); }} alt="Mobile Cover Preview" />
    );

    const handleImgWebCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setBannerImageTH(imageFile);
    };

    const handleImgMobileCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setBannerImageEN(imageFile);
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/setting/Banner" });
    };

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการป้ายโฆษณา"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/setting/Banner" }}>{"การจัดการป้ายโฆษณา"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link className="active" to={{ pathname: "/admin/setting/ViewBanner", state: { id: bannerID } }}>{"ตัวอย่าง"}</Link>
            </>}
            />
            <div className="col-12">
                <form className="row">
                    <div className="card-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <h4>รูปป้ายโฆษณาภาษาไทย</h4>
                                {WebCoverPreview}
                                <h4>รูปป้ายโฆษณาภาษาอังกฤษ</h4>
                                {MobileCoverPreview}
                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center" >
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ย้อนกลับ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
