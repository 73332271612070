import * as React from "react";
const SvgActivity = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20.651 20.651"
    {...props}
  >
    <g data-name="Group 130">
      <path
        d="M311.607 2.25a3.31 3.31 0 0 0-3.357 3.357v12.079a3.31 3.31 0 0 0 3.357 3.357l-.013.002h13.834l-.014-.002a3.31 3.31 0 0 0 3.357-3.357V5.607a3.31 3.31 0 0 0-3.357-3.357Zm-.027 1.5h13.861a1.785 1.785 0 0 1 1.83 1.83v12.133a1.787 1.787 0 0 1-1.83 1.832H311.58a1.787 1.787 0 0 1-1.83-1.832V5.58a1.785 1.785 0 0 1 1.83-1.83"
        style={{
          color: "#000",
          fill: "#faab3e",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          InkscapeStroke: "none",
        }}
        transform="translate(-308.25 -.391)"
      />
      <path
        d="M0-.75A.75.75 0 0 0-.75 0 .75.75 0 0 0 0 .75h19a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75Z"
        style={{
          color: "#000",
          fill: "#faab3e",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          InkscapeStroke: "none",
        }}
        transform="translate(.901 7.75)"
      />
      <path
        d="M0-.75A.75.75 0 0 0-.75 0v4a.75.75 0 0 0 .75.75A.75.75 0 0 0 .75 4V0A.75.75 0 0 0 0-.75"
        style={{
          color: "#000",
          fill: "#faab3e",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          InkscapeStroke: "none",
        }}
        transform="translate(9.901 .75)"
      />
      <path
        d="M0-.75A.75.75 0 0 0-.75 0v4a.75.75 0 0 0 .75.75A.75.75 0 0 0 .75 4V0A.75.75 0 0 0 0-.75"
        style={{
          color: "#000",
          fill: "#faab3e",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          InkscapeStroke: "none",
        }}
        transform="translate(5.901 .75)"
      />
      <path
        d="M0-.75A.75.75 0 0 0-.75 0v4a.75.75 0 0 0 .75.75A.75.75 0 0 0 .75 4V0A.75.75 0 0 0 0-.75"
        style={{
          color: "#000",
          fill: "#faab3e",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          InkscapeStroke: "none",
        }}
        transform="translate(14.901 .75)"
      />
    </g>
    <path
      d="M4.9 11a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h1a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75zM8.9 11a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h2a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75zM13.9 11a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h2a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75zM4.9 14a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h1a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75zM8.9 14a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h2a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75zM13.9 14a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h2a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75z"
      style={{
        color: "#000",
        fill: "#faab3e",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        InkscapeStroke: "none",
      }}
    />
  </svg>
);
export default SvgActivity;
