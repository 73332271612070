import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "material-ui-thai-datepickers";
import { scroller } from "react-scroll";
import 'moment/locale/th';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
//import 'react-summernote/lang/summernote-th-TH'; // you can import any other locale
import Dropzone from "react-dropzone";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import SingleFileTable from 'components/SingleFileTable';
import SError from 'components/SError';
import moment from "moment";
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
const swal = withReactContent(Swal)

export default function NewElibrary(props) {
    const currentUser = authenticationService.currentUserValue;
    //const [fields, setFields] = useState({});
    const [errors, setError] = useState({});
    const [filespreview, setFilesPreview] = useState([]);
    const [myFiles, setMyFiles] = useState([]);
    const [imgWebCover, setIMGWebCover] = useState([]);
    const [imgMobileCover, setIMGMobileCover] = useState([]);

    const [selectActivityCat, SetSelectActivityCat] = useState([]);

    const [bookName, setBookName] = useState('');
    const [shortDetail, setShortDetail] = useState('');
    const [description, setDescription] = useState('');
    const [bookCatID, setBookCatID] = useState(0);
    const [displayType, setDisplayType] = useState(0);
    const [filePath, setFilePath] = useState('');
    const [vdofile, setVDOFile] = useState(null);
    const [pdfFile, setPDFFile] = useState([]);
    const [author, setAuthor] = useState('');
    const [coAuthor, setCoAuthor] = useState('');
    const [translator, setTranslator] = useState('');
    const [corporateAgency, setCorporateAgency] = useState('');
    const [publisherLocation, setPublisherLocation] = useState('');
    const [publisher, setPublisher] = useState('');
    const [publishYear, setPublishYear] = useState(2564);
    const [pages, setPages] = useState(0);
    const [ISBN, setISBN] = useState('');

    const [bookNameEN, setBookNameEN] = useState('');
    const [descriptionEN, setDescriptionEN] = useState('');

    const [listofContent, setListofContent] = useState([]);
    //const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    //const rx_int = /^\d+$/;
    //const rx_latln = /^[0-9.,]+$/;
    const [dataLoaded, setDataLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState([]);

    const [publishstartDate, SetPublishStartDate] = useState(new Date());
    const [publishendDate, SetPublishEndDate] = useState(null);
    const [published, setPublished] = useState(false);
    const [pin, setPin] = useState(false);
    const [comment, SetComment] = useState(1);

    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getelibraryCatParent', { params: { status: 1 } })
            .then((response) => {
                SetSelectActivityCat(response.data);
                const date = new Date();
                let tmppublishendDate = new Date(date.setTime(date.getTime() + 50 * 365 * 86400000));
                SetPublishEndDate(tmppublishendDate);
            });
    }, []); /* <-- add this for run once*/
    useEffect(() => {
        if (moment(publishstartDate).format('YYYY-MM-DD') > moment(publishendDate).format('YYYY-MM-DD')) {
            SetPublishEndDate(publishstartDate);
        }
    }, [publishstartDate]);
    useEffect(() => {
        if (moment(publishendDate).format('YYYY-MM-DD') < moment(publishstartDate).format('YYYY-MM-DD')) {
            SetPublishStartDate(publishendDate);
        }
    }, [publishendDate]);
    function ShowLevel(level) {
        let str = "";
        for (let i = 0; i < level * 3; i++) {
            str += "-";
        }
        return str + " ";
    }
    const ActivityCatSelect = selectActivityCat.map((bookCatID, index) => (
        <option
            key={index}
            value={bookCatID.BookCatID}
        >
            {ShowLevel(bookCatID.Level) + bookCatID.CategoriesNameTH}
        </option>
    ));
    const files = myFiles.map((file, i) => (

        <tr key={i}>
            <td>{(Number(i) + 1)}</td>
            <td>{file.path.split('.')[0]}</td>
            <td>{utils.Bytes2Size(file.size)}</td>
            <td>{file.path.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={file.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveFile(file.name); }}>ลบ</button>
            </td>
        </tr>
    ));
    const HandlePin = (pinStatus) => {
        if (pinStatus === true) {
            axios.get(process.env.REACT_APP_APIURL + '/api-web/getELibraryPinCount')
                .then((response) => {
                    //console.log(response.data[0].PinCount)
                    if (Number(response.data[0].PinCount) >= 3) {
                        Swal.fire({
                            icon: 'error',
                            title: 'ผิดพลาด',
                            text: 'มีข้อมูลในการปักหมุดครบจำนวนสามชุดแล้ว',
                        })
                    }
                    else {
                        setPin(pinStatus)
                    }
                });
        }
        else {
            setPin(pinStatus)
        }
    }
    const rx_int = /^\d+$/;
    const handleYearChange = (e) => {
        if (rx_int.test(e.target.value)) {
            setPublishYear(parseInt(e.target.value));
        }
    }
    const handlePageChange = (e) => {
        if (rx_int.test(e.target.value)) {
            setPages(parseInt(e.target.value));
        }
    }

    const handleRemoveFile = filename => {
        for (let file of myFiles) {
            if (file.name === filename) {
                if (file.id !== 0) {
                    const formData = new FormData();
                    formData.append("id", file.id);
                    axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteAttachmentFile', formData, {
                        headers: {
                            'Content-Type': `multipart/form-data`
                        }
                    })
                        .then((response) => {

                        });
                }
            } // Add only file name not matched files
        }
        setMyFiles(myFiles.filter(item => item['name'] !== filename));
    };


    const WebCoverPreview = (
        (typeof imgWebCover.preview !== 'undefined') && (<img className="preview-upload" src={imgWebCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgWebCover); }} alt="Web Cover" />)
    );

    const MobileCoverPreview = (
        (typeof imgMobileCover.preview !== 'undefined') && (<img className="preview-upload" src={imgMobileCover.preview} onChange={(e) => { e.target.src = URL.createObjectURL(imgMobileCover); }} alt="Mobile Cover" />)
    );

    const handleImgWebCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGWebCover(imageFile);
    };

    const handleImgMobileCoverOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setIMGMobileCover(imageFile);
    };
    const handleRemoveVDOFile = () => {
        /*const formData = new FormData();
        formData.append("folder", 'vdo');
        formData.append("url", vdofile.file);
        formData.append("fieldName", 'FilePath');
        formData.append("id", props.location.state.id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteELibraryPathFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });*/
        setVDOFile(null);
    };
    const handleVDOOnDrop = (newImageFile, onChange) => {
        const newfile = {
            id: 0,
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setVDOFile(newfile);
        setFilePath('');
    };
    const VDOList = (vdofile !== null) && (
        <tr key={vdofile.name}>
            <td>{1}</td>
            <td>{vdofile.name.split('.')[0]}</td>
            <td>{utils.Bytes2Size(vdofile.size)}</td>
            <td>{vdofile.name.split('.').pop()}</td>
            <td>
                <a className="btn btn-sm btn-primary mr-2" href={vdofile.preview} rel="noreferrer" target="_blank">ดู</a>
                <button className="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); handleRemoveVDOFile(); }}>ลบ</button>
            </td>
        </tr>
    );
    const handlePDFOnDrop = (newImageFile, onChange) => {
        const ebookFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };
        setPDFFile(ebookFile);
    };
    const handleRemovePDFFile = () => {
        const formData = new FormData();
        formData.append("folder", 'attachment');
        formData.append("url", pdfFile.file);
        formData.append("fieldName", 'FilePath');
        formData.append("id", props.location.state.id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteELibraryPathFile', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        setPDFFile(null);
    };
    const handleRemoveListofContent = index => {
        const removedata = listofContent.filter((item) => Number(item.ListOfContentID) === Number(index));
        //console.log(removedata);
        if (Number(removedata[0].ListOfContentID) !== 0) {
            //console.log(removedata[0].ListOfContentID)
            const formData = new FormData();
            formData.append("id", removedata[0].ListOfContentID);
            axios.post(process.env.REACT_APP_APIURL + '/api-web/deleteListofContent', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });
        }
        const arr = listofContent.filter((item) => Number(item.ListOfContentID) !== Number(index));
        setListofContent(arr);

    };
    const AddListofContent = () => {
        const data = [...listofContent];
        setListofContent((prevFriends) => [
            ...prevFriends,
            {
                ListOfContentID: 0,
                ContentTitle: '',
                Page: 0,
            },
        ]);
    }
    const handleValidation = () => {
        let serrors = {};
        let formIsValid = true;
        //Name
        if (bookName.trim().length === 0) {
            formIsValid = false;
            serrors["bookName"] = "กรุณาระบุชื่อหนังสือภาษาไทย";
        }
        if (description.trim().length === 0) {
            formIsValid = false;
            serrors["description"] = "กรุณาระบุรายละเอียดภาษาไทยโดยย่อ";
        }
        /*if (bookNameEN.trim().length===0) {
            formIsValid = false;
            serrors["bookNameEN"] = "กรุณาระบุชื่อหนังสือภาษาอังกฤษ";
        }
        if(descriptionEN.trim().length===0){
            formIsValid = false;
            serrors["descriptionEN"] = "กรุณาระบุรายละเอียดภาษาอังกฤษโดยย่อ";
        }*/
        if (bookCatID === 0) {
            formIsValid = false;
            serrors["bookCatID"] = "กรุณาเลือกหมวดหมู่";
        }

        if (imgWebCover.length === 0) {
            formIsValid = false;
            serrors["webcover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับเว็บ";
        }
        if (imgMobileCover.length === 0) {
            formIsValid = false;
            serrors["mobilecover"] = "กรุณาเลือกไฟล์ภาพหน้าปกสำหรับมือถือ";
        }
        if (displayType === 0) {
            formIsValid = false;
            serrors["displayType"] = "กรุณาเลือกประเภทการแสดงผล";
        }
        if (displayType === 1 || displayType === 2) {
            if (typeof pdfFile.name === 'undefined') {
                formIsValid = false;
                serrors["pdfFile"] = "กรุณาอัปโหลดไฟล์";
            }
        }
        if (displayType === 3) {
            if (filePath.trim().length === 0) {
                formIsValid = false;
                serrors["filePath"] = "กรุณากรอก URL สำหรับ VDO";
            }
        }
        if (displayType === 4) {
            if (vdofile === null) {
                formIsValid = false;
                serrors["vdofile"] = "กรุณาอัปโหลดวีดีโอ";
            }
        }
        if (author.trim().length === 0) {
            formIsValid = false;
            serrors["author"] = "กรุณาระบุรายละเอียด";
        }
        if (formIsValid === false) {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            })
        }
        setError(serrors);
        return formIsValid;
    };

    const saveData = (open) => {
        if (handleValidation()) {
            const formData = new FormData();

            formData.append("bookName", bookName);
            formData.append("shortDetail", shortDetail);
            formData.append("description", description);
            formData.append("catid", bookCatID);

            formData.append("bookNameEN", bookNameEN);
            formData.append("descriptionEN", descriptionEN);

            formData.append("pin", pin);
            formData.append("comment", comment);

            formData.append("published", published);
            formData.append("publishstartDate", publishstartDate);
            /*if (publishendDate !== null) {
                formData.append("publishendDate", publishendDate);
            }
            else {
                const date = new Date();
                let tmppublishendDate = new Date(date.setTime(date.getTime() + 50 * 365 * 86400000));
                formData.append("publishendDate", tmppublishendDate);
            }*/
            formData.append("publishendDate", publishendDate);

            formData.append("webcoverfile", imgWebCover.file);
            formData.append("mobilecoverfile", imgMobileCover.file);
            formData.append("displayType", displayType);
            formData.append("filePath", filePath);
            if (vdofile !== null)
                formData.append("vdofile", vdofile.file);
            formData.append("pdfFile", pdfFile.file);
            formData.append("author", author);
            formData.append("coAuthor", coAuthor);

            formData.append("translator", translator);
            formData.append("corporateAgency", corporateAgency);
            formData.append("publisherLocation", publisherLocation);
            formData.append("publisher", publisher);
            formData.append("publishYear", publishYear);
            formData.append("pages", pages);
            formData.append("ISBN", ISBN);
            //formData.append("listofContent", listofContent);
            for (var i = 0; i < listofContent.length; i++) {
                //console.log(listofContent[i])
                formData.append('listofContent[]', JSON.stringify(listofContent[i]));

            }
            formData.append("userID", currentUser.UserID);
            for (let i = 0; i < myFiles.length; i += 1) {
                formData.append("files[]", myFiles[i]);
            }

            // Display the key/value pairs
            /*for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }*/
            axios.post(process.env.REACT_APP_APIURL + '/api-web/saveElibrary', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            })
                .then((response) => {
                    let timerInterval;
                    swal.fire({
                        title: "บันทึกข้อมูลเรียบร้อย", showConfirmButton: false,
                        timer: 1000, timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval);

                        }
                    }).then((result) => {
                        if (open) {
                            window.location.reload();
                        }
                        else {
                            props.history.push({ pathname: "/admin/elibrary/ElibraryList" });
                        }
                    })
                });

        }
        else {
            setTimeout(() => {
                scroller.scrollTo("error", {
                    duration: 800,
                    delay: 0,
                    offset: -50,
                    smooth: "easeInOutQuart",
                });
            }, 100);
        }
    };
    const cancel = () => {
        props.history.push({ pathname: "/admin/elibrary/ElibraryList" });
    };
    const fileHandler = (event) => {
        let fileObj = event.target.files[0];

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                //console.log(resp)
                setCols(resp.cols);
                setRows(resp.rows);
                let lstContent = [];
                let loop = 0;
                resp.rows.map(row => {
                    //console.log(row)
                    //let data = row.Split(',');
                    if (loop > 0) {
                        lstContent.push({ ListOfContentID: 0, ContentTitle: row[0], Page: row[1] });
                    }
                    loop++;
                });
                setListofContent(lstContent);
            }
        });

    }
    /*const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columnsdata = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          //handleSave: this.handleSave
        })
      };
    });*/

    return (
        <section className="row custom-admin-input" >
            <AdminBanner title={"การจัดการคลังความรู้ด้านสิทธิมนุษยชน"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <a href="/" onClick={e => { e.preventDefault(); }}>การจัดการคลังความรู้ด้านสิทธิมนุษยชน</a>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" to={{ pathname: "/admin/elibrary/ElibraryList" }}>{"จัดการคลังความรู้ด้านสิทธิมนุษยชน"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/elibrary/NewElibrary" }}>{"สร้าง"}</Link>
            </>}
            />
            <div className="col-12">
                <form method="post" encType="multipart/form-data">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">TH</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">EN</a>
                        </li>
                    </ul>
                    <div className="tab-content pl-4 pr-4" id="myTabContent">
                        <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                            <div className="card">
                                <div className="card-body row">
                                    <div className="col-12">
                                        <label htmlFor="bookName" className="required">ชื่อเรื่อง (ภาษาไทย)</label>
                                        <input type="text" className="form-control" id="bookName" name="bookName" placeholder="กรุณากรอก" value={bookName} onChange={(e) => { setBookName(e.currentTarget.value) }} />
                                        <SError error={errors["bookName"]} />
                                    </div>
                                    <div className="col-12">
                                        <label className="required">รายละเอียดแบบย่อ (ภาษาไทย)</label>
                                        <ReactSummernote
                                            value={description}
                                            options={{
                                                //lang: 'th-TH',
                                                height: 350,
                                                dialogsInBody: true,
                                                toolbar: [
                                                    ['style', ['style']],
                                                    ['font', ['bold', 'italic', 'underline', 'clear']],
                                                    ['color', ['color']],
                                                    /*['fontname', ['fontname']],*/
                                                    ['para', ['ul', 'ol', 'paragraph']],
                                                    ['table', ['table']],
                                                    ['insert', ['link', 'video']],
                                                    ['view', ['fullscreen']]
                                                ]
                                            }}
                                            onChange={setDescription}
                                        />
                                        <SError error={errors["description"]} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                            <div className="card">
                                <div className="card-body row">
                                    <div className="col-12">
                                        <label htmlFor="bookNameEN" >ชื่อเรื่อง (ภาษาอังกฤษ)</label>
                                        <input type="text" className="form-control" id="bookNameEN" name="bookNameEN" placeholder="กรุณากรอก" value={bookNameEN} onChange={(e) => { setBookNameEN(e.currentTarget.value) }} />
                                        <SError error={errors["bookNameEN"]} />
                                    </div>
                                    <div className="col-12">
                                        <label >รายละเอียดแบบย่อ (ภาษาอังกฤษ)</label>
                                        <ReactSummernote
                                            value={descriptionEN}
                                            options={{
                                                //lang: 'th-TH',
                                                height: 350,
                                                dialogsInBody: true,
                                                toolbar: [
                                                    ['style', ['style']],
                                                    ['font', ['bold', 'italic', 'underline', 'clear']],
                                                    ['color', ['color']],
                                                    /*['fontname', ['fontname']],*/
                                                    ['para', ['ul', 'ol', 'paragraph']],
                                                    ['table', ['table']],
                                                    ['insert', ['link', 'video']],
                                                    ['view', ['fullscreen']]
                                                ]
                                            }}
                                            onChange={setDescriptionEN}
                                        />
                                        <SError error={errors["descriptionEN"]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content pl-4 pr-4" >
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-12">
                                    <label htmlFor="">แหล่งเชื่อมโยง</label>
                                    <input type="text" className="form-control" id="shortDetail" name="shortDetail" placeholder="กรุณากรอก" value={shortDetail} onChange={(e) => { setShortDetail(e.currentTarget.value) }} />
                                    <SError error={errors["shortDetail"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="bookCatID" className="required">หมวดหมู่</label>
                                    <select className="form-control mb-2 mr-2" id="bookCatID" name="bookCatID" value={bookCatID} onChange={(e) => { setBookCatID(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        {ActivityCatSelect}
                                    </select>
                                    <SError error={errors["bookCatID"]} />
                                </div>

                                <div className="col-12">
                                    <label className="required mr-2">ปักหมุด</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pin" id="pin1" value="1" checked={pin === true} onChange={(e) => { HandlePin(true); }} />
                                        <label className="form-check-label" htmlFor="pin1"> ปักหมุด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="pin" id="pin2" value="0" checked={pin === false} onChange={(e) => { HandlePin(false); }} />
                                        <label className="form-check-label" htmlFor="pin2"> ไม่ปักหมุด</label>
                                    </div>
                                    <SError error={errors["pin"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปภาพหน้าปก สำหรับเว็บไซต์</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgWebCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {WebCoverPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                    <SError error={errors["webcover"]} />
                                </div>
                                <div className="col-12">
                                    <label className="required">รูปภาพหน้าปก สำหรับมือถือ</label>
                                    <Dropzone
                                        className='dropzone'
                                        activeClassName='active-dropzone'
                                        multiple={false}
                                        onDrop={handleImgMobileCoverOnDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                </div>
                                                {MobileCoverPreview}
                                            </section>
                                        )}
                                    </Dropzone>
                                    <SError error={errors["mobilecover"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="displayType" className="required">ประเภทการแสดงผล</label>
                                    <select className="form-control mb-2 mr-2" id="displayType" name="displayType" value={displayType} onChange={(e) => { setDisplayType(e.currentTarget.value); }}>
                                        <option key="0" value="0" >กรุณาเลือก</option>
                                        <option key="1" value="1" >E-book</option>
                                        <option key="2" value="2" >PDF</option>
                                        <option key="3" value="3" >Link VDO จากภายนอก</option>
                                        <option key="4" value="4" >VDO</option>
                                    </select>
                                    <SError error={errors["displayType"]} />
                                </div>
                                {(Number(displayType) === 1 || Number(displayType) === 2) && (<>
                                    <div className="col-12">
                                        <label className="required">ไฟล์ PDF</label>
                                        <Dropzone
                                            className='dropzone'
                                            activeClassName='active-dropzone'
                                            multiple={false}
                                            onDrop={handlePDFOnDrop}
                                            accept=".pdf"
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="container">
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <div>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</div>
                                                    </div>
                                                    {/*MobileCoverPreview*/}
                                                    <aside>
                                                        {typeof pdfFile.name !== 'undefined' && (<SingleFileTable filedata={pdfFile} removefn={handleRemovePDFFile} />)}
                                                    </aside>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <SError error={errors["pdfFile"]} />
                                    </div>
                                </>)}
                                {Number(displayType) === 3 && (<>
                                    <div className="col-12">
                                        <label htmlFor="filePath" className="required">ลิงค์ VDO จากภายนอก</label>
                                        <input type="text" className="form-control" id="filePath" name="filePath" placeholder="กรุณากรอกลิงค์ VDO" value={filePath} onChange={(e) => { setFilePath(e.currentTarget.value) }} />
                                        <SError error={errors["filePath"]} />
                                    </div>
                                </>)}
                                {Number(displayType) === 4 && (<>
                                    <div className="col-12">
                                        <label className="required">อัปโหลดวีดีโอ</label>
                                        <Dropzone
                                            className="input-dropzone dropzone"
                                            multiple={false}
                                            onDrop={handleVDOOnDrop}
                                            accept=".mp3,.mp4,.avi,.fla,.wma,.wmv,.mpeg,.mpeg4"
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="container">
                                                    <SError error={errors["vdofile"]} />
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                    </div>
                                                    <aside>

                                                        <h4>Files</h4>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>ลำดับ</th>
                                                                        <th>ชื่อ</th>
                                                                        <th>ขนาดไฟล์</th>
                                                                        <th>ประเภทไฟล์</th>
                                                                        <th>จัดการ</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody id="your-table-body-id">
                                                                    {VDOList}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </aside>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                </>)}
                                <div className="col-12">
                                    <label className="required mr-2">สถานะ</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published1" value="1" checked={published === true} onChange={(e) => { setPublished(true); }} />
                                        <label className="form-check-label" htmlFor="published1"> เผยแพร่</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="published" id="published2" value="0" checked={published === false} onChange={(e) => { setPublished(false); }} />
                                        <label className="form-check-label" htmlFor="published2"> ไม่เผยแพร่</label>
                                    </div>
                                    <SError error={errors["published"]} />
                                </div>
                                {published === true && <>
                                    <div className="col-6">
                                        <label className="required">วันที่เผยแพร่</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishstartDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543} onChange=
                                                {SetPublishStartDate} name="startpublishday" id="startpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-6">
                                        <label className="required">วันที่สิ้นสุด</label>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'} >
                                            <DatePicker value={publishendDate} format="D MMMM YYYY"
                                                pickerHeaderFormat="ddd D MMM" yearOffset={543}
                                                onChange={SetPublishEndDate} name="endpublishday" id="endpublishday" />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </>}
                                <div className="col-12">
                                    <label className="required mr-2">แสดงความคิดเห็น</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment1" value="1" checked={comment === 1} onChange={(e) => { SetComment(1) }} />
                                        <label className="form-check-label" htmlFor="comment1"> เปิดแสดงความคิดเห็นทั้งหมด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment2" value="2" checked={comment === 2} onChange={(e) => { SetComment(2) }} />
                                        <label className="form-check-label" htmlFor="comment2"> ปิดแสดงความคิดเห็นทั้งหมด</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment3" value="3" checked={comment === 3} onChange={(e) => { SetComment(3) }} />
                                        <label className="form-check-label" htmlFor="comment3"> เปิดแสดงความคิดเห็นอย่างเดียว</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="comment" id="comment4" value="4" checked={comment === 4} onChange={(e) => { SetComment(4) }} />
                                        <label className="form-check-label" htmlFor="comment4"> ปิดกล่องแสดงความคิดเห็น</label>
                                    </div>
                                    <SError error={errors["comment"]} />
                                </div>
                                <div className="alert alert-dark col-12" role="alert">รายละเอียด</div>
                                <div className="col-6">
                                    <label htmlFor="author" className="required">ชื่อผู้แต่ง</label>
                                    <input type="text" className="form-control" id="author" name="author" placeholder="กรุณากรอก" value={author} onChange={(e) => { setAuthor(e.currentTarget.value) }} />
                                    <SError error={errors["author"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="coAuthor">ชื่อผู้แต่งร่วม</label>
                                    <input type="text" className="form-control" id="coAuthor" name="coAuthor" placeholder="กรุณากรอก" value={coAuthor} onChange={(e) => { setCoAuthor(e.currentTarget.value) }} />
                                    <SError error={errors["coAuthor"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="translator">ชื่อผู้แปล</label>
                                    <input type="text" className="form-control" id="translator" name="translator" placeholder="กรุณากรอก" value={translator} onChange={(e) => { setTranslator(e.currentTarget.value) }} />
                                    <SError error={errors["translator"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="corporateAgency">ชื่อหน่วยงานนิติบุคคล</label>
                                    <input type="text" className="form-control" id="corporateAgency" name="corporateAgency" placeholder="กรุณากรอก" value={corporateAgency} onChange={(e) => { setCorporateAgency(e.currentTarget.value) }} />
                                    <SError error={errors["corporateAgency"]} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="publisherLocation">สถานที่พิมพ์ </label>
                                    <input type="text" className="form-control" id="publisherLocation" name="publisherLocation" placeholder="กรุณากรอก" value={publisherLocation} onChange={(e) => { setPublisherLocation(e.currentTarget.value) }} />
                                    <SError error={errors["publisherLocation"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="publisher">สำนักพิมพ์</label>
                                    <input type="text" className="form-control" id="publisher" name="publisher" placeholder="กรุณากรอก" value={publisher} onChange={(e) => { setPublisher(e.currentTarget.value) }} />
                                    <SError error={errors["publisher"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="publishYear">ปีที่พิมพ์</label>
                                    <input type="text" className="form-control" id="publishYear" name="publishYear" placeholder="กรุณากรอก" value={publishYear} onChange={(e) => { handleYearChange(e) }} />
                                    <SError error={errors["publishYear"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="pages">จำนวนหน้า</label>
                                    <input type="text" className="form-control" id="pages" name="pages" placeholder="กรุณากรอก" value={pages} onChange={(e) => { handlePageChange(e) }} />
                                    <SError error={errors["pages"]} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="ISBN">ISBN/ISSN</label>
                                    <input type="text" className="form-control" id="ISBN" name="ISBN" placeholder="กรุณากรอก" value={ISBN} onChange={(e) => { setISBN(e.currentTarget.value) }} />
                                    <SError error={errors["ISBN"]} />
                                </div>
                                <div className="alert alert-dark col-12" role="alert">สารบัญ</div>
                                <div className="col-12 mt-2">
                                    <div className="row">
                                        {/*<h4 className="ml-2 col-12">เอกสารผลงานด้านสิทธิมนุษยชนประกอบการพิจารณา</h4>*/}
                                        <div className="col-12">
                                            <a href='/template/TableofContent_Template.xlsx'><i className="fas fa-file-download"></i> ดาวน์โหลด Excel Template</a>
                                            <span className="text-right ml-2" style={{ display: 'inline-block' }}><i className="fas fa-file-import"></i> อัปโหลดไฟล์ Excel สำหรับนำเข้าข้อมูลสารบัญ<input type="file" onChange={fileHandler.bind(this)} style={{ "padding": "10px" }} /></span>
                                        </div>
                                        <div className="col-9">
                                            {listofContent.map((item, i) => (
                                                <div key={i} className="row mt-2">
                                                    <div className="col-7">
                                                        <label htmlFor="listofContent">หัวข้อสารบัญ</label>
                                                        <input type="text" className="form-control " id="listofContent" value={item.ContentTitle} name="listofContent" placeholder="กรุณากรอก" onChange={(e) => {
                                                            setListofContent(
                                                                listofContent.map((value, j) => {
                                                                    if (i === j) {
                                                                        value.ContentTitle = e.target.value;
                                                                    }
                                                                    return value;
                                                                })
                                                            )
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <label htmlFor="listofContent">เลขหน้า</label>
                                                        <input type="text" className="form-control " id="listofContentPage" value={item.Page} name="listofContentPage" placeholder="กรุณากรอก" onChange={(e) => {
                                                            setListofContent(
                                                                listofContent.map((value, j) => {
                                                                    if (i === j) {
                                                                        value.Page = e.target.value;
                                                                    }
                                                                    return value;
                                                                })
                                                            )
                                                        }} />
                                                    </div>
                                                    <span className="col-2" style={{ marginTop: 40 }}><a onClick={(e) => { e.preventDefault(); handleRemoveListofContent(item.ListOfContentID) }}><i className="text-danger fas fa-minus-circle"></i> ลบ</a></span>
                                                </div>
                                            ))
                                            }
                                        </div>
                                        <div className="col-3"><a href="/" onClick={(e) => { e.preventDefault(); AddListofContent(); }}><i className="fas fa-plus-circle"></i> เพิ่มหัวข้อสารบัญ</a></div>
                                    </div>

                                </div>
                                <div className="alert alert-dark col-12" role="alert">ไฟล์ดาวน์โหลด</div>
                                <div className="col-12">
                                    <label htmlFor="enname" >ไฟล์แนบ</label>
                                    <Dropzone
                                        className="input-dropzone dropzone"
                                        onDrop={acceptedFiles => {
                                            setMyFiles([...myFiles, ...acceptedFiles]);
                                            setFilesPreview(acceptedFiles.map(file => Object.assign(file, {
                                                id: 0,
                                                preview: URL.createObjectURL(file)
                                            })));
                                        }}
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.7z,.zip,.rar"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>ลากและวางไฟล์ไว้ทีนี้หรือคลิกที่ช่องนี้เพื่อเลือกไฟล์</p>
                                                </div>
                                                <aside>
                                                    <h4>Files</h4>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>ลำดับ</th>
                                                                    <th>ชื่อ</th>
                                                                    <th>ขนาดไฟล์</th>
                                                                    <th>ประเภทไฟล์</th>
                                                                    <th>จัดการ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="your-table-body-id">
                                                                {files}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="col-12">
                                    <div className="pl-4 pr-4 text-center" >
                                        <button type="button" className="btn btn-success mr-2" onClick={e => { e.preventDefault(); saveData(false); }}><i className="fas fa-save"></i> บันทึก</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={e => { e.preventDefault(); saveData(true); }}><i className="fas fa-save"></i> บันทึกและเปิด</button>
                                        <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
