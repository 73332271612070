import React from 'react'
import 'assets/styles/steper.css'

export default function Steper(props) {
    const { data } = props
    const dataLength = data.length
    return (
        <div className="followComplainStep">
            {
                data?.map((el, i) => (
                    <div key={i} className={"boxProcess " + el.status + (dataLength === i + 1 ? " ending" : " ")}>
                        {el.status === "success" ? <i className="fas fa-check" style={{ fontSize: "2.5rem" }}></i> : i + 1}
                        <div className="textTitle"  >
                            <span>{el.title.split("/")[0] + (el.title.split("/")[1] ? "/" : "")}</span><br />
                            <span>{el.title.split("/")[1]}</span>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
