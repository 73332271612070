import React, { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
export default function SearchBestPersonAward(props) {
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [bestPersonList, SetBestPersonList] = useState([]);
    const [activebestPersonList, SetActiveBestPersonList] = useState([]);

    const [yearList, SetYearList] = useState([]);
    const [selectType, setSelectType] = useState(0);
    const [selectTopic, setSelectTopic] = useState(0);
    const [yearSelect, setYearSelect] = useState(2021);
    const [topicList, setTopicList] = useState([]);

    useEffect(() => {
        utils.AccessInfo('รางวัลนักสิทธิมนุษยชนดีเด่น', '/authen/network/SearchBestPersonAward', 'F', currentUser);
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchMemberInterestTopic', { params: { status: 1 } })
            .then((response) => {
                setTopicList(response.data)
            });
        axios.get(process.env.REACT_APP_APIURL + '/api-web/searchBestPersonReward', { params: { status: 1 } }).then((response) => {
            response.data.sort(function (vote1, vote2) {
                if (vote1.ToppicTypeID < vote2.ToppicTypeID) return -1;
                if (vote1.ToppicTypeID > vote2.ToppicTypeID) return 1;
                return 0;
            });
            const yearlist = response.data.reduce((prev, cur) => prev.includes(cur.RewardYear)
                ? prev : prev.concat(cur.RewardYear), []).sort().reverse();
            SetYearList(yearlist);
            SetBestPersonList(response.data)
            if (yearlist.length > 0) {
                setYearSelect(yearlist[0])
                const active = response.data?.filter((item) => {
                    return Number(item.RewardYear) === Number(yearlist[0])
                });
                active.sort(function (a, b) {
                    if (a.ToppicTypeID < b.ToppicTypeID) { return -1; }
                    if (a.ToppicTypeID > b.ToppicTypeID) { return 1; }
                    return 0;
                })
                SetActiveBestPersonList(active);
            }
            setLoading(false);
        })
    }, []); /* <-- add this for run once*/

    const YearSelect = yearList?.map((year) => (
        <option
            key={year}
            value={year}
        >
            {String(language === "Thai" ? (Number(year) + 543) : Number(year))}
        </option>
    ));

    const NewsDIV = () => {
        let newli = null;
        let childrenlist = null;
        let sublist = null;
        let type = '';
        let loop = 0;
        let addchild = false;
        let topicsDataList = [];
        for (let j = 0; j < topicList.length; j++) {
            //console.log(selectTopic)
            if (selectTopic !== 0) {//กรองดูเฉพาะประเด็นสิทธิ

                if (selectTopic === Number(topicList[j].InterestTopicID)) {
                    let topicData = { 'topicID': topicList[j].InterestTopicID, 'title': language === "Thai" ? topicList[j].TextTH : topicList[j].TextEN.trim().length === 0 ? topicList[j].TextTH : topicList[j].TextEN, items: [] };
                    for (let i = 0; i < activebestPersonList.length; i++) {
                        let item = activebestPersonList[i];
                        var current = item.ToppicTypeID.split(",").map(Number);
                        if (current.includes(topicData.topicID)) {
                            topicData.items.push(item);
                        }
                    }
                    topicsDataList.push(topicData)
                }
            }
            else {
                let topicData = { 'topicID': topicList[j].InterestTopicID, 'title': language === "Thai" ? topicList[j].TextTH : topicList[j].TextEN.trim().length === 0 ? topicList[j].TextTH : topicList[j].TextEN, items: [] };
                for (let i = 0; i < activebestPersonList.length; i++) {
                    let item = activebestPersonList[i];
                    var current = item.ToppicTypeID.split(",").map(Number);
                    if (current.includes(topicData.topicID)) {
                        topicData.items.push(item);
                    }
                }
                topicsDataList.push(topicData)
            }
        }
        for (let j = 0; j < topicsDataList.length; j++) {
            if (topicsDataList[j].items.length > 0) {
                loop++;
                let rewardtype = null;
                rewardtype = (<><div className="col-12"><h2 className="text-blue">{language === "Thai" ? "ด้านที่" : "Article "} {loop + " " + topicsDataList[j].title}</h2></div></>);
                sublist = null;
                for(let i=0;i<topicsDataList[j].items.length;i++){
                    let item = topicsDataList[j].items[i];
                    sublist = (<>
                        {sublist}
                        <div className="row">
                            <div className="col-4">
                                <span className="logo-circle"><img src={item.PersonImgPath} alt="Avatar" /></span>
                            </div>
                            <span className="col-8 float-right text-gray">
                                <h4>
                                    <Link to={{ pathname: "/authen/network/ViewBestPerson", state: { rewardID: item.BestPersonRewardID } }}>{language === "Thai" ? item.Name : item.NameEN.trim().length === 0 ? item.Name : item.NameEN} </Link>
                                </h4>
                            </span>
                        </div>
                    </>);
                }
                childrenlist = (<>
                    {childrenlist}
                    <div className="card col-12">
                        <div className="card-body">
                            {rewardtype}
                            {sublist}
                        </div>
                    </div>
                </>)
            }
        }
        newli = (
            <>
                {childrenlist}
            </>
        );
        return (
            newli
        );
    }
    const typeRef = React.createRef();
    const topicRef = React.createRef();
    const yearRef = React.createRef();
    function SearchFilter() {
        const typeactive = bestPersonList.filter((item) => {
            if (Number(typeRef.current.value) !== 0) {
                return Number(item.IsPersonType) === Number(typeRef.current.value)
            }
            else
                return item;
        });
        const topicactive = typeactive.filter((item) => {
            if (Number(topicRef.current.value) !== 0) {
                var current = item.ToppicTypeID.split(",").map(Number);
                return current.find(element => Number(element) === Number(topicRef.current.value))
            }
            else
                return item;
        });
        const active = topicactive.filter((item) => {
            if (Number(yearRef.current.value) !== 0) {
                return Number(item.RewardYear) === Number(yearRef.current.value)
            }
            else
                return item;
        });
        SetActiveBestPersonList(active);
    }
    const SelectYear = (year) => {
        setYearSelect(Number(year))
        SearchFilter();
    }
    const SelectType = (value) => {
        setSelectType(Number(value));
        SearchFilter();
    }
    const SelectTopic = (value) => {
        setSelectTopic(Number(value))
        SearchFilter();
    }

    const TopicSelect = topicList.map((item) => (
        <option
            key={item.InterestTopicID}
            value={item.InterestTopicID}
        >
            {item.TextTH}
        </option>
    ));

    if (isLoading) {
        return (<></>);
    }
    return (
        <>
            <div id="header-wrapper">
                {/*{<Navbar />}*/}
                <ContentBanner language={language} theader={"เครือข่ายด้านสิทธิมนุษยชน"} eheader={"Network"} bannerkey="NETWORK" banner={banner}
                    path={<>
                        <span>/</span><a href="/" onClick={(e) => { e.preventDefault() }}>{language === "Thai" ? "เครือข่าย" : "Network"}</a>
                        <span>/</span><Link className="active" to={{ pathname: "/authen/network/SearchBestPersonAward", state: { language: language } }}>{language === "Thai" ? " รางวัลนักสิทธิมนุษยชนดีเด่น" : "Human Rights Best Person Award"}</Link>
                    </>}
                />
            </div>
            <main className="content-page ">
                <div className="container">
                    <section>
                        <div className="content-data SearchBestPersonAward">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sub-title">
                                        <h2 className="thai-header text-left"><span className="orang content-underline">{language === "Thai" ? "ค้นหารายชื่อองค์กรเอกชนด้านสิทธิมนุษยชนและสภาวิชาชีพ" : "Find a list of human rights and professional councils"}</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-12">
                                    <form className="formContent row">
                                        <div className="col-4">
                                            <select ref={typeRef} name="typeRef" id="typeRef" className="custom-select" value={selectType} onChange={(e) => { SelectType(e.currentTarget.value); }}>
                                                <option value="0">{language === "Thai" ? " เลือกประเภท" : "Select human rights issues for the organization network"}</option>
                                                <option value="1">{language === "Thai" ? " บุคคล" : "Person"}</option>
                                                <option value="2">{language === "Thai" ? " องค์กร" : "Organization"}</option>
                                            </select>
                                        </div>
                                        <div className="col-6">
                                            <select ref={topicRef} name="topicRef" id="topicRef" className="custom-select" value={selectTopic} onChange={(e) => { SelectTopic(e.currentTarget.value); }}>
                                                <option value="0">{language === "Thai" ? " ประเด็นสิทธิทั้งหมด" : "Select all rights issues"}</option>
                                                {TopicSelect}
                                            </select>
                                        </div>
                                        <div className="col-2">
                                            <select ref={yearRef} name="yearSelect" id="yearSelect" className="custom-select" value={yearSelect} onChange={(e) => { SelectYear(e.currentTarget.value); }}>
                                                <option value="0">{language === "Thai" ? "เลือกปี" : "Year"}</option>
                                                {YearSelect}
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {(Number(yearSelect) > 0) && (<><h2 className="thai-header text-left mt-4 mb-2 ">{language === "Thai" ? "รางวัลนักสิทธิมนุษยชนดีเด่น ประจำปี " + Number(yearSelect + 543) : "Human Rights Person Award of the Year " + Number(yearSelect)}</h2>
                                <div className="row custom-allPage">
                                    {<NewsDIV />}
                                </div></>)}
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}
