import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "../../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/network.svg';
import AttatchmentList from 'components/AttatchmentList';
import utils from 'utils/utils';

export default function ViewMOU(props) {
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [mouData, SetMOUData] = useState({});
    const [educationPerformance, setEducationPerformance] = useState([]);
    const [performanceYear, setPerformanceYear] = useState([]);
    const [activeEducationPerformance, setActiveEducationPerformance] = useState([]);
    const [activeYear, setActiveYear] = useState(0);

    const mouID = props.location.state.mouID;

    useEffect(() => {
        axios.get(process.env.REACT_APP_APIURL + '/api-web/networkMOUbyID', { params: { id: mouID } }).then((response) => {
            SetMOUData(response.data[0])
            axios.get(process.env.REACT_APP_APIURL + '/api-web/mouPerformance', { params: { id: response.data[0].CorporateMOUUUID } }).then((performance) => {
                setEducationPerformance(performance.data)
                setActiveEducationPerformance(performance.data)
                // console.log(performance.data)
                let year = [];
                for (let i = 0; i < performance.data.length; i++) {
                    if (year.indexOf(performance.data[i].PerformanceYear) === -1)
                        year.push(performance.data[i].PerformanceYear)
                }
                setPerformanceYear(year);
                setLoading(false);
            })

            setLoading(false);
        })

    }, []); /* <-- add this for run once*/

    function ViewsMap() {
        //props.history.push({ pathname: "/authen/network/MOUMap", state: { id: mouID, Lat: mouData.Lat, Lng: mouData.Lng, Name: mouData.Name } });

        window.open('https://www.google.com/maps/search/?api=1&query=' + mouData.Lat + ',' + mouData.Lng, '_blank');
    }
    const YearSelect = performanceYear.map((year, index) => (
        <option
            key={index}
            value={year}
        >
            {year}
        </option>
    ));
    if (isLoading) {
        return (<></>);
    } else {
        return (
            <>
                <div id="header-wrapper">
                    {/*{<Navbar />}*/}
                    <ContentBanner language={language} theader={"เครือข่ายด้านสิทธิมนุษยชน"} eheader={"Network"} bannerkey="NETWORK" banner={banner}
                        path={<>
                            <span>/</span><a href="/" onClick={(e) => { e.preventDefault() }}>{language === "Thai" ? "เครือข่าย" : "Network"}</a>
                            <span>/</span><Link to={{ pathname: "/authen/network/SearchMOU", state: { language: language } }}>{language === "Thai" ? " หน่วยงานตามบันทึกข้อตกลงความร่วมมือ (MOU)" : "Agencies under MOU"}</Link>
                            <span>/</span><Link className="active" to={{ pathname: "/authen/network/ViewMOU", state: { mouID: mouID } }}>{mouData.Name}</Link>
                        </>}
                    />
                </div>
                <main className="content-page ViewMOU">
                    <div className="container">
                        <section className="edit-profile row" >
                            <div className="news-activity-data">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="sub-title">
                                            <h2 className="thai-header text-center"><span className="orang content-underline">{language === "Thai" ? " หน่วยงานตามบันทึกข้อตกลงความร่วมมือ (MOU)" : "Agencies under MOU"}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-data">
                                <div className="row">
                                    <div className="col-5 logo-img">
                                        {mouData.LogoImgPath !== null && (<img src={mouData.LogoImgPath} alt="logo" />)}
                                    </div>
                                    <div className="col-7">
                                        <h2 className="text-left text-dark-blue header">{language === "Thai" ? mouData.Name : mouData.NameEN.trim().length === 0 ? mouData.Name : mouData.NameEN}</h2>
                                        <ul className="list-icon">
                                            <li className="list-icon"><i className="fas fa-map-marker-alt text-dark-blue mr-2"></i><span className="title">{language === "Thai" ? "ที่อยู่ :" : "Address :"}</span> <span className="detail">{language === "Thai" ? mouData.Location : mouData.LocationEN.trim().length === 0 ? mouData.Location : mouData.LocationEN}</span></li>
                                            <li className="list-icon"><i className="fas fa-phone-alt text-dark-blue mr-2"></i><span className="title">{language === "Thai" ? "โทรศัพท์ :" : "Phone Number :"}</span> <span className="detail">{mouData.Tel}</span></li>
                                            <li className="list-icon"><i className="far fa-envelope text-dark-blue mr-2"></i><span className="title">{language === "Thai" ? "อีเมล :" : "Email :"}</span> <span className="detail">{mouData.Email}</span></li>
                                            <li className="list-icon"><i className="fas fa-external-link-alt text-dark-blue mr-2"></i><span className="title">{language === "Thai" ? "ลิงก์ที่เกี่ยวข้อง :" : "Link :"}</span>
                                                <span className="detail"> <Link className="active" to={{ pathname: mouData.Website }} target="_blank" rel="noopener noreferrer">{mouData.Website}</Link></span>
                                            </li>
                                            <li className="list-icon"><i className="far fa-map text-dark-blue mr-2"></i><span className="title"><a href="/" onClick={(e) => { e.preventDefault(); ViewsMap();/*UpdateActivityDetail();*/ }}>{language === "Thai" ? "แผนที่" : "Map"} </a></span> </li>
                                        </ul>
                                    </div>
                                    <div className="subheader col-12 mt-4">{language === "Thai" ? "หลักการและเหตุผล" : "Rational Criterion"}</div>
                                    <div className="detail" dangerouslySetInnerHTML={{ __html: language === "Thai" ? mouData.Principle : mouData.PrincipleEN.trim().length === 0 ? mouData.Principle : mouData.PrincipleEN }}></div>
                                    <div className="subheader col-12 mt-4">{language === "Thai" ? "วัตถุประสงค์" : "Objective"}</div>
                                    <div className="detail" dangerouslySetInnerHTML={{ __html: language === "Thai" ? mouData.Objective : mouData.ObjectiveEN.trim().length === 0 ? mouData.Objective : mouData.ObjectiveEN }}></div>
                                    <div className="subheader col-12 mt-4">{language === "Thai" ? "กรอบความร่วมมือ" : "Cooperation Framework"}</div>
                                    <div className="detail" dangerouslySetInnerHTML={{ __html: language === "Thai" ? mouData.Cooperation : mouData.CooperationEN.trim().length === 0 ? mouData.Cooperation : mouData.CooperationEN }}></div>
                                    <div className="subheader col-12 mt-4">{language === "Thai" ? "เอกสารบันทึกข้อตกลงความร่วมมือ" : "Attachment"}</div>
                                    <div className="tab-pane col-12" >
                                        <AttatchmentList fileList={mouData.filelist} />
                                    </div>
                                    <div className="subheader col-12 mt-4">{language === "Thai" ? "ผลการดำเนินงาน/โครงการ/กิจกรรม ร่วมกัน" : "Join Performance"}</div>
                                    {
                                        (educationPerformance.length > 0) && (<div className="detail" style={{ width: '100%' }} >
                                            <div className="col-6 px-0 mb-2 formContent">
                                                <select id="yearSelect" name="yearSelect" className="custom-select" defaultValue="0" onChange={(e) => {
                                                    setActiveYear(e.currentTarget.value);
                                                    if (e.currentTarget.value === "0") {
                                                        setActiveEducationPerformance(educationPerformance);
                                                    }
                                                    else {
                                                        const result = educationPerformance.filter((item) => {
                                                            return item.PerformanceYear === e.currentTarget.value
                                                        })
                                                        setActiveEducationPerformance(result);
                                                    }
                                                }}>
                                                    <option value="0">{language === "Thai" ? "ผลดำเนินงานทั้งหมด" : "All years"}</option>
                                                    {YearSelect}
                                                </select>
                                            </div>
                                            <div className="col-6"></div>
                                            <div className="col-12 px-0">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>ลำดับ</th>
                                                                <th>ปี พ.ศ.</th>
                                                                <th>ชื่อเอกสาร</th>
                                                                <th>วันที่บันทึกเอกสาร</th>
                                                                <th>ดาวน์โหลด</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="your-table-body-id">
                                                            {
                                                                activeEducationPerformance.map((file, i) => (

                                                                    <tr key={i}>
                                                                        <td>{(Number(i) + 1)}</td>
                                                                        <td>{file.PerformanceYear}</td>
                                                                        <td>{file.FilenameOld.split('.')[0]}</td>
                                                                        <td>{utils.MariatoThaiDateString(file.CreatedDate)}</td>
                                                                        <td>
                                                                            <a href={file.PerformanceFile} rel="noreferrer" target="_blank"><i className="fas fa-download"></i></a>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                    {
                                        (educationPerformance.length === 0)&&(<div className="tab-pane col-12" >
                                            <div className="cardOut"><div className="cardIn">ไม่พบข้อมูล</div></div>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </>
        );
    }

}
