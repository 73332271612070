import React, { useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import {
  FacebookShareButton,
  EmailShareButton,
  LineShareButton,
  FacebookIcon,
  EmailIcon,
  LineIcon,
} from "react-share";
import Navbar from "../../components/Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from "assets/images/banner/advertise.svg";
import Footer from "components/Footer.js";
import searchicon from "assets/images/icon/search.svg";
import utils from "utils/utils";
import Pagination from "utils/Pagination";
import { authenticationService } from "services/authentication.service.js";

export default function NewsList(props) {
  const currentUser = authenticationService.currentUserValue;
  const language = localStorage.getItem("language");
  const PageSize = 12;
  //let language = props.location.state.language;
  const [selectActivityCat, SetSelectActivityCat] = useState([]);
  const [newsList, SetNewsList] = useState([]);
  //const [itemList, SetItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [popupItem, setPopupItem] = useState([]);
  useEffect(() => {
    utils.AccessInfo("ข่าวประชาสัมพันธ์", "/news/NewsList", "F", currentUser);
  }, []); /* <-- add this for run once*/
  useMemo(() => {
    axios
      .get(process.env.REACT_APP_APIURL + "/api-web/searchNewsCat", {
        params: { status: 1 },
      })
      .then((response) => {
        SetSelectActivityCat(response.data);
      });
    const formData = new FormData();
    formData.append("search", "");
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/newsList", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        SetNewsList(response.data);
        setCurrentPage(1);
      });
  }, []); /* <-- add this for run once*/
  const searchRef = React.createRef();
  const statusRef = React.createRef();
  const orderRef = React.createRef();
  const searchData = () => {
    const formData = new FormData();
    formData.append("search", searchRef.current.value);
    formData.append("catid", statusRef.current.value);
    formData.append("order", orderRef.current.value);
    axios
      .post(process.env.REACT_APP_APIURL + "/api-web/newsList", formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        SetNewsList(response.data);
        //console.log(newsList);
        setCurrentPage(0);
        setCurrentPage(1);
      });
  };
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return newsList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const ActivityCatSelect = selectActivityCat.map((newsCat) => (
    <option key={newsCat.CategoriesID} value={newsCat.CategoriesID}>
      {language === "Thai"
        ? newsCat.TitleTH
        : newsCat.TitleEN.trim().length === 0
        ? newsCat.TitleTH
        : newsCat.TitleEN}
    </option>
  ));
  const readData = (id) => {
    const formData = new FormData();
    formData.append("id", id);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateNewsViews",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {
        props.history.push({
          pathname: "/news/ReadNews/" + id,
          state: { id: id },
        });
      });
  };
  function UpdateShare(id, type) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", type);
    axios
      .post(
        process.env.REACT_APP_APIURL + "/api-web/updateNewsShare",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then((response) => {});
  }
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const Popup = (props) => {
    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={props.handleClose}>
            x
          </span>
          <div className="share-tool text-center">
            <h4>แชร์ให้เพื่อน</h4>
            <FacebookShareButton
              url={"http://118.174.14.124/news/ReadNews/" + popupItem.NewsID}
              quote={
                language === "Thai" ? popupItem.TitleTH : popupItem.TitleEN
              }
              className="Demo__some-network__share-button"
              onClick={(e) => {
                e.preventDefault();
                UpdateShare(popupItem.NewsID, "Facebook");
              }}
            >
              <FacebookIcon size={25} round />
            </FacebookShareButton>
            <LineShareButton
              url={"http://118.174.14.124/news/ReadNews/" + popupItem.NewsID}
              quote={
                language === "Thai" ? popupItem.TitleTH : popupItem.TitleEN
              }
              className="Demo__some-network__share-button"
              onClick={(e) => {
                e.preventDefault();
                UpdateShare(popupItem.NewsID, "Line");
              }}
            >
              <LineIcon size={25} round />
            </LineShareButton>
            <EmailShareButton
              url={"http://118.174.14.124/news/ReadNews/" + popupItem.NewsID}
              quote={
                language === "Thai" ? popupItem.TitleTH : popupItem.TitleEN
              }
              className="Demo__some-network__share-button"
              /*onClick={(e) => { e.preventDefault(); UpdateShare(popupItem.NewsID, 'Email')}}*/
            >
              <EmailIcon size={25} round />
            </EmailShareButton>
          </div>
        </div>
      </div>
    );
  };
  const shareData = (item) => {
    setPopupItem(item);
    togglePopup();
  };
  const NewsDIV = currentTableData.map((newsItem) => (
    <div className="col-sm-4 col-xs-12 mt-3 mb-3" key={newsItem.NewsID}>
      <div className="card cover">
        <img
          src={newsItem.ThumbnailWeb}
          className="cover"
          alt="Bookmark cover"
        />
        <div
          className="share-button"
          onClick={(e) => {
            e.preventDefault();
            shareData(newsItem);
          }}
        >
          <i className="fas fa-share-alt"></i>
        </div>
        <div className="card-body">
          <h4 className="content-title">
            {language === "Thai"
              ? newsItem.TitleTH
              : newsItem.TitleEN.trim().length === 0
              ? newsItem.TitleTH
              : newsItem.TitleEN}
          </h4>
          <div
            dangerouslySetInnerHTML={{
              __html:
                language === "Thai"
                  ? newsItem.ShortDetailTH
                  : newsItem.ShortDetailEN.trim().length === 0
                  ? newsItem.ShortDetailTH
                  : newsItem.ShortDetailEN,
            }}
            className="content-info"
          />
          <div className="mt-2 mb-2">
            <span className="time-ago">
              <i className="far fa-clock" />
              <p>
                {" "}
                {utils.MariatoThaiDateStringShort(newsItem.PublishedStartDate)}
              </p>
            </span>
            <span className="view-total float-right">
              <i className="fas fa-eye" />
              <p> {newsItem.Views}</p>
            </span>
          </div>
          <a
            href="/"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              readData(newsItem.NewsID);
            }}
          >
            {language === "Thai" ? "อ่านต่อ" : "Read more"}
          </a>
        </div>
      </div>
    </div>
  ));
  return (
    <>
      <div id="header-wrapper">
        {/*{<Navbar />}*/}

        <ContentBanner
          language={language}
          theader={"ประชาสัมพันธ์"}
          eheader={"Information news"}
          bannerkey="NEWS"
          banner={banner}
          path={
            <>
              <span>/</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {" "}
                {language === "Thai"
                  ? " ประชาสัมพันธ์และกิจกรรม "
                  : " Information news and Activities "}
              </a>
              <span>/</span>
              <Link
                id="profile-btn"
                className="active"
                to={{
                  pathname: "/news/NewsList",
                  state: { language: language },
                }}
              >
                {language === "Thai" ? "ประชาสัมพันธ์" : "Information news"}
              </Link>
            </>
          }
        />
      </div>
      <main className="content-page">
        <div className="container">
          <section className="edit-profile">
            <form className="formContent row">
              <div className="col-sm-12 col-xs-12">
                <input
                  className="form-control mr-sm-2 search"
                  ref={searchRef}
                  type="text"
                  placeholder={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                  aria-label="Search"
                />
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    searchData();
                  }}
                >
                  <img
                    src={searchicon}
                    className="searchicon"
                    alt={language === "Thai" ? "ค้นหาที่นี่" : "Search"}
                  />
                </a>
              </div>
              <div className="col-sm-6 col-xs-12">
                <select
                  name="cars"
                  className="custom-select"
                  defaultValue="default"
                  ref={statusRef}
                  onChange={(e) => {
                    searchData();
                  }}
                >
                  <option value="-1">
                    {language === "Thai" ? "เลือกหมวดหมู่" : "Select Category"}
                  </option>
                  {ActivityCatSelect}
                </select>
              </div>
              <div className="col-sm-6 col-xs-12">
                <select
                  name="cars"
                  className="custom-select"
                  ref={orderRef}
                  onChange={(e) => {
                    searchData();
                  }}
                >
                  <option value="0">
                    {language === "Thai" ? "กรองข้อมูล" : "Filter"}
                  </option>
                  <option value="1">
                    {language === "Thai" ? "ล่าสุด/แนะนำ" : "new/Recomment"}
                  </option>
                  <option value="2">
                    {language === "Thai" ? "ยอดนิยม" : "Popular"}
                  </option>
                </select>
              </div>
            </form>
            <div className="controlflex">
              <div className="textInfo">{newsList.length}</div>
              <div className="textSum"> ผลลัพธ์</div>
            </div>
            <div className="row">
              {NewsDIV}
              <div className="col-12">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={newsList.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
      {isOpen && <Popup handleClose={togglePopup} />}
      {/*<Footer />*/}
    </>
  );
}
