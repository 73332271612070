import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import utils from 'utils/utils';
import { authenticationService } from 'services/authentication.service.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
const swal = withReactContent(Swal)
export default function Activity(props) {
    const currentUser = authenticationService.currentUserValue;
    const language = localStorage.getItem("language");
    const [activeActivity, setActiveActivity] = useState([]);
    const [oldactivity, setOldActivity] = useState([]);
    const history = useHistory();
    useEffect(() => {
        const formData = new FormData();
        formData.append("userID", currentUser.UserID);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/UserActivity', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                const activityItemPrepair = [...response.data];
                /*เพิ่มฟิลด์คำตอบที่ผู้ใช้เลือก*/
                const active = [];
                const old = [];
                let now = new Date(Date.now());
                activityItemPrepair.forEach(function (item) {
                    if (new Date(item.RegisEndDate) >= now) {
                        active.push(item);
                    }
                    else {
                        old.push(item);
                    }
                });
                setActiveActivity(active);
                setOldActivity(old);
                if (response.data.length === 0) {
                    Swal.fire({
                        icon: 'info',
                        title: language==='Thai'?'แจ้งให้ทราบ':'Information',
                        text: language === "Thai" ? " ไม่พบข้อมูล " : " No information found ",
                    })
                }
                //console.log(response.data)
            });
    }, []); /* <-- add this for run once*/
    const readData = (id) => {
        const formData = new FormData();
        formData.append("id", id);
        axios.post(process.env.REACT_APP_APIURL + '/api-web/updateActivityViews', formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then((response) => {
                history.push({ pathname: "/activity/ReadActivity/" + id, state: { id: id } });
            });

    }
    const ActiveActivityList = activeActivity.map((item, i) => (
        <div className="col-md-4" key={item.ActivitiesID}>
            <div className="card">
                <img src={item.ThumbnailWeb} className="cover" alt="Activity cover" />
                <div className="card-body">
                    <span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateStringShort(new Date(item.PublishedStartDate))}</p></span>
                    <h4 className="card-content-title" style={{cursor:'pointer'}} onClick={(e) => { readData(item.ActivitiesID) }}>{item.TitleTH}</h4>
                </div>
            </div>
        </div>
    ));
    const OldActivityList = oldactivity.map((item, i) => (
        <li key={item.ActivitiesID}>
            <img src={item.ThumbnailWeb} className="cover" alt="Old Activity cover" />
            <div className="activity-list-content">
                <h4 className="card-content-title" style={{cursor:'pointer'}} onClick={(e) => { readData(item.ActivitiesID) }} >{item.TitleTH}</h4>
                <span className="time-ago"><i className="far fa-clock" /><p> {utils.MariatoThaiDateStringShort(new Date(item.PublishedStartDate))}</p></span>
            </div>
        </li>
    ));
    return (
        <div id="activity-list" className="row">
            <div className="col-12">
                <ul className="tab nav">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#now">{props.language === "Thai" ? "ปัจจุบัน" : "New Content"}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#old">{props.language === "Thai" ? "ย้อนหลัง" : "Old Content"}</a>
                    </li>
                </ul>
                <div className="tab tab-content">
                    <div className="tab-pane active" id="now">
                        <div className="row same-height">

                            {ActiveActivityList}

                        </div>
                    </div>
                    <div className="tab-pane fade" id="old">
                        <ul className="activity-list">
                            {OldActivityList}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}