import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminBanner from 'components/Banner/AdminBanner';
import { Chart, Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import html2canvas from 'html2canvas';
import pdfMake from "pdfmake/build/pdfmake";
Chart.register(ChartDataLabels);
const swal = withReactContent(Swal)
export default function ViewSurveyResult(props) {
    const [isLoading, setLoading] = useState(true);
    const language = localStorage.getItem("language");
    const [nameTH, setNameTH] = useState('');
    const [nameEN, setNameEN] = useState('');
    const [detailTH, setDetailTH] = useState("");
    const [detailEN, setDetailEN] = useState("");
    const [questionSet, setQuestionSet] = useState([]);
    const [answerSet, setAnswerSet] = useState([]);
    const [userAnswerList, setUserAnswerList] = useState([]);
    const surveyID = props.location.state.id;

    useEffect(() => {
        let questionlist = [];
        let answerlist = [];
        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSurveybyID', { params: { id: surveyID } })
            .then((response) => {
                setNameTH(response.data[0].SurveyNameTH);
                setNameEN(response.data[0].SurveyNameEN);
                setDetailTH(response.data[0].SurveyDetailTH);
                setDetailEN(response.data[0].SurveyDetailEN);


                axios.get(process.env.REACT_APP_APIURL + '/api-web/getSurveyQuestionbyID', { params: { id: surveyID } })
                    .then((response) => {
                        questionlist = response.data;

                        axios.get(process.env.REACT_APP_APIURL + '/api-web/getSurveyAnswerbyID', { params: { id: surveyID } })
                            .then((aresponse) => {
                                answerlist = aresponse.data;
                                //console.log(questionlist)
                                for (let i = 0; i < questionlist.length; i++) {
                                    let question = questionlist[i];
                                    //console.log(question)
                                    setQuestionSet((prevQuestions) => [
                                        ...prevQuestions,
                                        {
                                            SurveyQuestionID: question.SurveyQuestionID,
                                            SurveyID: question.SurveyID,
                                            QuestionType: question.QuestionType,
                                            QuestionTitle: question.QuestionTitle,
                                            AnswerNum: question.AnswerNum,
                                            AnswerList: answerlist.filter((item) => Number(item.SurveyQuestionID) === Number(question.SurveyQuestionID)),
                                        },
                                    ]);
                                    setAnswerSet((prevAnswers) => [
                                        ...prevAnswers,
                                        {
                                            SurveyQuestionID: question.SurveyQuestionID,
                                            QuestionType: question.QuestionType,
                                            SurveyID: question.SurveyID,
                                            AnswerID: 0,
                                            AnswerIDList: [],
                                            AnswerText: '',
                                        },
                                    ]);
                                }
                                axios.get(process.env.REACT_APP_APIURL + '/api-web/getSurveyUserAnswerbyID', { params: { id: surveyID } })
                                    .then((useransresponse) => {
                                        setUserAnswerList(useransresponse.data);
                                        setLoading(false);
                                    })
                            })
                    })
            })


    }, []); /* <-- add this for run once*/

    const AnswerChart = props => {
        let div = (<></>);
        let question = props.question;

        const anslist = userAnswerList.filter((item) => {
            return Number(item.SurveyQuestionID) === Number(props.questionID)
        })
        if (Number(question.QuestionType) === 1 || Number(question.QuestionType) === 3) {
            let label = [];
            let datalist = [];
            //console.log(anslist);
            question.AnswerList.map((ansitem, i) => {
                label.push(ansitem.AnswerTitle);
                const totalans = anslist.filter((item) => {
                    return Number(item.SurveyAnswerID) === Number(ansitem.SurveyAnswerID)
                })
                datalist.push(totalans.length * 100.0 / anslist.length);
            });
            //console.log(label)
            //console.log(datalist)
            const data = {
                labels: label,
                datasets: [
                    {
                        label: '%',
                        data: datalist,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.9)',
                            'rgba(54, 162, 235, 0.9)',
                            'rgba(255, 206, 86, 0.9)',
                            'rgba(75, 192, 192, 0.9)',
                            'rgba(153, 102, 255, 0.9)',
                            'rgba(255, 159, 64, 0.9)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                        datalabels: {
                            color: 'white'
                        }
                    },
                ],

            };
            const options = {
                indexAxis: 'x',
                // Elements options apply to all of the options unless overridden in a dataset
                // In this case, we are setting the border of each horizontal bar to be 2px wide
                elements: {
                    pie: {
                        borderWidth: 2,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: question.QuestionTitle,
                    },
                    datalabels: {
                        display: true,
                        color: "black",
                        align: "end",
                        padding: {
                            right: 2
                        },
                        labels: {
                            padding: { top: 10 },
                            title: {
                                font: {
                                    weight: "bold"
                                }
                            },
                            value: {
                                color: "green"
                            }
                        },
                        formatter: function (value) {
                            return "\n" + parseFloat(value).toFixed(2) + "%";
                        }
                    }
                },
            };
            div = (
                <div className="col-12 mt-2 question-item" >
                    <label className="control-label" > {anslist.length} responses</label>
                    <div className="piechart">
                        <Pie data={data} height={200}
                            width={200}
                            options={options}
                        />
                    </div>
                </div>
            )
        }
        else if (Number(question.QuestionType) === 2) {
            let label = [];
            let datalist = [];
            question.AnswerList.map((ansitem, i) => {
                label.push(ansitem.AnswerTitle);
                const totalans = anslist.filter((item) => {
                    return Number(item.SurveyAnswerID) === Number(ansitem.SurveyAnswerID)
                })
                datalist.push(totalans.length * 100.0 / anslist.length);
            });
            //console.log(label)
            //console.log(datalist)
            const data = {
                labels: label,
                datasets: [
                    {
                        label: '%',
                        data: datalist,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.9)',
                            'rgba(54, 162, 235, 0.9)',
                            'rgba(255, 206, 86, 0.9)',
                            'rgba(75, 192, 192, 0.9)',
                            'rgba(153, 102, 255, 0.9)',
                            'rgba(255, 159, 64, 0.9)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            const options = {
                indexAxis: 'y',
                // Elements options apply to all of the options unless overridden in a dataset
                // In this case, we are setting the border of each horizontal bar to be 2px wide
                elements: {
                    bar: {
                        borderWidth: 2,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: question.QuestionTitle,
                    },
                    datalabels: {
                        display: true,
                        backgroundColor: function (context) {
                            return context.dataset.backgroundColor;
                        },
                        borderRadius: 4,
                        color: 'white',
                        font: {
                            weight: 'bold'
                        },
                        formatter: function (value) {
                            return "\n" + parseFloat(value).toFixed(2) + "%";
                        },
                        padding: 6
                    }
                },
            };
            div = (
                <div className="col-12 mt-2 question-item" >
                    <label className="control-label" > {anslist.length} responses</label>
                    <div className="piechart">
                        <Bar data={data}
                            options={options}
                        />
                    </div>
                </div>
            )
        }
        else {
            div = anslist.map((ansitem, i) => (

                <div className="col-12 mt-2 question-item" key={"ans-" + ansitem.UserAnswerID}>
                    <label className="control-label" > {ansitem.UserAnswerText}</label>
                </div>
            ))
            div = (<div className="col-12 mt-2 question-item" ><label className="control-label" > {anslist.length} responses</label>{div}</div>)
        }
        return div;
    }
    const cancel = () => {
        props.history.push({ pathname: "/admin/survey/Survey" });
    };

    const printPDF = () => {
        const DOWNLOAD_FILE_NAME = "ผลแบบสอบถาม-" + nameTH + ".pdf";
        const idstmp = document.getElementsByClassName('result-data');
        const ids = [].slice.call(idstmp);
        function nextStep(sections = [], i = 0) {
            if (i >= ids.length) {
                let pdfExportSetting = {
                    content: sections
                };
                pdfMake.createPdf(pdfExportSetting).download(DOWNLOAD_FILE_NAME);
                swal.close()
                return;
            }

            html2canvas(ids[i]).then(canvas => {
                let data = canvas.toDataURL();
                let d = {
                    image: data,
                    width: 510
                }
                sections.push(d);
                nextStep(sections, ++i);
            });
        }
        nextStep();
    };
    if (isLoading) {
        return (<></>);
    }
    return (
        <section className="row" >
            <AdminBanner title={"การจัดการการประชาสัมพันธ์"} path={<>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/survey/Survey" }}>{"การจัดการแบบสอบถาม"}</Link>
                <span><i className="fas fa-angle-right"></i></span>
                <Link id="profile-btn" className="active" to={{ pathname: "/admin/survey/ViewSurveyResult", state: { id: surveyID } }}>{"ผลแบบสอบถาม"}</Link>
            </>}
            />
            <div className="row" style={{ width: '100%' }}>
                <div className="col-12">
                    <div className="pl-4 pr-4 text-right" >
                        <button type="button" className="btn btn-success " onClick={e => {
                            e.preventDefault();
                            Swal.fire({
                                title: 'Please wait.',
                                imageUrl: "/images/SVKl.gif",
                                showConfirmButton: false,
                                didOpen: () => {
                                    /*Swal.showLoading()*/
                                    setTimeout(function () { printPDF() }, 100);
                                },
                            })
                        }}
                        ><i className="far fa-times-circle"></i> Export</button>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="content-data" style={{ padding: 15 }}>
                    <div id="result" className="row result-data">
                        <h1 className="survey-header">{language === "Thai" ? nameTH : nameEN.trim().length === 0 ? nameTH : nameEN}</h1>
                        <div className="survey-detail" dangerouslySetInnerHTML={{ __html: language === "Thai" ? detailTH : detailEN.trim().length === 0 ? detailTH : detailEN }} />
                    </div>

                    <div className="row">
                        <div className="col-12" >
                            {questionSet.map((item, i) => (
                                <div id="question" key={"question-data-" + item.SurveyQuestionID} className="row mt-2 question-item  result-data">
                                    <div className="col-12">
                                        <h2>{i + 1}. {item.QuestionTitle}</h2>
                                    </div>
                                    <AnswerChart question={item} questionID={item.SurveyQuestionID} />
                                </div>
                            ))
                            }
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="pl-4 pr-4 text-center" >
                                <button type="button" className="btn btn-dark" onClick={e => { e.preventDefault(); cancel(); }}><i className="far fa-times-circle"></i> ยกเลิก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
