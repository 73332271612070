import React, { useCallback, useState, useEffect, useMemo } from "react";
//import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import Navbar from "./Navbar/Navbar.jsx";
import ContentBanner from "components/Banner/ContentBanner.js";
import banner from 'assets/images/banner/advertise.svg';
import { Rating } from '@material-ui/lab';
import utils from 'utils/utils';
import HTMLFlipBook from "react-pageflip";
// import { Document, Outline, Page as ReactPdfPage, View, Text, pdfjs } from 'react-pdf';
import { authenticationService } from 'services/authentication.service.js';
// import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import ReactPlayer from 'react-player'
import { useDebounce } from "use-debounce";
import addbookmark from "assets/images/icon/addbookmark.svg";
import viewbookmark from "assets/images/icon/viewbookmark.svg";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const width = 600;
const height = 870;
const Page = React.forwardRef(({ pageNumber }, ref) => {
    return (
        <div ref={ref}>
            {/* <ReactPdfPage pageNumber={pageNumber} height={height} /> */}
        </div>
    );
});
function highlightPattern(text, pattern) {
    const splitText = text.split(pattern);

    if (splitText.length <= 1) {
        return text;
    }

    const matches = text.match(pattern);

    return splitText.reduce((arr, element, index) => (matches[index] ? [
        ...arr,
        element,
        <mark key={index}>
            {matches[index]}
        </mark>,
    ] : [...arr, element]), []);
}

export default function PDFView(props) {
    const elibID = props.bookid;
    const currentUser = authenticationService.currentUserValue;
    const [isLoading, setLoading] = useState(true);
    const [elibData, SetElibData] = useState({});
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [useID, setUserID] = useState(null);
    const [searchText, setSearchText] = useState("");
    const flipbook = React.useRef(null);
    useEffect(() => {
        if (currentUser != null) {
            setUserID(currentUser.UserID);
        }
        axios.get(process.env.REACT_APP_APIURL + '/api-web/elibrarybyID', { params: { id: elibID, userID: useID } }).then((response) => {
            SetElibData(response.data[0])
            setLoading(false)
        })

    }, []);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);

    }
    var makeTextRenderer = searchText => textItem => highlightPattern(textItem.str, searchText);
    const pageList = () => {
        let content = [];
        for (let i = 0; i < numPages; i++) {
            //const item = animals[i];
            content.push(<Page key={"Page" + i} pageNumber={i + 1} renderInteractiveForms={true} customTextRenderer={makeTextRenderer(searchText)} />);
        }
        //const bookmarkpage = localStorage.getItem(props.location.state.id);
        //console.log(bookmarkpage)
        return content;
    };
    const setPageBookmark = (bookID, page) =>{
        localStorage.setItem(bookID, JSON.stringify(page));
    };
    const openBookmark = () => {
        const page = localStorage.getItem(props.location.state.id);
        if (page !== null) {
            flipbook.current.pageFlip().turnToPage(Number(page));
            setPageNumber(page);
        }
    }
    const onFlip = useCallback((e) => {
        setPageNumber(e.data);
        //setPageBookmark(props.location.state.id, e.data);
        //console.log('Current page: ' + e.data);
    }, []);
    const customEnterAnimation = {
        from: { transform: 'scale(0.5, 1)' },
        to:   { transform: 'scale(1, 1)' }
      };
    if (isLoading) {
        return (<></>);
    }

    return (
        <>
            {/* <Document  onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
                <HTMLFlipBook ref={flipbook} width={width} height={height} showCover={true} size={"fixed"} autoSize={true}
                    minWidth={315}
                    maxWidth={1000}
                    minHeight={400}
                    maxHeight={1533}
                    maxShadowOpacity={0.5}
                    mobileScrollSupport={true}
                    onFlip={onFlip}
                    enterAnimation={customEnterAnimation}>
                    {pageList()}
                </HTMLFlipBook>
            </Document> */}
            <div className="viewer-toolbar" id="toolbar">
                <img src={addbookmark} className="mr-2 bookmarkbutton" style={{ width: '32px', cursor: 'pointer', float: 'right' }} onClick={(e) => { e.preventDefault(); setPageBookmark(props.location.state.id, pageNumber) }} />
                <img src={viewbookmark} className="mr-2 bookmarkbutton" style={{ width: '32px', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); openBookmark() }} />
            </div>
        </>)

}
